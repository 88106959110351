export const getHeightFromAspectRatio = (
  width: number,
  aspectRatio: string,
) => {
  const [aspectWidth, aspectHeight] = aspectRatio.split(':').map(Number);

  return (width / aspectWidth) * aspectHeight;
};

export const getHeightFromAspectRatioLabel = (
  width: number,
  aspectRatio: string,
) => {
  return String(Math.ceil(getHeightFromAspectRatio(width, aspectRatio)));
};

export const getAspectRatioNumber = (aspectRatio: string) => {
  return aspectRatio
    .split(':')
    .map(Number)
    .reduce((a, b) => a / b);
};
