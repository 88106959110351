import { makerStore, useMakerStore } from '@web/store/maker/state';
import { useHotkeys } from 'react-hotkeys-hook';

export const useMakerHotkeys = () => {
  const { dialogs } = useMakerStore();

  const dialogsClosed = Object.values(dialogs).every((dialog) => !dialog);

  useHotkeys(
    ['d', 'w'],
    () => {
      const { page, perPage, frames } = makerStore;

      if (page < Math.ceil(frames.length / perPage)) {
        makerStore.page++;
      }
    },
    { enabled: dialogsClosed },
  );

  useHotkeys(
    ['a', 's'],
    () => {
      if (makerStore.page > 1) {
        makerStore.page--;
      }
    },
    { enabled: dialogsClosed },
  );

  useHotkeys(
    'v',
    () => {
      makerStore.dialogs.sceneEditor = !makerStore.dialogs.sceneEditor;
    },
    { enabled: dialogsClosed || !!dialogs.sceneEditor },
  );

  useHotkeys(
    'e',
    () => {
      if (!makerStore.frames?.length) return;

      makerStore.sceneEditorFrameIndex = Math.max(
        0,
        Math.min(
          (makerStore.sceneEditorFrameIndex || 0) + 1,
          makerStore.frames.length - 1,
        ),
      );
    },
    { enabled: !!dialogs.sceneEditor },
  );

  useHotkeys(
    'q',
    () => {
      if (!makerStore.frames?.length) return;

      makerStore.sceneEditorFrameIndex = Math.max(
        0,
        Math.min(
          (makerStore.sceneEditorFrameIndex || 0) - 1,
          makerStore.frames.length - 1,
        ),
      );
    },
    { enabled: !!dialogs.sceneEditor },
  );

  useHotkeys(
    'f',
    () => {
      makerStore.dialogs.frameEffects = !makerStore.dialogs.frameEffects;
    },
    { enabled: dialogsClosed || !!dialogs.frameEffects },
  );

  useHotkeys(
    't',
    () => {
      makerStore.dialogs.frameActions = !makerStore.dialogs.frameActions;
    },
    { enabled: dialogsClosed || !!dialogs.frameActions },
  );

  useHotkeys(
    'i',
    () => {
      makerStore.dialogs.aliases = !makerStore.dialogs.aliases;
    },
    { enabled: dialogsClosed || !!dialogs.aliases },
  );

  useHotkeys(
    'r',
    () => {
      makerStore.dialogs.projectPairs = !makerStore.dialogs.projectPairs;
    },
    { enabled: dialogsClosed || !!dialogs.projectPairs },
  );

  useHotkeys(
    'p',
    () => {
      makerStore.dialogs.preview = !makerStore.dialogs.preview;
    },
    { enabled: dialogsClosed || !!dialogs.preview },
  );

  useHotkeys(
    'o',
    () => {
      makerStore.dialogs.projectOptions = !makerStore.dialogs.projectOptions;
    },
    { enabled: dialogsClosed || !!dialogs.projectOptions },
  );

  useHotkeys(
    'ctrl+s',
    () => {
      makerStore.dialogs.saveProject = !makerStore.dialogs.saveProject;
    },
    { enabled: dialogsClosed || !!dialogs.saveProject, preventDefault: true },
  );

  useHotkeys(
    'ctrl+l',
    () => {
      makerStore.dialogs.loadProject = !makerStore.dialogs.loadProject;
    },
    { enabled: dialogsClosed || !!dialogs.loadProject, preventDefault: true },
  );

  useHotkeys(
    'ctrl+alt+n',
    () => {
      makerStore.dialogs.newProject = !makerStore.dialogs.newProject;
    },
    { enabled: dialogsClosed || !!dialogs.newProject, preventDefault: true },
  );
};
