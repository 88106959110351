import { Character_Side } from '@shared/types';
import { useEffect, useMemo } from 'react';
import { usePlayer } from './providers/PlayerProvider';
import { GallerySprite } from './ui/GallerySprite';

export const PlayerGallery = () => {
  const {
    state: { galleryImageUrls, galleryImageUrlsAj },
    actions: { update },
    frame: { character },
  } = usePlayer();

  const show = useMemo(() => {
    return character?.side === Character_Side.Gallery;
  }, [character]);

  useEffect(() => {
    if (!character) {
      return;
    }

    if (character.galleryImageUrl) {
      update({
        galleryImageUrls: {
          ...galleryImageUrls,
          [character.side as Character_Side]: character.galleryImageUrl,
        },
      });
    }

    if (character.galleryAJImageUrl) {
      update({
        galleryImageUrlsAj: {
          ...galleryImageUrlsAj,
          [character.side as Character_Side]: character.galleryAJImageUrl,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [character]);

  if (!show) {
    return null;
  }

  return character?.id === 28
    ? Object.entries(galleryImageUrls).map(([side, url]) => (
        <GallerySprite key={side} url={url} />
      ))
    : Object.entries(galleryImageUrlsAj).map(([side, url]) => (
        <GallerySprite key={side} url={url} />
      ));
};
