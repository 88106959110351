import Grid, { GridProps } from '@mui/material/Grid';
import React from 'react';

type DualGridPickerProps = {
  children: React.ReactNode;
  mobileMb?: number;
} & GridProps;

export const DualGridPicker = ({
  children,
  mobileMb,
  ...props
}: DualGridPickerProps) => {
  const [firstChild, secondChild] = React.Children.toArray(children).slice(
    0,
    2,
  );

  return (
    <Grid container {...props}>
      <Grid
        item
        xs={12}
        sm={6}
        pr={{ xs: 0, sm: 1 }}
        mb={{ xs: mobileMb ?? 1, sm: 0 }}
      >
        {firstChild}
      </Grid>
      <Grid item xs={12} sm={6} pl={{ xs: 0, sm: 1 }}>
        {secondChild}
      </Grid>
    </Grid>
  );
};
