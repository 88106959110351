import { BoxProps, styled } from '@mui/material';
import { Image } from '../../common/ui/Image';
import { ZIndex } from '../utils/constants';

type SpeechBubbleProps = {
  url: string;
} & BoxProps;

export const SpeechBubble = ({ url, ...props }: SpeechBubbleProps) => {
  // TODO: match width/height with existing old project (character isCustom or not)
  return <SpeechBubbleStyled src={url} alt="Speech bubble" {...props} />;
};

const SpeechBubbleStyled = styled(Image)(({ theme }) => {
  return {
    width: '70%',
    height: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: ZIndex.SpeechBubble,
    userSelect: 'none',
    pointerEvents: 'none',
  };
});
