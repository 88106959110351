export const presetDialogueBoxes = Object.freeze([
  {
    text: 'Classic',
    value: '0',
    hint: 'Text will auto resize if needed. Nameplate has a flexible width',
  },
  {
    text: 'Trilogy',
    value: '1',
    hint: 'Text will not auto resize, it will scroll down if needed. Nameplate has a fixed width',
  },
  {
    text: 'Apollo Justice',
    value: '2',
    hint: 'Text will not auto resize, it will scroll down if needed. Nameplate has a fixed width',
  },
  {
    text: 'The Great Ace Attorney',
    value: '3',
    hint: 'Text will not auto resize, it will scroll down if needed. Nameplate has a fixed width',
  },
  {
    text: 'DS Style',
    value: '4',
    hint: 'Text will not auto resize, it will scroll down if needed. Nameplate has a fixed width',
  },
]);
