import { useCallback, useEffect, useRef } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { usePlayer } from '../providers/PlayerProvider';

type usePlayerInteractionState = {
  leftClickTime: number;
  skipTimeout: ReturnType<typeof setTimeout> | null;
  skipInterval: ReturnType<typeof setInterval> | null;
  skipping: boolean;
};

const defaultState: usePlayerInteractionState = {
  leftClickTime: 0,
  skipTimeout: null,
  skipInterval: null,
  skipping: false,
};

export const usePlayerInteraction = () => {
  const state = useRef(proxy<usePlayerInteractionState>(defaultState)).current;
  const snapshot = useSnapshot(state);
  const skipThreshold = 225;

  const {
    actions,
    isEnded,
    playerDefaults: { enableSkipping },
  } = usePlayer();

  const beginSkipping = useCallback(() => {
    if (isEnded) return;

    state.skipping = true;
  }, [isEnded, state]);

  const generalEnd = useCallback(() => {
    state.skipping = false;
  }, [state]);

  const startInteraction = useCallback(() => {
    state.leftClickTime = Date.now();

    if (snapshot.skipInterval) {
      clearInterval(snapshot.skipInterval);
    }

    if (snapshot.skipTimeout) {
      clearTimeout(snapshot.skipTimeout);
    }

    if (!enableSkipping) return;

    state.skipTimeout = setTimeout(beginSkipping, skipThreshold);
  }, [
    beginSkipping,
    enableSkipping,
    snapshot.skipInterval,
    snapshot.skipTimeout,
    state,
  ]);

  const endInteraction = useCallback(
    (
      event:
        | React.MouseEvent<HTMLDivElement>
        | React.PointerEvent<HTMLDivElement>,
    ) => {
      generalEnd();

      if (snapshot.skipTimeout) {
        clearTimeout(snapshot.skipTimeout);
      }

      const delay = Date.now() - snapshot.leftClickTime;

      const rect = (event.currentTarget as HTMLElement).getBoundingClientRect();
      const clientX = event.clientX;
      const clientY = event.clientY;

      const x = ((clientX - rect.left) / rect.width) * 100;
      const y = ((clientY - rect.top) / rect.height) * 100;

      if (delay < skipThreshold || !enableSkipping) {
        actions.onClick({
          x,
          y,
          direction: clientX > rect.left + rect.width / 2 ? 'next' : 'previous',
        });
      }
    },
    [
      actions,
      enableSkipping,
      generalEnd,
      snapshot.leftClickTime,
      snapshot.skipTimeout,
    ],
  );

  const handleMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (e.target === e.currentTarget && e.button === 0) {
        startInteraction();
      }
    },
    [startInteraction],
  );

  const handleTouchStart = useCallback(
    (e: React.PointerEvent<HTMLDivElement>) => {
      if (e.target === e.currentTarget) {
        startInteraction();
      }
    },
    [startInteraction],
  );

  const handleMouseUp = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (e.target === e.currentTarget && e.button === 0) {
        endInteraction(e);
      }
    },
    [endInteraction],
  );

  const handleTouchEnd = useCallback(
    (e: React.PointerEvent<HTMLDivElement>) => {
      if (e.target === e.currentTarget) {
        endInteraction(e);
      }
    },
    [endInteraction],
  );

  const preventContextMenu = (
    e: React.MouseEvent<HTMLDivElement> | React.PointerEvent<HTMLDivElement>,
  ) => {
    if (e.target === e.currentTarget) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (!snapshot.skipping) {
      clearInterval(state.skipInterval!);
      return;
    }

    state.skipInterval = setInterval(() => {
      if (snapshot.skipping && !isEnded) {
        actions.onClick({
          x: 50,
          y: 50,
          direction: 'next',
        });
      }
    }, 100);

    return () => clearInterval(state.skipInterval!);
  }, [actions, isEnded, snapshot.skipping, state]);

  useEffect(() => {
    document.addEventListener('mouseup', generalEnd);
    document.addEventListener('touchend', generalEnd);

    return () => {
      document.removeEventListener('mouseup', generalEnd);
      document.removeEventListener('touchend', generalEnd);
    };
  }, [generalEnd]);

  return {
    handleMouseDown,
    handleMouseUp,
    handleTouchStart,
    handleTouchEnd,
    preventContextMenu,
  };
};
