import { Box } from '@mui/material';
import React, { forwardRef } from 'react';
import { FixedSizeList } from 'react-window';

type VirtualizedListboxProps = {
  children: React.ReactNode;
  height: number;
  itemHeight: number;
} & React.HTMLAttributes<HTMLElement>;

export const VirtualizedListbox = forwardRef<
  HTMLDivElement,
  VirtualizedListboxProps
>((props, ref) => {
  const { children, itemHeight, height, ...other } = props;
  const items = React.Children.toArray(children);
  const itemCount = items.length;

  return (
    <Box ref={ref} {...other} sx={{ paddingBlock: '0 !important' }}>
      <FixedSizeList
        height={height}
        width="100%"
        itemSize={itemHeight}
        itemCount={itemCount}
        outerElementType="div"
        innerElementType="div"
      >
        {({ index, style }) => (
          <div style={style} key={index}>
            {items[index]}
          </div>
        )}
      </FixedSizeList>
    </Box>
  );
});
