import * as yup from 'yup';

const validateYupUrl = (value: string | undefined) => {
  if (!value || value.startsWith('/')) {
    return true;
  }

  return /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/i.test(value);
};

export const urlValidation = (message = 'Invalid URL') =>
  yup.string().test('is-url', message, validateYupUrl);
