import { CaseFrame } from '@shared/types';
import { presetSpeechBlips } from '@web/data/preset-speech-blips';
import { frameUtils } from '@web/utils/frame';
import { useMemo } from 'react';
import { useCharacter } from './useCharacter';

export const useSpeechBlip = (frame: CaseFrame | undefined) => {
  const character = useCharacter(frame);

  const speechBlipUrl = useMemo(
    () =>
      frame
        ? frameUtils.getSpeechBlipUrl(frame, character)
        : presetSpeechBlips[0].url,
    [character, frame],
  );

  return speechBlipUrl;
};
