import { CaseFrame } from '@shared/types';
import { PopupDto } from '@web/api/api';
import { Thumbnail } from '@web/components/thumbnail/Thumbnail';
import { usePreviewBackground } from '@web/store/assets/state';
import { memo } from 'react';
import { AssetTabMobile } from '../AssetTabMobile';

export const PopupTabMobile = () => {
  return <AssetTabMobile type="popup" PreviewComponent={PreviewPopup} />;
};

const PreviewPopup = memo(({ value }: { value: PopupDto | null }) => {
  const previewBackground = usePreviewBackground();

  if (value === null) {
    return null;
  }

  const frame: CaseFrame = {
    id: -1,
    text: '',
    popupId: value.id,
    backgroundId: previewBackground?.id,
  };

  return <Thumbnail frame={frame} serverThumbnails={false} />;
});
