import {
  Chip,
  Paper,
  PaperProps,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { CharacterDto } from '@web/api/api';
import { useToggleValue } from '@web/hooks/useToggleValue';
import { useAssetStore } from '@web/store/assets/state';
import { Character } from '@web/types/project';
import React, { forwardRef, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import {
  AutocompleteWithFavorites,
  StyledAutocompletePopper,
} from '../../common/form/AutocompleteWithFavorites';
import { RenderAutocompleteInput } from '../../common/ui/RenderAutocompleteInput';
import { BaseRenderAutocompleteOptionWithFavorite } from '../../common/ui/RenderAutocompleteOptionWithFavorite';
import { VirtualizedListbox } from '../../common/ui/VirtualizedListbox';
import { useAssetOptions } from '../hooks/useAssetOptions';
import { AssetImageIcon } from './AssetImageIcon';

type CharacterPickerProps = {
  value: number | null;
  onChange: (value: number | null) => void;
  customFilter?: (character: CharacterDto) => boolean;
};

export const CharacterPicker = ({
  value,
  onChange,
  customFilter,
}: CharacterPickerProps) => {
  const {
    character: { preset, user },
  } = useAssetStore();

  const [filter, setFilter] = useToggleValue<'preset' | 'custom' | undefined>(
    undefined,
  );

  const userList = useMemo(
    () => user.filter((f) => f.poses?.length > 0),
    [user],
  );

  const { selectedValue, sortedOptions } = useAssetOptions({
    value,
    filter,
    customFilter,
    type: 'character',
    presetList: preset,
    userList: userList,
    allowNull: true,
  });

  return (
    <AutocompleteWithFavorites
      type="character"
      label=""
      size="small"
      PopperComponent={StyledAutocompletePopper}
      ListboxComponent={CharacterVirtualizedListBox}
      ListboxProps={{ style: { maxHeight: '364px' } }}
      PaperComponent={CharacterPickerPaper}
      slotProps={{
        paper: {
          filter,
          setFilter,
          sx: { width: { xs: '100%', sm: '450px' } },
        } as PaperProps,
      }}
      value={selectedValue}
      onChange={(_, value) => onChange(value?.id ?? null)}
      options={sortedOptions}
      renderInput={(params) => (
        <RenderAutocompleteInput
          variant="outlined"
          placeholder="Character"
          params={params}
        />
      )}
      renderOption={(
        props,
        option,
        optionState,
        favoriteList,
        handleFavoriteToggle,
      ) => {
        const character = option as Character;

        return (
          <BaseRenderAutocompleteOptionWithFavorite
            key={option.id}
            props={props}
            optionId={option.id}
            optionState={optionState}
            favoriteList={favoriteList}
            handleFavoriteToggle={handleFavoriteToggle}
          >
            <RenderCharacterOption character={character} />
          </BaseRenderAutocompleteOptionWithFavorite>
        );
      }}
      fullWidth
      disableClearable
    />
  );
};

type CharacterPickerPaperProps = {
  filter: 'preset' | 'custom' | undefined;
  setFilter: (filter: 'preset' | 'custom' | undefined) => void;
} & PaperProps;

const CharacterPickerPaper = (props: CharacterPickerPaperProps) => {
  const { filter, setFilter, ...other } = props;

  return (
    <Paper {...other} onMouseDown={(event) => event.preventDefault()}>
      <Stack
        p={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Stack direction="row" spacing={1}>
          <Chip
            label="Preset"
            color={filter === 'preset' ? 'info' : 'default'}
            onClick={() => setFilter('preset')}
            size="small"
            clickable
          />
          <Chip
            label="Custom"
            color={filter === 'custom' ? 'info' : 'default'}
            onClick={() => setFilter('custom')}
            size="small"
            clickable
          />
        </Stack>

        {/* <MobileSearchField /> */}
      </Stack>

      {props.children}
    </Paper>
  );
};

const CharacterVirtualizedListBox = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>((props, ref) => {
  return (
    <VirtualizedListbox ref={ref} {...props} itemHeight={52} height={52 * 7}>
      {props.children}
    </VirtualizedListbox>
  );
});

const RenderCharacterOption = ({ character }: { character: Character }) => {
  const image = character.isPreset
    ? character?.poses?.[0]?.iconUrl
    : character.iconUrl;

  return (
    <Stack direction="row" spacing={2} alignItems="center" overflow="hidden">
      <AssetImageIcon iconUrl={image} alt={character.name} />

      <Stack overflow="hidden">
        <Typography variant="body2" noWrap={true} color="textPrimary">
          {character.name}
        </Typography>

        <Typography variant="caption" noWrap={true} color="textSecondary">
          {character.side}
        </Typography>
      </Stack>
    </Stack>
  );
};

// TODO: this causes issues with the menu closing...
const MobileSearchField = () => {
  const ref = React.useRef<HTMLInputElement>(null);

  if (!isMobile) {
    return null;
  }

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    ref.current?.focus();
  };

  return (
    <TextField
      inputRef={ref}
      placeholder="Search"
      size="small"
      onClick={handleClick}
      fullWidth
    />
  );
};
