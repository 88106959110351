import { Alias, Character_Side, Options } from '@shared/types';
import { BackgroundDto, CaseProjectDto, SceneProjectDto } from '@web/api/api';
import { createContext, useContext } from 'react';
import { useFrame } from '../hooks/useFrame';
import { usePlayerAudio } from '../hooks/usePlayerAudio';
import { usePlayerLoading } from '../hooks/usePlayerLoading';
import { usePlayerUi } from '../hooks/usePlayerUi';
import { useTimeline } from '../hooks/useTimeline';
import { PlayerDefaults } from '../types/playerDefaults';
import { EvidenceState } from '../types/state';

export type Step =
  | 'start'
  | 'fade_out'
  | 'fade_in'
  | 'speech_bubble'
  | 'transition'
  | 'transition_wait'
  | 'character'
  | 'delayed_actions'
  | 'dialogue'
  | 'finished';

export type PlayerContextType = {
  state: {
    steps?: Step[];
    stepIndex?: number;
    characterStep?: 'idle' | 'talking' | 'animation';
    characterAnimationPlaying?: boolean;
    characterTalking?: boolean;
    options?: Options;
    startedPlaying?: boolean;
    isPlaying?: boolean;
    musicId?: number;
    evidence?: EvidenceState;
    presetPopupsImageUrls?: string[];
    showNextButton?: boolean;
    showDialoguebox?: boolean;
    skipping?: boolean;
    galleryImageUrls: Record<Character_Side, string>;
    galleryImageUrlsAj: Record<Character_Side, string>;
    isAutoplay?: boolean;
  };
  step?: Step;
  playerDefaults: PlayerDefaults;
  aliases: Alias[];
  frame: ReturnType<typeof useFrame>;
  playerUi: ReturnType<typeof usePlayerUi>;
  playerLoading: ReturnType<typeof usePlayerLoading>;
  playerContainerRef: React.RefObject<HTMLDivElement>;
  actions: {
    init: (
      project: SceneProjectDto | CaseProjectDto,
      startingFrameId?: number,
    ) => void;
    start: () => void;
    next: () => void;
    update: (data: Partial<PlayerContextType['state']>) => void;
    reset: () => void;
    nextStep: () => void;
    playMusic: (url: string, volume: number) => void;
    stopMusic: () => void;
    onClick: (args: PlayerClickType) => void;
  };
  timeline: ReturnType<typeof useTimeline>;
  audio: ReturnType<typeof usePlayerAudio>;
  defaultBackground: BackgroundDto;
  isEnded?: boolean;
};

export type PlayerStateType = PlayerContextType['state'];
export type PlayerActionsType = PlayerContextType['actions'];

export const PlayerContext = createContext<PlayerContextType>(
  {} as PlayerContextType,
);

export type PlayerClickType = {
  x: number;
  y: number;
  direction: 'next' | 'previous';
  isRightClick?: boolean;
};

export const usePlayer = (): PlayerContextType => {
  const context = useContext(PlayerContext);

  if (!context) {
    throw new Error('usePlayer must be used within a PlayerProvider');
  }

  return context;
};
