import { CharacterDto } from '@web/api/api';
import { createContext, useContext } from 'react';

export type CharacterEditorContextValue = {
  characterId: number;
  characterIndex: number;
  character: CharacterDto;
};

const CharacterEditorContext = createContext<
  CharacterEditorContextValue | undefined
>(undefined);

export const useCharacterEditor = () => {
  const context = useContext(CharacterEditorContext);

  if (!context) {
    throw new Error(
      'useCharacterEditor must be used within a CharacterEditorProvider',
    );
  }

  return context;
};

export const CharacterEditorProvider = CharacterEditorContext.Provider;

// poses and speech bubbles

export type CharacterExtraEditorContextValue = {
  expanded: number | false;
  previewIndex: number | undefined;
  setPreviewIndex: (index: number | undefined) => void;
  scrollTop: {
    value: number;
  };
};

const CharacterExtraEditorContext = createContext<
  CharacterExtraEditorContextValue | undefined
>(undefined);

export const useCharacterExtraEditor = () => {
  const context = useContext(CharacterExtraEditorContext);

  if (!context) {
    throw new Error(
      'useCharacterExtraEditor must be used within a CharacterEditorProvider',
    );
  }

  return context;
};

export const CharacterExtraEditorProvider =
  CharacterExtraEditorContext.Provider;
