import {
  Archive,
  Delete,
  Edit,
  Unarchive,
  Visibility,
} from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Pagination,
  Stack,
  TablePagination,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  CaseDto,
  PaginatedResponseOfCaseDto,
  PaginatedResponseOfSceneDto,
  SceneDto,
} from '@web/api/api';
import { ApiClient } from '@web/api/api-client';
import { useAnchorMenu } from '@web/hooks/useAnchorMenu';
import { rootStore, useRootStore } from '@web/store/root/state';
import { dashify } from '@web/utils/utils';
import { debounce } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { memo, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { proxy, useSnapshot } from 'valtio';
import { AppBarButton } from '../ui/AppBarButton';
import {
  DraggableDialog,
  DraggableDialogDefaultTitle,
} from '../ui/DraggableDialog';
import { Popper } from '../ui/Popper';

const state = proxy({
  type: 'scene' as 'scene' | 'case',
  objections: [] as
    | PaginatedResponseOfSceneDto['data']
    | PaginatedResponseOfCaseDto['data'],
  meta: {
    total: 0,
    page: 1,
    perPage: 20,
  } as PaginatedResponseOfSceneDto['meta'],
  loading: false,
  archived: false,
  deleteIndex: undefined as number | undefined,
  deleteLoading: false,
});

export const MyObjectionsDialog = () => {
  const {
    dialogs: { myObjections },
  } = useRootStore();

  const handleClose = () => {
    rootStore.dialogs.myObjections = false;
  };

  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <DraggableDialog
      open={myObjections}
      onClose={handleClose}
      titleComponent={<MyObjectionsDialogTitle handleClose={handleClose} />}
      maxWidth="sm"
      fullscreen={fullscreen}
      persist
      fullWidth
    >
      <MyObjectionsDialogContent />
    </DraggableDialog>
  );
};

const MyObjectionsDialogContent = memo(() => {
  const { loading } = useSnapshot(state);

  return loading && !state.objections.length ? <Loading /> : <ObjectionsList />;
});

const ObjectionsList = memo(() => {
  const { objections } = useSnapshot(state);

  return (
    <List sx={{ py: 0 }} dense>
      <PaginationUI />

      {objections.map((objection, index) => (
        <ObjectionListItem key={objection.id} index={index} />
      ))}
    </List>
  );
});

const PaginationUI = memo(() => {
  const { meta, loading } = useSnapshot(state);
  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.down(400));

  if (!meta.total || meta.total <= meta.perPage) return null;

  const count = Math.ceil(meta.total / meta.perPage);

  const handleChange = (_, page: number) => {
    state.meta.page = page;

    fetchObjections();
  };

  return (
    <ListSubheader
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {breakpoint ? (
        <TablePagination
          component="div"
          count={meta.total}
          page={meta.page - 1}
          onPageChange={(e, v) => handleChange(e, v + 1)}
          rowsPerPage={meta.perPage}
          rowsPerPageOptions={[]}
          sx={{ '.MuiToolbar-root': { minHeight: 48 } }}
        />
      ) : (
        <Pagination
          variant="text"
          shape="rounded"
          color="standard"
          count={count}
          onChange={handleChange}
          page={meta.page}
          disabled={loading}
        />
      )}
    </ListSubheader>
  );
});

const ObjectionListItem = ({ index }: { index: number }) => {
  const { objections, deleteIndex } = useSnapshot(state);
  const objection = objections[index];

  return (
    <ListItem key={objection.id} divider={index < objections.length - 1}>
      <ListItemText
        primary={
          deleteIndex !== index ? (
            <Stack direction="row" spacing={1} alignItems="center">
              <ObjectionTitleEditor index={index} />
              <ObjectionActions index={index} />
            </Stack>
          ) : (
            <ObjectionConfirmDelete />
          )
        }
        secondary={
          <Typography component="span" variant="body2" color="text.secondary">
            {objection.views} views | ID: {objection.id}
          </Typography>
        }
      />
    </ListItem>
  );
};

const ObjectionConfirmDelete = () => {
  const { deleteLoading } = useSnapshot(state);

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="body2">
        Are you sure you want to delete this objection?
      </Typography>

      <Stack direction="row" spacing={1}>
        <Button
          size="small"
          color="error"
          variant="contained"
          onClick={confirmDelete}
          disabled={deleteLoading}
        >
          Confirm Delete
        </Button>

        <Button
          size="small"
          color="error"
          variant="outlined"
          onClick={cancelDelete}
          disabled={deleteLoading}
        >
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};

const ObjectionActions = ({ index }: { index: number }) => {
  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.down(400));

  return !breakpoint ? (
    <ObjectionActionsDesktop index={index} />
  ) : (
    <ObjectionActionsMobile index={index} />
  );
};

const ObjectionActionsDesktop = ({ index }: { index: number }) => {
  const { objections } = useSnapshot(state);
  const objection = objections[index];

  const handleArchive = () => {
    archive(
      'caseId' in objection ? objection.caseId : objection.id,
      objection.archived,
    );
  };

  const handleDelete = () => {
    startDelete(index);
  };

  return (
    <Stack direction="row" spacing={0.5}>
      <IconButton component={Link} to={getViewUrl(objection)} size="small">
        <Visibility />
      </IconButton>

      <IconButton component={Link} to={getEditUrl(objection)} size="small">
        <Edit />
      </IconButton>

      <IconButton size="small" onClick={handleArchive}>
        {objection.archived ? <Unarchive /> : <Archive />}
      </IconButton>

      <IconButton size="small" onClick={handleDelete}>
        <Delete />
      </IconButton>
    </Stack>
  );
};

const ObjectionActionsMobile = ({ index }: { index: number }) => {
  const { objections } = useSnapshot(state);
  const objection = objections[index];

  const { anchorEl, toggle, close } = useAnchorMenu();

  const handleClose = () => {
    close();

    rootStore.dialogs.myObjections = false;
  };

  const handleDelete = () => {
    startDelete(index);
  };

  const handleArchive = () => {
    close();

    archive(
      'caseId' in objection ? objection.caseId : objection.id,
      objection.archived,
    );
  };

  return (
    <Box>
      <Button variant="contained" color="inherit" size="small" onClick={toggle}>
        Actions
      </Button>

      <Popper
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={close}
        disablePortal
      >
        <List dense>
          <ListItemButton
            component={Link}
            to={getViewUrl(objection)}
            onClick={handleClose}
          >
            <ListItemIcon>
              <Visibility />
            </ListItemIcon>
            <ListItemText primary="View" />
          </ListItemButton>

          <ListItemButton
            component={Link}
            to={getEditUrl(objection)}
            onClick={handleClose}
          >
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <ListItemText primary="Edit" />
          </ListItemButton>

          <ListItemButton onClick={handleArchive}>
            <ListItemIcon>
              {objection.archived ? <Unarchive /> : <Archive />}
            </ListItemIcon>
            <ListItemText
              primary={objection.archived ? 'Unarchive' : 'Archive'}
            />
          </ListItemButton>

          <ListItemButton onClick={handleDelete}>
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <ListItemText primary="Delete" />
          </ListItemButton>
        </List>
      </Popper>
    </Box>
  );
};

const ObjectionTitleEditor = memo(({ index }: { index: number }) => {
  const { objections } = useSnapshot(state);
  const objection = objections[index];

  const id = 'caseId' in objection ? objection.caseId : objection.id;

  const theme = useTheme();

  const debounceUpdateObjectionTitle = useMemo(
    () => debounce(updateObjectionTitle, 500),
    [],
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      state.objections = state.objections.map((obj, i) =>
        i === index ? { ...obj, title: e.target.value } : obj,
      );

      debounceUpdateObjectionTitle(id, index, e.target.value);
    },
    [debounceUpdateObjectionTitle, id, index],
  );

  return (
    <TextField
      value={objection.title || ''}
      onChange={handleChange}
      placeholder={objection.title?.length ? undefined : String(id)}
      variant="standard"
      InputProps={{ disableUnderline: true }}
      inputProps={{ maxLength: 100 }}
      sx={{
        input: {
          '&::placeholder': {
            opacity: 1,
            color: theme.palette.text.primary,
          },
        },
      }}
      size="small"
      fullWidth
    />
  );
});

const Loading = () => {
  return (
    <Stack alignItems="center" p={2}>
      <CircularProgress />
    </Stack>
  );
};

const MyObjectionsDialogTitle = ({
  handleClose,
}: {
  handleClose: () => void;
}) => {
  const { type, archived, loading } = useSnapshot(state);

  const handleSwitch = () => {
    state.archived = !archived;
    state.meta.page = 1;

    fetchObjections();
  };

  return (
    <DraggableDialogDefaultTitle
      title={type === 'scene' ? 'My Scenes' : 'My Cases'}
      onClose={handleClose}
    >
      <AppBarButton onClick={handleSwitch} disabled={loading}>
        {archived ? 'Back' : 'Archived'}
      </AppBarButton>
    </DraggableDialogDefaultTitle>
  );
};

const fetchObjections = async () => {
  if (state.loading) return;

  try {
    state.loading = true;

    const res = await ApiClient[state.type].getMine(
      state.archived,
      state.meta.page,
      state.meta.perPage,
    );

    state.objections = res.data.data;
    state.meta = res.data.meta;
  } catch (error) {
    enqueueSnackbar('Failed to fetch objections', { variant: 'error' });
  } finally {
    state.loading = false;
  }
};

const updateObjectionTitle = async (
  id: string | number,
  index: number,
  title: string,
) => {
  try {
    let res: CaseDto | SceneDto;

    if (state.type === 'case') {
      res = (await ApiClient.case.update(String(id), { title: title || '' }))
        .data;
    } else {
      res = (await ApiClient.scene.update(Number(id), { title: title || '' }))
        .data;
    }

    state.objections = state.objections.map((obj, i) =>
      i === index ? { ...obj, title: res.title } : obj,
    );
  } catch (error) {
    enqueueSnackbar('Failed to update objection title', { variant: 'error' });
  }
};

const archive = async (id: number | string, isArchived: boolean) => {
  try {
    if (state.type === 'case') {
      await ApiClient.case.archive(String(id), {
        data: { archived: !isArchived },
      });
    } else {
      await ApiClient.scene.archive(Number(id), {
        data: { archived: !isArchived },
      });
    }

    state.objections = state.objections.filter((obj) =>
      'caseId' in obj ? obj.caseId !== id : obj.id !== id,
    );
    state.meta.total -= 1;
  } catch (error) {
    enqueueSnackbar(
      `Failed to ${!isArchived ? 'archive' : 'unarchive'} objection`,
      { variant: 'error' },
    );
  }
};

const startDelete = (index: number) => {
  state.deleteIndex = index;
};

const cancelDelete = () => {
  state.deleteIndex = undefined;
};

const confirmDelete = async () => {
  try {
    state.deleteLoading = true;

    const objection = state.objections[state.deleteIndex!];

    if (state.type === 'case' && 'caseId' in objection) {
      await ApiClient.case.delete(objection.caseId);
    } else {
      await ApiClient.scene.delete(objection.id);
    }

    state.objections = state.objections.filter(
      (_, i) => i !== state.deleteIndex,
    );
    state.meta.total -= 1;

    state.deleteIndex = undefined;
  } catch (error) {
    enqueueSnackbar('Failed to delete objection', { variant: 'error' });
  } finally {
    state.deleteLoading = false;
  }
};

type Objection = SceneDto | CaseDto;

const getViewUrl = (objection: Objection) => {
  return 'caseId' in objection
    ? `/case/${objection.caseId}${objection.title ? '/' : ''}${dashify(objection.title)}`
    : `/objection/${objection.id}`;
};

const getEditUrl = (objection: Objection) => {
  return 'caseId' in objection
    ? `/edit/case/${objection.caseId}`
    : `/edit/scene/${objection.id}`;
};

export const setMyObjectionsDialogType = (type: 'scene' | 'case') => {
  state.meta.page = 1;
  state.type = type;
  state.archived = false;

  fetchObjections();
};
