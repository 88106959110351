import { createContext, useContext } from 'react';
import { usePlayerDialogueBox } from '../hooks/usePlayerDialogueBox';

type DialogueBoxContextType = ReturnType<typeof usePlayerDialogueBox>;

export const PlayerDialogueBoxContext = createContext<DialogueBoxContextType>(
  {} as DialogueBoxContextType,
);

export const usePlayerDialogueBoxContext = () =>
  useContext(PlayerDialogueBoxContext);
