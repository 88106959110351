import {
  Autocomplete,
  AutocompleteCloseReason,
  Box,
  Button,
  Paper,
  PaperProps,
  Stack,
  Typography,
} from '@mui/material';
import { DeleteConfirmation } from '@web/components/common/ui/DeleteConfirmation';
import { RenderAutocompleteInput } from '@web/components/common/ui/RenderAutocompleteInput';
import { useDeleteConfirmation } from '@web/hooks/useDeleteConfirmation';
import { AssetDto } from '@web/store/assets';
import { assetActions } from '@web/store/assets/actions';
import { AssetType, assetStore, useAssetStore } from '@web/store/assets/state';
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSnapshot } from 'valtio';
import { useProxy } from 'valtio/utils';
import { VirtualizedListbox } from '../common/ui/VirtualizedListbox';
import { ShareCodeField, SharePopper } from './AssetSharing';
import { DataGridSearchField } from './AssetsSearchField';
import { assetDialogState } from './dialogs/AssetsDialogState';
import { getDefaultAssetUpdateValue } from './utils';

type AssetTabMobileProps<T extends AssetDto> = {
  type: AssetType;
  PreviewComponent: React.ComponentType<{ value: T | null }>;
};

export const AssetTabMobile = <T extends AssetDto>({
  type,
  PreviewComponent,
}: AssetTabMobileProps<T>) => {
  const { editingAsset, addedAsset } = useProxy(assetDialogState);

  const [value, setValue] = useState<T | null>(null);
  const enableSharing = type === 'character';

  const deleteConfirmation = useDeleteConfirmation();

  const handleAssetChange = (newValue: T | null) => {
    setValue(newValue);
  };

  const handleEditClick = () => {
    if (value === null) {
      return;
    }

    const asset = assetStore[type].user.find((f) => f.id === value.id);

    if (!asset) {
      return;
    }

    assetDialogState.editingAssetId = asset.id;
    assetDialogState.editingAsset = getDefaultAssetUpdateValue(type, asset);
  };

  const handleDelete = async () => {
    if (value === null) {
      return;
    }

    await deleteConfirmation.confirm(async () => {
      try {
        const index = assetStore[type].user
          .sort((a, b) => a.name.localeCompare(b.name))
          .findIndex((f) => f.id === value.id);

        await assetActions.deleteAssets(type, [value.id]);

        const nextIndex =
          assetStore[type].user.length > index ? index : index - 1;

        setValue(assetStore[type].user[nextIndex] as T | null);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const selectAsset = useCallback((asset: AssetDto) => {
    setValue(asset as T | null);
  }, []);

  useEffect(() => {
    if (addedAsset) {
      selectAsset(addedAsset);

      assetDialogState.addedAsset = undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedAsset]);

  return (
    <Stack spacing={2} display={!editingAsset ? 'inherit' : 'none'} pt={1}>
      <ListAssets
        type={type}
        value={value}
        onChange={handleAssetChange as (newValue: AssetDto | null) => void}
      />

      <PreviewComponent value={value} />

      <DeleteConfirmation
        deleteConfirmation={deleteConfirmation}
        message="Are you sure you want to delete this asset?"
        handleDelete={handleDelete}
      >
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Button size="small" variant="contained" onClick={handleEditClick}>
            Edit
          </Button>

          <Stack direction="row" spacing={1}>
            {enableSharing && value && (
              <SharePopper type={type} id={value.id}>
                {({ toggle }) => (
                  <Button
                    size="small"
                    variant="contained"
                    color="success"
                    onClick={toggle}
                  >
                    Share
                  </Button>
                )}
              </SharePopper>
            )}
            <Button
              size="small"
              variant="contained"
              color="error"
              onClick={deleteConfirmation.startConfirming}
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      </DeleteConfirmation>

      {enableSharing && (
        <Box pt={4}>
          <Typography mb={1}>Use share code</Typography>
          <ShareCodeField type="character" fullWidth />
        </Box>
      )}
    </Stack>
  );
};

type ListAssetsProps<T extends AssetDto> = {
  type: AssetType;
  value: T | null;
  onChange: (newValue: T | null) => void;
};

const ListAssets = memo(
  <T extends AssetDto>({ type, value, onChange }: ListAssetsProps<T>) => {
    const [open, setOpen] = useState(false);

    const {
      [type]: { user },
    } = useAssetStore();

    const { search } = useSnapshot(assetDialogState);

    const options = useMemo(
      () => [...user].sort((a, b) => a.name.localeCompare(b.name)),
      [user],
    );

    const filteredOptions = useMemo(
      () =>
        search
          ? options.filter(
              (f) =>
                f.name.toLowerCase().includes(search.toLowerCase()) ||
                value?.id === f.id,
            )
          : options,
      [options, search, value?.id],
    );

    const handleOpen = useCallback(() => {
      setOpen(true);
    }, []);

    const handleClose = useCallback(
      (event: React.SyntheticEvent, reason: AutocompleteCloseReason) => {
        if (reason === 'blur') {
          return;
        }

        setOpen(false);

        assetDialogState.search = '';
      },
      [],
    );

    useEffect(() => {
      if (value === null && options.length > 0) {
        onChange(options[0] as T | null);
      }
    }, [options, value, onChange]);

    return (
      <Autocomplete
        open={open}
        value={value}
        onChange={(_, newValue) => {
          onChange(newValue as T | null);
        }}
        options={filteredOptions}
        renderInput={(params) => (
          <RenderAutocompleteInput
            params={params}
            label={`My ${type.charAt(0).toUpperCase() + type.slice(1)}`}
            variant="outlined"
          />
        )}
        getOptionLabel={(option) => option.name}
        getOptionKey={(option) => option.id}
        disableClearable={value !== null}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        ListboxComponent={SearchVirtualizedListBox}
        ListboxProps={{ style: { maxHeight: '336px' } }}
        PaperComponent={ListAssetPaper}
        onOpen={handleOpen}
        onClose={handleClose}
        fullWidth
        disablePortal
      />
    );
  },
);

const ListAssetPaper = memo((props: PaperProps) => {
  const { children, ...other } = props;

  return (
    <Paper {...other}>
      <Stack py={1} alignItems="center">
        <DataGridSearchField variant="outlined" fullWidth />
      </Stack>

      {children}
    </Paper>
  );
});

const SearchVirtualizedListBox = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>((props, ref) => {
  return (
    <VirtualizedListbox ref={ref} {...props} itemHeight={48} height={7 * 48}>
      {props.children}
    </VirtualizedListbox>
  );
});
