export default {
  scene: {
    chatbox: 0,
    textSpeed: 35,
    textBlipFrequency: 64,
    autoplaySpeed: 500,
    continueSoundUrl: '',
  },
  case: {
    chatbox: 0,
    textSpeed: 28,
    textBlipFrequency: 56,
    autoplaySpeed: 500,
    continueSoundUrl: '',
  },
};
