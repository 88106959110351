import { keyframes } from '@emotion/react';
import { useCallback } from 'react';
import { usePlayer } from '../providers/PlayerProvider';

type ChildrenProps = {
  onClick: () => void;
  showNextButton?: boolean;
  size: number;
};

export const NextButton = ({
  children,
}: {
  children: (props: ChildrenProps) => React.ReactNode;
}) => {
  const {
    state: { showNextButton },
    actions: { next },
    playerUi: {
      state: { size },
    },
  } = usePlayer();

  const onClick = useCallback(() => {
    next();
  }, [next]);

  return children({ onClick, showNextButton, size });
};

export const continueButtonAnimation = keyframes`
0% {
  transform: translate(0, 0);
}

20% {
  transform: translate(1.25%, 0);
}

30% {
  transform: translate(1.875%, 0);
}

40% {
  transform: translate(2.8125%, 0);
}

60% {
  transform: translate(1.875%, 0);
}

80% {
  transform: translate(0.9375%, 0);
}

100% {
  transform: translate(0, 0);
}
`;
