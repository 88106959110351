import { keyframes } from '@emotion/react';

const continueAnimationClassicKeyframes = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }

  20% {
    transform: translate3d(2%, 0, 0);
  }

  30% {
    transform: translate3d(3%, 0, 0);
  }

  40% {
    transform: translate3d(4.5%, 0, 0);
  }

  60% {
    transform: translate3d(3%, 0, 0);
  }

  80% {
    transform: translate3d(1.5%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
`;

export const continueAnimationClassic: React.CSSProperties = {
  animation: `${continueAnimationClassicKeyframes}`,
  animationIterationCount: 'infinite',
  animationTimingFunction: 'ease-in-out',
  animationDuration: '0.7s',
  animationFillMode: 'both',
};
