import {
  AnyGroup,
  CaseFrame,
  CrossExaminationGroup,
  GameoverGroup,
  GroupType,
  InvestigationGroup,
  Location,
  NormalGroup,
} from '@shared/types';
import { CaseProjectDto, SceneProjectDto } from '@web/api/api';
import { FramesTarget } from '@web/types/project';
import { GameCategory, GameState } from '../types/state';

export const GameUtils = {
  getStartingGroup(project: SceneProjectDto | CaseProjectDto) {
    return project.groups.find(
      (f) =>
        (f.type === GroupType.Investigation &&
          (f as InvestigationGroup).locations?.length > 0) ||
        ((f.type === GroupType.Normal ||
          f.type === GroupType.CrossExamination) &&
          (f as NormalGroup).frames?.length > 0),
    );
  },
  getTargetFrames(
    framesTarget: FramesTarget,
    project: SceneProjectDto | CaseProjectDto,
  ) {
    const group = project.groups.find(
      (f) => f.id === framesTarget.groupId,
    ) as AnyGroup;

    if (!group) return [];

    const { locationId, conversationId, examineId, pressedFrame, category } =
      framesTarget;

    switch (group.type) {
      case GroupType.Investigation: {
        const location = group.locations?.find((f) => f.id === locationId);

        if (!location) return [];

        return conversationId
          ? location.conversations?.find((f) => f.id === conversationId)?.frames
          : examineId
            ? location.examine?.find((f) => f.id === examineId)?.frames
            : category === GameCategory.InvestigationPresentFailure
              ? location.presentFailureFrames
              : category === GameCategory.InvestigationExamineFailure
                ? location.examineFailureFrames
                : category === GameCategory.InvestigationCompletion
                  ? location.completionFrames
                  : location.frames;
      }
      case GroupType.CrossExamination: {
        return pressedFrame
          ? group.pressFrames[pressedFrame]
          : category === GameCategory.CrossExaminationCounsel
            ? group.counselFrames
            : category === GameCategory.CrossExaminationEvidenceFailure
              ? group.failureFrames
              : group.frames;
      }
      default:
        return group.frames;
    }
  },
  getNextFrames(
    amount: number,
    project: SceneProjectDto | CaseProjectDto,
    currentState: GameState,
  ) {
    const frames = [] as CaseFrame[];
    const state = JSON.parse(JSON.stringify(currentState)) as GameState;

    const group = project.groups.find(
      (f) => f.id === state.groupId,
    ) as AnyGroup;

    for (let i = 0; i < amount; i++) {
      const framesTarget = {
        groupId: state.groupId,
        locationId: state.locationId,
        conversationId: state.conversationId,
        examineId: state.examineId,
        pressedFrame: state.pressedFrame,
        category: state.category,
      } as FramesTarget;

      const targetFrames = this.getTargetFrames(framesTarget, project) ?? [];

      if (state.frameIndex < targetFrames.length - 1) {
        state.frameIndex++;

        frames.push(targetFrames[state.frameIndex]);

        continue;
      }

      // move to next logically

      if (
        !group ||
        [GroupType.Normal, GroupType.Gameover].includes(group.type)
      ) {
        state.isEnded = true;

        break;
      }

      if (group.type === GroupType.CrossExamination) {
        if (state.pressedFrame) {
          // frame was pressed
          if (
            state.pressIndex !== undefined &&
            state.pressIndex + 1 < group.frames.length
          ) {
            // continue to next witness statement
            state.frameIndex = state.pressIndex + 1;
            state.pressIndex = 0;
            state.category = undefined;
            state.pressedFrame = undefined;

            frames.push(group.frames[state.frameIndex]);

            continue;
          } else if (group.counselFrames.length > 0) {
            // go to counsel conversation
            state.category = GameCategory.CrossExaminationCounsel;
            state.frameIndex = 0;
            state.pressedFrame = undefined;

            frames.push(group.counselFrames[state.frameIndex]);

            continue;
          } else {
            // return back to first statement
            state.frameIndex = 0;
            state.category = undefined;
            state.pressedFrame = undefined;

            frames.push(group.frames[state.frameIndex]);

            continue;
          }
        }

        // ended counsel conversation
        if (state.category === GameCategory.CrossExaminationCounsel) {
          state.category = undefined;
          state.frameIndex = 0;

          frames.push(group.frames[state.frameIndex]);

          continue;
        }

        // ended present evidence failure
        if (state.category === GameCategory.CrossExaminationEvidenceFailure) {
          state.category = undefined;
          state.frameIndex = 0;

          frames.push(group.frames[state.frameIndex]);

          continue;
        }

        // ended witness testimony, go to counsel conversation
        if (!state.category) {
          // go to counsel conversation if available
          if (group.counselFrames.length > 0) {
            state.category = GameCategory.CrossExaminationCounsel;
            state.frameIndex = 0;

            frames.push(group.counselFrames[state.frameIndex]);

            continue;
          } else {
            // return back to first statement
            state.frameIndex = 0;

            frames.push(group.frames[state.frameIndex]);

            continue;
          }
        }
      }

      if (group.type === GroupType.Investigation) {
        const location = group.locations.find((f) => f.id === state.locationId);

        if (!location) {
          state.isEnded = true;

          break;
        }

        if (state.investigationMenu || state.resumeExamine) {
          break;
        }

        // ended investigation frames, just show menu

        if (state.conversationId) {
          state.conversationId = undefined;
          state.investigationMenu = true;
        }

        if (state.examineId) {
          state.examineId = undefined;
          state.resumeExamine = true;
        }

        if (
          state.category === GameCategory.InvestigationPresentFailure ||
          state.category === GameCategory.InvestigationCompletion
        ) {
          state.category = undefined;
          state.investigationMenu = true;
        }

        if (state.category === GameCategory.InvestigationExamineFailure) {
          state.category = undefined;
          state.resumeExamine = true;
        }

        state.frameIndex = location.frames.length - 1;
      }
    }

    return { frames, state };
  },
  getFrameById(id: number, project: SceneProjectDto | CaseProjectDto) {
    let res: GameState = { frameIndex: -1 };

    project.groups.every((group) => {
      switch (group.type) {
        case GroupType.Investigation: {
          (group as InvestigationGroup).locations?.every(
            (location: Location) => {
              [
                'frames',
                GameCategory.InvestigationPresentFailure,
                GameCategory.InvestigationExamineFailure,
                GameCategory.InvestigationCompletion,
              ].every((key) => {
                const field = key as keyof Location;
                const found = (location[field] as CaseFrame[]).findIndex(
                  (x) => x.id === id,
                );

                if (found >= 0) {
                  res = {
                    groupId: group.id,
                    frameIndex: found,
                    locationId: location.id,
                    category:
                      key !== 'frames' ? (key as GameCategory) : undefined,
                  };
                }

                return !res;
              });

              if (!res) {
                location.conversations.every((conv) => {
                  const found = conv.frames.findIndex((f) => f.id === id);

                  if (found >= 0) {
                    res = {
                      groupId: group.id,
                      frameIndex: found,
                      locationId: location.id,
                      conversationId: conv.id,
                    };
                  }

                  return !res;
                });
              }

              if (!res) {
                location.examine.every((exam) => {
                  const found = exam.frames.findIndex((f) => f.id === id);

                  if (found >= 0) {
                    res = {
                      groupId: group.id,
                      frameIndex: found,
                      locationId: location.id,
                      examineId: exam.id,
                    };
                  }

                  return !res;
                });
              }

              return !res;
            },
          );

          return !res;
        }

        case GroupType.CrossExamination: {
          [
            'frames',
            GameCategory.CrossExaminationCounsel,
            GameCategory.CrossExaminationEvidenceFailure,
          ].every((key) => {
            const field = key as keyof CrossExaminationGroup;
            const found = (
              (group as CrossExaminationGroup)[field] as CaseFrame[]
            ).findIndex((x) => x.id === id);

            if (found >= 0) {
              res = {
                groupId: group.id,
                frameIndex: found,
                category: key as GameCategory,
              };
            }

            return !res;
          });

          Object.entries((group as CrossExaminationGroup).pressFrames).every(
            ([key, value]) => {
              const found = value.findIndex((x) => x.id === id);

              if (found >= 0) {
                res = {
                  groupId: group.id,
                  frameIndex: found,
                  pressIndex: found,
                  pressedFrame: key,
                };
              }

              return !res;
            },
          );

          return !res;
        }

        case GroupType.Normal:
        case GroupType.Gameover: {
          const found = (group as NormalGroup | GameoverGroup).frames.findIndex(
            (x) => x.id === id,
          );

          if (found >= 0) {
            res = {
              groupId: group.id,
              frameIndex: found,
            };
          }

          return !res;
        }

        default:
          return true;
      }
    });

    return res;
  },
  getAllFrames(project: SceneProjectDto | CaseProjectDto) {
    let frames = [] as CaseFrame[];

    project.groups.forEach((group) => {
      switch (group.type) {
        case GroupType.Investigation: {
          (group as InvestigationGroup).locations?.forEach((location) => {
            frames = frames.concat(location.frames);

            location.conversations?.forEach((conv) => {
              frames = frames.concat(conv.frames);
            });

            location.examine?.forEach((exam) => {
              frames = frames.concat(exam.frames);
            });
          });

          break;
        }

        case GroupType.CrossExamination: {
          const ceGroup = group as CrossExaminationGroup;

          frames = frames.concat(ceGroup.frames);

          Object.values(ceGroup.pressFrames).forEach((value) => {
            frames = frames.concat(value);
          });

          frames = frames.concat(ceGroup.counselFrames);
          frames = frames.concat(ceGroup.failureFrames);

          break;
        }

        case GroupType.Normal:
        case GroupType.Gameover: {
          frames = frames.concat((group as NormalGroup | GameoverGroup).frames);

          break;
        }

        default:
          break;
      }
    });

    return frames;
  },
};
