import { Breakpoint, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

type BreakpointProps = {
  breakpoint: Breakpoint | number;
  children: React.ReactNode;
};

export const BreakpointRender = ({ children, breakpoint }: BreakpointProps) => {
  const theme = useTheme();
  const isBreakpoint = useMediaQuery(theme.breakpoints.down(breakpoint));

  const childrenArray = React.Children.toArray(children);

  if (childrenArray.length !== 2) {
    throw new Error('Breakpoint component must have exactly 2 children');
  }

  if (!isBreakpoint) {
    return childrenArray[0];
  } else {
    return childrenArray[1];
  }
};
