import { Add, Delete } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { useDeleteConfirmation } from '@web/hooks/useDeleteConfirmation';
import { AssetType } from '@web/store/assets/state';
import { memo } from 'react';
import { useProxy } from 'valtio/utils';
import { assetDialogState } from './dialogs/AssetsDialogState';

type AssetTabOptionsProps = {
  type: AssetType;
  deleteConfirmation: ReturnType<typeof useDeleteConfirmation>;
  onAddClick: () => void;
  onDeleteClick: () => void;
};

export const AssetTabOptions = memo(
  ({
    type,
    deleteConfirmation,
    onDeleteClick,
    onAddClick,
  }: AssetTabOptionsProps) => {
    const { selectedIds } = useProxy(assetDialogState);
    const {
      confirming,
      startConfirming,
      cancelConfirming,
      deleting,
      enableConfirm,
    } = deleteConfirmation;

    return (
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        {selectedIds.length > 0 ? (
          confirming ? (
            <Stack spacing={1}>
              <Typography variant="body2">
                Are you sure you want to delete {selectedIds.length} items?
              </Typography>

              <Stack direction="row" spacing={1}>
                <Button
                  size="small"
                  variant="contained"
                  color="error"
                  disabled={deleting || !enableConfirm}
                  onClick={onDeleteClick}
                >
                  Confirm
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  disabled={deleting}
                  onClick={cancelConfirming}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          ) : (
            <Button
              startIcon={<Delete />}
              size="small"
              variant="contained"
              color="error"
              onClick={startConfirming}
            >
              Delete Selected
            </Button>
          )
        ) : (
          <span></span>
        )}

        <Button
          startIcon={<Add />}
          size="small"
          variant="contained"
          onClick={onAddClick}
        >
          Add {type}
        </Button>
      </Stack>
    );
  },
);
