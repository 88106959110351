export default Object.freeze<Record<string, string>>({
  allegro2001: '1791-59104',
  allegro2002: '6857-51428',
  allegro2004: '1846-75692',
  'blue badger': '4799-23999',
  circus: '12007-52078',
  congratulations: '1714-29142',
  'cornered variation': '1599-39999',
  cornered: '8000-46399',
  'cornered2002 variation': '8000-39999',
  cornered2002: '12799-44799',
  'cornered2004 - pursuit variation': '4800-43199',
  'cornered2004 - pursuit': '8000-46409',
  crossexamining: '6486-59999',
  crossexaminingvariation: '1621-55135',
  'dahlia hawthorne': '0-68307',
  darkcoffee: '6760-74366',
  'ema skye': '6857-54857',
  gant: '16006-67942',
  gumshoe: '2181-56727',
  'luke atmey': '13759-53759',
  maya: '2307-76153',
  maya2: '2307-53076',
  'miles edgeworth': '13452-46842',
  moderato2001: '1904-47619',
  moderato2002: '3387-42580',
  moderato2004: '1967-97376',
  objection: '25999-49999',
  objection2002: '18000-49999',
  objection2004: '2000-55999',
  reminiscence: '8000-63999',
  'scars etched by flame': '2857-71428',
  'search core': '6000-57999',
  'search midst 2002': '2086-47999',
  'search opening 2002': '2400-40799',
  'sl-9 incident': '4173-45912',
  'steel samurai': '3428-56571',
  suspense: '8571-35999',
  trial: '9599-57599',
  trial2: '4800-52799',
  trial3: '2448-66122',
  trick: '2181-71999',
  trick2002: '10-54867',
  truth2001: '3333-36666',
  truth2002: '8000-50666',
  truth2004: '3333-59999',
  'von karma': '18947-52631',
  'warehouse tiger': '6000-49999',
  'wild west': '5760-49919',
  'won the case': '6153-55384',
  truth2007: '0-47710',
  'seeing through': '6368-29782',
  allegro2007: '3637-49091',
  trial4: '0-29689',
  suspense2007: '5474-23405',
  moderato2007: '0-34357',
  'kitaki family': '12750-65860',
  cornered2007: '7297-52702',
  klavier: '5293-47650',
  'trucy wright': '4910-87680',
  trial5: '0-54876',
  trick2007: '2160-47569',
  'cornered2007 variation': '2431-47837',
  'troupe gramarye': '7378-55388',
  'psycho lock': '10240-68811',
  objection2007: '11440-57143',
  "steel samurai's ballad": '6164-66768',
  'kurain village': '10285-55714',
  allegro2009: '12584-99375',
  'the great truth burglar': '0-67572',
  cornered2009: '4646-97574',
  presto2009: '1548-88278',
  moderato2009: '5217-117107',
  objection2009: '6965-86982',
  truth2009: '6672-74693',
  'shi-long lang': '11403-88223',
  trial6: '22114-90337',
  'hazy scenery': '4897-112653',
  'defendant lobby': '4800-38399',
  'pearl fey': '3428-32571',
  'tragicomic interview': '0-103513',
  'drew studio': '0-89150',
  'solitary confinement': '0-49223',
  'a hurt fox': '0-64845',
  'a fate smeared by tricks and gadgets': '902-61811',
  'search opening 2007': '0-38819',
  'search core 2007': '2710-57951',
  'forgotten legend': '1431-65362',
  'won the case 2007': '3000-60610',
  'eccentric 2007': '0-45360',
  "the guitar's serenade": '6693-85764',
});
