import { TextFieldVariants } from '@mui/material';
import { useAssetStore } from '@web/store/assets/state';
import { useMemo } from 'react';
import { SimpleSelect } from './SimpleSelect';

type BackgroundInputProps = {
  label: string;
  value: number;
  onChange: (value: number) => void;
  variant?: TextFieldVariants | undefined;
  allowWide?: boolean;
  error?: string;
};

export const BackgroundInput = ({
  label,
  value,
  onChange,
  variant,
  allowWide,
  error,
}: BackgroundInputProps) => {
  const {
    background: { user, preset },
  } = useAssetStore();

  const options = useMemo(() => {
    const sortedUser = [...user]
      .filter((f) => allowWide || !f.isWide)
      .sort((a, b) => a.name.localeCompare(b.name));

    const sortedPreset = [...preset]
      .filter((f) => allowWide || !f.isWide)
      .sort((a, b) => a.name.localeCompare(b.name));

    return [...sortedUser, ...sortedPreset].map((f) => ({
      id: f.id,
      name: f.name,
    }));
  }, [user, preset, allowWide]);

  const selectedValue = useMemo(
    () => options.find((f) => f.id === value) || null,
    [options, value],
  );

  return (
    <SimpleSelect
      label={label}
      value={selectedValue}
      onChange={(newValue) => onChange(newValue?.id || 0)}
      options={options}
      variant={variant}
      error={error}
    />
  );
};
