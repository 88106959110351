import {
  ThemeOptions,
  alpha,
  darkScrollbar,
  getContrastRatio,
} from '@mui/material';
import { grey } from '@mui/material/colors';

const getCommonTheme = (mode: 'light' | 'dark'): ThemeOptions => ({
  components: {
    MuiAccordionDetails: {
      defaultProps: {
        sx: {
          paddingInline: { xs: 1, sm: 2 },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        inputProps: {
          autoComplete: 'off',
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        PaperProps: {
          elevation: 1,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          ...darkScrollbar(
            mode === 'light'
              ? {
                  track: grey[200],
                  thumb: grey[400],
                  active: grey[400],
                }
              : undefined,
          ),
          // scrollbarWidth for Firefox
          // scrollbarWidth: 'thin',
        },
        body: {
          letterSpacing: 'inherit',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        startIcon: {
          marginBottom: 2.5,
        },
      },
      defaultProps: {
        disableElevation: true,
      },
    },
  },
});

export const lightTheme: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#009688',
    },
    secondary: {
      main: '#7f3f45',
    },
    success: {
      main: '#4caf50',
      contrastText: '#fff',
    },
    info: {
      main: '#2196f3',
      contrastText: '#fff',
    },
    accent: {
      main: '#775555',
      light: alpha('#775555', 0.5),
      dark: alpha('#775555', 0.9),
      contrastText: getContrastRatio('#775555', '#fff') > 4.5 ? '#fff' : '#111',
    },
    violet: {
      main: '#3f51b5',
      light: alpha('#3f51b5', 0.5),
      dark: alpha('#3f51b5', 0.9),
      contrastText: getContrastRatio('#3f51b5', '#fff') > 4.5 ? '#fff' : '#111',
    },
  },
  ...getCommonTheme('light'),
};

export const darkTheme: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#009688',
    },
    secondary: {
      main: '#7f3f45',
    },
    success: {
      main: '#4caf50',
      contrastText: '#fff',
    },
    info: {
      main: '#2196f3',
      contrastText: '#fff',
    },
    accent: {
      main: '#775555',
      light: alpha('#775555', 0.5),
      dark: alpha('#775555', 0.9),
      contrastText: getContrastRatio('#775555', '#fff') > 4.5 ? '#fff' : '#111',
    },
    violet: {
      main: '#3f51b5',
      light: alpha('#3f51b5', 0.5),
      dark: alpha('#3f51b5', 0.9),
      contrastText: getContrastRatio('#3f51b5', '#fff') > 4.5 ? '#fff' : '#111',
    },
  },
  ...getCommonTheme('dark'),
};
