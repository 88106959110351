import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  TouchSensor,
  UniqueIdentifier,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { ReactNode } from 'react';

type UniqueId = { id: UniqueIdentifier };

type DndProviderProps<T extends UniqueId> = {
  items: T[];
  onDragStart?: (sourceId: UniqueIdentifier) => void;
  onDragEnd: (
    sourceId: UniqueIdentifier,
    destinationId?: UniqueIdentifier,
  ) => void;
  children: ReactNode;
};

export const SortableDndProvider = <T extends UniqueId>({
  items,
  onDragStart,
  onDragEnd,
  children,
}: DndProviderProps<T>) => {
  const sensors = useSensors(useSensor(PointerSensor), useSensor(TouchSensor));

  const handleDragStart = ({ active }: { active: UniqueId }) => {
    onDragStart?.(active.id);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      onDragEnd(active.id, over?.id);
    }
  };

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToVerticalAxis]}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {children}
      </SortableContext>
    </DndContext>
  );
};
