import { settingsStore, SettingsStoreType } from '@web/store/settings';

export const getVolume = (
  setting: keyof SettingsStoreType['audio']['volume'],
) => {
  if (
    settingsStore.audio.muted['master'] ||
    settingsStore.audio.muted[setting]
  ) {
    return 0;
  }

  return settingsStore.audio.volume[setting] / 100;
};
