/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuidv4 } from 'uuid';
import {
  OldAliases,
  OldCourtRecord,
  OldFrame,
  OldOptions,
  OldPairs,
} from '../types/project';
import defaultOptions from './defaultOptions';
import EntityService from './entityService';
import { Pair } from './pair';

export class OldProject {
  id: string;
  type: string;
  options: OldOptions;
  groups: any[];
  courtRecord: OldCourtRecord;
  aliases: OldAliases;
  pairs: OldPairs;

  constructor(type = 'scene') {
    this.id = uuidv4();
    this.type = type;
    this.options = { ...defaultOptions[type] };
    this.groups = [];
    this.courtRecord = { evidence: [], profiles: [] };
    this.aliases = [];
    this.pairs = [];
  }

  getData() {
    return JSON.parse(JSON.stringify({ ...this, version: 4 }));
  }

  getAllFrames(gid: any | undefined) {
    let res = [];

    this.groups
      .slice(0, 100)
      .filter((f) => (gid ? f.iid == gid || f.id == gid : true))
      .forEach((group) => {
        const frames = group.frames ?? [];

        res = res.concat(frames);
        res = res.concat(group.counselFrames ?? []);
        res = res.concat(group.failureFrames ?? []);

        const pressFrames = frames
          .filter((f) => f.pressFrames && Array.isArray(f.pressFrames))
          .map((m) => m.pressFrames)
          .flat();

        res = res.concat(pressFrames);

        if (group.type == 'inv') {
          res = res.concat(group.locations.map((m) => m.frames).flat());
          res = res.concat(
            group.locations.map((m) => m.presentFailure ?? []).flat(),
          );
          res = res.concat(
            group.locations.map((m) => m.examineFailure ?? []).flat(),
          );
          res = res.concat(
            group.locations.map((m) => m.completion ?? []).flat(),
          );
          res = res.concat(
            group.locations
              .map((m) => {
                return (
                  m.conversations?.map((n) => n.frames).flat() ?? []
                ).concat(m.examine?.map((n) => n.frames).flat() ?? []);
              })
              .flat(),
          );
        }
      });

    return res;
  }

  loadData(payload) {
    const version = payload.version
      ? payload.version
      : payload.type
        ? 4
        : payload.pairs
          ? 3
          : payload.aliases
            ? 2
            : 1;

    switch (version) {
      case 4:
        this.type = payload.type ?? 'scene';
        this.groups =
          payload.groups && payload.groups.length > 0
            ? payload.groups
            : [NewGroup(1, 'Main', 'n')];
        this.courtRecord = payload.courtRecord ?? {
          evidence: [],
          profiles: [],
        };
        this.aliases = payload.aliases ?? [];
        this.pairs = payload.pairs ?? [];
        this.options = payload.options ?? { ...defaultOptions[this.type] };
        break;
      case 3:
        this.groups = [NewGroup(1, 'Main', 'n', payload.frames)];
        this.aliases = payload.aliases ?? [];
        this.pairs = payload.pairs ?? [];
        this.options = payload.options ?? { ...defaultOptions[this.type] };
        this.groups[0].comments = payload.comments ?? {};
        break;
      case 2:
        this.groups = [NewGroup(1, 'Main', 'n', payload.frames)];
        this.aliases = payload.aliases ?? [];
        break;
      default:
        this.groups = [NewGroup(1, 'Main', 'n', payload)];
        break;
    }

    if (payload.id) {
      this.id = payload.id;
    } else if (!this.id) {
      this.id = uuidv4();
    }

    this.groups.forEach((e) => {
      if (!e.frames && e.type != 'inv') {
        e.frames = [];
      }
    });

    this.pairs = this.pairs.map((m) => new Pair(m));

    this._assertFramesFid();
  }

  _assertFramesFid() {
    const frames = this.getAllFrames(undefined);

    frames.forEach((e: OldFrame) => {
      if (!e.iid || e.iid <= 0) {
        e.iid = EntityService._getAvailableId(frames);
      }
    });
  }
}

function NewGroup(iid, name, type, frames = []) {
  let g: any =
    type == 'ce'
      ? {
          iid,
          name,
          type,
          frames: frames,
          counselFrames: [],
          failureFrames: [],
        }
      : type == 'inv'
        ? { iid, name, type, locations: [] }
        : { iid, name, type, frames: frames };

  g = { ...g, comments: {} };

  return g;
}
