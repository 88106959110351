import { Box, Stack } from '@mui/material';
import { SceneProjectDto } from '@web/api/api';
import { ApiClient } from '@web/api/api-client';
import { Player } from '@web/components/player/Player';
import { PlayerActionsType } from '@web/components/player/providers/PlayerProvider';
import { enqueueSnackbar } from 'notistack';
import { memo, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { proxy } from 'valtio';
import { useProxy } from 'valtio/utils';

const state = proxy({
  project: undefined as SceneProjectDto | undefined,
});

const EmbedPage = () => {
  return (
    <Box>
      <ObjectionPlayer />
    </Box>
  );
};

const ObjectionPlayer = memo(() => {
  const { id } = useParams();
  const { project } = useProxy(state);

  const ref = useRef<PlayerActionsType>(null);

  useEffect(() => {
    if (!id) return;

    fetchObjection(parseInt(id));
  }, [id]);

  useEffect(() => {
    if (!project) return;

    ref.current?.reset();
    ref.current?.init(project);
  }, [project]);

  if (!id) {
    return null;
  }

  return (
    <Stack alignItems="center">
      <Player
        ref={ref}
        width={project?.options.width}
        aspectRatio={project?.options.aspectRatio}
      />
    </Stack>
  );
});

const fetchObjection = async (id: number) => {
  try {
    const response = await ApiClient.scene.get(id);

    state.project = response.data.data;
  } catch (error) {
    enqueueSnackbar('Failed to fetch objection', { variant: 'error' });
  }
};

export default EmbedPage;
