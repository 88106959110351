/* eslint-disable @typescript-eslint/no-explicit-any */
export class Pair {
  id: any;
  pairId: any;
  name: any;
  characterId: any;
  characterId2: any;
  offsetX: any;
  offsetY: any;
  offsetX2: any;
  offsetY2: any;
  front: any;

  constructor(props) {
    this.id = props && props.id != undefined ? props.id : -1;
    this.pairId = props && props.pairId != undefined ? props.pairId : -1;
    this.name = props && props.name != undefined ? props.name : '';
    this.characterId =
      props && props.characterId != undefined ? props.characterId : null;
    this.characterId2 =
      props && props.characterId2 != undefined ? props.characterId2 : null;
    this.offsetX = props && props.offsetX != undefined ? props.offsetX : 0;
    this.offsetY = props && props.offsetY != undefined ? props.offsetY : 0;
    this.offsetX2 = props && props.offsetX2 != undefined ? props.offsetX2 : 0;
    this.offsetY2 = props && props.offsetY2 != undefined ? props.offsetY2 : 0;
    this.front = props && props.front != undefined ? props.front : true;
  }
}
