import { Stack, Typography } from '@mui/material';
import { ApiClient } from '@web/api/api-client';
import Switch from '@web/components/common/form/Switch';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import { proxy, useSnapshot } from 'valtio';

const state = proxy({
  loading: false,
  maintenanceMode: false,
  exportMaintenanceMode: false,
});

const AdminPage = () => {
  const snapshot = useSnapshot(state);

  const handleChangeMaintenanceMode = async (isChecked: boolean) => {
    try {
      state.loading = true;

      await ApiClient.maintenance.changeMaintenanceMode({
        maintenanceMode: isChecked,
        exportMaintenanceMode: snapshot.exportMaintenanceMode,
      });

      fetchMaintenanceMode();
    } catch (error) {
      enqueueSnackbar('Failed to change maintenance mode', {
        variant: 'error',
      });
    } finally {
      state.loading = false;
    }
  };

  const handleChangeExportMaintenanceMode = async (isChecked: boolean) => {
    try {
      state.loading = true;

      await ApiClient.maintenance.changeMaintenanceMode({
        maintenanceMode: snapshot.maintenanceMode,
        exportMaintenanceMode: isChecked,
      });

      fetchMaintenanceMode();
    } catch (error) {
      enqueueSnackbar('Failed to change export maintenance mode', {
        variant: 'error',
      });
    } finally {
      state.loading = false;
    }
  };

  useEffect(() => {
    fetchMaintenanceMode();
  }, []);

  return (
    <Stack spacing={2} p={{ xs: 1, sm: 1.5 }}>
      <Typography variant="h5">Maintenance</Typography>

      <Switch
        label="Maintenance mode"
        checked={snapshot.maintenanceMode}
        onChange={(e, v) => handleChangeMaintenanceMode(v)}
        disabled={snapshot.loading}
      />

      <Switch
        label="Export maintenance mode"
        checked={snapshot.exportMaintenanceMode}
        onChange={(e, v) => handleChangeExportMaintenanceMode(v)}
        disabled={snapshot.loading}
      />
    </Stack>
  );
};

const fetchMaintenanceMode = async () => {
  try {
    const res = await ApiClient.maintenance.maintenanceMode();

    state.maintenanceMode = res.data.maintenanceMode;
    state.exportMaintenanceMode = res.data.exportMaintenanceMode;
  } catch (error) {
    enqueueSnackbar('Failed to fetch maintenance mode', { variant: 'error' });
  }
};

export default AdminPage;
