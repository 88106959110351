import { useAssetStore } from '@web/store/assets/state';
import { Frame } from '@web/types/project';
import { frameUtils } from '@web/utils/frame';
import { useMemo } from 'react';
import { usePlayerAssets } from '../providers/PlayerAssetsProvider';

export const useCharacter = (frame: Frame | undefined) => {
  const {
    character: { cache },
  } = useAssetStore();

  const playerAssets = usePlayerAssets();

  const character = useMemo(() => {
    if (!frame) return undefined;

    const characterId = frameUtils.getCharacterId(frame);

    if (!characterId) return undefined;

    return playerAssets?.character?.[characterId] || cache[characterId];
  }, [cache, frame, playerAssets?.character]);

  return character;
};
