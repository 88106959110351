import { Character_Side, FrameAction } from '@shared/types';
import frameActions from '@web/data/frame-actions';
import { assetStore } from '@web/store/assets/state';
import {
  PlayerActionsType,
  PlayerStateType,
  Step,
} from '../providers/PlayerProvider';
import { PlayerDefaults } from '../types/playerDefaults';
import { usePlayerAudio } from './usePlayerAudio';
import { useTimeline } from './useTimeline';

export const runFrameActions = (
  actions: FrameAction[],
  playerState: PlayerStateType,
  step: Step,
  playerActions: PlayerActionsType,
  update: (data: Partial<PlayerStateType>) => void,
  audio: ReturnType<typeof usePlayerAudio>,
  timeline: ReturnType<typeof useTimeline>,
  playerDefaults: PlayerDefaults,
  runOnStartFlag: boolean,
) => {
  actions.forEach((action) => {
    const frameAction = frameActions.find((fa) => fa.id === action.actionId);

    if (!frameAction || frameAction.runAtStart !== runOnStartFlag) return;

    switch (action.actionId) {
      case 1:
        update({ showDialoguebox: action.actionParam === '1' });
        break;
      case 2:
      case 10: {
        const character = assetStore.character.cache[action.actionParam];

        if (!character) return;

        if (action.actionId === 2 && character.galleryImageUrl) {
          update({
            galleryImageUrls: {
              ...playerState.galleryImageUrls,
              [character.side]: character.galleryImageUrl,
            },
          });
        }

        if (action.actionId === 10 && character.galleryAJImageUrl) {
          update({
            galleryImageUrlsAj: {
              ...playerState.galleryImageUrlsAj,
              [character.side]: character.galleryAJImageUrl,
            },
          });
        }

        break;
      }
      case 3:
        delete playerState.galleryImageUrls[
          action.actionParam as Character_Side
        ];

        break;
      case 11:
        delete playerState.galleryImageUrlsAj[
          action.actionParam as Character_Side
        ];

        break;
      case 5:
        playerDefaults.muteSpeechBlip = true;

        break;
      case 6:
        // useFrame handles it

        break;
      case 7:
        {
          const states = frameAction.options.find(
            (option) => option.value === action.actionParam,
          )?.states;

          if (!states) {
            playerActions.nextStep();

            return;
          }

          const totalDelay = states.reduce((acc, s) => acc + (s.ttl ?? 0), 0);

          states
            .filter((f) => f.type === 'image' && f.image)
            .forEach((imageState) => {
              playerState.presetPopupsImageUrls = [
                ...(playerState.presetPopupsImageUrls ?? []),
                imageState.image!,
              ];

              if (imageState.ttl) {
                timeline.addEvent(() => {
                  removePresetPopupImage(playerState, imageState.image!);
                }, imageState.ttl);
              }
            });

          states
            .filter((f) => f.type === 'sound' && f.sound)
            .forEach((sound) => {
              timeline.addEvent(() => {
                audio.playSound(sound.sound!, sound.volume ?? 100, 10);
              }, sound.time ?? 0);
            });

          timeline.addEvent(() => {
            if (step !== 'delayed_actions') return;

            playerActions.nextStep();
          }, totalDelay);
        }
        break;
      case 8: {
        switch (action.actionParam) {
          case '1':
            removePresetPopupImage(playerState, '/Images/Popups/testimony.gif');
            break;
          default:
            break;
        }

        playerActions.nextStep();

        break;
      }
      case 9:
        playerDefaults.centerText = true;

        break;
      case 12:
        playerDefaults.chatbox = parseInt(action.actionParam);

        break;
      case 13:
        playerDefaults.defaultTextSpeed = parseInt(action.actionParam ?? 35);

        break;
      case 14:
        playerDefaults.textBlipFrequency = parseInt(action.actionParam ?? 64);

        break;
      case 15:
        playerDefaults.autoplaySpeed = parseInt(action.actionParam ?? 500);

        break;
      case 16:
        playerDefaults.enableSkipping = action.actionParam === '1';

        break;
    }
  });
};

const removePresetPopupImage = (state: PlayerStateType, imageUrl: string) => {
  state.presetPopupsImageUrls = state.presetPopupsImageUrls?.filter(
    (f) => f !== imageUrl,
  );
};
