import presetMusicLoops from '@web/data/audio/preset_music_loop';
import { useSettingsStore } from '@web/store/settings';
import { Howl } from 'howler';
import { useCallback, useEffect, useRef } from 'react';
import { getVolume } from '../utils/audio';

export const usePlayerAudio = () => {
  const sounds = useRef<HowlAudio[]>([]);
  const music = useRef<Howl | undefined>(undefined);

  const {
    audio: { muted, volume },
  } = useSettingsStore();

  const playMusic = useCallback((url: string, volume = 100) => {
    if (music.current) {
      music.current.unload();
    }

    const isPreset = url.startsWith('/');
    const filename = isPreset ? url.split('/').pop()?.split('.').shift() : '';
    const [startLoop, endLoop] =
      filename && filename in presetMusicLoops
        ? presetMusicLoops[filename as keyof typeof presetMusicLoops]
            .split('-')
            .map(Number)
        : [0, 0];

    if (endLoop > startLoop) {
      const sound = new Howl({
        src: [url],
        html5: !isPreset,
        volume: (volume / 100) * getVolume('music'),
        loop: false,
        sprite: {
          main: [0, endLoop],
          loop: [startLoop, endLoop - startLoop, true],
        },
      });

      sound.once('end', () => {
        sound.play('loop');
      });

      sound.play('main');

      music.current = sound;
    } else {
      const sound = new Howl({
        src: [url],
        html5: !isPreset,
        volume: (volume / 100) * getVolume('music'),
        loop: true,
      });

      sound.play();

      music.current = sound;
    }
  }, []);

  const fadeMusic = useCallback((volume: number, duration: number) => {
    if (!music.current) {
      return;
    }

    music.current.fade(
      music.current.volume(),
      (volume / 100) * getVolume('music'),
      duration,
    );
  }, []);

  const stopMusic = useCallback(() => {
    if (music.current) {
      music.current.unload();
    }

    music.current = undefined;
  }, []);

  const playSound = useCallback((url: string, volume = 100, limit = 5) => {
    if (!!limit && sounds.current.length >= limit) {
      console.warn('Too many sounds playing at once');

      return;
    }

    const isPreset = url.startsWith('/');
    const sound = new Howl({
      src: [url],
      html5: !isPreset,
      volume: (volume / 100) * getVolume('sound'),
    });

    sound.on('end', (id) => {
      sounds.current = sounds.current.filter((sound) => sound.id !== id);

      sound.unload();
    });

    const id = sound.play();

    sounds.current.push({ id, howl: sound });

    return id;
  }, []);

  const stopSound = useCallback((id: number) => {
    const sound = sounds.current.find((sound) => sound.id === id);

    if (!sound) {
      return;
    }

    sound.howl.unload(); // TODO unload or stop?

    sounds.current = sounds.current.filter((sound) => sound.id !== id);
  }, []);

  const stopAllSounds = useCallback(() => {
    sounds.current.forEach((sound) => sound.howl.unload());

    sounds.current = [];
  }, []);

  const reset = useCallback(() => {
    stopAllSounds();
    stopMusic();
  }, [stopAllSounds, stopMusic]);

  // volume change

  const handleVolumeChange = useCallback(() => {
    Howler.volume(getVolume('master'));

    sounds.current.forEach((sound) => {
      sound.howl.volume(getVolume('sound'));
    });

    if (music.current) {
      music.current.volume(getVolume('music'));
    }
  }, []);

  useEffect(() => {
    handleVolumeChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [volume, muted]);

  useEffect(() => {
    return () => {
      stopAllSounds();
      stopMusic();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    playSound,
    stopSound,
    stopAllSounds,
    playMusic,
    stopMusic,
    fadeMusic,
    reset,
    sounds,
    music,
  };
};

type HowlAudio = {
  id: number;
  howl: Howl;
};
