import { Frame } from '@web/types/project';
import { useMemo } from 'react';
import { useCharacter } from './useCharacter';

// not reactive
export const useSpeechBubble = (frame: Frame | undefined) => {
  const character = useCharacter(frame);

  const speechBubble = useMemo(() => {
    if (!frame) return undefined;

    return character?.speechBubbles.find(
      (speechBubble) => speechBubble.id === frame.speechBubble,
    );
  }, [character?.speechBubbles, frame]);

  return speechBubble;
};
