import { CssBaseline } from '@mui/material';
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from '@mui/material/styles';
import { useRootStore } from '@web/store/root/state';
import { useMemo } from 'react';
import { darkTheme, lightTheme } from './theme';

const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const { theme } = useRootStore();

  const muiTheme = useMemo(
    () => createTheme(theme === 'light' ? lightTheme : darkTheme),
    [theme],
  );

  return (
    <MuiThemeProvider theme={muiTheme}>
      <CssBaseline />

      {children}
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
