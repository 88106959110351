import {
  ContentCopy,
  Delete,
  FlashOn,
  Settings,
  Visibility,
} from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { useAnchorMenu } from '@web/hooks/useAnchorMenu';
import { makerActions } from '@web/store/maker/actions';
import { makerStore, updateFrame, useMakerStore } from '@web/store/maker/state';
import React, { ChangeEvent, useCallback, useState } from 'react';
import Switch from '../common/form/Switch';
import { MenuList } from '../common/ui/MenuList';
import { Popper } from '../common/ui/Popper';
import { FrameToolbarButton } from './FrameToolbarButton';
import { useFrameEditor } from './providers/FrameEditorProvider';

export const FrameEditorAppBar = React.memo(() => {
  const { frameId, frameIndex } = useFrameEditor();

  const handlePreview = useCallback(() => {
    makerStore.previewFrameId = frameId;
    makerStore.dialogs.preview = true;
  }, [frameId]);

  return (
    <AppBar position="static" color="secondary" elevation={0} enableColorOnDark>
      <Toolbar
        variant="dense"
        sx={{ minHeight: '32px' }}
        onClick={() => {
          makerStore.focusedFrameIndex = frameIndex;
          makerStore.sceneEditorFrameIndex = frameIndex;
          makerStore.frameActionsFrameIndex = frameIndex;
        }}
        disableGutters
      >
        <FrameIdCopyToolbarButton id={frameId} />

        <FrameMenuToolbarButton />

        <Box flexGrow={1} />

        <FrameSettingsToolbarButton />

        <FrameToolbarButton
          bl={true}
          width={48}
          height={32}
          onClick={handlePreview}
        >
          <Visibility />
        </FrameToolbarButton>

        <DeleteFrameToolbarButton />

        <ActionsShortcutToolbarButton />
      </Toolbar>
    </AppBar>
  );
});

const ActionsShortcutToolbarButton = React.memo(() => {
  const { frameIndex } = useFrameEditor();

  const handleFrameActionsClick: React.MouseEventHandler<HTMLButtonElement> = (
    e,
  ) => {
    e.stopPropagation();

    makerStore.frameActionsFrameIndex = frameIndex;
    makerStore.dialogs.frameActions = true;
  };

  return (
    <FrameToolbarButton
      bl={true}
      sx={{ paddingInline: 2, display: { xs: 'none', sm: 'flex' } }}
      width={48}
      height={32}
      onClick={handleFrameActionsClick}
    >
      Actions
    </FrameToolbarButton>
  );
});

const DeleteFrameToolbarButton = React.memo(() => {
  const { frameIndex } = useFrameEditor();

  const handleDelete = () => {
    makerActions.deleteFrame(frameIndex);
  };

  return (
    <FrameToolbarButton bl={true} width={48} height={32} onClick={handleDelete}>
      <Delete />
    </FrameToolbarButton>
  );
});

const FrameIdCopyToolbarButton = React.memo(({ id }: { id: number }) => {
  const [tooltipText, setTooltipText] = useState('Copy frame ID');

  const { project } = useMakerStore();
  const { frameIndex } = useFrameEditor();

  const handleCopy = () => {
    navigator.clipboard.writeText(String(id));

    setTooltipText('Copied!');

    setTimeout(() => {
      setTooltipText('Copy frame ID');
    }, 1500);
  };

  return (
    <Tooltip title={tooltipText} placement="top">
      <Box>
        <FrameToolbarButton
          br={true}
          width={48}
          height={32}
          onClick={handleCopy}
        >
          <Typography variant="h6">
            {project?.type === 'case' ? id : frameIndex + 1}
          </Typography>
        </FrameToolbarButton>
      </Box>
    </Tooltip>
  );
});

const FrameSettingsToolbarButton = React.memo(() => {
  const { anchorEl, close, toggle } = useAnchorMenu();
  const { frames } = useMakerStore();
  const { frameIndex } = useFrameEditor();

  const handleMoveToNextChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    updateFrame({ moveToNext: checked }, frameIndex);
  };

  const handleMergeNextChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    updateFrame({ mergeWithNext: checked }, frameIndex);
  };

  const handleFrameActionsClick: React.MouseEventHandler<HTMLButtonElement> = (
    e,
  ) => {
    e.stopPropagation();

    makerStore.frameActionsFrameIndex = frameIndex;
    makerStore.dialogs.frameActions = true;

    close();
  };

  const handleFrameEffectsClick: React.MouseEventHandler<HTMLButtonElement> = (
    e,
  ) => {
    e.stopPropagation();

    makerStore.frameActionsFrameIndex = frameIndex;
    makerStore.dialogs.frameEffects = true;

    close();
  };

  return (
    <>
      <FrameToolbarButton bl={true} width={48} height={32} onClick={toggle}>
        {frames[frameIndex].actions?.length ? <FlashOn /> : <Settings />}
      </FrameToolbarButton>

      <Popper
        sx={{ width: 300 }}
        placement="bottom-start"
        anchorEl={anchorEl}
        onClose={close}
        open={!!anchorEl}
      >
        <Stack spacing={2} p={1}>
          <Typography fontWeight="bold">Frame Options</Typography>
          <Stack spacing={1}>
            <Switch
              color="info"
              label="Move to next frame immediately"
              checked={frames[frameIndex].moveToNext || false}
              onChange={handleMoveToNextChange}
            />
            <Switch
              color="info"
              label="Merge text with next"
              checked={frames[frameIndex].mergeWithNext || false}
              onChange={handleMergeNextChange}
            />
          </Stack>
        </Stack>

        <Divider />

        <Grid container spacing={1} p={1}>
          <Grid item xs>
            <Button
              variant="contained"
              color="info"
              onClick={handleFrameActionsClick}
              fullWidth
            >
              Actions
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              variant="contained"
              color="info"
              onClick={handleFrameEffectsClick}
              fullWidth
            >
              Effects
            </Button>
          </Grid>
        </Grid>
      </Popper>
    </>
  );
});

const FrameMenuToolbarButton = () => {
  const { frameIndex, frameId } = useFrameEditor();
  const { anchorEl, close, toggle } = useAnchorMenu();

  const comment = makerStore.group?.comments[frameId];

  const handleCopyFrame = (full: boolean) => {
    makerActions.copyFrame(frameIndex, full);

    close();
  };

  const handleToggleComment = () => {
    if (!makerStore.group) return;

    if (comment !== undefined) {
      delete makerStore.group?.comments[frameId];
    } else {
      makerStore.newCommentFrameId = frameId;
      makerStore.group.comments[frameId] = '';
    }

    close();
  };

  return (
    <>
      <FrameToolbarButton br={true} width={48} height={32} onClick={toggle}>
        <ContentCopy />
      </FrameToolbarButton>

      <MenuList
        placement="bottom-start"
        anchorEl={anchorEl}
        onClose={close}
        open={!!anchorEl}
      >
        <MenuItem onClick={() => handleCopyFrame(false)}>Copy frame</MenuItem>
        <MenuItem onClick={() => handleCopyFrame(true)}>
          Copy frame (full)
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleToggleComment}>
          {comment !== undefined ? 'Remove' : 'Add'} comment
        </MenuItem>
      </MenuList>
    </>
  );
};
