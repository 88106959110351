import { useAssetStore } from '@web/store/assets/state';
import { useSettingsStore } from '@web/store/settings';
import { AudioTag } from './AudioTag';

export const SoundTag = ({ width }: { width: number }) => {
  const {
    favorite: { sound: favoriteList },
  } = useSettingsStore();

  const {
    sound: { preset, user },
  } = useAssetStore();

  return (
    <AudioTag
      type="sound"
      favoriteList={favoriteList}
      presetList={preset}
      userList={user}
      width={width}
    />
  );
};
