import { CaseFrame } from '@shared/types';
import { frameUtils } from '@web/utils/frame';
import easings from './easings';

export const migrateCharacterIds = (frames: CaseFrame[]) => {
  frames.forEach((frame) => {
    if (frame.poseId && !frame.characterId) {
      frame.characterId = frameUtils.getCharacterId(frame);
    }
  });
};

export const migrateFadeEasings = (frames: CaseFrame[]) => {
  frames.forEach((frame) => {
    if (frame.fade) {
      const easing = easings.find((f) => f.oldId === frame.fade!.easing);

      if (!easing) {
        frame.fade.easing = undefined;
      } else {
        frame.fade.easing = easing.id || undefined;
      }
    }
  });
};
