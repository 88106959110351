import { useTheme } from '@mui/material';
import { makerStore } from '@web/store/maker/state';
import {
  forwardRef,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { isMobile } from 'react-device-detect';
import { useSnapshot } from 'valtio';
import { useFrameEditor } from './providers/FrameEditorProvider';

export const FrameTextEditor = forwardRef<HTMLTextAreaElement>(
  (props, forwardedRef) => {
    const { frameIndex, updateFrame } = useFrameEditor();
    const { frames } = useSnapshot(makerStore);

    // is this needed?
    const handleFocus = useCallback(() => {
      makerStore.focusedFrameIndex = frameIndex;

      const currentScrollPos = window.scrollY;

      requestAnimationFrame(() => {
        // TODO: is this needed?
        //window.scrollTo(0, currentScrollPos);
      });
    }, [frameIndex]);

    const onChange = useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        updateFrame({ text: e.target.value });
        setSelection([e.target.selectionStart, e.target.selectionEnd]);
      },
      [updateFrame],
    );

    const onKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key !== 'Tab') return;

        e.preventDefault();

        const match = frames[frameIndex].text.match(shortcutsRegex);

        if (!match) return;

        updateFrame({
          text: frames[frameIndex].text.replace(
            shortcutsRegex,
            '[#(1)(2)]'.replace('(1)', '$1').replace('(2)', '$2'),
          ),
        });
      },
      [frameIndex, frames, updateFrame],
    );

    const theme = useTheme();

    // ♿
    const [selection, setSelection] = useState<
      [number | null, number | null] | null
    >(null);

    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    useLayoutEffect(() => {
      if (!textAreaRef.current) return;
      if (forwardedRef && typeof forwardedRef === 'function') {
        forwardedRef(textAreaRef.current);
      } else if (forwardedRef && 'current' in forwardedRef) {
        forwardedRef.current = textAreaRef.current;
      }

      if (selection) {
        textAreaRef.current.selectionStart = selection[0] ?? 0;
        textAreaRef.current.selectionEnd = selection[1] ?? 0;
      }
    }, [selection, forwardedRef]);

    // TODO: use an editor like slate.js maybe
    return (
      <textarea
        ref={textAreaRef}
        onFocus={handleFocus}
        maxLength={600}
        value={frames[frameIndex].text}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={frameIndex === 0 ? 'Enter your text here...' : ''}
        style={{
          width: '100%',
          height: '100%',
          paddingInline: 4,
          paddingBlock: 4,
          overflowY: 'auto',
          border: 'none',
          outline: 'none',
          backgroundColor: 'transparent',
          resize: 'none',
          color: theme.palette.text.primary,
          fontFamily: 'roboto, sans-serif',
          fontSize: '100%',
          letterSpacing: isMobile ? '0.5px' : '1px',
        }}
      />
    );
  },
);

const shortcutsRegex = /(p|ts|ss|sm|sl)\.([0-9]+)/gim;
