import { createContext, useContext } from 'react';

export type PlayerMetaContextType = {
  isRecording?: boolean;
  preventSamePoseAnimation?: boolean;
};

export const PlayerMetaContext = createContext<
  PlayerMetaContextType | undefined
>(undefined);

export const PlayerMetaProvider = PlayerMetaContext.Provider;

export const usePlayerMeta = () => useContext(PlayerMetaContext);
