import { CaseFrame, Pair } from '@shared/types';
import { Frame } from '@web/types/project';

export const PairingUtils = {
  changeMainCharacter(frame: CaseFrame, characterId: number) {
    const currentCharacterId = frame?.characterId;

    if (!frame || !frame.pair || currentCharacterId === characterId) {
      return frame;
    }

    const newPoseIds = { ...frame.pair.poseIds };
    const currentPoseId = newPoseIds[characterId];

    if (currentCharacterId) {
      newPoseIds[currentCharacterId] = frame.poseId ?? null;
    }

    delete newPoseIds[characterId];

    return {
      ...frame,
      characterId,
      poseId: currentPoseId ?? undefined,
      pair: {
        ...frame.pair,
        poseIds: newPoseIds,
      },
    } satisfies Frame;
  },
  removeUnusedPoses(frame: CaseFrame, pair: Pair) {
    if (!frame?.pair || !pair) return frame.pair;

    const usedCharacterIds: number[] = pair.pairs
      .map((m) => m.characterId)
      .filter((f): f is number => f !== null && f !== undefined);

    const newPoseIds = Object.entries(frame.pair.poseIds).reduce<
      Record<number, number | null>
    >((acc, [characterId, poseId]) => {
      if (usedCharacterIds.includes(+characterId)) {
        acc[+characterId] = poseId;
      }

      return acc;
    }, {});

    return {
      ...frame.pair,
      poseIds: newPoseIds,
    } satisfies Frame['pair'];
  },
};
