import { SceneTargets } from '@shared/types/scene-targets';
import { Frame } from '@web/types/project';
import { useEffect, useMemo, useState } from 'react';
import { usePlayer } from '../providers/PlayerProvider';
import { usePlayerResetRegister } from './usePlayerResetRegister';
import { usePlayerSaveLoadRegister } from './usePlayerSaveLoadRegister';

export const useFlipped = (frame: Frame | undefined, target: SceneTargets) => {
  const [flipped, setFlipped] = useState(
    (frame && frame.flipped && (frame.flipped & target ? true : false)) ||
      false,
  );

  const { step } = usePlayer();

  usePlayerSaveLoadRegister({
    name: `flipped-${target}`,
    onSave: () => flipped,
    onLoad: (data) => setFlipped(data),
  });

  usePlayerResetRegister({
    name: `flipped-${target}`,
    onReset: () => setFlipped(false),
  });

  useEffect(() => {
    if (!frame || step !== 'character') {
      return;
    }

    setFlipped(!!frame.flipped && (frame.flipped & target ? true : false));
  }, [frame, step, target]);

  return flipped;
};

export const useFlippedBasic = (
  flipped: SceneTargets | undefined,
  target: SceneTargets | undefined,
) => {
  const isFlipped = useMemo(() => {
    if (!flipped || !target) {
      return false;
    }

    return flipped & target ? true : false;
  }, [flipped, target]);

  return isFlipped;
};
