import { useColorFade } from './hooks/useFade';
import { FadeColorBox } from './ui/FadeBox';

export const PlayerColorFade = () => {
  const { fade, fadeAnimation } = useColorFade();

  if (!fade) return null;

  return (
    <FadeColorBox
      style={{
        backgroundColor: fade.color,
        zIndex: fade.zIndex ?? 0,
        ...fadeAnimation,
      }}
    />
  );
};
