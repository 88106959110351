import { PlayArrow, Stop } from '@mui/icons-material';
import { Button, ButtonProps } from '@mui/material';
import { useAudio } from '@web/hooks/useAudio';

type AudioPreviewButtonProps = {
  url: string;
  volume: number;
  disabled?: boolean;
} & ButtonProps;

export const AudioPreviewButton = ({
  url,
  volume,
  disabled,
  ...props
}: AudioPreviewButtonProps) => {
  const { play, playing } = useAudio({ url, volume });

  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={playing ? <Stop /> : <PlayArrow />}
      onClick={play}
      disabled={!url || disabled}
      {...props}
    >
      {playing ? 'Stop' : 'Preview'}
    </Button>
  );
};
