import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { ProjectType } from '@shared/types';
import { CaseDto, SceneDto } from '@web/api/api';
import { ApiClient } from '@web/api/api-client';
import { useAuth } from '@web/providers/auth/AuthProvider';
import { makerActions } from '@web/store/maker/actions';
import { makerStore, useMakerStore } from '@web/store/maker/state';
import { getErrorMessage } from '@web/utils/error';
import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { proxy, useSnapshot } from 'valtio';

const state = proxy({
  loading: false,
  error: undefined as string | undefined,
});

export const SubmitDialog = () => {
  const {
    project,
    editId,
    dialogs: { submit },
  } = useMakerStore();

  const { loggedIn } = useAuth();

  const { error } = useSnapshot(state);

  const handleClose = () => {
    makerStore.dialogs.submit = false;
  };

  const dialogTitle =
    project?.type === 'case'
      ? editId
        ? 'Edit case'
        : 'Submit case'
      : editId
        ? 'Edit scene'
        : 'Submit scene';

  return (
    <Dialog
      open={!!submit}
      onClose={handleClose}
      PaperProps={{ sx: { m: 0, width: 'calc(100% - 16px)' }, elevation: 1 }}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>{dialogTitle}</DialogTitle>

      <DialogContent>
        {error && (
          <Alert severity="error" variant="filled" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {!loggedIn && !error && (
          <Alert severity="warning" variant="filled" sx={{ mb: 2 }}>
            You are not logged in, you will not be able to modify or delete this
            submission later
          </Alert>
        )}

        <TitleInput />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <SubmitButton />
      </DialogActions>
    </Dialog>
  );
};

const SubmitButton = () => {
  const { editId } = useMakerStore();

  const { loading } = useSnapshot(state);
  const navigate = useNavigate();

  const handleSubmit = useCallback(async () => {
    if (!makerStore.project) return;

    state.error = undefined;
    state.loading = true;

    try {
      if (makerStore.project.type === 'case') {
        let res: AxiosResponse<CaseDto, unknown>;

        if (!editId) {
          res = await ApiClient.case.create({
            data: makerStore.project,
            title: makerStore.title || '',
          });
        } else {
          res = await ApiClient.case.update(String(editId), {
            data: makerStore.project,
            title: makerStore.title || '',
          });
        }

        makerActions.newProject(ProjectType.Case);

        console.log(res.data.caseId);
      } else {
        let res: AxiosResponse<SceneDto, unknown>;

        if (!editId) {
          res = await ApiClient.scene.create({
            data: makerStore.project,
            title: makerStore.title || '',
          });
        } else {
          res = await ApiClient.scene.update(Number(editId), {
            data: makerStore.project,
            title: makerStore.title || '',
          });
        }

        makerActions.newProject(ProjectType.Scene);

        navigate(`/objection/${res.data.id}`);
      }

      makerActions.addFrame(0);

      localStorage.setItem('makerFrames', makerActions.getSaveData());

      makerStore.dialogs.submit = false;
    } catch (error) {
      state.error = getErrorMessage(error);
    } finally {
      state.loading = false;
    }
  }, [editId, navigate]);

  return (
    <Button onClick={handleSubmit} disabled={loading}>
      Submit
    </Button>
  );
};

const TitleInput = () => {
  const { project, title } = useMakerStore();

  const helperText =
    project?.type === 'case' ? 'Visible to players' : 'Only visible to you';

  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    makerStore.title = event.target.value;
  };

  return (
    <TextField
      label="Title"
      variant="standard"
      value={title}
      onChange={handleChangeTitle}
      helperText={helperText}
      autoFocus
      fullWidth
    />
  );
};
