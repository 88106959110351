import {
  Alert,
  AlertTitle,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import { useAuth } from '@web/providers/auth/AuthProvider';
import { getErrorMessage } from '@web/utils/error';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

const ProviderLoginPage = () => {
  const [success, setSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { discordLogin, googleLogin } = useAuth();
  const { provider } = useParams();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const callbackError = searchParams.get('error');

  const onSuccessLogin = () => {
    setSuccess(true);
    window.close();
  };

  useEffect(() => {
    if (!code || !provider) {
      setErrorMessage(
        !callbackError
          ? 'Invalid code or provider.'
          : 'Access was denied by the user.',
      );

      return;
    }

    const providerLogin = async () => {
      try {
        switch (provider) {
          case 'discord':
            await discordLogin(code);
            onSuccessLogin();
            break;
          case 'google':
            await googleLogin(code);
            onSuccessLogin();
            break;
          default:
            setErrorMessage('Invalid provider.');
            break;
        }

        window.close();
      } catch (error) {
        setErrorMessage(getErrorMessage(error));
      }
    };

    providerLogin();
  }, []);

  const error = errorMessage !== '';

  return (
    <Stack p={4} spacing={2} alignItems="center">
      {error && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      )}

      {!error && !success && (
        <Stack spacing={2} alignItems="center">
          <CircularProgress />
          <Typography variant="h5">Logging in...</Typography>
        </Stack>
      )}

      {success && (
        <>
          <Typography variant="h5">Successfully logged in.</Typography>
          <Typography variant="caption">You may close this window.</Typography>
        </>
      )}
    </Stack>
  );
};

export default ProviderLoginPage;
