import { ProjectType } from '@shared/types';
import { ApiClient } from '@web/api/api-client';
import { useWait } from '@web/hooks/useWait';
import { useAuth } from '@web/providers/auth/AuthProvider';
import { makerStore, useMakerStore } from '@web/store/maker/state';
import { settingsStore } from '@web/store/settings';
import { useEffect } from 'react';
import { useInterval } from 'usehooks-ts';

export const useMakerProjectBackup = () => {
  const { loggedIn } = useAuth();
  const { project } = useMakerStore();

  const waited = useWait(2500); // wait time because project loading at the start of the app

  const handleBackup = () => {
    if (
      !project ||
      !loggedIn ||
      !makerStore.changesDetected ||
      !settingsStore.enableBackups
    )
      return;

    try {
      if (project.type === ProjectType.Scene) {
        ApiClient.draft.createScene({
          title: makerStore.title || `Scene Backup`,
          data: project,
        });
      } else {
        ApiClient.draft.createCase({
          title: makerStore.title || `Case Backup`,
          data: project,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      makerStore.changesDetected = false;
    }
  };

  useEffect(() => {
    if (!waited) return;

    makerStore.changesDetected = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useInterval(handleBackup, 2 * 60 * 1000);
};
