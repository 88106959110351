import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { makerStore } from '@web/store/maker/state';
import { projectUtils } from '@web/utils/project';
import React, { useCallback, useState } from 'react';
import { useSnapshot } from 'valtio';

export const SaveDialog = React.memo(() => {
  const [fileName, setFileName] = useState('Untitled Project');
  const snapshot = useSnapshot(makerStore.dialogs);

  const handleClose = useCallback(() => {
    makerStore.dialogs.saveProject = false;
  }, []);

  const handleSave = useCallback(() => {
    if (!makerStore.project) return;

    const base64Data = projectUtils.saveProject(makerStore.project);

    const a = document.createElement('a');
    a.href = 'data:application/octet-stream,' + base64Data;
    a.download = `${fileName ?? 'Untitled Project'}.objection`;
    a.click();

    handleClose();
  }, [fileName, handleClose]);

  if (!snapshot.saveProject) {
    return null;
  }

  return (
    <Dialog
      open={!!snapshot.saveProject}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>Save Project</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            label="File Name"
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleSave()}
            variant="standard"
            autoFocus
            fullWidth
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button color="primary" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
});
