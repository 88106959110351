import { UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragHandle, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
} from '@mui/material';
import { CaseFrame } from '@shared/types';
import { SpeechBubbleDto } from '@web/api/api';
import { ApiClient } from '@web/api/api-client';
import { FramePreviewer } from '@web/components/common/FramePreviewer';
import MultiHideView from '@web/components/common/ui/MultiHideView';
import { PlayerAssetsProvider } from '@web/components/player/providers/PlayerAssetsProvider';
import { SortableDndProvider } from '@web/providers/dnd/SortableDndProvider';
import { assetActions } from '@web/store/assets/actions';
import { debounce } from 'lodash';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { proxy, ref, useSnapshot } from 'valtio';
import { useProxy } from 'valtio/utils';
import { assetDialogState } from '../../dialogs/AssetsDialogState';
import { useCharacterFormTab } from '../AdvancedCharacterFormState';
import {
  CharacterExtraEditorContextValue,
  CharacterExtraEditorProvider,
  useCharacterEditor,
  useCharacterExtraEditor,
} from '../providers/CharacterEditorProvider';
import { MainSpeechBubbleEditor } from './SpeechBubbleMainEditor';

export const SpeechBubblesEditor = () => {
  const { scrollRef } = useSnapshot(assetDialogState);

  const state = useRef(
    proxy<CharacterExtraEditorContextValue>({
      expanded: false,
      previewIndex: undefined,
      scrollTop: ref({ value: 0 }),
      setPreviewIndex: (index: number | undefined) => {
        if (index !== undefined) {
          state.scrollTop.value = scrollRef.current?.scrollTop || 0;
        } else {
          setTimeout(() => {
            scrollRef.current?.scrollTo({
              top: state.scrollTop.value,
              behavior: 'instant',
            });
          }, 0);
        }
        state.previewIndex = index;
      },
    }),
  ).current;

  const characterState = useCharacterEditor();

  const { character } = useProxy(characterState);
  const { previewIndex } = useProxy(state);

  const handleAddSpeechBubble = () => {
    characterState.character.speechBubbles.push({
      id: -Math.random() * 1000 - 1,
      name: `Speech Bubble ${characterState.character.speechBubbles.length + 1}`,
      duration: 1200,
      imageUrl: '',
      soundUrl: '',
      order: characterState.character.speechBubbles.length,
      shake: true,
    });

    state.expanded = characterState.character.speechBubbles.length - 1;
  };

  const debounceReorderSpeechBubbles = useRef(
    debounce((characterId: number, speechBubbles: SpeechBubbleDto[]) => {
      const savedItems = speechBubbles.filter((f) => f.id > 0);

      if (savedItems.length === 0) return;

      reorderSpeechBubbles(characterId, savedItems);
    }, 800),
  ).current;

  const handleDragEnd = (
    sourceId: UniqueIdentifier,
    destinationId?: UniqueIdentifier | undefined,
  ) => {
    const oldIndex = characterState.character.speechBubbles.findIndex(
      (i) => i.id === sourceId,
    );
    const newIndex = characterState.character.speechBubbles.findIndex(
      (i) => i.id === destinationId,
    );

    characterState.character.speechBubbles = arrayMove(
      characterState.character.speechBubbles,
      oldIndex,
      newIndex,
    );
    characterState.character.speechBubbles =
      characterState.character.speechBubbles.map((p, i) => ({
        ...p,
        order: i,
      }));

    debounceReorderSpeechBubbles(
      characterState.characterId,
      characterState.character.speechBubbles,
    );
  };

  return (
    <Stack spacing={2}>
      <CharacterExtraEditorProvider value={state}>
        <MultiHideView index={previewIndex === undefined ? 0 : 1}>
          <>
            <Button
              variant="contained"
              color="info"
              onClick={handleAddSpeechBubble}
              disabled={character.speechBubbles.length >= 50}
              fullWidth
            >
              Add Speech Bubble
            </Button>

            <SortableDndProvider
              items={character.speechBubbles}
              onDragEnd={handleDragEnd}
            >
              {character.speechBubbles.map((speechBubble, index) => (
                <DraggableSpeechBubble key={speechBubble.id} index={index} />
              ))}
            </SortableDndProvider>
          </>

          <Previewer />
        </MultiHideView>
      </CharacterExtraEditorProvider>
    </Stack>
  );
};

const DraggableSpeechBubble = memo(({ index }: { index: number }) => {
  const state = useCharacterExtraEditor();
  const characterState = useCharacterEditor();

  const { character } = useSnapshot(characterState);
  const { expanded } = useSnapshot(state);

  const speechBubble = character.speechBubbles[index];

  const handleExpand = (index: number) => {
    state.expanded = state.expanded === index ? false : index;
  };

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: speechBubble.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Accordion
      ref={setNodeRef}
      style={style}
      expanded={index === expanded}
      onChange={() => handleExpand(index)}
      slotProps={{ transition: { unmountOnExit: true } }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        {speechBubble.name}

        <Box flexGrow={1} />

        <Box display="flex" {...attributes} {...listeners}>
          <DragHandle sx={{ mx: 1, cursor: 'move' }} />
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <MainSpeechBubbleEditor index={index} />
      </AccordionDetails>
    </Accordion>
  );
});

const Previewer = () => {
  const [frame, setFrame] = useState(undefined as CaseFrame | undefined);

  const characterFormTab = useCharacterFormTab();

  const state = useCharacterExtraEditor();
  const characterState = useCharacterEditor();

  const { character } = useProxy(characterState);

  const { setPreviewIndex, previewIndex } = useSnapshot(state);

  const onBack = useCallback(() => {
    setPreviewIndex(undefined);
  }, [setPreviewIndex]);

  useEffect(() => {
    if (
      previewIndex !== undefined &&
      characterState.character.poses.length > 0
    ) {
      setFrame({
        id: -1,
        text: 'This is sample text.',
        characterId: characterState.characterId,
        poseId: characterState.character.poses[0].id,
        speechBubble: characterState.character.speechBubbles[previewIndex].id,
      });
    } else {
      setFrame(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewIndex]);

  useEffect(() => {
    setPreviewIndex(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [characterFormTab]);

  return (
    <PlayerAssetsProvider
      value={{
        character: { [character.id]: character },
      }}
    >
      <FramePreviewer frame={frame} onBack={onBack} />
    </PlayerAssetsProvider>
  );
};

const reorderSpeechBubbles = async (
  characterId: number,
  speechBubbles: SpeechBubbleDto[],
) => {
  await ApiClient.assets.character.reorderSpeechBubbles({
    characterId,
    speechBubbles: speechBubbles.map((p) => p.id),
  });

  assetActions.updateUserAssetLocally('character', characterId, {
    speechBubbles,
  });
};
