import easings from '@web/data/easings';
import { useEffect, useState } from 'react';
import { animated, useSpring, useSpringRef } from 'react-spring';
import { usePlayerResetRegister } from './hooks/usePlayerResetRegister';
import { usePlayerSaveLoadRegister } from './hooks/usePlayerSaveLoadRegister';
import { usePlayer } from './providers/PlayerProvider';
import { Viewport } from './ui/Viewport';

export const PlayerViewport = ({ children }: { children: React.ReactNode }) => {
  const [fullWidth, setFullWidth] = useState<boolean | undefined>(false);

  const {
    frame: { pose, background, shouldPanBackground, transitionDuration, frame },
    step,
    actions: { nextStep },
    timeline: { addEvent },
    state: { skipping },
  } = usePlayer();

  const api = useSpringRef();
  const leftAnimation = useSpring({
    ref: api,
    from: {
      left: 0,
    },
  });

  usePlayerSaveLoadRegister({
    name: 'viewport',
    onSave: () => ({ fullWidth, left: leftAnimation.left.get() }),
    onLoad: (data) => {
      setFullWidth(data.fullWidth);

      api.start({
        from: {
          left: data.left,
        },
        to: {
          left: data.left,
        },
        immediate: true,
      });
    },
  });

  usePlayerResetRegister({
    name: 'viewport',
    onReset: () => {
      setFullWidth(false);

      api.start({
        from: {
          left: 0,
        },
        to: {
          left: 0,
        },
        immediate: true,
      });
    },
  });

  useEffect(() => {
    if (step !== 'character') return;

    setFullWidth(!background?.isWide || pose?.isSpeedlines);

    if (frame && shouldPanBackground && !transitionDuration) {
      api.start({
        left: frame.transition?.left ?? 0,
        immediate: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    if (step !== 'transition') return;

    if (frame && transitionDuration) {
      const left = frame.transition?.left ?? 0;
      const easing = frame?.transition?.easing
        ? easings.find((f) => f.id === frame.transition!.easing)?.value
        : (t: number) => t;

      api.start({
        left: left,
        config: { duration: transitionDuration, easing: easing },
        onStart: () => {
          addEvent(() => {
            nextStep();
          }, transitionDuration / 2);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    if (step !== 'transition_wait') return;

    addEvent(() => {
      nextStep();
    }, transitionDuration / 2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    if (!skipping) return;

    api.start({
      immediate: true,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipping]);

  return (
    <AnimatedViewport
      fullWidth={fullWidth}
      left={leftAnimation.left.to((left) => Math.min(left, 99.98))}
    >
      {children}
    </AnimatedViewport>
  );
};

const AnimatedViewport = animated(Viewport);
