import { useFont } from '@web/hooks/useFont';
import { CSSProperties, useEffect, useMemo, useRef } from 'react';
import { usePlayerDialogueBoxContext } from '../providers/PlayerDialogueBoxContext';
import { usePlayer } from '../providers/PlayerProvider';
import {
  DialogueBoxContainer,
  DialogueBoxImage as DialogueBoxImageComponent,
  DialogueBoxTextContainer,
} from '../ui/Dialogue';
import { DialogueBoxText } from './DialogueBoxText';
import { Nameplate, NameplateProps } from './Nameplate';
import { DialogueBoxTextContainerProps } from './types';

type DialogueBoxProps = {
  url: string;
  opacity?: number;
  imageOpacity?: number;
  wordSpacing?: number;
  letterSpacing?: number;
  text: DialogueBoxTextContainerProps;
  nameplate: NameplateProps;
  NextButton?: React.ReactNode;
};

export const DialogueBox = ({
  text,
  nameplate,
  url,
  opacity = 1,
  imageOpacity = 1,
  wordSpacing,
  letterSpacing,
  NextButton,
}: DialogueBoxProps) => {
  const hiddenTextRef = useRef<HTMLDivElement>(null);
  const visibleTextRef = useRef<HTMLDivElement>(null);

  const {
    playerUi: {
      state: { size },
    },
    playerDefaults: { centerText },
  } = usePlayer();

  const { fontUrl, ...textProps } = text;

  useFont(text.fontFamily, text.fontUrl);
  useFont(nameplate.fontFamily, nameplate.fontUrl);

  const handleAutoScroll = () => {
    const hiddenTextDiv = hiddenTextRef.current;
    const visibleTextDiv = visibleTextRef.current;

    if (hiddenTextDiv && visibleTextDiv) {
      hiddenTextDiv.scrollTop =
        hiddenTextDiv.scrollHeight - hiddenTextDiv.clientHeight;

      visibleTextDiv.scrollTop = hiddenTextDiv.scrollTop;
    }
  };

  useEffect(() => {
    const hiddenTextDiv = hiddenTextRef.current;

    if (hiddenTextDiv) {
      const observer = new MutationObserver(() => {
        handleAutoScroll();
      });

      observer.observe(hiddenTextDiv, {
        childList: true,
        subtree: true,
        characterData: true,
      });

      const resizeObserver = new ResizeObserver(() => {
        handleAutoScroll();
      });

      resizeObserver.observe(hiddenTextDiv);

      return () => {
        observer.disconnect();
        resizeObserver.disconnect();
      };
    }
  }, []);

  const wordSpacingSize = wordSpacing ? wordSpacing * size : undefined;
  const letterSpacingSize = letterSpacing ? letterSpacing * size : undefined;

  const containerStyle = useMemo<CSSProperties>(
    () => ({
      opacity,
      ...(wordSpacingSize && { wordSpacing: `${wordSpacingSize}em` }),
      ...(letterSpacingSize && { letterSpacing: `${letterSpacingSize}em` }),
    }),
    [letterSpacingSize, opacity, wordSpacingSize],
  );

  const textContainerStyle = useMemo<CSSProperties>(
    () => ({
      fontFamily: textProps.fontFamily,
      height: `${textProps.height}em`,
      textAlign: !centerText ? 'start' : 'center',
      fontSize: `${text.fontSize * size}px`,
      width: centerText
        ? `${textProps.widthCenter ?? textProps.width}%`
        : `${textProps.width}%`,
      left: centerText
        ? `${textProps.leftCenter ?? textProps.left}%`
        : `${textProps.left}%`,
      top: centerText
        ? `${textProps.topCenter ?? textProps.top}%`
        : `${textProps.top}%`,
      lineHeight: textProps.lineHeight ? `${textProps.lineHeight}em` : 'unset',
    }),
    [
      centerText,
      size,
      text.fontSize,
      textProps.fontFamily,
      textProps.height,
      textProps.left,
      textProps.leftCenter,
      textProps.lineHeight,
      textProps.top,
      textProps.topCenter,
      textProps.width,
      textProps.widthCenter,
    ],
  );

  return (
    <>
      <DialogueBoxContainer style={containerStyle}>
        <DialogueBoxImage
          url={url}
          nameplate={nameplate}
          opacity={imageOpacity}
        />

        <DialogueBoxTextContainer
          ref={visibleTextRef}
          style={textContainerStyle}
          dir="auto"
        >
          <DialogueBoxText />
        </DialogueBoxTextContainer>

        {NextButton}
      </DialogueBoxContainer>

      <DialogueBoxContainer
        sx={{ visibility: 'hidden' }}
        style={containerStyle}
      >
        <DialogueBoxImage url={url} nameplate={nameplate} opacity={0} />

        <DialogueBoxTextContainer
          ref={hiddenTextRef}
          style={textContainerStyle}
          dir="auto"
        >
          <DialogueBoxText disableLeadingTransparentText />
        </DialogueBoxTextContainer>
      </DialogueBoxContainer>

      <Nameplate {...nameplate} />
    </>
  );
};

const DialogueBoxImage = ({
  url,
  opacity,
  nameplate,
}: {
  url: string;
  opacity: number;
  nameplate: NameplateProps;
}) => {
  const { showNameplate } = usePlayerDialogueBoxContext();

  const dialogueBoxUrl =
    nameplate.replaceBox && showNameplate ? nameplate.url : url;

  return (
    <DialogueBoxImageComponent
      alt="Dialogue Box"
      src={dialogueBoxUrl}
      style={{
        opacity,
      }}
    />
  );
};
