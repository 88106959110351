import { PlayArrow, Stop } from '@mui/icons-material';
import { Alert, Button, Stack, TextField, Typography } from '@mui/material';
import { CreateSoundDto, SoundDto, UpdateSoundDto } from '@web/api/api';
import { SliderWithInput } from '@web/components/common/form/SliderWithInput';
import { useAudio } from '@web/hooks/useAudio';
import { assetActions } from '@web/store/assets/actions';
import { urlValidation } from '@web/utils/yup';
import { memo, useEffect } from 'react';
import { Control, Controller, useFormState, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import Form from '../../common/form/Form';
import { DualGridPicker } from '../../maker/ui/DualGridPicker';
import { assetDialogState } from '../dialogs/AssetsDialogState';

export const SoundForm = memo(
  ({ onAdded }: { onAdded?: (data: SoundDto) => void }) => {
    const schema = yup.object().shape({
      id: yup.number().optional(),
      name: yup.string().required(),
      url: urlValidation().required(),
      deskUrl: urlValidation().optional().nullable(),
      isWide: yup.boolean().optional(),
    });

    const onSubmit = async (data: CreateSoundDto | UpdateSoundDto) => {
      if (assetDialogState.editingAssetId) {
        await assetActions.updateAsset(
          'sound',
          assetDialogState.editingAssetId,
          data,
        );
      } else {
        const asset = await assetActions.addAsset('sound', data);

        onAdded?.(asset);
      }

      assetDialogState.editingAsset = undefined;
    };

    const handleCancel = () => {
      assetDialogState.editingAsset = undefined;
    };

    return (
      <Form
        schema={schema}
        onSubmit={onSubmit}
        defaultValues={assetDialogState.editingAsset}
        mode="onChange"
      >
        {({ control, register, errors, loading }) => (
          <Stack spacing={2}>
            <Typography variant="h6">
              {assetDialogState.editingAssetId ? 'Edit' : 'Add'} Sound
            </Typography>

            <DualGridPicker>
              <Stack spacing={2}>
                {errors.root?.message && (
                  <Alert severity="error" variant="filled">
                    {errors.root.message}
                  </Alert>
                )}

                <TextField
                  label="Name"
                  {...register('name')}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  autoFocus
                  fullWidth
                />

                <TextField
                  label="URL"
                  {...register('url')}
                  error={!!errors.url}
                  helperText={errors.url?.message}
                  fullWidth
                />

                <Controller
                  control={control}
                  name="volume"
                  render={({ field }) => (
                    <SliderWithInput
                      label="Volume"
                      value={field.value ?? 0}
                      onChange={(value) => field.onChange(value)}
                      valueLabelDisplay="auto"
                      min={0}
                      max={100}
                      step={1}
                      size="small"
                    />
                  )}
                />
              </Stack>

              <SoundPreview control={control} />
            </DualGridPicker>

            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                {assetDialogState.editingAssetId ? 'Edit' : 'Add'}
              </Button>

              <Button variant="outlined" onClick={handleCancel}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        )}
      </Form>
    );
  },
);

const SoundPreview = ({
  control,
}: {
  control: Control<CreateSoundDto | UpdateSoundDto> | undefined;
}) => {
  const [url, volume] = useWatch({ control, name: ['url', 'volume'] });
  const { errors } = useFormState({ control });
  const { play, stop, playing } = useAudio({ url, volume });

  useEffect(() => {
    if (errors.url) {
      stop();
    }
  }, [errors.url, stop]);

  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={playing ? <Stop /> : <PlayArrow />}
      onClick={play}
      disabled={!url || !!errors.url}
    >
      {playing ? 'Stop' : 'Preview'}
    </Button>
  );
};
