import { Stack } from '@mui/material';
import { useDeleteConfirmation } from '@web/hooks/useDeleteConfirmation';
import { assetActions } from '@web/store/assets/actions';
import { AssetType, assetStore } from '@web/store/assets/state';
import { useCallback } from 'react';
import { useProxy } from 'valtio/utils';
import { AssetTabOptions } from './AssetDataGridOptions';
import { AssetsDataGridProps } from './AssetsDataGrid';
import { ShareCodeField } from './AssetSharing';
import { DataGridSearchField } from './AssetsSearchField';
import { assetDialogState } from './dialogs/AssetsDialogState';
import { getDefaultAssetAddValue, getDefaultAssetUpdateValue } from './utils';

const setSelectedIds = (selectedIds: number[]) => {
  assetDialogState.selectedIds = selectedIds;
};

type AssetsTabProps = {
  type: AssetType;
  DataGridComponent: React.ComponentType<Partial<AssetsDataGridProps>>;
};

export const AssetsTab = ({ type, DataGridComponent }: AssetsTabProps) => {
  const { selectedIds, editingAsset } = useProxy(assetDialogState);

  const deleteConfirmation = useDeleteConfirmation(selectedIds);

  const handleAddClick = useCallback(() => {
    assetDialogState.editingAsset = getDefaultAssetAddValue(type);
    assetDialogState.editingAssetId = undefined;
  }, [type]);

  const handleActionClick = useCallback(
    (action: string, id: number) => {
      if (action === 'edit') {
        const asset = assetStore[type].user.find((f) => f.id === id);

        if (!asset) return;

        assetDialogState.editingAssetId = id;
        assetDialogState.editingAsset = getDefaultAssetUpdateValue(type, asset);
      }
    },
    [type],
  );

  const handleDelete = useCallback(async () => {
    await deleteConfirmation.confirm(async () => {
      try {
        await assetActions.deleteAssets(type, selectedIds);
      } catch (error) {
        console.error(error);
      }
    });
  }, [deleteConfirmation, selectedIds, type]);

  return (
    <Stack spacing={2} display={!editingAsset ? 'inherit' : 'none'}>
      <AssetTabOptions
        type={type}
        onAddClick={handleAddClick}
        onDeleteClick={handleDelete}
        deleteConfirmation={deleteConfirmation}
      />

      <Stack spacing={1}>
        <Stack direction="row" spacing={1} justifyContent="space-between">
          <DataGridSearchField sx={{ width: 300 }} />

          {type === 'character' && <ShareCodeField type={type} />}
        </Stack>

        <DataGridComponent
          onSelectChange={setSelectedIds}
          onAction={handleActionClick}
          loading={deleteConfirmation.deleting}
          initialState={{
            sorting: { sortModel: [{ field: 'id', sort: 'desc' }] },
          }}
        />
      </Stack>
    </Stack>
  );
};
