import { useEffect } from 'react';
import { useSpring, useSpringRef } from 'react-spring';
import { FlashType } from '../types';

type useFlashProps = {
  flash?: FlashType;
  setFlash: (flash?: FlashType) => void;
};

export const useFlash = ({ flash, setFlash }: useFlashProps) => {
  const api = useSpringRef();

  const flashAnimation = useSpring({
    ref: api,
    from: { opacity: 1 },
  });

  useEffect(() => {
    if (!flash) {
      return;
    }

    const [duration1, duration2, opacity] = getFlashData(flash);

    api.start({
      opacity: opacity,
      config: { duration: duration1 },
      onRest: () => {
        api.start({
          opacity: 1,
          config: { duration: duration2 },
          onRest: () => {
            setFlash(undefined);
          },
        });
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flash]);

  return flashAnimation;
};

const getFlashData = (flash: FlashType | undefined) => {
  switch (flash) {
    case 'small':
      return [50, 30, 0.25];
    case 'medium':
      return [75, 45, 0.15];
    case 'large':
      return [100, 55, 0.05];
    default:
      return [undefined, undefined, undefined];
  }
};
