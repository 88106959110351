import { Box } from '@mui/material';
import { usePlayerDialogueBoxContext } from '../providers/PlayerDialogueBoxContext';

export const DialogueBoxText = ({
  disableLeadingTransparentText,
}: {
  disableLeadingTransparentText?: boolean;
}) => {
  const dialogue = usePlayerDialogueBoxContext();

  return (
    <>
      {dialogue?.dialogues.map((dialogue, index) => (
        <Box component="span" key={index} color={dialogue.color}>
          {dialogue.text}
        </Box>
      ))}

      {!disableLeadingTransparentText && (
        <Box component="span" color="transparent" sx={{ opacity: 0 }}>
          {dialogue?.plainText ?? ''}
        </Box>
      )}
    </>
  );
};
