import {
  PoseAudioDto,
  PoseFunctionDto,
  PoseStateDto,
  UpdateCharacterDto,
  UpdatePoseDto,
  UpdateSpeechBubbleDto,
} from '@web/api/api';
import { useEffect } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { useCharacterEditor } from './providers/CharacterEditorProvider';

type CharacterUpdateWatcherProps = {
  control: Control<UpdateCharacterDto>;
};

export const CharacterUpdateWatcher = ({
  control,
}: CharacterUpdateWatcherProps) => {
  const state = useCharacterEditor();

  const watch = useWatch({ control: control });

  useEffect(() => {
    state.character = {
      ...state.character,
      ...watch,
    };
  }, [state, watch]);

  return null;
};

type PoseUpdateWatcherProps = {
  control: Control<UpdatePoseDto>;
  index: number;
};

export const PoseUpdateWatcher = ({
  control,
  index,
}: PoseUpdateWatcherProps) => {
  const state = useCharacterEditor();

  const watch = useWatch({ control: control });

  useEffect(() => {
    state.character.poses[index] = {
      ...state.character.poses[index],
      ...watch,
    };
  }, [index, state.character.poses, watch]);

  return null;
};

type SpeechBubbleUpdateWatcherProps = {
  control: Control<UpdateSpeechBubbleDto>;
  index: number;
};

export const SpeechBubbleUpdateWatcher = ({
  control,
  index,
}: SpeechBubbleUpdateWatcherProps) => {
  const state = useCharacterEditor();

  const watch = useWatch({ control: control });

  useEffect(() => {
    state.character.speechBubbles[index] = {
      ...state.character.speechBubbles[index],
      ...watch,
    };
  }, [index, state.character.speechBubbles, watch]);

  return null;
};

type PoseExtraUpdateWatcherProps = {
  index: number;
  poseIndex: number;
} & (
  | { control: Control<PoseFunctionDto>; propKey: 'poseFunctionTicks' }
  | { control: Control<PoseStateDto>; propKey: 'poseStates' }
  | { control: Control<PoseAudioDto>; propKey: 'poseAudioTicks' }
);

export const PoseExtraUpdateWatcher = ({
  control,
  propKey,
  index,
  poseIndex,
}: PoseExtraUpdateWatcherProps) => {
  const state = useCharacterEditor();

  const watch = useWatch({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: control as any,
  });

  useEffect(() => {
    state.character.poses[poseIndex][propKey][index] = {
      ...state.character.poses[poseIndex][propKey][index],
      ...watch,
    };
  }, [index, poseIndex, propKey, state.character.poses, watch]);

  return null;
};
