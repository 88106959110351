import { Palette } from '@mui/icons-material';
import { Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { Popper } from '@web/components/common/ui/Popper';
import { useAnchorMenu } from '@web/hooks/useAnchorMenu';
import { MuiColorInput } from 'mui-color-input';
import { useState } from 'react';

import { FrameToolbarButton } from '../FrameToolbarButton';
import { useFrameEditor } from '../providers/FrameEditorProvider';

export const ColorTag = ({ width }: { width: number }) => {
  const { surroundTextWithColor } = useFrameEditor();

  const { anchorEl, toggle, close } = useAnchorMenu();
  const [color, setColor] = useState<string>('#ffffff');

  return (
    <>
      <FrameToolbarButton width={width} height={28} onClick={toggle}>
        <Palette />
      </FrameToolbarButton>

      <Popper
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={close}
        placement="top"
      >
        <Stack p={1}>
          <Typography>Text Color</Typography>
          <Typography color="text.secondary" variant="body2">
            Highlight the text you want to color
          </Typography>
        </Stack>

        <Divider />

        <Grid container p={1} spacing={1}>
          <Grid item xs>
            <Button
              color="error"
              variant="contained"
              size="small"
              onClick={() => surroundTextWithColor('r')}
              fullWidth
            >
              Red
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              color="success"
              variant="contained"
              size="small"
              onClick={() => surroundTextWithColor('g')}
              fullWidth
            >
              Green
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              color="info"
              variant="contained"
              size="small"
              onClick={() => surroundTextWithColor('b')}
              fullWidth
            >
              Blue
            </Button>
          </Grid>
        </Grid>

        <Divider />

        <Stack p={1} spacing={1}>
          <MuiColorInput
            value={color}
            onChange={setColor}
            format="hex"
            size="small"
            fallbackValue="#ffffff"
            isAlphaHidden
          />

          <Button
            color="accent"
            variant="contained"
            size="small"
            onClick={() => surroundTextWithColor(`c${color}`)}
            fullWidth
          >
            Insert Tag
          </Button>
        </Stack>
      </Popper>
    </>
  );
};
