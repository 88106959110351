import { ContentCopy } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useState } from 'react';

type CopyButtonProps = {
  onCopy: () => void;
} & React.ComponentProps<typeof IconButton>;

export const CopyButton = ({ onCopy, ...props }: CopyButtonProps) => {
  const [copied, setCopied] = useState(false);

  const handleCopyCode = () => {
    setCopied(true);

    onCopy();

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Tooltip title={!copied ? 'Copy' : 'Copied!'} disableInteractive>
      <IconButton {...props} onClick={handleCopyCode}>
        <ContentCopy />
      </IconButton>
    </Tooltip>
  );
};
