import React from 'react';

const MultiView = ({
  index,
  children,
}: {
  index: number;
  children: React.ReactNode;
}) => {
  const childrenArray = React.Children.toArray(children);

  if (index >= 0 && index < childrenArray.length) {
    return childrenArray[index];
  } else {
    return null;
  }
};

export default MultiView;
