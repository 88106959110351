import { AnyGroup, GroupType, InvestigationGroup } from '@shared/types';
import { GameUtils } from '@web/components/player/utils/game-utils';

import { CaseProjectDto, SceneProjectDto } from '@web/api/api';
import { FramesTarget } from '@web/types/project';
import { useCallback, useMemo, useRef, useState } from 'react';
import { proxy } from 'valtio';
import { useProxy } from 'valtio/utils';
import {
  GameDerivedState,
  GameInvestigationState,
  GameState,
} from '../types/state';
import { useInvestigationPlayer } from './useInvestigationPlayer';

export type ProjectPlayerType = {
  state: GameState & GameDerivedState & GameInvestigationState;
  project?: SceneProjectDto | CaseProjectDto;
  actions: {
    init: (
      newProject: SceneProjectDto | CaseProjectDto,
      framesTarget?: FramesTarget,
      frameIndex?: number,
    ) => void;
    reset: () => void;
    nextFrame: () => void;
    onFramePlayEnd: () => boolean;
  };
};

const defaultState: GameState = {
  frameIndex: -1,
  health: 100,
};

export const useProjectPlayer = (): ProjectPlayerType => {
  const [project, setProject] = useState<
    SceneProjectDto | CaseProjectDto | undefined
  >(undefined);

  const state = useRef(proxy<GameState>(defaultState)).current;
  const snapshot = useProxy(state);

  const { state: investigationState, actions: investigationActions } =
    useInvestigationPlayer(state);

  const group = useMemo(
    () =>
      project?.groups.find((f) => f.id === snapshot.groupId) as
        | AnyGroup
        | undefined,
    [project?.groups, snapshot.groupId],
  );

  const location = useMemo(
    () =>
      group?.type === GroupType.Investigation
        ? (group as InvestigationGroup).locations?.find(
            (f) => f.id === snapshot.locationId,
          ) ?? undefined
        : undefined,
    [group, snapshot.locationId],
  );

  const targetFrames = useMemo(() => {
    if (!project) return [];

    return GameUtils.getTargetFrames(snapshot, project) ?? [];
  }, [project, snapshot]);

  const frame = useMemo(
    () => targetFrames[snapshot.frameIndex ?? 0],
    [targetFrames, snapshot.frameIndex],
  );

  const nextFrame = useCallback(() => {
    if (!project) return;

    const newState = GameUtils.getNextFrames(1, project, snapshot);

    if (newState.frames.length > 0) {
      newState.state.previousFrame = frame;
    }

    Object.assign(state, newState.state);
  }, [frame, project, snapshot, state]);

  const onFramePlayEnd = useCallback(() => {
    if (!project) return true;

    const newState = GameUtils.getNextFrames(1, project, snapshot);

    if (newState.frames.length <= 0) {
      state.isEnded = true;

      return true;
    }

    return false;
  }, [project, snapshot, state]);

  const init = useCallback(
    (
      newProject: SceneProjectDto | CaseProjectDto,
      framesTarget?: FramesTarget,
      frameIndex?: number,
    ) => {
      setProject(newProject);

      if (!newProject || !framesTarget) {
        state.isEnded = true;

        return;
      }

      state.groupId = framesTarget.groupId;
      state.locationId = framesTarget.locationId;
      state.conversationId = framesTarget.conversationId;
      state.examineId = framesTarget.examineId;
      state.pressedFrame = framesTarget.pressedFrame;
      state.category = framesTarget.category;
      state.frameIndex = frameIndex ? frameIndex - 1 : -1;
      state.isEnded = false;
    },
    [state],
  );

  const reset = useCallback(() => {
    investigationActions.reset();

    for (const key in snapshot) {
      if (key === 'frame' || key === 'group') continue;
      delete state[key as keyof GameState];
    }

    Object.assign(state, defaultState);

    setProject(undefined);
  }, [investigationActions, snapshot, state]);

  const actions: ProjectPlayerType['actions'] = useMemo(
    () => ({
      init,
      reset,
      nextFrame,
      onFramePlayEnd,
    }),
    [init, reset, nextFrame, onFramePlayEnd],
  );

  return {
    state: { ...snapshot, ...investigationState, frame, group, location },
    project,
    actions,
  };
};
