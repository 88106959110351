import { Pause, PlayArrow } from '@mui/icons-material';
import { Box, Button, Stack, useMediaQuery, useTheme } from '@mui/material';
import { memo } from 'react';
import { PlayerVolumeControl } from './PlayerVolumeControl';
import { usePlayer } from './providers/PlayerProvider';

type PlayerControlsProps = {
  width: number;
  hideVolume?: boolean;
  hideAutoplay?: boolean;
  controls?: React.ReactNode;
};

export const PlayerControls = memo(
  ({ width, controls, hideVolume, hideAutoplay }: PlayerControlsProps) => {
    const theme = useTheme();
    const widthThreshold = useMediaQuery(theme.breakpoints.down(width + 8));

    return (
      <Stack
        py={1}
        px={widthThreshold ? 0.5 : 0}
        flexGrow={1}
        width="100%"
        maxWidth={width}
      >
        <Stack
          direction="row"
          alignItems="start"
          justifyContent="space-between"
          gap={1}
        >
          {controls || <Box />}

          <Stack direction="row" spacing={1}>
            {!hideAutoplay && <PlayerAutoplayControl />}
            {!hideVolume && <PlayerVolumeControl />}
          </Stack>
        </Stack>
      </Stack>
    );
  },
);

const PlayerAutoplayControl = memo(() => {
  const {
    state: { isAutoplay, isPlaying, startedPlaying },
    actions: { update, onClick, start },
  } = usePlayer();

  const toggle = () => {
    update({ isAutoplay: !isAutoplay });

    if (!startedPlaying) {
      start();
    } else if (!isAutoplay && !isPlaying) {
      onClick({ direction: 'next', x: 50, y: 50 });
    }
  };

  return (
    <Button
      size="small"
      variant="contained"
      color="info"
      onClick={toggle}
      sx={{ minWidth: 50 }}
    >
      {isAutoplay ? <Pause /> : <PlayArrow />}
    </Button>
  );
});
