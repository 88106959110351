import { Close } from '@mui/icons-material';
import { IconButton, IconButtonProps, InputAdornment } from '@mui/material';

type AdornmentButtonProps = {
  icon?: React.ReactNode;
  onClick: () => void;
} & Omit<IconButtonProps, 'onClick'>;

export const AdornmentButton = ({
  icon = <Close />,
  onClick,
  ...props
}: AdornmentButtonProps) => {
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <InputAdornment position="end">
      <IconButton {...props} onClick={handleClick} size="small" edge="end">
        {icon}
      </IconButton>
    </InputAdornment>
  );
};
