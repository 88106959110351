import { usePlayer } from '../providers/PlayerProvider';
import { usePlayerStyles } from './usePlayerStyles';

export const useShake = () => {
  const {
    playerUi: {
      state: { shakes, size },
    },
  } = usePlayer();

  const shake = shakes[0]?.shake;
  const { shake: shakePlayer } = usePlayerStyles(size);
  const shakeSx = shake ? shakePlayer[shake] : undefined;

  return shakeSx;
};
