import { Character_Side } from '@shared/types';
import { useEffect, useState } from 'react';
import { SceneCharacter } from './hooks/useCharacters';
import { useCharactersFade } from './hooks/useFade';
import { useFlipped } from './hooks/useFlipped';
import { useFrameFilter } from './hooks/useFrameFilter';
import { usePlayerResetRegister } from './hooks/usePlayerResetRegister';
import { usePlayerSaveLoadRegister } from './hooks/usePlayerSaveLoadRegister';
import { useShake } from './hooks/useShake';
import { usePlayer } from './providers/PlayerProvider';
import { ShakeType } from './types';
import { Character } from './ui/Character';
import { FadeBox } from './ui/FadeBox';

export const PlayerCharacters = () => {
  const [characters, setCharacters] = useState<SceneCharacter[]>([]);

  const {
    state,
    step,
    actions: { nextStep },
    frame: {
      character: frameCharacter,
      characters: frameCharacters,
      pose,
      frame,
      shouldPlayPoseAnimation,
      shouldWaitForPoseAnimation,
      shouldPlayPoseTalkingAnimation,
    },
    audio: { playSound },
    playerUi: {
      actions: { addShake, setFlash },
    },
    timeline: { addEvent },
  } = usePlayer();

  const { characterAnimationPlaying, characterTalking } = state;

  const playCharacterAnimations = (characters: SceneCharacter[]) => {
    state.characterAnimationPlaying = true;

    characters
      .filter(
        (sceneCharacter) =>
          sceneCharacter?.character &&
          sceneCharacter.character.id === frameCharacter?.id,
      )
      .forEach((sceneCharacter, index) => {
        if (!sceneCharacter) {
          return;
        }

        const isPrimary = index === 0;
        const { pose } = sceneCharacter;

        if (pose?.poseStates) {
          let delay = 0;
          let speakDelay = 0;

          pose.poseStates.forEach((poseState, index) => {
            if (index === 0) {
              setCharacters((prevCharacters) =>
                prevCharacters.map((prevCharacter) =>
                  prevCharacter
                    ? prevCharacter.character?.id ===
                      sceneCharacter.character?.id
                      ? { ...prevCharacter, imageUrl: poseState.imageUrl }
                      : prevCharacter
                    : undefined,
                ),
              );
            } else {
              addEvent(() => {
                setCharacters((prevCharacters) =>
                  prevCharacters.map((prevCharacter) =>
                    prevCharacter
                      ? prevCharacter.character?.id ===
                        sceneCharacter.character?.id
                        ? { ...prevCharacter, imageUrl: poseState.imageUrl }
                        : prevCharacter
                      : undefined,
                  ),
                );
              }, delay);
            }

            if (isPrimary) {
              delay += poseState.nextPoseDelay;
              speakDelay += poseState.noSpeakDelay
                ? 0
                : poseState.nextPoseDelay;
            }
          });

          if (isPrimary) {
            addEvent(() => {
              state.characterAnimationPlaying = false;
            }, delay);

            addEvent(() => {
              if (shouldWaitForPoseAnimation) {
                nextStep();
              }
            }, speakDelay);
          }
        }

        if (!isPrimary) {
          return;
        }

        if (pose?.poseAudioTicks) {
          pose.poseAudioTicks.forEach((poseAudio) => {
            addEvent(() => {
              playSound(poseAudio.fileName, poseAudio.volume, 100);
            }, poseAudio.time);
          });
        }

        if (pose?.poseFunctionTicks) {
          pose.poseFunctionTicks.forEach((poseFunction) => {
            if (poseFunction.functionName?.toLocaleLowerCase() === 'shake') {
              addEvent(() => {
                addShake(
                  shakeFunctionParamMap[poseFunction.functionParam],
                  poseFunction.time + 700,
                );
              }, poseFunction.time);
            }

            if (poseFunction.functionName?.toLocaleLowerCase() === 'quake') {
              addEvent(() => {
                addShake('large', 3500);
              }, poseFunction.time);
            }

            if (poseFunction.functionName?.toLocaleLowerCase() === 'flash') {
              addEvent(() => {
                setFlash(shakeFunctionParamMap[poseFunction.functionParam]);
              }, poseFunction.time);
            }
          });
        }

        if (!shouldWaitForPoseAnimation) {
          nextStep();
        }
      });
  };

  useEffect(() => {
    if (step !== 'character' || characterAnimationPlaying || !frame) {
      return;
    }

    setCharacters(frameCharacters);

    if (!shouldPlayPoseAnimation || !frameCharacter) {
      nextStep();

      return;
    }

    playCharacterAnimations(frameCharacters);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  // talking/idle
  useEffect(() => {
    if (characterAnimationPlaying) {
      return;
    }

    const shouldTalk =
      shouldPlayPoseTalkingAnimation && characterTalking && pose?.speakImageUrl;

    setCharacters((prevCharacters) =>
      prevCharacters.map((prevCharacter) =>
        prevCharacter && prevCharacter.character?.id === frameCharacter?.id
          ? {
              ...prevCharacter,
              imageUrl: shouldTalk ? pose?.speakImageUrl : pose?.idleImageUrl,
            }
          : prevCharacter,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [characterTalking, characterAnimationPlaying]);

  usePlayerSaveLoadRegister({
    name: 'character',
    onSave: () => ({ characters }),
    onLoad: ({ characters }) => {
      setCharacters(characters);
    },
  });

  usePlayerResetRegister({
    name: 'character',
    onReset: () => {
      setCharacters([]);
    },
  });

  return characters.map((sceneCharacter, index) => {
    if (
      !sceneCharacter ||
      !sceneCharacter.imageUrl ||
      !sceneCharacter.character
    ) {
      return null;
    }

    // TODO: duplicate keys?
    return <PlayerCharacter sceneCharacter={sceneCharacter} key={index} />;
  });
};

const PlayerCharacter = ({
  sceneCharacter,
}: {
  sceneCharacter: NonNullable<SceneCharacter>;
}) => {
  const {
    frame: { frame },
  } = usePlayer();

  const { imageUrl, pose, target, character, pairedCharacter } = sceneCharacter;

  const flipped = useFlipped(frame, target);
  const shakeSx = useShake();

  const filter = useFrameFilter(frame?.effect, target);
  const { fadeAnimation } = useCharactersFade(target);

  if (!imageUrl || !character) {
    return null;
  }

  const isSpeedlines = !!pose?.isSpeedlines;
  const offsetX =
    character.offsetX + (!isSpeedlines ? pairedCharacter?.offsetX ?? 0 : 0);
  const offsetY =
    character.offsetY + (!isSpeedlines ? pairedCharacter?.offsetY ?? 0 : 0);

  return (
    <FadeBox style={fadeAnimation}>
      <Character
        url={imageUrl}
        alignment={character.alignment}
        side={character.side as Character_Side}
        offsetX={offsetX}
        offsetY={offsetY}
        flipped={flipped}
        limitWidth={character.limitWidth}
        front={pairedCharacter?.front}
        sx={{ ...shakeSx }}
        style={filter}
      />
    </FadeBox>
  );
};

export const shakeFunctionParamMap: Record<string, ShakeType> = {
  s: 'small',
  m: 'medium',
  l: 'large',
};
