import { CaseFrame } from '@shared/types';
import { RefObject, createContext, useContext } from 'react';

type FrameEditorContext = {
  editor: RefObject<HTMLTextAreaElement>;
  frameId: number;
  frameIndex: number;
  updateFrame: (frame: Partial<CaseFrame>) => void;
  surroundTextWithColor: (tag: string) => void;
  insertTag: (tag: string) => void;
};

const frameEditorContext = createContext<FrameEditorContext>({
  editor: { current: null } as RefObject<HTMLTextAreaElement>,
  frameId: -1,
  frameIndex: -1,
  updateFrame: () => {},
  surroundTextWithColor: () => {},
  insertTag: () => {},
});

export const FrameEditorProvider = frameEditorContext.Provider;

export const useFrameEditor = () => useContext(frameEditorContext);
