import styled from '@emotion/styled';
import { SxProps, Theme } from '@mui/material';
import { CSSProperties } from 'react';
import { Image } from '../../common/ui/Image';
import { ZIndex } from '../utils/constants';

type BackgroundProps = {
  url: string;
  isWide?: boolean;
  flipped?: boolean;
  sx?: SxProps<Theme>;
  style?: CSSProperties;
};

export const Background = ({
  url,
  isWide,
  flipped,
  sx,
  style,
}: BackgroundProps) => {
  return (
    <BackgroundStyled
      src={url}
      alt="Background"
      sx={sx}
      style={{
        width: !isWide ? '100%' : 'auto',
        ...(flipped ? { scale: '-1 1' } : {}),
        ...style,
      }}
    />
  );
};

const BackgroundStyled = styled(Image)(({ theme }) => {
  return {
    height: '100%',
    position: 'relative',
    top: 0,
    left: 0,
    zIndex: ZIndex.Background,
    userSelect: 'none',
    pointerEvents: 'none',
  };
});
