import { Send } from '@mui/icons-material';
import {
  CircularProgress,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { ApiClient } from '@web/api/api-client';
import { useAnchorMenu } from '@web/hooks/useAnchorMenu';
import { assetActions } from '@web/store/assets/actions';
import { getErrorMessage } from '@web/utils/error';
import { enqueueSnackbar } from 'notistack';
import { ChangeEvent, useEffect, useState } from 'react';
import { CopyButton } from '../common/CopyButton';
import Switch from '../common/form/Switch';
import { StaticCircularProgress } from '../common/StaticCircularProgress';
import { Popper } from '../common/ui/Popper';
import { AdornmentButton } from '../maker/ui/AdornmentButton';

type SharableAssetsType = 'character';
type SharePopperProps = {
  id: number | string;
  type: SharableAssetsType;
  children: (props: {
    toggle: ReturnType<typeof useAnchorMenu>['toggle'];
  }) => React.ReactNode;
};

export const SharePopper = ({ id, type, children }: SharePopperProps) => {
  const { anchorEl, toggle, close } = useAnchorMenu();
  const open = !!anchorEl;

  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);

  const handleToggleShare = async () => {
    try {
      setLoading(true);

      if (code) {
        await ApiClient.assets[type].unshare(Number(id));

        setCode('');
      } else {
        const res = await ApiClient.assets[type].share(Number(id));

        setCode(res.data.code || '');
      }
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleCopyCode = () => {
    navigator.clipboard.writeText(code);
  };

  useEffect(() => {
    if (!id || !type || !open) return;

    const fetchIsShared = async () => {
      try {
        setLoading(true);

        const res = await ApiClient.assets[type].isShared(Number(id));

        setCode(res.data.isShared ? res.data.code || '' : '');
      } catch (error) {
        enqueueSnackbar('Failed to fetch share status', { variant: 'error' });
      } finally {
        setLoading(false);
      }
    };

    fetchIsShared();
  }, [id, type, open]);

  return (
    <>
      <Popper
        anchorEl={anchorEl}
        onClose={close}
        open={open}
        popperOptions={{ placement: 'auto' }}
        disablePortal
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          minWidth={250}
          p={2}
        >
          <Switch
            size="small"
            label="Share"
            checked={!!code}
            onChange={handleToggleShare}
            disabled={loading}
          />

          {code ? (
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>{code}</Typography>

              <CopyButton size="small" onCopy={handleCopyCode} />
            </Stack>
          ) : loading ? (
            <CircularProgress size={24} />
          ) : null}
        </Stack>
      </Popper>

      {children({ toggle })}
    </>
  );
};

export const ShareCodeField = ({
  type,
  ...props
}: { type: SharableAssetsType } & TextFieldProps) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setValue(event.target.value.toUpperCase());
  };

  const handleUseCode = async () => {
    if (value.length !== 6 || loading) return;

    try {
      setLoading(true);

      const res = await ApiClient.assets[type].useShareCode(value);
      const asset = await assetActions.getAsset(type, res.data.id, true);

      enqueueSnackbar(`Received: ${asset.name}`, { variant: 'success' });

      setValue('');
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <TextField
      variant="outlined"
      size="small"
      placeholder="Share Code"
      value={value}
      onChange={handleChange}
      inputProps={{ maxLength: 6 }}
      InputProps={{
        endAdornment: loading ? (
          <StaticCircularProgress size={24} />
        ) : (
          <AdornmentButton
            onClick={handleUseCode}
            icon={<Send />}
            disabled={loading || value.length !== 6}
          />
        ),
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleUseCode();
        }
      }}
      {...props}
    />
  );
};
