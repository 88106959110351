import { Autocomplete, TextFieldVariants } from '@mui/material';
import { CreateCharacterDto, UpdateCharacterDto } from '@web/api/api';
import { RenderAutocompleteInput } from '@web/components/common/ui/RenderAutocompleteInput';
import { useMemo } from 'react';
import { Control, Controller, useFormState, useWatch } from 'react-hook-form';

const alignmentOptions = [
  { id: 0, name: 'Default' },
  { id: 1, name: 'Left' },
  { id: 2, name: 'Right' },
];

export const AlignmentInput = ({
  control,
  variant,
}: {
  variant?: TextFieldVariants | undefined;
  control: Control<CreateCharacterDto | UpdateCharacterDto> | undefined;
}) => {
  const alignment = useWatch({ control, name: 'alignment' });
  const { errors } = useFormState({ control });

  const selectedValue = useMemo(
    () => alignmentOptions.find((f) => f.id === alignment) || null,
    [alignment],
  );

  return (
    <Controller
      control={control}
      name="alignment"
      render={({ field }) => (
        <Autocomplete
          value={selectedValue}
          onChange={(event, newValue) => {
            field.onChange(newValue?.id);
          }}
          options={alignmentOptions}
          renderInput={(params) => (
            <RenderAutocompleteInput
              params={params}
              variant={variant ?? 'outlined'}
              error={!!errors.alignment}
              helperText={errors.alignment?.message}
              label="Alignment"
            />
          )}
          getOptionLabel={(option) => option.name}
          getOptionKey={(option) => option.id.toString()}
          disableClearable={selectedValue !== null}
          fullWidth
        />
      )}
    />
  );
};
