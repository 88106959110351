import { Box } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { GetThumbnailIconTypeEnum } from '@web/api/api';
import { ApiClient } from '@web/api/api-client';
import { useState } from 'react';

export const AssetImageIcon = ({
  iconUrl,
  alt,
}: {
  iconUrl: string;
  alt?: string;
}) => {
  const [imageUrl, setImageUrl] = useState(iconUrl);

  return (
    <BaseAssetImageIcon
      url={imageUrl}
      alt={alt}
      onError={() => setImageUrl(emptyIconBase64)}
    />
  );
};

export const AssetImageServerIcon = ({
  id,
  type,
  alt,
}: {
  id: number;
  type: GetThumbnailIconTypeEnum;
  alt?: string;
}) => {
  const queryClient = useQueryClient();

  const getThumbnail = async () => {
    const response = await ApiClient.thumbnail.getThumbnailIcon(id, type);

    return `${import.meta.env.VITE_BACKEND_BASE_URL}${response.data.url}`;
  };

  const { data } = useQuery({
    queryKey: ['icon', type, id],
    queryFn: getThumbnail,
    staleTime: 30 * 60 * 1000,
    enabled: !!id,
  });

  const handleImageLoadError = () => {
    queryClient.setQueryData(['icon', type, id], emptyIconBase64);
  };

  return (
    <BaseAssetImageIcon
      url={data || emptyIconBase64}
      alt={alt}
      onError={handleImageLoadError}
    />
  );
};

const BaseAssetImageIcon = ({
  url,
  alt,
  onError,
}: {
  url: string;
  alt?: string;
  onError?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
}) => {
  return (
    <Box
      component="img"
      width={40}
      height={40}
      src={url || emptyIconBase64}
      loading="lazy"
      onError={onError}
      borderRadius="50%"
      alt={alt}
    />
  );
};

const emptyIconBase64 =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAQAAAAm93DmAAAAJUlEQVR42u3MMQEAAAwCoNm/9ELoCQHIjUUoFAqFQqFQKBQKOw8QKwApHZ3ALwAAAABJRU5ErkJggg==';
