import { CheckCircle } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Divider,
  Fade,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ApiClient } from '@web/api/api-client';
import { useAuth } from '@web/providers/auth/AuthProvider';
import { rootStore } from '@web/store/root/state';
import { getErrorMessage } from '@web/utils/error';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useCountdown } from 'usehooks-ts';
import { proxy, useSnapshot } from 'valtio';
import * as yup from 'yup';
import { Checkbox } from '../common/form/Checkbox';
import Form from '../common/form/Form';
import ReCaptcha from '../common/form/ReCaptcha';
import Dialog from '../common/ui/Dialog';
import MultiView from '../common/ui/MultiView';

const state = proxy<{
  viewIndex: number;
  checkmarkTimeout?: NodeJS.Timeout;
  error?: string;
}>({
  viewIndex: 0,
});

const setViewIndex = (index: number) => {
  state.viewIndex = index;
  state.error = undefined;
};

const AccountDialog = () => {
  const rootSnapshot = useSnapshot(rootStore.dialogs);
  const { viewIndex } = useSnapshot(state);

  const title =
    viewIndex === 0
      ? 'Account'
      : viewIndex === 1
        ? 'Change Password'
        : 'Delete Account';

  const handleClose = () => {
    rootStore.dialogs.account = false;

    setViewIndex(0);
  };

  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      color="primary"
      title={title}
      dividers={false}
      persist={true}
      open={rootSnapshot.account}
      onClose={handleClose}
      maxWidth="xs"
      fullScreen={fullscreen}
    >
      <MultiView index={viewIndex}>
        <AccountForm />
        <ChangePasswordForm />
        <DeleteAccountForm />
      </MultiView>
    </Dialog>
  );
};

const AccountForm = React.memo(() => {
  const { me, user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState(user?.name);
  const [showCheckmark, setShowCheckmark] = useState(false);

  const handleChangeUsername = async (value: string) => {
    if (!value) {
      setUsername(user?.name);

      return;
    }

    if (value === user?.name) {
      setUsername(value);

      return;
    }

    try {
      setLoading(true);

      await ApiClient.auth.changeUsername({ username: value });

      const res = await ApiClient.auth.refreshToken();

      localStorage.setItem('token', res.data.token);

      await me();

      setUsername(value);
      setShowCheckmark(true);

      clearTimeout(state.checkmarkTimeout);

      state.checkmarkTimeout = setTimeout(() => {
        setShowCheckmark(false);
      }, 2000);
    } catch (err) {
      setUsername(user?.name);

      enqueueSnackbar(getErrorMessage(err), {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Stack spacing={2}>
        <TextField
          label="Email"
          type="email"
          disabled={true}
          value={user?.email}
          size="small"
          variant="filled"
          fullWidth
        />

        <TextField
          key={user?.name}
          label="Username"
          type="text"
          size="small"
          variant="filled"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          onBlur={(e) => handleChangeUsername(e.target.value)}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              handleChangeUsername(e.target.value);
            }
          }}
          disabled={loading}
          inputProps={{ maxLength: 12 }}
          InputProps={{
            endAdornment: <CheckmarkAdornment show={showCheckmark} />,
          }}
          fullWidth
        />
      </Stack>

      <Box mt={4}>
        <Divider />

        <Stack
          direction="row"
          justifyContent="space-between"
          mt={2}
          spacing={2}
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => setViewIndex(1)}
          >
            Change Password
          </Button>

          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={() => setViewIndex(2)}
          >
            Delete Account
          </Button>
        </Stack>
      </Box>
    </>
  );
});

const CheckmarkAdornment = ({ show }: { show: boolean }) => (
  <Fade in={show} timeout={500}>
    <InputAdornment position="end">
      <CheckCircle color="success" />
    </InputAdornment>
  </Fade>
);

type ChangePasswordFormType = {
  password: string;
  newPassword: string;
  confirmPassword: string;
  captcha: string | null;
};

const ChangePasswordForm = React.memo(() => {
  const { error } = useSnapshot(state);

  const { enqueueSnackbar } = useSnackbar();

  const schema = useMemo(
    () =>
      yup.object().shape({
        password: yup.string().required().min(8),
        newPassword: yup.string().label('new password').required().min(8),
        confirmPassword: yup
          .string()
          .oneOf(
            [yup.ref('newPassword')],
            'confirm password must match new password',
          ),
      }),
    [],
  );

  const handleChangePassword = async (data: ChangePasswordFormType) => {
    try {
      state.error = undefined;

      await ApiClient.auth.changePassword(
        {
          currentPassword: data.password,
          password: data.newPassword,
        },
        { headers: { recaptcha: data.captcha } },
      );

      rootStore.dialogs.account = false;

      setViewIndex(0);

      enqueueSnackbar('Password changed successfully', {
        variant: 'success',
      });
    } catch (err) {
      state.error = getErrorMessage(err);
    }
  };

  return (
    <Form schema={schema} onSubmit={handleChangePassword}>
      {({ control, register, errors, loading }) => (
        <Stack spacing={2}>
          {error && <Alert severity="error">{error}</Alert>}

          <TextField
            label="Current Password"
            type="password"
            size="small"
            variant="filled"
            {...register('password')}
            error={!!errors.password}
            helperText={errors.password?.message}
            fullWidth
          />

          <TextField
            label="New Password"
            type="password"
            size="small"
            variant="filled"
            {...register('newPassword')}
            error={!!errors.newPassword}
            helperText={errors.newPassword?.message}
            fullWidth
          />

          <TextField
            label="Confirm New Password"
            type="password"
            size="small"
            variant="filled"
            {...register('confirmPassword')}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword?.message}
            fullWidth
          />

          <Controller
            control={control}
            name="captcha"
            render={({ field }) => <ReCaptcha onChange={field.onChange} />}
          />

          <Button
            variant="contained"
            color="primary"
            size="small"
            type="submit"
            disabled={loading}
            fullWidth
          >
            Change Password
          </Button>

          <Button
            variant="text"
            color="secondary"
            size="small"
            onClick={() => setViewIndex(0)}
            fullWidth
          >
            Cancel
          </Button>
        </Stack>
      )}
    </Form>
  );
});

type DeleteAccountFormType = {
  password: string;
  deleteAssets: boolean;
  captcha: string | null;
};

const DeleteAccountForm = React.memo(() => {
  const { user, logout } = useAuth();
  const [confirmed, setConfirmed] = useState(false);

  const { error } = useSnapshot(state);

  const { enqueueSnackbar } = useSnackbar();

  const schema = useMemo(
    () =>
      yup.object().shape({
        ...(!user?.isProvider && {
          password: yup.string().required().min(6),
        }),
        deleteAssets: yup.boolean().required(),
      }),
    [user?.isProvider],
  );

  const defaultValues = {
    deleteAssets: false,
  };

  const handleDeleteAccount = async (data: DeleteAccountFormType) => {
    try {
      state.error = undefined;

      await ApiClient.auth.deleteAccount(
        {
          currentPassword: data.password,
          deleteAssets: data.deleteAssets,
        },
        { headers: { recaptcha: data.captcha } },
      );

      await logout();

      rootStore.dialogs.account = false;

      setViewIndex(0);

      enqueueSnackbar('Account deletion successful', {
        variant: 'success',
      });
    } catch (err) {
      state.error = getErrorMessage(err);
    }
  };

  return (
    <Form
      schema={schema}
      onSubmit={handleDeleteAccount}
      defaultValues={defaultValues}
    >
      {({ control, register, errors, loading }) => (
        <Stack spacing={2}>
          {error && <Alert severity="error">{error}</Alert>}

          <Typography variant="body2">
            Please confirm that you want to delete your account. This action is
            irreversible.
          </Typography>

          {!user?.isProvider && (
            <TextField
              label="Current Password"
              type="password"
              size="small"
              variant="filled"
              {...register('password')}
              error={!!errors.password}
              helperText={errors.password?.message}
              fullWidth
            />
          )}

          <Box>
            <Checkbox
              label="I confirm that I want to delete my account"
              value={confirmed}
              onChange={setConfirmed}
              size="small"
            />

            <Controller
              control={control}
              name="deleteAssets"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  label="Also delete all of my assets, scenes and cases"
                  disabled={!confirmed}
                  size="small"
                />
              )}
            />
          </Box>

          <Controller
            control={control}
            name="captcha"
            render={({ field }) => <ReCaptcha onChange={field.onChange} />}
          />

          <ConfirmAccountDeleteButton confirmed={confirmed} loading={loading} />

          <Button
            variant="text"
            color="secondary"
            size="small"
            onClick={() => setViewIndex(0)}
            fullWidth
          >
            Cancel
          </Button>
        </Stack>
      )}
    </Form>
  );
});

type ConfirmAccountDeleteButtonProps = {
  confirmed: boolean;
  loading: boolean;
};

const ConfirmAccountDeleteButton = ({
  confirmed,
  loading,
}: ConfirmAccountDeleteButtonProps) => {
  const [count, { startCountdown, stopCountdown, resetCountdown }] =
    useCountdown({
      countStart: 60,
      intervalMs: 1000,
    });

  useEffect(() => {
    if (confirmed) {
      startCountdown();
    } else {
      stopCountdown();
      resetCountdown();
    }

    return () => {
      stopCountdown();
      resetCountdown();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmed]);

  return (
    <Button
      variant="contained"
      color="error"
      size="small"
      type="submit"
      disabled={loading || !confirmed || count > 0}
      fullWidth
    >
      {count > 0 && confirmed
        ? `Confirm Deletion (${count})`
        : 'Confirm Deletion (Irreversible)'}
    </Button>
  );
};

export default AccountDialog;
