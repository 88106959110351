import { Alert, Button, Stack, TextField, Typography } from '@mui/material';
import { ApiClient } from '@web/api/api-client';
import Form from '@web/components/common/form/Form';
import { enqueueSnackbar } from 'notistack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

type ResetPasswordFields = {
  password: string;
  confirmPassword: string;
  captcha: string;
};

const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const token = searchParams.get('token');
  const email = searchParams.get('email');

  const schema = yup.object().shape({
    password: yup.string().required().min(8),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], 'Passwords must match'),
  });

  const handleSubmit = async (data: ResetPasswordFields) => {
    try {
      await ApiClient.auth.resetPassword({
        token: token as string,
        password: data.password,
      });

      enqueueSnackbar('Password reset successfully', { variant: 'success' });

      navigate('/');
    } catch (error) {
      enqueueSnackbar('An error occurred', { variant: 'error' });
    }
  };

  return (
    <Form schema={schema} onSubmit={handleSubmit}>
      {({ register, loading, errors }) => (
        <Stack spacing={2} maxWidth="sm">
          <Typography variant="h5">Reset Password</Typography>

          {errors.root?.message && (
            <Alert severity="error">{errors.root.message}</Alert>
          )}

          <TextField
            label="Email"
            type="text"
            fullWidth
            variant="filled"
            disabled
            defaultValue={email}
          />

          <TextField
            {...register('password')}
            label="New Password"
            type="password"
            fullWidth
            variant="filled"
            error={!!errors.password}
            helperText={errors.password?.message}
          />

          <TextField
            {...register('confirmPassword')}
            label="Confirm Password"
            type="password"
            fullWidth
            variant="filled"
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword?.message}
          />

          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            Submit
          </Button>
        </Stack>
      )}
    </Form>
  );
};

export default ResetPasswordPage;
