import { Box, styled } from '@mui/material';
import { Image } from '@web/components/common/ui/Image';
import { ZIndex } from '../utils/constants';

export const NextButtonIcon = styled(Box)({
  position: 'absolute',
  zIndex: ZIndex.NextButton,
  cursor: 'pointer',
  pointerEvents: 'all',
});

export const NextButtonImage = styled(Image)({
  position: 'absolute',
  bottom: 0,
  left: 0,
  zIndex: ZIndex.NextButton,
  width: '100%',
  height: 'auto',
});
