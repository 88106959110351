import { Button } from '@mui/material';
import {
  SnackbarProvider as NotistackSnackbarProvider,
  closeSnackbar,
} from 'notistack';

export const SnackbarProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <NotistackSnackbarProvider
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      autoHideDuration={6000}
      action={(snackbarId) => (
        <Button
          variant="text"
          size="small"
          color="inherit"
          onClick={() => closeSnackbar(snackbarId)}
        >
          Dismiss
        </Button>
      )}
    >
      {children}
    </NotistackSnackbarProvider>
  );
};
