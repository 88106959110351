import { Character_Side } from '@shared/types';
import { SceneTargets } from '@shared/types/scene-targets';
import { assetStore } from '@web/store/assets/state';
import { useEffect } from 'react';
import { useFlipped } from './hooks/useFlipped';
import { usePlayer } from './providers/PlayerProvider';
import { Evidence } from './ui/Evidence';

export const PlayerEvidence = () => {
  const {
    state: { evidence: evidenceState },
    frame: { character, frame },
    playerUi: {
      state: { size },
    },
    audio: { playSound },
  } = usePlayer();

  useEffect(() => {
    if (!evidenceState || !evidenceState.isIcon) {
      return;
    }

    playSound('/Audio/Sound/shooop.mp3', 75, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evidenceState?.id]);

  const flipped = useFlipped(frame, SceneTargets.CHARACTER_1);

  if (!evidenceState) {
    return null;
  }

  const evidence = assetStore.evidence.cache[evidenceState.id];
  const flippedEvidence =
    (character?.side !== Character_Side.Prosecution && flipped) ||
    (character?.side === Character_Side.Prosecution && !flipped);

  if (!evidence) {
    return null;
  }

  return (
    <Evidence
      url={evidence.url}
      isIcon={evidenceState.isIcon}
      size={size}
      flipped={flippedEvidence}
    />
  );
};
