export default Object.freeze<Record<string, string>>({
  '2': 'truth2002',
  '3': 'cornered',
  '4': 'objection2004',
  '6': 'crossexamining',
  '7': 'cornered',
  '8': 'trial2',
  '9': 'objection2004',
  '11': 'crossexamining',
  '12': 'truth2002',
  '13': 'trial2',
  '14': 'crossexaminingvariation',
  '16': 'objection',
  '17': 'crossexamining',
  '18': 'crossexaminingvariation',
  '19': 'trial2',
  '20': 'payne',
  '21': 'trial2',
  '22': 'objection',
  '23': 'truth2002',
  '24': 'trial2',
  '25': 'crossexamining',
  '26': 'crossexaminingvariation',
  '28': 'truth2001',
  '29': 'truth2002',
  '31': 'trial2',
  '33': 'trial2',
  '34': 'allegro2004',
  '35': 'trial2',
  '36': 'truth2001',
  '37': 'objection2004',
  '39': 'allegro2001',
  '40': 'objection',
  '41': 'truth2004',
  '42': 'truth2004',
  '43': 'allegro2001',
  '44': 'trial2',
  '46': 'allegro2004',
  '47': 'crossexamining',
  '48': 'crossexaminingvariation',
  '49': 'truth2004',
  '51': 'darkcoffee',
  '52': 'crossexamining',
  '53': 'crossexamining',
  '54': 'objection2004',
  '55': 'truth2007',
  '56': 'trial5',
  '57': 'objection2007',
  '58': 'moderato2007',
  '59': 'objection',
  '60': 'trial4',
  '61': 'cornered2007',
  '62': 'trial4',
  '63': 'moderato2007',
  '64': 'truth2007',
  '65': 'trial5',
  '66': 'klavier',
  '67': 'trial4',
  '68': 'moderato2007',
  '69': 'objection2007',
  '70': 'truth2007',
  '71': 'moderato2001',
  '72': 'allegro2001',
  '73': 'cornered',
  '74': 'cornered',
  '75': 'cornered',
  '76': 'truth2007',
  '77': 'maya',
  '78': 'maya',
  '79': 'maya',
  '80': 'allegro2004',
  '81': 'reminiscence',
  '82': 'reminiscence',
  '83': 'truth2002',
  '84': 'maya',
  '85': 'truth2001',
  '86': 'allegro2004',
  '87': 'reminiscence',
  '90': 'truth2001',
  '91': 'truth2002',
  '92': 'gant',
  '93': 'congratulations',
  '94': 'congratulations',
  '95': 'gant',
  '96': 'gant',
  '97': 'allegro2001',
  '98': 'crossexamining',
  '99': 'cornered',
  '103': 'moderato2001',
  '104': 'moderato2001',
  '105': 'moderato2001',
  '106': 'allegro2001',
  '108': 'allegro2004',
  '109': 'allegro2001',
  '110': 'moderato2001',
  '111': 'allegro2001',
  '112': 'crossexamining',
  '117': 'congratulations',
  '118': 'truth2002',
  '119': 'allegro2001',
  '120': 'allegro2001',
  '122': 'truth2001',
  '124': 'truth2001',
  '126': 'trial2',
  '128': 'trial2',
  '129': 'truth2001',
  '131': 'truth2002',
  '133': 'moderato2001',
  '134': 'objection2004',
  '135': 'moderato2001',
  '136': 'truth2001',
  '137': 'truth2001',
  '138': 'truth2002',
  '139': 'darkcoffee',
  '140': 'truth2001',
  '141': 'objection2004',
  '173': 'crossexaminingvariation',
  '174': 'truth2004',
  '176': 'darkcoffee',
  '177': 'crossexamining',
  '178': 'crossexamining',
  '179': 'objection2004',
  '180': 'truth2002',
  '181': 'darkcoffee',
  '183': 'darkcoffee',
  '184': 'darkcoffee',
  '185': 'crossexamining',
  '188': 'darkcoffee',
  '189': 'truth2002',
  '190': 'objection',
  '191': 'truth2009',
  '192': 'truth2002',
  '193': 'moderato2002',
  '211': 'truth2002',
  '212': 'allegro2001',
  '213': 'allegro2001',
  '214': 'allegro2001',
  '217': 'objection2007',
  '218': 'truth2004',
  '219': 'darkcoffee',
  '224': 'congratulations',
  '225': 'truth2002',
  '226': 'allegro2001',
  '227': 'allegro2001',
  '233': 'truth2002',
  '241': 'truth2002',
  '242': 'truth2002',
  '243': 'truth2002',
  '244': 'allegro2001',
  '248': 'crossexaminingvariation',
  '250': 'allegro2001',
  '251': 'allegro2001',
  '252': 'allegro2001',
  '258': 'allegro2001',
  '259': 'allegro2001',
  '260': 'allegro2001',
  '271': 'truth2001',
  '272': 'truth2001',
  '273': 'truth2001',
  '274': 'truth2001',
  '275': 'truth2001',
  '306': 'crossexaminingvariation',
  '308': 'allegro2001',
  '309': 'truth2001',
  '310': 'truth2001',
  '311': 'truth2001',
  '312': 'truth2001',
  '316': 'crossexaminingvariation',
  '318': 'allegro2001',
  '319': 'allegro2001',
  '320': 'allegro2001',
  '321': 'allegro2001',
  '325': 'crossexaminingvariation',
  '330': 'crossexaminingvariation',
  '351': 'allegro2001',
  '354': 'moderato2001',
  '355': 'moderato2001',
  '356': 'moderato2001',
  '357': 'truth2002',
  '358': 'moderato2001',
  '360': 'moderato2001',
  '362': 'moderato2001',
  '363': 'moderato2001',
  '364': 'moderato2001',
  '365': 'moderato2001',
  '401': 'truth2007',
  '402': 'truth2007',
  '403': 'trial5',
  '404': 'allegro2007',
  '405': 'allegro2007',
  '406': 'truth2001',
  '407': 'truth2001',
  '408': 'objection2004',
  '409': 'allegro2001',
  '410': 'objection',
  '411': 'truth2002',
  '412': 'truth2009',
  '413': 'truth2004',
  '414': 'truth2007',
  '415': 'moderato2007',
  '416': 'cornered2007',
  '417': 'objection2007',
  '442': 'objection',
  '443': 'objection',
  '449': 'objection',
  '450': 'objection',
  '451': 'objection',
  '452': 'objection',
  '453': 'objection',
  '454': 'objection',
  '455': 'objection',
  '456': 'objection',
  '457': 'objection',
  '478': 'allegro2001',
  '479': 'allegro2001',
  '480': 'allegro2001',
  '481': 'allegro2001',
  '519': 'cornered2004 - pursuit',
  '520': 'cornered2004 - pursuit variation',
  '542': 'truth2001',
  '567': 'objection',
  '568': 'objection',
  '569': 'objection',
  '570': 'objection',
  '571': 'objection',
  '604': 'trial2',
  '605': 'trial2',
  '607': 'trial2',
  '609': 'trial2',
  '611': 'trial2',
  '615': 'truth2002',
  '616': 'allegro2001',
  '628': 'trial2',
  '640': 'trial2',
  '669': 'moderato2001',
  '671': 'moderato2001',
  '673': 'moderato2001',
  '676': 'moderato2001',
  '679': 'moderato2001',
  '681': 'moderato2001',
  '691': 'crossexamining',
  '692': 'cornered',
  '693': 'trial2',
  '694': 'objection2004',
  '695': 'truth2001',
  '696': 'crossexamining',
  '697': 'crossexamining',
  '699': 'truth2001',
  '700': 'truth2001',
  '701': 'truth2001',
  '703': 'moderato2001',
  '709': 'moderato2001',
  '713': 'moderato2001',
  '723': 'moderato2001',
  '726': 'moderato2001',
  '731': 'moderato2001',
  '735': 'moderato2001',
  '745': 'truth2007',
  '749': 'gant',
});
