import Navbar from '@web/components/common/Navbar';
import { Outlet } from 'react-router-dom';

const PrimaryLayoutNoFooter = () => {
  return (
    <>
      <Navbar />

      <Outlet />
    </>
  );
};

export default PrimaryLayoutNoFooter;
