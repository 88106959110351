import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ThemeProvider from '@web/providers/theme/ThemeProvider';

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false } },
});

const CoreLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </ThemeProvider>
  );
};

export default CoreLayout;
