import { Alert, Box, Button, Paper, Stack } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import {
  CreatePoseFunctionDtoFunctionNameEnum,
  CreatePoseFunctionDtoFunctionParamEnum,
  PoseFunctionDto,
} from '@web/api/api';
import { ApiClient } from '@web/api/api-client';
import Form from '@web/components/common/form/Form';
import { NumberTextField } from '@web/components/common/form/NumberTextField';
import { SimpleSelect } from '@web/components/common/form/SimpleSelect';
import { enqueueSnackbar } from 'notistack';
import { memo, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useSnapshot } from 'valtio';
import * as yup from 'yup';
import { PoseExtraUpdateWatcher } from '../CharacterUpdateWatchers';
import { useCharacterEditor } from '../providers/CharacterEditorProvider';
import { PoseExtraDeleteButton } from './PoseDeleteButtons';
import { updateCharacterPoses } from './utils';

export const EffectPoseEditor = memo(({ index }: { index: number }) => {
  const state = useCharacterEditor();

  const { character } = useSnapshot(state);

  const handleAdd = () => {
    const newPoseEffect: PoseFunctionDto = {
      id: -Math.random() * 1000 - 1,
      functionName: 'Shake',
      functionParam: 'm',
      time: 0,
    };

    state.character.poses[index].poseFunctionTicks.push(newPoseEffect);
  };

  const disabled =
    character.poses[index].poseFunctionTicks.length >= 3 ||
    character.poses[index].poseFunctionTicks.some((s) => s.id < 0);

  return (
    <Stack spacing={2}>
      <Box>
        <Button
          variant="contained"
          color="info"
          onClick={handleAdd}
          disabled={disabled}
        >
          Add Effect
        </Button>
      </Box>

      <Stack
        spacing={2}
        maxHeight={350}
        sx={{ scrollbarWidth: 'thin', overflowY: 'auto' }}
      >
        {character.poses[index].poseFunctionTicks.map((tick, tickIndex) => (
          <EffectPoseForm
            key={tick.id}
            poseFunctionTick={tick}
            index={tickIndex}
            poseIndex={index}
          />
        ))}
      </Stack>
    </Stack>
  );
});

type FormType = {
  id: number;
  functionName: string;
  functionParam: string;
  time: number;
};

const EffectPoseForm = ({
  poseFunctionTick,
  index,
  poseIndex,
}: {
  poseFunctionTick: PoseFunctionDto;
  index: number;
  poseIndex: number;
}) => {
  const state = useCharacterEditor();

  const schema = useMemo(
    () =>
      yup.object<FormType>().shape({
        functionName: yup
          .string()
          .oneOf(Object.values(CreatePoseFunctionDtoFunctionNameEnum))
          .required(),
        functionParam: yup
          .string()
          .oneOf(Object.values(CreatePoseFunctionDtoFunctionParamEnum))
          .required(),
        time: yup.number().min(0).required(),
      }),
    [],
  );

  const onSubmit = async (data: FormType) => {
    const poseFunctionTick =
      state.character.poses[poseIndex].poseFunctionTicks[index];

    const poseFunctionData = {
      functionName: data.functionName as CreatePoseFunctionDtoFunctionNameEnum,
      functionParam:
        data.functionParam as CreatePoseFunctionDtoFunctionParamEnum,
      time: data.time,
      poseId: state.character.poses[poseIndex].id,
    };

    const result =
      poseFunctionTick.id < 0
        ? await ApiClient.assets.poseFunction.create(poseFunctionData)
        : await ApiClient.assets.poseFunction.update(
            poseFunctionTick.id,
            poseFunctionData,
          );

    state.character.poses[poseIndex].poseFunctionTicks[index] = result.data;

    updateCharacterPoses(state.characterId, state.character.poses);

    enqueueSnackbar('Effect saved', {
      variant: 'success',
      autoHideDuration: 2000,
    });
  };

  const handleCancel = () => {
    state.character.poses[poseIndex].poseFunctionTicks = state.character.poses[
      poseIndex
    ].poseFunctionTicks.filter((_, i) => i !== index);
  };

  return (
    <Form schema={schema} onSubmit={onSubmit} defaultValues={poseFunctionTick}>
      {({ control, register, errors, loading }) => (
        <>
          <Paper
            sx={(theme) => ({
              p: 1,
              mb: 1,
              bgcolor: blueGrey[700],
              borderRadius: 0,
            })}
          >
            Effect {index + 1}
          </Paper>
          <Stack spacing={2}>
            {errors.root?.message && (
              <Alert severity="error" variant="filled">
                {errors.root.message}
              </Alert>
            )}

            <Stack direction="row" spacing={2}>
              <Controller
                control={control}
                name="functionName"
                render={({ field, fieldState }) => (
                  <SimpleSelect
                    options={effectOptions}
                    label="Effect"
                    variant="standard"
                    value={
                      effectOptions.find((f) => f.id === field.value) || null
                    }
                    onChange={(v) => field.onChange(v?.id)}
                    error={fieldState.error?.message}
                    fullWidth
                  />
                )}
              />

              <Controller
                control={control}
                name="functionParam"
                render={({ field, fieldState }) => (
                  <SimpleSelect
                    options={effectParamsOptions}
                    label="Mode"
                    variant="standard"
                    value={
                      effectParamsOptions.find((f) => f.id === field.value) ||
                      null
                    }
                    onChange={(v) => field.onChange(v?.id)}
                    error={fieldState.error?.message}
                    fullWidth
                  />
                )}
              />
            </Stack>

            <NumberTextField
              label="Delay time (milliseconds)"
              {...register('time')}
              variant="standard"
              size="small"
              error={!!errors.time}
              helperText={errors.time?.message}
              fullWidth
            />

            <Stack direction="row" justifyContent="space-between" spacing={2}>
              <Button
                variant="contained"
                color="info"
                type="submit"
                disabled={loading}
              >
                Save Effect
              </Button>

              {poseFunctionTick.id > 0 && (
                <PoseExtraDeleteButton
                  id={poseFunctionTick.id}
                  label="effect"
                  poseIndex={poseIndex}
                  propKey="poseFunctionTicks"
                  apiKey="poseFunction"
                />
              )}

              {poseFunctionTick.id < 0 && (
                <Button variant="outlined" onClick={handleCancel}>
                  Cancel
                </Button>
              )}
            </Stack>
          </Stack>

          <PoseExtraUpdateWatcher
            control={control}
            propKey="poseFunctionTicks"
            index={index}
            poseIndex={poseIndex}
          />
        </>
      )}
    </Form>
  );
};

const effectOptions = Object.entries(CreatePoseFunctionDtoFunctionNameEnum).map(
  ([key, value]) => ({
    id: value,
    name: key,
  }),
);

const effectParamsOptions = Object.entries(
  CreatePoseFunctionDtoFunctionParamEnum,
).map(([key, value]) => ({
  id: value,
  name: key === 'S' ? 'Small' : key === 'M' ? 'Medium' : 'Large',
}));
