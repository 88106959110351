import { Add, Remove } from '@mui/icons-material';
import { IconButton, Slider, Stack, Typography } from '@mui/material';

type NumberSliderProps = {
  label?: string;
  value: number;
  onChange: (value: number) => void;
} & Omit<React.ComponentProps<typeof Slider>, 'value' | 'onChange'>;

export const NumberSlider = ({
  label,
  value,
  onChange,
  step,
  max,
  min,
  ...props
}: NumberSliderProps) => {
  const handleAddValue = (amount: number) => {
    const newValue = value + amount;
    const minVal = min ?? 0;
    const maxVal = max ?? 100;

    if (newValue >= minVal && newValue <= maxVal) {
      onChange(newValue);
    }
  };

  return (
    <Stack spacing={1}>
      <Stack spacing={1} direction="row" alignItems="center">
        <IconButton
          size="small"
          onClick={() => handleAddValue(step ? -step : -1)}
        >
          <Remove />
        </IconButton>

        <Slider
          value={value}
          onChange={(e, v) => onChange(v as number)}
          step={step}
          max={max}
          min={min}
          {...props}
        />

        <IconButton size="small" onClick={() => handleAddValue(step ?? 1)}>
          <Add />
        </IconButton>
      </Stack>

      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        paddingInline="5px"
      >
        <Typography variant="caption">{label}</Typography>
        <Typography variant="caption" minWidth={26}>
          {value}
        </Typography>
      </Stack>
    </Stack>
  );
};
