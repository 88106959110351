import { Autocomplete, TextField, TextFieldVariants } from '@mui/material';
import { RenderAutocompleteInput } from '../ui/RenderAutocompleteInput';

type WithIdAndName = {
  id: string | null;
  name: string;
};

type ComboBoxProps<T extends WithIdAndName> = {
  options: T[];
  label: string;
  value: string | T | null;
  onChange: (value: string | null) => void;
  error?: string;
  variant?: TextFieldVariants;
  textFieldProps?: Omit<
    React.ComponentProps<typeof TextField>,
    'variant' | 'label'
  >;
};

export const ComboBox = <T extends WithIdAndName>({
  options,
  label,
  value,
  onChange,
  variant = 'outlined',
  error,
  textFieldProps,
}: ComboBoxProps<T>) => {
  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        if (newValue === null) {
          onChange(null);

          return;
        }

        if (typeof newValue === 'string') {
          onChange(newValue);

          return;
        }

        onChange(newValue.id);
      }}
      // onInputChange={(event, newInputValue) => {
      //   console.log(8, typeof newInputValue);
      //   onChange(newInputValue);
      // }}
      options={options}
      renderInput={(params) => (
        <RenderAutocompleteInput
          params={params}
          variant={variant}
          error={!!error}
          helperText={error}
          label={label}
          onChange={(event) => {
            onChange(event.target.value);
          }}
          {...textFieldProps}
          search
        />
      )}
      sx={{
        '.MuiAutocomplete-endAdornment': { overflow: 'hidden' },
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') return option;
        return option.name || '';
      }}
      getOptionKey={(option) => {
        if (typeof option === 'string') return option;
        return option.id!;
      }}
      // isOptionEqualToValue={(option, value) => {
      //   if (typeof value === 'string') return false;
      //   return option.id === value.id;
      // }}
      disableClearable={value !== null}
      freeSolo
    />
  );
};
