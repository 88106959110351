import { Add, ArrowLeft, Menu } from '@mui/icons-material';
import {
  AppBar,
  Badge,
  Box,
  Button,
  DialogContent,
  MenuItem,
  Paper,
  Stack,
  styled,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { MenuList } from '@web/components/common/ui/MenuList';
import { useAnchorMenu } from '@web/hooks/useAnchorMenu';
import { useAuth } from '@web/providers/auth/AuthProvider';
import { AssetDto } from '@web/store/assets';
import { AssetType, useAssetStore } from '@web/store/assets/state';
import { rootStore, useRootStore } from '@web/store/root/state';
import { memo, useCallback, useMemo, useState } from 'react';
import Draggable from 'react-draggable';
import { useSnapshot } from 'valtio';
import { AppBarButton } from '../../common/ui/AppBarButton';
import {
  DraggableDialog,
  DraggableDialogPaperProps,
} from '../../common/ui/DraggableDialog';
import { BackgroundForm } from '../background/BackgroundForm';
import { BackgroundsTab } from '../background/BackgroundTab';
import { BackgroundTabMobile } from '../background/BackgroundTabMobile';
import { CharacterForm } from '../character/CharacterForm';
import { CharactersTab } from '../character/CharacterTab';
import { CharacterTabMobile } from '../character/CharacterTabMobile';
import { EvidenceForm } from '../evidence/EvidenceForm';
import { EvidencesTab } from '../evidence/EvidenceTab';
import { EvidenceTabMobile } from '../evidence/EvidenceTabMobile';
import { MusicForm } from '../music/MusicForm';
import { MusicTab } from '../music/MusicTab';
import { MusicTabMobile } from '../music/MusicTabMobile';
import { PopupForm } from '../popup/PopupForm';
import { PopupsTab } from '../popup/PopupTab';
import { PopupTabMobile } from '../popup/PopupTabMobile';
import { SoundForm } from '../sound/SoundForm';
import { SoundTab } from '../sound/SoundTab';
import { SoundTabMobile } from '../sound/SoundTabMobile';
import { getDefaultAssetAddValue } from '../utils';
import { assetDialogState } from './AssetsDialogState';

type TabType = { title: string; type: AssetType };

const TABS: TabType[] = [
  { title: 'Backgrounds', type: 'background' },
  { title: 'Characters', type: 'character' },
  { title: 'Popups', type: 'popup' },
  { title: 'Music', type: 'music' },
  { title: 'Sounds', type: 'sound' },
  { title: 'Evidence', type: 'evidence' },
];

export const AssetsDialog = () => {
  const {
    dialogs: { assets },
  } = useRootStore();

  const handleClose = () => {
    rootStore.dialogs.assets = false;
  };

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down(800));

  const titleComponent = useMemo(() => <AssetsDialogTitle />, []);
  return (
    <DraggableDialog
      open={assets}
      onClose={handleClose}
      titleComponent={titleComponent}
      maxWidth="md"
      transitionDuration={0}
      fullscreen={md}
      PaperComponent={DialogPaper}
      persist
      fullWidth
    >
      <AssetsDialogContent />
    </DraggableDialog>
  );
};

// copied from DraggableDialog because we need scrollRef from this state
const DialogPaper = ({
  disableDraggable,
  ...other
}: DraggableDialogPaperProps) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const { scrollRef } = useSnapshot(assetDialogState);

  const id = `drag-dialog-${Math.random()}`.replace('.', '');

  const paperProps = {
    ...other,
    id,
  };

  return (
    <Draggable
      handle={`#${id}`}
      nodeRef={scrollRef}
      cancel={'[class*="MuiDialogContent-root"], .MuiButton-root'}
      bounds="html"
      position={position}
      onStop={(e, data) => setPosition({ x: data.x, y: data.y })}
      disabled={disableDraggable}
    >
      <Paper {...paperProps} ref={scrollRef} />
    </Draggable>
  );
};

const AssetsDialogContent = memo(() => {
  const { loggedIn } = useAuth();

  if (!loggedIn) {
    return <NotLoggedIn />;
  }

  return (
    <Stack direction="column">
      <TabNavigation />

      <DialogContent sx={{ p: { xs: 1, sm: 1.5 } }}>
        <AssetForm />
        <AssetManagement />
      </DialogContent>
    </Stack>
  );
});

const AssetForm = memo(() => {
  const snapshot = useSnapshot(assetDialogState);
  const { assetsDialogTab } = useRootStore();

  const onAdded = useCallback((asset: AssetDto) => {
    assetDialogState.addedAsset = asset;
  }, []);

  if (!snapshot.editingAsset) {
    return null;
  }

  switch (assetsDialogTab) {
    case 'background':
      return <BackgroundForm onAdded={onAdded} />;
    case 'character':
      return <CharacterForm onAdded={onAdded} />;
    case 'popup':
      return <PopupForm onAdded={onAdded} />;
    case 'music':
      return <MusicForm onAdded={onAdded} />;
    case 'sound':
      return <SoundForm onAdded={onAdded} />;
    case 'evidence':
      return <EvidenceForm onAdded={onAdded} />;
    default:
      return null;
  }
});

const AssetManagement = memo(() => {
  const { assetsDialogTab } = useRootStore();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(800));

  switch (assetsDialogTab) {
    case 'background':
      return !mobile ? <BackgroundsTab /> : <BackgroundTabMobile />;
    case 'character':
      return !mobile ? <CharactersTab /> : <CharacterTabMobile />;
    case 'popup':
      return !mobile ? <PopupsTab /> : <PopupTabMobile />;
    case 'music':
      return !mobile ? <MusicTab /> : <MusicTabMobile />;
    case 'sound':
      return !mobile ? <SoundTab /> : <SoundTabMobile />;
    case 'evidence':
      return !mobile ? <EvidencesTab /> : <EvidenceTabMobile />;
    default:
      return null;
  }
});

const TabNavigation = memo(() => {
  const snapshot = useSnapshot(assetDialogState);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down(800));

  if (snapshot.editingAsset) {
    return null;
  }

  return sm ? <MobileTabNavigation /> : <DesktopTabNavigation />;
});

const DesktopTabNavigation = () => {
  const { assetsDialogTab } = useRootStore();

  const handleTabChange = (value: AssetType) => {
    assetDialogState.selectedIds = [];
    assetDialogState.editingAsset = undefined;
    assetDialogState.editingAssetId = undefined;

    rootStore.assetsDialogTab = value;

    assetDialogState.search = '';
  };

  return (
    <Tabs
      value={assetsDialogTab}
      onChange={(_, value) => handleTabChange(value)}
      variant="fullWidth"
    >
      {TABS.map((tab) => (
        <Tab key={tab.title} value={tab.type} label={tab.title} />
      ))}
    </Tabs>
  );
};

const MobileTabNavigation = () => {
  const { assetsDialogTab } = useRootStore();
  const { anchorEl, toggle, close } = useAnchorMenu();

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down(370));

  const handleAddClick = useCallback(() => {
    assetDialogState.editingAsset =
      getDefaultAssetAddValue(rootStore.assetsDialogTab) ||
      ({
        id: 0,
      } as AssetDto);

    assetDialogState.editingAssetId = undefined;
  }, []);

  return (
    <AppBar position="static" elevation={1} sx={{ boxShadow: 'none' }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <AppBarButton onClick={toggle} startIcon={<Menu />} color="inherit">
          {assetsDialogTab}
        </AppBarButton>

        <MenuList
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={close}
          onClick={close}
          placement={xs ? 'bottom' : 'right-end'}
          sx={{ minWidth: 200 }}
          popperProps={{ disablePortal: true }}
        >
          {TABS.map((tab) => (
            <MenuItem
              key={tab.title}
              onClick={() => (rootStore.assetsDialogTab = tab.type)}
            >
              <MobileAssetLabel key={tab.title} tab={tab} />
            </MenuItem>
          ))}
        </MenuList>

        <AppBarButton
          color="primary"
          onClick={handleAddClick}
          startIcon={<Add />}
        >
          Add New
        </AppBarButton>
      </Stack>
    </AppBar>
  );
};

const MobileAssetLabel = ({ tab }: { tab: TabType }) => {
  const assets = useAssetStore();

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="space-between"
      flexGrow={1}
    >
      <Typography>{tab.title}</Typography>

      <StyledBadge
        badgeContent={String(assets[tab.type].user.length)}
        color="primary"
      />
    </Stack>
  );
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  '.MuiBadge-badge': {
    position: 'inherit',
    transform: 'none',
  },
}));

// const ListNavigation = memo(() => {
//   const handleTabChange = (value: AssetTab) => {
//     assetDialogState.tab = value;
//   };

//   const snapshot = useSnapshot(assetDialogState);

//   return (
//     <Box maxWidth={240}>
//       <List>
//         {[
//           'Backgrounds',
//           'Characters',
//           'Popups',
//           'Music',
//           'Sounds',
//           'Evidence',
//         ].map((text) => (
//           <ListItemButton
//             key={text}
//             selected={text === snapshot.tab}
//             color="info"
//             onClick={() => handleTabChange(text as AssetTab)}
//           >
//             <ListItemText primary={text} />
//           </ListItemButton>
//         ))}
//       </List>
//     </Box>
//   );
// });

const NotLoggedIn = () => {
  const handleLoginClick = () => {
    rootStore.dialogs.assets = false;
    rootStore.dialogs.login = true;
  };

  return (
    <Stack
      spacing={2}
      p={2}
      alignItems="center"
      justifyContent="center"
      flexGrow={1}
    >
      <Typography variant="h6">Please login to view your assets</Typography>
      <Button variant="contained" onClick={handleLoginClick}>
        Login
      </Button>
    </Stack>
  );
};

const AssetsDialogTitle = memo(() => {
  const { editingAsset } = useSnapshot(assetDialogState);
  const { assetsDialogTab } = useRootStore();

  const handleClose = () => {
    rootStore.dialogs.assets = false;
  };

  const handleBack = () => {
    assetDialogState.editingAsset = undefined;
    assetDialogState.editingAssetId = undefined;
  };

  const isEditingCharacter = editingAsset && assetsDialogTab === 'character';

  return (
    <>
      <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
        <Typography variant="h6">
          {isEditingCharacter ? 'Manage Character' : 'My Assets'}
        </Typography>
      </Box>

      <Box flexGrow={1} />

      <Stack direction="row">
        {isEditingCharacter && (
          <AppBarButton onClick={handleBack} startIcon={<ArrowLeft />}>
            Back
          </AppBarButton>
        )}
        <AppBarButton onClick={handleClose}>Close</AppBarButton>
      </Stack>
    </>
  );
});
