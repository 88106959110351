import { ProjectMigration } from '@shared/migration';
import { GroupType, NormalGroup, ProjectType } from '@shared/types';
import {
  BackgroundDto,
  CaseProjectDto,
  OptionsDto,
  SceneProjectDto,
} from '@web/api/api';
import { Frame } from '@web/types/project';
import { decode, encode } from 'js-base64';

export const projectUtils = {
  getSceneProject(frames: Frame[]): SceneProjectDto {
    return {
      id: '',
      type: 'scene',
      aliases: [],
      pairs: [],
      groups: [
        {
          id: 'id',
          name: '',
          type: GroupType.Normal,
          frames: frames.map((frame, index) => ({ ...frame, id: index + 1 })),
          comments: {},
        } as NormalGroup,
      ],
      options: this.getDefaultProjectOptions(),
      nextFrameId: frames.length + 1,
      freeFrameIds: [],
      version: 5,
    };
  },
  getDefaultProjectOptions(): OptionsDto {
    return {
      autoplaySpeed: 500,
      chatbox: 0,
      continueSoundUrl: '',
      textBlipFrequency: 64,
      textSpeed: 35,
    };
  },
  saveProject(project: SceneProjectDto | CaseProjectDto) {
    return encode(JSON.stringify(project));
  },
  loadProject(data: string) {
    try {
      let project: SceneProjectDto | CaseProjectDto = JSON.parse(decode(data));

      if (project.version !== 5) {
        project = ProjectMigration.migrate(
          project,
          (project.type ?? 'scene') as ProjectType,
        ) as CaseProjectDto | SceneProjectDto;
      }

      return project;
    } catch (e) {
      console.error(e);

      return undefined;
    }
  },
};

export const generateDefaultBackground = (
  width: number,
  aspectRatio: number,
) => {
  const height = width / aspectRatio;

  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext('2d');

  ctx!.fillStyle = 'black';
  ctx!.fillRect(0, 0, width, height);

  const base64Image = canvas.toDataURL('image/png');

  return base64Image;
};

export const defaultBackground: BackgroundDto = {
  id: 0,
  name: 'Default',
  url: generateDefaultBackground(960, 3 / 2),
  isWide: false,
};
