export default Object.freeze<Record<string, number>>({
  truth2007: 0.6,
  'seeing through': 0.6,
  allegro2007: 0.6,
  trial4: 0.6,
  suspense2007: 0.65,
  moderato2007: 0.6,
  'kitaki family': 0.6,
  cornered2007: 0.6,
  klavier: 0.5,
  'trucy wright': 0.7,
  trial5: 0.6,
  trick2007: 0.6,
  'cornered2007 variation': 0.6,
  'troupe gramarye': 0.6,
  'psycho lock': 0.6,
  objection2007: 0.6,
  'tragicomic interview': 0.6,
  'drew studio': 0.6,
  'solitary confinement': 0.6,
  'a hurt fox': 0.6,
  'a fate smeared by tricks and gadgets': 0.6,
  'search opening 2007': 0.6,
  'search core 2007': 0.6,
  'forgotten legend': 0.6,
  'won the case 2007': 0.6,
  'eccentric 2007': 0.6,
  "the guitar's serenade": 0.6,
  allegro2009: 1,
  'the great truth burglar': 1,
  cornered2009: 1,
  cornered2011: 0.8,
  presto2009: 1,
  moderato2009: 1,
  objection2009: 1,
  truth2009: 1,
  'shi-long lang': 1,
  trial6: 1,
});
