import { Viewport } from '@web/components/player/ui/Viewport';
import { useThumbnail } from '../maker/providers/ThumbnailProvider';

export const ThumbnailViewport = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { background, pose, transition } = useThumbnail();

  const fullWidth = !background?.isWide || pose?.isSpeedlines;
  const left = !transition?.left ? 0 : Math.min(transition.left, 99.9);

  return (
    <Viewport fullWidth={fullWidth} left={left}>
      {children}
    </Viewport>
  );
};
