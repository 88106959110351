/* eslint-disable @typescript-eslint/no-explicit-any */
import { camelCase } from 'lodash';
import { Evidence } from '../types/evidence';
import { ReferenceId } from './types/referenceId';

export const removeFalsyProperties = (
  obj: Record<string, any>,
  skipKeys: string[] = [],
): Record<string, any> => {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([key, value]) =>
        skipKeys.includes(key) ||
        (![undefined, null, false, 'null', '', 0].includes(value) &&
          !(Array.isArray(value) && value.length === 0)),
    ),
  );
};

export const referenceToString = (reference: ReferenceId | undefined) =>
  reference ? String(reference) : '';

export const evidenceStringToEvidence = (
  str: string | undefined | null,
): Evidence | undefined => {
  if (!str) return undefined;

  const arr = str.split('-');

  if (arr.length == 0) {
    return undefined;
  }

  const type = arr[0];

  if (type == 'e') {
    return { evidenceType: 'evidence', evidenceId: str.substring(2) };
  }

  if (type == 'p') {
    return { evidenceType: 'profile', evidenceId: str.substring(2) };
  }

  return undefined;
};

export const getFrameIdsFromString = (str: string | undefined | null) => {
  if (!str || str.length == 0) return [];

  let res: number[] = [];

  const items = str.split(',');

  items.forEach((item) => {
    item = item.trim();

    if (item.includes('-')) {
      const [from, to] = [item.split('-')[0], item.split('-')[1]];

      const parsedFrom = parseInt(from.trim());
      const parsedTo = parseInt(to.trim());

      if (
        !isNaN(parsedFrom) &&
        !isNaN(parsedTo) &&
        parsedFrom <= parsedTo &&
        parsedFrom > 0 &&
        parsedTo > 0 &&
        parsedTo - parsedFrom < 10000
      ) {
        const range = new Array(parsedTo - parsedFrom + 1)
          .fill(undefined)
          .map((el, ind) => ind + parsedFrom);

        res = res.concat(range);
      }
    } else {
      const parsed = parseInt(item);

      if (!isNaN(parsed)) {
        res.push(parsed);
      }
    }
  });

  return res;
};

export const camelizeKeys = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelizeKeys(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(obj[key]),
      }),
      {},
    );
  }
  return obj;
};
