type presetSpeechBlips = {
  id: number;
  label: string;
  url: string;
};

export const presetSpeechBlips: presetSpeechBlips[] = [
  { id: 1, label: 'Male', url: '/Audio/blip.wav' },
  { id: 2, label: 'Female', url: '/Audio/blip-female.wav' },
  { id: 3, label: 'Typewriter', url: '/Audio/blip-machine.wav' },
  { id: 4, label: 'Echoing Blip', url: '/Audio/blip-echo.wav' },
  { id: 5, label: 'Katonk', url: '/Audio/blip-katonk.wav' },
  { id: 6, label: 'Muted', url: '/Audio/blip-muted.wav' },
];
