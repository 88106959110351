import { ModeComment } from '@mui/icons-material';
import { TextField } from '@mui/material';
import { makerStore } from '@web/store/maker/state';
import { useSnapshot } from 'valtio';

export const FrameEditorComment = ({ frameId }: { frameId: number }) => {
  const { group, newCommentFrameId } = useSnapshot(makerStore);
  const comment = group?.comments[frameId];

  if (comment === undefined) return null;

  const shouldFocus = () => {
    const result = newCommentFrameId === frameId;

    if (result) {
      makerStore.newCommentFrameId = undefined;
    }

    return result;
  };

  return (
    <TextField
      value={comment}
      onChange={(e) => {
        makerStore.group!.comments[frameId] = e.target.value;
      }}
      variant="standard"
      size="small"
      placeholder="Comment"
      sx={(theme) => ({
        '.MuiInputBase-root': {
          background: theme.palette.action.selected,
          borderRadius: 0.5,
        },
        '.MuiInputBase-input': { padding: 0 },
      })}
      inputProps={{ maxLength: 1000 }}
      InputProps={{
        disableUnderline: true,
        sx: { padding: 0.5 },
        startAdornment: <ModeComment sx={{ marginRight: 1 }} />,
      }}
      fullWidth
      autoFocus={shouldFocus()}
    />
  );
};
