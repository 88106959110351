import { useCallback, useEffect, useRef } from 'react';

type TimelineEvent = {
  timeout: ReturnType<typeof setTimeout>;
  cancellable?: boolean;
  callback: () => void;
};

export const useTimeline = () => {
  const events = useRef<TimelineEvent[]>([]);

  const addEvent = (
    callback: (args: void) => void,
    time: number,
    cancellable = true,
  ) => {
    if (time <= 0) {
      callback();

      return;
    }

    const timeout = setTimeout(() => {
      callback();

      events.current = events.current.filter((f) => f.timeout !== timeout);

      clearTimeout(timeout);
    }, time);

    events.current.push({ timeout, cancellable, callback });

    return timeout;
  };

  const flushAllEvents = useCallback(() => {
    events.current.forEach((event) => {
      clearTimeout(event.timeout);
    });

    events.current.forEach((event) => {
      event.callback();
    });

    events.current = [];
  }, [events]);

  const clearEvents = useCallback(() => {
    events.current.forEach((event) => {
      clearTimeout(event.timeout);
    });

    events.current = [];
  }, [events]);

  const flushCancellableEvents = useCallback(() => {
    events.current
      .filter((f) => f.cancellable)
      .forEach((event) => {
        clearTimeout(event.timeout);
      });

    events.current
      .filter((f) => f.cancellable)
      .forEach((event) => {
        event.callback();
      });

    events.current = events.current.filter((f) => !f.cancellable);
  }, [events]);

  useEffect(() => {
    return () => {
      clearEvents();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { addEvent, flushAllEvents, clearEvents, flushCancellableEvents };
};
