import { Button, ButtonProps, Stack, Typography } from '@mui/material';
import { useDeleteConfirmation } from '@web/hooks/useDeleteConfirmation';
import React, { ReactNode } from 'react';

type DeleteConfirmationProps = {
  children: ReactNode;
  message: string;
  handleDelete: () => Promise<void>;
  deleteConfirmation: ReturnType<typeof useDeleteConfirmation>;
  variant?: ButtonProps['variant'];
};

export const DeleteConfirmation = ({
  children,
  deleteConfirmation,
  handleDelete,
  message,
  variant,
}: DeleteConfirmationProps) => {
  const childrenArray = React.Children.toArray(children);

  if (!deleteConfirmation.confirming) {
    return childrenArray[0];
  }

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="body2">{message}</Typography>

      <Stack direction="row" spacing={1} alignItems="center">
        <Button
          size="small"
          variant={variant ?? 'contained'}
          color="error"
          onClick={handleDelete}
          disabled={deleteConfirmation.deleting}
        >
          Confirm
        </Button>

        <Button
          size="small"
          variant={variant ?? 'contained'}
          color="success"
          onClick={deleteConfirmation.cancelConfirming}
        >
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};
