import { useQuery } from '@tanstack/react-query';
import { ApiClient } from '@web/api/api-client';
import { Popup } from '@web/components/player/ui/Popup';
import { useThumbnail } from '../maker/providers/ThumbnailProvider';

export const ThumbnailPopup = () => {
  const { serverThumbnails, popup } = useThumbnail();

  const getThumbnail = async () => {
    if (!popup) return;

    const response = await ApiClient.thumbnail.getPopupThumbnail(popup.id);

    return `${import.meta.env.VITE_BACKEND_BASE_URL}${response.data.url}`;
  };

  const { data: imageUrl, isLoading } = useQuery({
    queryKey: ['thumbnail', 'popup', popup?.id || 0],
    queryFn: getThumbnail,
    staleTime: 30 * 60 * 1000,
    enabled: !!popup && serverThumbnails,
  });

  if (!popup || isLoading) return null;

  return (
    <Popup
      url={imageUrl || popup.url}
      x={popup.posX}
      y={popup.posY}
      center={popup.center}
      size={popup.resize}
    />
  );
};
