import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Switch from '@web/components/common/form/Switch';
import { PageContainer } from '@web/components/common/ui/PageContainer';
import { RenderAutocompleteInput } from '@web/components/common/ui/RenderAutocompleteInput';
import { PreviewDialog } from '@web/components/maker/dialogs/PreviewDialog';
import { assetActions } from '@web/store/assets/actions';
import { useAssetStore } from '@web/store/assets/state';
import { Frame } from '@web/types/project';
import { projectUtils } from '@web/utils/project';
import React, { useEffect, useMemo } from 'react';
import { proxy, useSnapshot } from 'valtio';

const characters = [
  [1, 2, 3, 4],
  [5, 6, 7, 8],
  [25, 10, 11, 12],
];

type HomePageState = {
  frame: Frame;
  music: boolean;
  previewDialog: boolean;
  error: string;
  onPreview: () => void;
  getFrame: () => Frame;
};

const state = proxy<HomePageState>({
  frame: {
    characterId: 1,
    text: 'Pick a character, pose, submit your objection, then surprise your friends with your irrefutable evidence! Pick a character, pose, submit your objection, then surprise your friends with your irrefutable evidence!',
  },
  music: false,
  previewDialog: false,
  error: '',
  onPreview: () => {
    if (!state.frame.text) {
      state.error = 'Please enter your text';

      return;
    }

    state.previewDialog = true;
  },
  getFrame: () => {
    const frame = JSON.parse(JSON.stringify(state.frame));
    if (state.music) {
      frame.text = `[#bgmd]${frame.text}`;
    }
    return frame;
  },
});

const HomePage = () => {
  useEffect(() => {
    assetActions.loadPresetAssetsType('character');
    assetActions.loadPresetAssetsType('background');
  }, []);

  return (
    <PageContainer>
      <Stack spacing={2}>
        <Typography variant="h3" textAlign="center">
          Objection!
        </Typography>

        <Typography variant="body1" textAlign="center">
          Pick a character, pose, submit your objection, then surprise your
          friends with your irrefutable evidence!
        </Typography>

        <Box py={{ xs: 1, sm: 2 }}>
          <SimpleObjectionForm />
        </Box>
      </Stack>

      <HomePreviewDialog />
    </PageContainer>
  );
};

const HomePreviewDialog = () => {
  const snapshot = useSnapshot(state);

  const handleClose = () => {
    state.previewDialog = false;
  };

  const project = useMemo(
    () => projectUtils.getSceneProject([state.getFrame()]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [snapshot.previewDialog],
  );

  return (
    <PreviewDialog
      open={snapshot.previewDialog}
      onClose={handleClose}
      project={project}
    />
  );
};

const SimpleObjectionForm = React.memo(() => {
  const snapshot = useSnapshot(state);

  const handleCharacterChange = (characterId: number) => {
    state.frame.characterId = characterId;
    state.frame.speechBubble = undefined;
    state.frame.poseId = undefined;
  };

  useEffect(() => {
    state.error = '';
  }, [snapshot.frame.text]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} display="flex" justifyContent="center">
        <CharacterPicker
          value={snapshot.frame.characterId}
          onChange={handleCharacterChange}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Grid container spacing={{ xs: 1, md: 2 }}>
          <Grid item xs={12} sm={6}>
            <SpeechBubblePicker />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PosesPicker />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Text"
              multiline={true}
              rows={5}
              value={snapshot.frame.text}
              onChange={(e) => (state.frame.text = e.target.value)}
              variant="filled"
              error={!!snapshot.error}
              helperText={snapshot.error}
              inputProps={{ maxLength: 500 }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="space-between">
            <Switch
              label="Music"
              size="medium"
              value={snapshot.music}
              onChange={(e) => {
                state.music = e.target.checked;
              }}
            />
            <Switch
              label="Pose Animation"
              size="medium"
              value={snapshot.frame.noPoseAnimation}
              onChange={(e) => {
                state.frame.noPoseAnimation = !e.target.checked;
              }}
              defaultChecked
            />
          </Grid>
        </Grid>

        <Stack direction="row" mt={2} spacing={2}>
          <Button variant="contained" color="info" onClick={snapshot.onPreview}>
            Preview
          </Button>
          <Button variant="contained" color="success">
            Objection!
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
});

const SpeechBubblePicker = React.memo(() => {
  const store = useAssetStore();
  const {
    frame: { characterId, speechBubble },
  } = useSnapshot(state);

  const bubbles = useMemo(() => {
    if (!state.frame.characterId) return [];

    const result = (
      store.character.cache[characterId ?? '']?.speechBubbles ?? []
    ).map((f) => ({
      value: f.id,
      label: f.name,
    }));

    return result;
  }, [characterId, store.character.cache]);

  const bubblesList = useMemo(() => {
    return [{ value: undefined, label: 'None' }, ...bubbles];
  }, [bubbles]);

  const selectedBubble = useMemo(() => {
    const foundBubble = bubbles.find((f) => f.value === speechBubble);

    return foundBubble || bubbles[0] || null;
  }, [bubbles, speechBubble]);

  useEffect(() => {
    state.frame.speechBubble = bubbles[0]?.value;
  }, [bubbles]);

  return (
    <Autocomplete
      options={bubblesList}
      value={selectedBubble}
      onChange={(e, v) => {
        state.frame.speechBubble = v?.value;
      }}
      renderInput={(params) => (
        <RenderAutocompleteInput params={params} label="Speech Bubble" />
      )}
      disableClearable
      fullWidth
    />
  );
});

const PosesPicker = React.memo(() => {
  const store = useAssetStore();
  const snapshot = useSnapshot(state);

  const poses = useMemo(() => {
    if (!state.frame.characterId) return [];

    return (
      store.character.cache[snapshot.frame.characterId ?? '']?.poses.filter(
        (f) => !f.name.includes('(custom)'),
      ) ?? []
    ).map((f) => ({
      value: f.id,
      label: f.name,
    }));
  }, [snapshot.frame.characterId, store.character.cache]);

  const selectedPose = useMemo(() => {
    const foundPose = poses.find((f) => f.value === snapshot.frame.poseId);
    return foundPose || poses[0] || { value: -1, label: '' };
  }, [poses, snapshot.frame.poseId]);

  useEffect(() => {
    state.frame.poseId = poses[0]?.value;
  }, [poses]);

  return (
    <Autocomplete
      options={poses}
      value={selectedPose}
      onChange={(e, v) => {
        state.frame.poseId = v?.value;
      }}
      renderInput={(params) => (
        <RenderAutocompleteInput params={params} label="Pose" />
      )}
      disableClearable
      fullWidth
    />
  );
});

const CharacterPicker = React.memo(
  ({
    value,
    onChange,
  }: {
    value: number | undefined;
    onChange: (id: number) => void;
  }) => {
    return (
      <Stack direction="column" spacing={0.25}>
        {characters.map((row, i) => (
          <Stack key={i} direction="row" spacing={0.25}>
            {row.map((id) => (
              <CharacterIcon
                key={id}
                characterId={id}
                isSelected={id === value}
                onClick={() => onChange(id)}
              />
            ))}
          </Stack>
        ))}
      </Stack>
    );
  },
);

const CharacterIcon = React.memo(
  ({
    characterId,
    isSelected,
    onClick,
  }: {
    characterId: number;
    isSelected: boolean;
    onClick: () => void;
  }) => {
    const store = useAssetStore();
    const character = store.character.cache[characterId];

    return (
      <Box
        component="img"
        src={`/Images/Characters/${characterId}/icon.png`}
        style={{ cursor: 'pointer', opacity: isSelected ? 1 : 0.5 }}
        onClick={onClick}
        alt={character?.name}
        title={character?.name}
      />
    );
  },
);

export default HomePage;
