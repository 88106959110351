import { useState } from 'react';

export const useToggleValue = <T>(defaultValue: T) => {
  const [value, setValue] = useState<T>(defaultValue);

  const toggleValue = (newValue: T) => {
    if (value === newValue) {
      setValue(defaultValue);
    } else {
      setValue(newValue);
    }
  };

  return [value, toggleValue] as const;
};
