import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { Image } from '../../common/ui/Image';
import { evidenceIconScale } from '../hooks/usePlayerStyles';
import { ZIndex } from '../utils/constants';

type EvidenceProps = {
  url: string;
  isIcon?: boolean;
  size: number;
  flipped?: boolean;
};

export const Evidence = ({ url, isIcon, size, flipped }: EvidenceProps) => {
  return !isIcon ? (
    <FullscreenEvidence url={url} />
  ) : (
    <EvidenceIcon url={url} size={size} flipped={flipped} />
  );
};

const FullscreenEvidence = ({ url }: { url: string }) => (
  <>
    <FullscreenEvidenceBackground />
    <FullscreenEvidenceStyled src={url} alt="Evidence" />
  </>
);

const EvidenceIcon = ({
  url,
  size,
  flipped,
}: {
  url: string;
  size: number;
  flipped?: boolean;
}) => {
  return (
    <EvidenceIconContainer
      style={{
        maxWidth: `${176 * size}px`,
        ...(flipped ? { left: '1%' } : { right: '1%' }),
      }}
    >
      <EvidenceIconImageContainer src="/Images/evidence container.png" alt="" />
      <EvidenceIconStyled src={url} alt="Evidence" />
    </EvidenceIconContainer>
  );
};

const FullscreenEvidenceStyled = styled(Image)(({ theme }) => {
  return {
    width: '100%',
    maxHeight: '100%',
    top: 0,
    left: 0,
    position: 'absolute',
    zIndex: ZIndex.Evidence,
    userSelect: 'none',
    pointerEvents: 'none',
  };
});

const FullscreenEvidenceBackground = styled(Box)(({ theme }) => {
  return {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    userSelect: 'none',
    pointerEvents: 'none',
    zIndex: ZIndex.Evidence,
    backgroundColor: 'black',
  };
});

const EvidenceIconContainer = styled(Box)(({ theme }) => {
  return {
    position: 'absolute',

    userSelect: 'none',
    pointerEvents: 'none',
    zIndex: ZIndex.Evidence,
    display: 'flex',
    top: '1%',
    ...evidenceIconScale,
  };
});

const EvidenceIconImageContainer = styled(Image)(({ theme }) => {
  return {
    maxWidth: `100%`,
    maxHeight: `100%`,
    position: 'relative',
    zIndex: ZIndex.Evidence,
    userSelect: 'none',
    pointerEvents: 'none',
  };
});

const EvidenceIconStyled = styled(Image)(({ theme }) => {
  return {
    position: 'absolute',
    maxWidth: `87.5%`, // 154 / 176
    maxHeight: `87.5%`, // 154 / 176
    zIndex: ZIndex.Evidence,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    userSelect: 'none',
    pointerEvents: 'none',
  };
});
