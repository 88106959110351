import { useEffect, useRef } from 'react';
import { usePlayerReset } from '../providers/PlayerResetProvider';

type Reset = {
  name: string;
  onReset: () => void;
};

export const usePlayerResetRegister = ({ name, onReset }: Reset) => {
  const context = usePlayerReset();

  const savedOnReset = useRef(onReset);

  useEffect(() => {
    savedOnReset.current = onReset;
  }, [onReset]);

  useEffect(() => {
    if (!context) {
      return;
    }

    const { register } = context;

    register(name, savedOnReset.current);
  }, [context, name]);
};
