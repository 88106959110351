import { Chip } from '@mui/material';
import { useThumbnail } from '../maker/providers/ThumbnailProvider';
import { ZIndex } from '../player/utils/constants';

export const ThumbnailSpeechBubble = () => {
  const { serverThumbnails, speechBubble } = useThumbnail();

  if (!speechBubble || !serverThumbnails) return null;

  return (
    <Chip
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: ZIndex.SpeechBubble,
        borderRadius: 0,
        maxWidth: '80px',
        height: 'auto',
        fontSize: '0.65rem',
      }}
      sx={{ '.MuiChip-label': { padding: 0, paddingInline: 0.5 } }}
      size="small"
      color="error"
      label={speechBubble.name}
    />
  );
};
