import { Box } from '@mui/material';
import React, { useCallback } from 'react';
import { animated } from 'react-spring';
import { useFlash } from './hooks/useFlash';
import { usePlayerInteraction } from './hooks/usePlayerInteraction';
import { usePlayer } from './providers/PlayerProvider';
import { Container } from './ui/Container';

const FlashBox = animated(Box);

export const PlayerContainer = React.forwardRef(
  (
    { id, children }: { id: string | undefined; children: React.ReactNode },
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const {
      playerUi: {
        state: { flash, width, aspectRatio },
        actions: { setFlash },
      },
    } = usePlayer();

    const {
      handleMouseDown,
      handleMouseUp,
      // preventContextMenu,
      // handleTouchEnd,
      // handleTouchStart,
    } = usePlayerInteraction();

    const handleContextMenu = useCallback<
      React.MouseEventHandler<HTMLDivElement>
    >((e) => {
      e.preventDefault();
    }, []);

    const flashStyle = useFlash({ flash, setFlash });

    return (
      <Container
        id={id}
        aspectRatio={aspectRatio}
        maxWidth={width}
        // onMouseDown={handleMouseDown}
        // onMouseUp={handleMouseUp}
        // onContextMenu={preventContextMenu}
        onPointerDown={handleMouseDown}
        onPointerUp={handleMouseUp}
        onContextMenu={handleContextMenu}
        ref={ref}
      >
        <FlashBox
          sx={{
            width: '100%',
            height: '100%',
            bgcolor: 'white',
          }}
          style={flashStyle}
        >
          {children}
        </FlashBox>
      </Container>
    );
  },
);
