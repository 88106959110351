import {
  Alert,
  Button,
  Divider,
  Link,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ApiClient } from '@web/api/api-client';
import { useAuth } from '@web/providers/auth/AuthProvider';
import { rootStore } from '@web/store/root/state';
import { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { proxy, useSnapshot } from 'valtio';
import * as yup from 'yup';
import Form from '../common/form/Form';
import ReCaptcha from '../common/form/ReCaptcha';
import Dialog from '../common/ui/Dialog';
import MultiView from '../common/ui/MultiView';

enum TABS {
  'LOGIN',
  'REGISTER',
  'FORGOT_PASSWORD',
}

const LOGIN_PROVIDERS = [
  { name: 'Discord', color: '#5865F2' },
  { name: 'Google', color: '#DB4437' },
];

type StateType = {
  tab: TABS;
};

const state = proxy<StateType>({ tab: TABS.LOGIN });

const LoginDialog = () => {
  const snapshot = useSnapshot(state);
  const rootSnapshot = useSnapshot(rootStore.dialogs);

  const { loggedIn } = useAuth();

  const handleClose = () => {
    rootStore.dialogs.login = false;
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (loggedIn) {
      rootStore.dialogs.login = false;
    }
  }, [loggedIn]);

  return (
    <Dialog
      open={rootSnapshot.login}
      onClose={handleClose}
      title={
        snapshot.tab === TABS.LOGIN
          ? 'Login'
          : snapshot.tab === TABS.REGISTER
            ? 'Register'
            : 'Reset Password'
      }
      dividers={false}
      fullWidth
      maxWidth="sm"
      fullScreen={fullScreen}
    >
      <MultiView index={snapshot.tab}>
        <MainLoginForm />
        <RegisterForm />
        <ForgotPasswordForm />
      </MultiView>
    </Dialog>
  );
};

const MainLoginForm = () => {
  const handleProviderLogin = async (provider: string) => {
    const newWindow = window.open(
      `${import.meta.env.VITE_BACKEND_BASE_URL}/auth/${provider}`,
      'Login',
    );

    newWindow?.focus();
  };

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        {LOGIN_PROVIDERS.map((provider) => (
          <Button
            key={provider.name}
            variant="contained"
            sx={{
              backgroundColor: provider.color,
              color: 'white',
              '&:hover': { backgroundColor: provider.color },
            }}
            onClick={() => handleProviderLogin(provider.name.toLowerCase())}
          >
            {provider.name}
          </Button>
        ))}
      </Stack>

      <Divider sx={(theme) => ({ color: theme.palette.text.secondary })}>
        Or
      </Divider>

      <EmailLoginForm />
    </Stack>
  );
};

type EmailLoginFormFields = {
  email: string;
  password: string;
};

const EmailLoginForm = () => {
  const { login } = useAuth();

  const schema = yup
    .object({
      email: yup.string().email().required(),
      password: yup.string().min(6).required(),
    })
    .required();

  const onSubmit = async (data: EmailLoginFormFields) => {
    await login({
      email: data.email,
      password: data.password,
    });
  };

  return (
    <Form schema={schema} onSubmit={onSubmit}>
      {({ register, errors, loading }) => (
        <Stack spacing={2}>
          {errors.root?.message && (
            <Alert severity="error">{errors.root.message}</Alert>
          )}

          <TextField
            {...register('email')}
            label="Email"
            type="email"
            fullWidth
            variant="filled"
            error={!!errors.email}
            helperText={errors.email?.message}
          />

          <TextField
            {...register('password')}
            label="Password"
            type="password"
            fullWidth
            variant="filled"
            error={!!errors.password}
            helperText={errors.password?.message}
          />

          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            Login
          </Button>

          <LoginModeSwitcher />
        </Stack>
      )}
    </Form>
  );
};

type RegisterFormFields = {
  email: string;
  password: string;
  confirmPassword: string;
  captcha: string | null;
};

const RegisterForm = () => {
  const { register } = useAuth();

  const schema = yup
    .object({
      email: yup.string().email().required(),
      password: yup.string().min(8).required(),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password')], 'Passwords must match'),
    })
    .required();

  const onSubmit = async (data: RegisterFormFields) => {
    await register({
      email: data.email,
      password: data.password,
      captcha: data.captcha as string,
    });
  };

  return (
    <Form schema={schema} onSubmit={onSubmit}>
      {({ control, register, errors, loading }) => (
        <Stack spacing={2}>
          {errors.root?.message && (
            <Alert severity="error">{errors.root.message}</Alert>
          )}

          <TextField
            {...register('email')}
            label="Email"
            type="email"
            fullWidth
            variant="filled"
            error={!!errors.email}
            helperText={errors.email?.message}
          />

          <TextField
            {...register('password')}
            label="Password"
            type="password"
            fullWidth
            variant="filled"
            error={!!errors.password}
            helperText={errors.password?.message}
          />

          <TextField
            {...register('confirmPassword')}
            label="Confirm Password"
            type="password"
            fullWidth
            variant="filled"
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword?.message}
          />

          <Controller
            control={control}
            name="captcha"
            render={({ field }) => <ReCaptcha onChange={field.onChange} />}
          />

          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            Register
          </Button>

          <LoginModeSwitcher />
        </Stack>
      )}
    </Form>
  );
};

type ForgotPasswordFormFields = {
  email: string;
  captcha: string | null;
};

const ForgotPasswordForm = () => {
  const [error, setError] = useState<boolean | undefined>(undefined);

  const schema = yup
    .object({
      email: yup.string().email().required(),
    })
    .required();

  const onSubmit = async (data: ForgotPasswordFormFields) => {
    const res = await ApiClient.auth.forgotPassword(
      { email: data.email },
      { headers: { recaptcha: data.captcha } },
    );

    setError(!res.data);
  };

  return (
    <Form schema={schema} onSubmit={onSubmit}>
      {({ control, register, errors, loading }) => (
        <Stack spacing={2}>
          {errors.root?.message && (
            <Alert severity="error">{errors.root.message}</Alert>
          )}

          {error !== undefined && (
            <Alert severity={error ? 'error' : 'success'}>
              {error
                ? 'An error occurred. Please try again later.'
                : 'An email has been sent to your inbox with a link to reset your password.'}
            </Alert>
          )}

          <TextField
            {...register('email')}
            label="Email"
            type="email"
            fullWidth
            variant="filled"
            error={!!errors.email}
            helperText={errors.email?.message}
          />

          <Controller
            control={control}
            name="captcha"
            render={({ field }) => <ReCaptcha onChange={field.onChange} />}
          />

          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            Submit
          </Button>

          <LoginModeSwitcher />
        </Stack>
      )}
    </Form>
  );
};

const LoginModeSwitcher = () => {
  const snapshot = useSnapshot(state);

  const handleSwitch = (tabIndex: number) => {
    state.tab = tabIndex;
  };

  const Options = useMemo(() => {
    switch (snapshot.tab) {
      case TABS.LOGIN:
        return [
          {
            title: 'Reset password',
            onClick: () => handleSwitch(TABS.FORGOT_PASSWORD),
          },
          { title: 'Register', onClick: () => handleSwitch(TABS.REGISTER) },
        ];
      case TABS.REGISTER:
        return [
          {
            title: 'Reset password',
            onClick: () => handleSwitch(TABS.FORGOT_PASSWORD),
          },
          { title: 'Login instead', onClick: () => handleSwitch(TABS.LOGIN) },
        ];
      case TABS.FORGOT_PASSWORD:
        return [
          { title: '', onClick: () => {} },
          { title: 'Cancel', onClick: () => handleSwitch(TABS.LOGIN) },
        ];
      default:
        return [{ title: 'Cancel', onClick: () => handleSwitch(TABS.LOGIN) }];
    }
  }, [snapshot.tab]);

  return (
    <Stack direction="row" spacing={1} justifyContent="space-between" pt={1.5}>
      {Options?.map((option) => (
        <Link
          key={option.title}
          component="button"
          type="button"
          underline="hover"
          onClick={option.onClick}
        >
          {option.title}
        </Link>
      ))}
    </Stack>
  );
};

LoginDialog.displayName = 'LoginDialog';

export default LoginDialog;
