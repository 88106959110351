import { KeyboardDoubleArrowRight } from '@mui/icons-material';
import { useMemo } from 'react';
import { NextButtonIcon } from '../../ui/NextButton';
import { DialogueBox } from '../DialogueBox';
import { NameplateProps } from '../Nameplate';
import { NextButton, continueButtonAnimation } from '../NextButton';
import { DialogueBoxTextContainerProps } from '../types';

export const TrilogyDialogueBox = () => {
  const nameplateProps = useMemo<NameplateProps>(
    () => ({
      fontSize: 29,
      url: '/Images/UI/Chatbox/Trilogy/nameplate.png',
      top: 62.9,
      right: 31.7,
      fontFamily: 'Trilogy-body',
      fontUrl: '/Fonts/Trilogy-body.woff2',
    }),
    [],
  );

  const textProps = useMemo<DialogueBoxTextContainerProps>(
    () => ({
      fontSize: 34,
      fontFamily: 'Trilogy-body',
      width: 67,
      widthCenter: 98,
      height: 4.5179,
      left: 23.2,
      leftCenter: 1,
      top: 69,
      topCenter: 68.5,
      fontUrl: '/Fonts/Trilogy-body.woff2',
    }),
    [],
  );

  return (
    <DialogueBox
      url="/Images/UI/Chatbox/Trilogy/textbox.png"
      nameplate={nameplateProps}
      text={textProps}
      NextButton={<TrilogyNextButton />}
      imageOpacity={0.92}
    />
  );
};

const TrilogyNextButton = () => {
  const defaultFontSize = 3.75;

  return (
    <NextButton>
      {({ onClick, showNextButton, size }) => (
        <NextButtonIcon
          display={showNextButton ? 'flex' : 'none'}
          style={{
            display: showNextButton ? 'flex' : 'none',
            top: '75.7%',
            left: '91%',
          }}
          onClick={onClick}
        >
          <KeyboardDoubleArrowRight
            sx={{
              animation: `${continueButtonAnimation} 0.5s ease-in-out infinite both`,
            }}
            style={{
              fontSize: `${defaultFontSize * size}em`,
              color: '#ffd560',
              lineHeight: 1,
              filter: `drop-shadow(-0.015em 0 #b58a18) 
                       drop-shadow(0 0.015em #b58a18) 
                       drop-shadow(0.015em 0 #b58a18) 
                       drop-shadow(0 -0.015em #b58a18)`,
            }}
          />
        </NextButtonIcon>
      )}
    </NextButton>
  );
};
