import { Effect } from '@shared/types';
import { SceneTargets } from '@shared/types/scene-targets';
import { CSSProperties, useMemo } from 'react';
import { getFilterCSS } from '../utils/filter';

export const useFrameFilter = (
  effect: Effect | undefined,
  target: SceneTargets,
) => {
  const filter: CSSProperties = useMemo(
    () =>
      effect?.type && (effect.target & target) === target
        ? { filter: getFilterCSS(effect) }
        : {},
    [effect, target],
  );

  return filter;
};
