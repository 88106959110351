import { VolumeDown, VolumeUp } from '@mui/icons-material';
import {
  Slider as MuiSlider,
  SliderProps as MuiSliderProps,
  Stack,
} from '@mui/material';

type SliderProps = {
  label: string;
  downIcon?: React.ReactNode;
  upIcon?: React.ReactNode;
} & MuiSliderProps;

export const VolumeSlider = ({
  label,
  downIcon,
  upIcon,
  ...props
}: SliderProps) => {
  const DownIcon = downIcon || <VolumeDown />;
  const UpIcon = upIcon || <VolumeUp />;

  return (
    <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
      {DownIcon}
      <MuiSlider {...props} />
      {UpIcon}
    </Stack>
  );
};
