import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';

type ResetContext = {
  comps: {
    [key: string]: () => void;
  };
  register: (key: string, reset: () => void) => void;
  reset: () => void;
};

const resetContext = createContext<ResetContext | undefined>(undefined);

export const usePlayerReset = () => {
  const context = useContext(resetContext);

  const contextValue = useMemo(() => {
    if (!context) {
      return undefined;
    }

    const { reset, register } = context;

    return { reset, register };
  }, [context]);

  return contextValue;
};

export const PlayerResetProvider = ({ children }: { children: ReactNode }) => {
  const comps = useRef<ResetContext['comps']>({}).current;

  const register = useCallback(
    (key: string, reset: () => void) => {
      if (comps[key]) {
        delete comps[key];
      }

      comps[key] = reset;
    },
    [comps],
  );

  const reset = useCallback(() => {
    for (const key in comps) {
      comps[key]();
    }
  }, [comps]);

  return (
    <resetContext.Provider value={{ comps: comps, register, reset }}>
      {children}
    </resetContext.Provider>
  );
};
