import { MeResponseDto } from '@web/api/api';
import { ApiClient } from '@web/api/api-client';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { AuthContextType } from './authService';

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useLocalStorage<MeResponseDto | null>('user', null);

  const login = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    const res = await ApiClient.auth.login({ email, password });

    localStorage.setItem('token', res.data.token);

    await me();
  };

  const register = async ({
    email,
    password,
    captcha,
  }: {
    email: string;
    password: string;
    captcha: string;
  }) => {
    const res = await ApiClient.auth.register(
      { email, password },
      { headers: { recaptcha: captcha } },
    );

    localStorage.setItem('token', res.data.token);

    await me();
  };

  const discordLogin = async (code: string) => {
    const res = await ApiClient.auth.discordCallback(code);

    localStorage.setItem('token', res.data.token);

    await me();
  };

  const googleLogin = async (code: string) => {
    const res = await ApiClient.auth.googleCallback(code);

    localStorage.setItem('token', res.data.token);

    await me();
  };

  const me = async () => {
    try {
      const res = await ApiClient.auth.me();

      setUser(res.data);

      localStorage.setItem('user', JSON.stringify(res.data));
    } catch (error: any) {
      console.error(error);
      if (error?.response?.status === 401) {
        await logout();
      }
    }
  };

  const clear = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');

    setUser(null);
  };

  const logout = async () => {
    try {
      await ApiClient.auth.logout();
    } catch (error) {
      console.error(error);
    } finally {
      clear();
    }
  };

  const loggedIn = !!user;

  const getMe = useCallback(async () => {
    if (loggedIn) {
      await me();
    }
  }, [loggedIn]);

  useEffect(() => {
    getMe();
  }, []);

  // useEffect(() => {
  //   window.addEventListener('focus', getMe);

  //   return () => {
  //     window.removeEventListener('focus', getMe);
  //   };
  // }, [getMe]);

  return (
    <AuthContext.Provider
      value={{
        user,
        loggedIn,
        login,
        register,
        me,
        discordLogin,
        googleLogin,
        clear,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};
