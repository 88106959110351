import { forwardRef, useImperativeHandle } from 'react';
import { PlayerProps, usePlayer } from './hooks/usePlayer';
import { PlayerBackground } from './PlayerBackground';
import { PlayerCharacters } from './PlayerCharacters';
import { PlayerContainer } from './PlayerContainer';
import { PlayerControls } from './PlayerControls';
import { PlayerDialogueBox } from './PlayerDialogueBox';
import { PlayerEvidence } from './PlayerEvidence';
import { PlayerColorFade } from './PlayerFade';
import { PlayerGallery } from './PlayerGallery';
import { PlayerLoading } from './PlayerLoading';
import { PlayerPopup, PlayerPresetPopups } from './PlayerPopup';
import { PlayerPreloadBox } from './PlayerPreloadBox';
import { PlayerSpeechBubble } from './PlayerSpeechBubble';
import { PlayerViewport } from './PlayerViewport';
import { PlayerActionsType, PlayerContext } from './providers/PlayerProvider';
import { PlayerResetProvider } from './providers/PlayerResetProvider';

const PlayerComponent = forwardRef<PlayerActionsType, PlayerProps>(
  (
    { id, autoStart, width, aspectRatio, hideVolume, hideAutoplay, controls },
    ref,
  ) => {
    const {
      state,
      step,
      playerDefaults,
      aliases,
      frame,
      timeline,
      audio,
      actions,
      playerUi,
      playerLoading,
      playerContainerRef,
      defaultBackground,
    } = usePlayer({ autoStart, width, aspectRatio });

    useImperativeHandle(ref, () => actions, [actions]);

    return (
      <PlayerContext.Provider
        value={{
          state,
          step,
          playerDefaults,
          aliases,
          frame,
          timeline,
          audio,
          actions,
          playerUi,
          playerLoading,
          playerContainerRef,
          defaultBackground,
        }}
      >
        <PlayerContainer id={id} ref={playerContainerRef}>
          <PlayerViewport>
            <PlayerBackground />
            <PlayerCharacters />
          </PlayerViewport>
          <PlayerEvidence />
          <PlayerSpeechBubble />
          <PlayerPopup />
          <PlayerPresetPopups />
          <PlayerGallery />
          <PlayerDialogueBox />
          <PlayerColorFade />
          <PlayerLoading />
        </PlayerContainer>

        <PlayerControls
          width={width || 960}
          hideAutoplay={hideAutoplay}
          hideVolume={hideVolume}
          controls={controls}
        />

        <PlayerPreloadBox />
      </PlayerContext.Provider>
    );
  },
);

export const Player = forwardRef<PlayerActionsType, PlayerProps>(
  (props, ref) => (
    <PlayerResetProvider>
      <PlayerComponent ref={ref} {...props} />
    </PlayerResetProvider>
  ),
);
