import { Autocomplete, TextFieldVariants } from '@mui/material';
import { Character_Side } from '@shared/types';
import { CreateCharacterDto, UpdateCharacterDto } from '@web/api/api';
import { RenderAutocompleteInput } from '@web/components/common/ui/RenderAutocompleteInput';
import { useMemo } from 'react';
import { Control, Controller, useFormState, useWatch } from 'react-hook-form';

export const LocationInput = ({
  variant,
  control,
}: {
  variant?: TextFieldVariants | undefined;
  control: Control<CreateCharacterDto | UpdateCharacterDto> | undefined;
}) => {
  const { errors } = useFormState({ control });
  const side = useWatch({ control, name: 'side' });

  const locationOptions = useMemo(
    () =>
      Object.entries(Character_Side).map(([key, value]) => ({
        id: value,
        name: key,
      })),
    [],
  );

  const selectedValue = useMemo(
    () => locationOptions.find((f) => f.id === side) || null,
    [locationOptions, side],
  );

  return (
    <Controller
      control={control}
      name="side"
      render={({ field }) => (
        <Autocomplete
          value={selectedValue}
          onChange={(event, newValue) => {
            field.onChange(newValue?.id);
          }}
          options={locationOptions}
          renderInput={(params) => (
            <RenderAutocompleteInput
              params={params}
              variant={variant ?? 'outlined'}
              error={!!errors.side}
              helperText={errors.side?.message}
              label="Location"
            />
          )}
          getOptionLabel={(option) => option.name}
          getOptionKey={(option) => option.id.toString()}
          disableClearable={selectedValue !== null}
          fullWidth
        />
      )}
    />
  );
};
