import { EvidenceDto } from '@web/api/api';
import { Background } from '@web/components/player/ui/Background';
import { Container } from '@web/components/player/ui/Container';
import { Evidence } from '@web/components/player/ui/Evidence';
import { usePreviewBackground } from '@web/store/assets/state';
import { useMakerStore } from '@web/store/maker/state';
import { defaultBackground } from '@web/utils/project';
import { memo } from 'react';
import { AssetTabMobile } from '../AssetTabMobile';

export const EvidenceTabMobile = () => {
  return <AssetTabMobile type="evidence" PreviewComponent={PreviewEvidence} />;
};

const PreviewEvidence = memo(({ value }: { value: EvidenceDto | null }) => {
  const { aspectRatio } = useMakerStore();

  const previewBackground = usePreviewBackground();

  if (value === null) {
    return null;
  }

  return (
    <Container aspectRatio={aspectRatio}>
      <Background url={previewBackground?.url || defaultBackground.url} />
      <Evidence url={value.url} isIcon={value.isIcon} size={0.6} />
    </Container>
  );
});
