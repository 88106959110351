import { CaseFrame } from '@shared/types';
import { BackgroundDto } from '@web/api/api';
import { Thumbnail } from '@web/components/thumbnail/Thumbnail';
import { memo } from 'react';
import { AssetTabMobile } from '../AssetTabMobile';

export const BackgroundTabMobile = () => {
  return (
    <AssetTabMobile type="background" PreviewComponent={PreviewBackground} />
  );
};

const PreviewBackground = memo(({ value }: { value: BackgroundDto | null }) => {
  if (value === null) {
    return null;
  }

  const frame: CaseFrame = { id: -1, text: '', backgroundId: value.id };

  return <Thumbnail frame={frame} serverThumbnails={false} />;
});
