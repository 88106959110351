import { ChevronRight } from '@mui/icons-material';
import { useMemo } from 'react';
import { NextButtonIcon } from '../../ui/NextButton';
import { DialogueBox } from '../DialogueBox';
import { NameplateProps } from '../Nameplate';
import { NextButton, continueButtonAnimation } from '../NextButton';
import { DialogueBoxTextContainerProps } from '../types';

export const TheGreatAceAttorneyDialogueBox = () => {
  const nameplateProps = useMemo<NameplateProps>(
    () => ({
      fontSize: 24,
      url: '/Images/UI/Chatbox/TGAA/textbox.png',
      top: 69,
      right: 27.8,
      fontFamily: 'TGAA-nameplate',
      fontUrl: '/Fonts/TGAA-nameplate.woff2',
      replaceBox: true,
      letterSpacing: 0.05,
      style: {
        WebkitTextStroke: '0.016em #81743b',
      },
    }),
    [],
  );

  const textProps = useMemo<DialogueBoxTextContainerProps>(
    () => ({
      fontSize: 30,
      fontFamily: 'TGAA-body',
      width: 73,
      height: 2.8,
      left: 13.6,
      top: 76.5,
      fontUrl: '/Fonts/TGAA-body.otf',
      lineHeight: 1.4,
    }),
    [],
  );

  return (
    <DialogueBox
      url="/Images/UI/Chatbox/TGAA/textbox-nameless.png"
      nameplate={nameplateProps}
      text={textProps}
      NextButton={<TheGreatAceAttorneyNextButton />}
      letterSpacing={0.05}
    />
  );
};

const TheGreatAceAttorneyNextButton = () => {
  const defaultFontSize = 2.5;

  return (
    <NextButton>
      {({ onClick, showNextButton, size }) => (
        <NextButtonIcon
          display={showNextButton ? 'flex' : 'none'}
          style={{
            display: showNextButton ? 'flex' : 'none',
            top: '80.1%',
            left: '86%',
          }}
          onClick={onClick}
        >
          <ChevronRight
            sx={{
              animation: `${continueButtonAnimation} 0.5s ease-in-out infinite both`,
            }}
            style={{
              fontSize: `${defaultFontSize * size}em`,
              color: '#dad79f',
            }}
          />
        </NextButtonIcon>
      )}
    </NextButton>
  );
};
