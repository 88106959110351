import { SceneTargets } from '@shared/types/scene-targets';
import { useQuery } from '@tanstack/react-query';
import { ApiClient } from '@web/api/api-client';
import { Background } from '@web/components/player/ui/Background';
import { Desk } from '@web/components/player/ui/Desk';
import { defaultBackground } from '@web/utils/project';
import { useThumbnail } from '../maker/providers/ThumbnailProvider';
import { useFlippedBasic } from '../player/hooks/useFlipped';
import { useFrameFilter } from '../player/hooks/useFrameFilter';

export const ThumbnailBackground = () => {
  const { serverThumbnails, background, effect, flipTargets } = useThumbnail();

  const filter = useFrameFilter(effect, SceneTargets.BACKGROUND);
  const flipped = useFlippedBasic(flipTargets, SceneTargets.BACKGROUND);

  const getThumbnail = async () => {
    if (!background) return;

    const response = await ApiClient.thumbnail.getBackgroundThumbnail(
      background.id,
    );

    return {
      url: `${import.meta.env.VITE_BACKEND_BASE_URL}${response.data.url}`,
      deskUrl: response.data.deskUrl
        ? `${import.meta.env.VITE_BACKEND_BASE_URL}${response.data.deskUrl}`
        : '',
    };
  };

  const { data } = useQuery({
    queryKey: ['thumbnail', 'background', background?.id || 0],
    queryFn: getThumbnail,
    staleTime: 30 * 60 * 1000,
    enabled: !!background && serverThumbnails,
  });

  const bg = background || defaultBackground;

  const url = !serverThumbnails ? bg.url : data?.url || defaultBackground.url;
  const desk = !serverThumbnails ? bg.deskUrl : data?.deskUrl || '';

  return (
    <>
      <Background
        url={url}
        isWide={bg.isWide}
        flipped={flipped}
        style={filter}
      />

      <Desk url={desk} isWide={bg.isWide} flipped={flipped} style={filter} />
    </>
  );
};
