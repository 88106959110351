import { CaseFrame, Frame } from '@shared/types';
import { GameUtils } from '@web/components/player/utils/game-utils';
import { makerStore } from './state';

export const makerUtils = {
  copyFrame(frame: CaseFrame, full: boolean): CaseFrame {
    const newFrame = JSON.parse(JSON.stringify(frame)) as CaseFrame;

    if (!full) {
      delete newFrame.caseAction;
    }

    return {
      ...newFrame,
      id: -1,
      text: '',
      actions: !full ? [] : newFrame.actions,
      fade: !full ? undefined : newFrame.fade,
      noPoseAnimation: !full ? false : newFrame.noPoseAnimation,
      mergeWithNext: !full ? false : newFrame.mergeWithNext,
      speechBubble: !full ? undefined : newFrame.speechBubble,
    };
  },
  framesOverLimit(frames: Frame[]) {
    if (frames.length >= makerStore.framesLimit) return true;

    const uniqueChars = Array.from(
      new Set(frames.map((m) => m.characterId).filter((f) => !!f)),
    );
    const uniquePairs = Array.from(
      new Set(frames.map((m) => m.popupId).filter((f) => !!f)),
    );

    return uniqueChars.length >= 200 || uniquePairs.length >= 200;
  },
  getAllFrames() {
    return makerStore.project ? GameUtils.getAllFrames(makerStore.project) : [];
  },
  getNextFrameId() {
    if (!makerStore.project) return -1;

    if (makerStore.project.freeFrameIds.length > 0) {
      return makerStore.project.freeFrameIds.pop()!;
    }

    return makerStore.project.nextFrameId++;
  },
};
