import { Alias, Character_Side } from '@shared/types';
import {
  BackgroundDto,
  CaseProjectDto,
  OptionsDto,
  SceneProjectDto,
} from '@web/api/api';
import { FrameTextUtils } from '@web/components/maker/utils/frameText';
import { usePlayerAudio } from '@web/components/player/hooks/usePlayerAudio';
import { useTimeline } from '@web/components/player/hooks/useTimeline';
import { FramesTarget } from '@web/types/project';
import { generateDefaultBackground } from '@web/utils/project';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { proxy } from 'valtio';
import { useProxy } from 'valtio/utils';
import { usePlayerMeta } from '../providers/PlayerMetaProvider';
import {
  PlayerActionsType,
  PlayerClickType,
  PlayerContextType,
  PlayerStateType,
  Step,
} from '../providers/PlayerProvider';
import { usePlayerReset } from '../providers/PlayerResetProvider';
import { PlayerDefaults } from '../types/playerDefaults';
import { PlayerSaveType } from '../types/playerSave';
import { defaultPlayerDefaults } from '../utils/constants';
import { GameUtils } from '../utils/game-utils';
import { useFrame } from './useFrame';
import { runFrameActions } from './useFrameActions';
import { usePlayerLoading } from './usePlayerLoading';
import { usePlayerResetRegister } from './usePlayerResetRegister';
import { usePlayerSaveLoadRegister } from './usePlayerSaveLoadRegister';
import { usePlayerUi } from './usePlayerUi';
import { useProjectPlayer } from './useProjectPlayer';

const initialState: PlayerStateType = {
  galleryImageUrls: {} as Record<Character_Side, string>,
  galleryImageUrlsAj: {} as Record<Character_Side, string>,
};

export type PlayerProps = {
  id?: string;
  autoStart?: boolean;
  width?: number;
  aspectRatio?: string;
  hideVolume?: boolean;
  hideAutoplay?: boolean;
  controls?: React.ReactNode;
};

export const usePlayer = ({
  autoStart,
  width,
  aspectRatio,
}: PlayerProps): PlayerContextType => {
  const state = useRef(proxy<PlayerStateType>(initialState)).current;
  const playerDefaults = useRef({ ...defaultPlayerDefaults }).current;
  const aliases = useRef<Alias[]>([]).current;
  const timeoutAutoplay = useRef<NodeJS.Timeout>();
  const snapshot = useProxy(state);
  const playerMeta = usePlayerMeta();

  const playerContainerRef = useRef<HTMLDivElement>(null);

  const timeline = useTimeline();
  const audio = usePlayerAudio();
  const playerReset = usePlayerReset();

  const {
    project,
    state: projectState,
    actions: projectActions,
  } = useProjectPlayer();

  const playerLoading = usePlayerLoading({
    project,
    framesTarget: projectState, // TODO: change this line to be projectState.framesTarget ?
    frameIndex: projectState.frameIndex,
    isEnded: projectState.isEnded,
  });

  usePlayerSaveLoadRegister<PlayerSaveType>({
    name: 'player',
    onSave: () => ({
      state: { ...snapshot, isAutoplay: false },
      playerDefaults,
      aliases,
    }),
    onLoad: (data) => {
      Object.assign(state, data.state);
      Object.assign(playerDefaults, data.playerDefaults);
      aliases.splice(0, aliases.length, ...data.aliases);
    },
  });

  usePlayerResetRegister({
    name: 'player',
    onReset: () => {
      timeline.clearEvents();

      audio.reset();

      for (const key in state) {
        delete state[key as keyof PlayerStateType];
      }

      for (const key in playerDefaults) {
        delete playerDefaults[key as keyof PlayerDefaults];
      }

      Object.assign(state, initialState);
      Object.assign(playerDefaults, defaultPlayerDefaults);

      projectActions.reset();
      playerLoading.reset();
      playerUi.actions.reset();

      aliases.splice(0, aliases.length);
    },
  });

  const step = useMemo(
    () =>
      snapshot.steps
        ? snapshot.stepIndex || snapshot.stepIndex === 0
          ? snapshot.steps[snapshot.stepIndex]
          : undefined
        : undefined,
    [snapshot.stepIndex, snapshot.steps],
  );

  state.isPlaying =
    snapshot.startedPlaying && step !== undefined && step !== 'finished';

  const playerUi = usePlayerUi(
    playerContainerRef,
    aspectRatio,
    width,
    timeline.addEvent,
  );

  const defaultBackground = useMemo<BackgroundDto>(
    () => ({
      id: 0,
      url: generateDefaultBackground(
        playerUi.state.width,
        playerUi.state.aspectRatio,
      ),
      name: 'Default',
    }),
    [playerUi.state.aspectRatio, playerUi.state.width],
  );

  const frame = useFrame(
    projectState.frame,
    projectState.previousFrame,
    aliases,
    project?.pairs,
  );

  const setSteps = useCallback(() => {
    const steps: Step[] = ['start'];

    if (frame.previousFrame?.fade?.type === 'fadeOutIn') {
      steps.push('fade_out');
    }

    if (frame.shouldShowSpeechBubble) {
      steps.push('speech_bubble');
    }

    if (!frame.offscreenFrame) {
      if (frame.transitionDuration && frame.transitionDuration > 0) {
        steps.push('transition');
      }

      steps.push('character');
    }

    if (frame.previousFrame?.fade?.type === 'fadeOutIn') {
      steps.push('fade_in');
    }

    if (!frame.offscreenFrame) {
      if (frame.transitionDuration && frame.transitionDuration > 0) {
        steps.push('transition_wait');
      }

      if (frame.shouldRunDelayedActions) {
        steps.push('delayed_actions');
      }

      steps.push('dialogue');
    }

    steps.push('finished');

    state.steps = steps;
    state.stepIndex = 0;
  }, [
    frame.offscreenFrame,
    frame.previousFrame?.fade?.type,
    frame.shouldRunDelayedActions,
    frame.shouldShowSpeechBubble,
    frame.transitionDuration,
    state,
  ]);

  const playNextSound = useCallback(() => {
    if (
      project?.options.continueSoundUrl &&
      !projectState.isEnded &&
      !playerLoading.preloadStuck
    ) {
      audio.playSound(project.options.continueSoundUrl, 50, 100);
    }
  }, [
    audio,
    playerLoading.preloadStuck,
    project?.options.continueSoundUrl,
    projectState.isEnded,
  ]);

  const next = useCallback(() => {
    if (
      snapshot.isPlaying ||
      !snapshot.startedPlaying ||
      !playerLoading.initialized ||
      projectState.isEnded
    ) {
      return;
    }
    timeline.flushCancellableEvents();

    projectActions.nextFrame();
  }, [
    snapshot.isPlaying,
    snapshot.startedPlaying,
    playerLoading.initialized,
    projectState.isEnded,
    timeline,
    projectActions,
  ]);

  const start = useCallback(() => {
    if (
      snapshot.isPlaying ||
      snapshot.startedPlaying ||
      projectState.isEnded ||
      !playerLoading.initialized
    ) {
      return;
    }

    state.startedPlaying = true;

    next();
  }, [
    next,
    playerLoading.initialized,
    projectState.isEnded,
    snapshot.isPlaying,
    snapshot.startedPlaying,
    state,
  ]);

  // TODO: separate reset from saveLoad
  const reset = useCallback(async () => {
    playerLoading.reset();

    if (playerReset) {
      playerReset.reset();
    }
  }, [playerLoading, playerReset]);

  const setPlayerDefaultsFromOptions = useCallback(
    (options: OptionsDto) => {
      playerDefaults.chatbox = options.chatbox ?? defaultPlayerDefaults.chatbox;
      playerDefaults.textSpeed =
        options.textSpeed ?? defaultPlayerDefaults.textSpeed;
      playerDefaults.defaultTextSpeed = playerDefaults.textSpeed;
      playerDefaults.textBlipFrequency =
        options.textBlipFrequency ?? defaultPlayerDefaults.textBlipFrequency;
      playerDefaults.autoplaySpeed =
        options.autoplaySpeed ?? defaultPlayerDefaults.autoplaySpeed;
    },
    [playerDefaults],
  );

  const endNext = useCallback(() => {
    // TODO: implement this with case feature, skip below code if jumping to another frame
    // if (frame.frame?.caseAction) {
    //   runCaseActions();
    // }

    state.showNextButton =
      !frame.frame?.moveToNext &&
      !playerLoading.preloadStuck &&
      !state.isAutoplay &&
      !playerMeta?.isRecording;

    const offscreenFrame = frame.frame?.actions?.find((f) => f.actionId === 6);

    if (!playerLoading.preloadStuck) {
      if (
        frame.frame?.moveToNext ||
        frame.plainText?.length === 0 ||
        offscreenFrame
      ) {
        next();
      } else if (state.isAutoplay || playerMeta?.isRecording) {
        clearTimeout(timeoutAutoplay.current);

        timeoutAutoplay.current = setTimeout(() => {
          next();
        }, playerDefaults.autoplaySpeed);
      }
    }
  }, [
    frame.frame?.actions,
    frame.frame?.moveToNext,
    frame.plainText?.length,
    next,
    playerDefaults.autoplaySpeed,
    playerLoading.preloadStuck,
    playerMeta?.isRecording,
    state,
  ]);

  const initialPlayerDefaults = useCallback(() => {
    playerDefaults.textSpeed = playerDefaults.defaultTextSpeed;

    delete playerDefaults.muteSpeechBlip;
    delete playerDefaults.centerText;
  }, [playerDefaults]);

  const onFramePlayEnd = useCallback(() => {
    state.skipping = false;

    const isEnded = projectActions.onFramePlayEnd();

    if (isEnded) {
      state.showNextButton = false;

      return;
    }

    endNext();
  }, [endNext, projectActions, state]);

  const init = useCallback(
    (project: SceneProjectDto | CaseProjectDto, startingFrameId?: number) => {
      if (snapshot.startedPlaying) {
        return;
      }

      let framesTarget: FramesTarget | undefined;
      let frameIndex = 0;

      if (startingFrameId) {
        framesTarget = GameUtils.getFrameById(startingFrameId, project);

        const targetFrames = GameUtils.getTargetFrames(framesTarget, project);

        frameIndex = targetFrames
          ? targetFrames.findIndex((frame) => frame.id === startingFrameId)
          : 0;
      } else {
        const startingGroup =
          GameUtils.getStartingGroup(project) || project.groups[0];

        framesTarget = startingGroup
          ? { groupId: startingGroup.id }
          : undefined;
      }

      setPlayerDefaultsFromOptions(project.options);

      aliases.splice(0, aliases.length, ...project.aliases);

      projectActions.init(project, framesTarget, frameIndex);
      playerLoading.init(project, framesTarget, frameIndex);

      playerUi.actions.init();

      state.options = { ...project.options };
    },
    [
      snapshot.startedPlaying,
      playerLoading,
      setPlayerDefaultsFromOptions,
      aliases,
      projectActions,
      playerUi.actions,
      state,
    ],
  );

  const onClick = useCallback(
    ({ x, y, direction, isRightClick }: PlayerClickType) => {
      if (
        !snapshot.startedPlaying ||
        projectState.isEnded ||
        playerLoading.preloadStuck ||
        (snapshot.isPlaying && !playerDefaults.enableSkipping) ||
        playerMeta?.isRecording
      )
        return;

      if (snapshot.isPlaying) {
        state.skipping = true;

        timeline.flushAllEvents();

        audio.stopAllSounds();

        return;
      }

      next();
      playNextSound();
    },
    [
      audio,
      next,
      playNextSound,
      playerDefaults.enableSkipping,
      playerLoading.preloadStuck,
      playerMeta?.isRecording,
      projectState.isEnded,
      snapshot.isPlaying,
      snapshot.startedPlaying,
      state,
      timeline,
    ],
  );

  const playMusic = useCallback(
    (url: string, volume: number) => {
      audio.playMusic(url, volume);

      state.musicId = 1; // TODO
    },
    [audio, state],
  );

  const stopMusic = useCallback(() => {
    audio.stopMusic();

    state.musicId = undefined;
  }, [audio, state]);

  const nextStep = useCallback(() => {
    state.stepIndex = (state.stepIndex || 0) + 1;
  }, [state]);

  const setCharacterStep = useCallback(
    (characterStep: PlayerStateType['characterStep']) => {
      state.characterStep = characterStep;
    },
    [state],
  );

  const update = useCallback(
    (newState: Partial<PlayerStateType>) => {
      Object.assign(state, newState);
    },
    [state],
  );

  const actions: PlayerActionsType = useMemo(
    () => ({
      init,
      onClick,
      start,
      next,
      update,
      reset,
      nextStep,
      setCharacterStep,
      playMusic,
      stopMusic,
    }),
    [
      init,
      onClick,
      start,
      next,
      update,
      reset,
      nextStep,
      setCharacterStep,
      playMusic,
      stopMusic,
    ],
  );

  useEffect(() => {
    if (
      !projectState.frame ||
      !snapshot.startedPlaying ||
      projectState.isEnded
    ) {
      return;
    }

    setSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectState.frame]);

  // step handler for internal stuff
  useEffect(() => {
    if (step === 'start') {
      state.showNextButton = false;

      if (
        frame.frame &&
        FrameTextUtils.isStopMusicTagAtStart(frame.frame.text)
      ) {
        audio.stopMusic();
      }

      if (frame.shouldPlayPoseAnimation) {
        audio.stopAllSounds();
      }

      initialPlayerDefaults();

      if (frame.shouldRunStartActions && frame.frame?.actions) {
        runFrameActions(
          frame.frame.actions,
          state,
          step,
          actions,
          update,
          audio,
          timeline,
          playerDefaults,
          true,
        );
      }

      nextStep();
    }

    if (
      step === 'delayed_actions' &&
      frame.shouldRunDelayedActions &&
      frame.frame?.actions
    ) {
      runFrameActions(
        frame.frame.actions,
        state,
        step,
        actions,
        update,
        audio,
        timeline,
        playerDefaults,
        false,
      );
    }

    if (step === 'finished') {
      onFramePlayEnd();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  // on isPlaying change
  // useEffect(() => {
  //   if (snapshot.isPlaying === undefined) return;

  //   if (!snapshot.isPlaying) {
  //     onFramePlayEnd();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [snapshot.isPlaying]);

  // if preload is unstuck
  useEffect(() => {
    if (projectState.isEnded) return;

    if (
      state.startedPlaying &&
      !state.isPlaying &&
      playerLoading.initialized &&
      !playerLoading.preloadStuck
    ) {
      endNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerLoading.preloadStuck]);

  useEffect(() => {
    if (!projectState.isEnded) {
      return;
    }

    if (playerMeta?.isRecording) {
      console.log('DONE'); // for recording
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectState.isEnded]);

  useEffect(() => {
    if ((autoStart || state.isAutoplay) && playerLoading.initialized) {
      start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerLoading.initialized]);

  // cleanup on dismount
  useEffect(() => {
    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    state: snapshot,
    step,
    playerDefaults,
    aliases,
    frame,
    timeline,
    audio,
    actions,
    playerUi,
    playerLoading,
    playerContainerRef,
    defaultBackground,
    isEnded: projectState.isEnded,
  };
};
