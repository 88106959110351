import { PlayArrow, StopCircle } from '@mui/icons-material';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { deepOrange } from '@mui/material/colors';
import { PoseAudioDto } from '@web/api/api';
import { ApiClient } from '@web/api/api-client';
import Form from '@web/components/common/form/Form';
import { NumberTextField } from '@web/components/common/form/NumberTextField';
import { VolumeSlider } from '@web/components/common/form/VolumeSlider';
import { RenderAutocompleteInput } from '@web/components/common/ui/RenderAutocompleteInput';
import { useAudio } from '@web/hooks/useAudio';
import { assetStore, useAssetStore } from '@web/store/assets/state';
import { urlValidation } from '@web/utils/yup';
import { enqueueSnackbar } from 'notistack';
import { memo, useMemo } from 'react';
import { Control, Controller, useFormState, useWatch } from 'react-hook-form';
import { useSnapshot } from 'valtio';
import * as yup from 'yup';
import { PoseExtraUpdateWatcher } from '../CharacterUpdateWatchers';
import { useCharacterEditor } from '../providers/CharacterEditorProvider';
import { PoseExtraDeleteButton } from './PoseDeleteButtons';

export const AudioPoseEditor = memo(({ index }: { index: number }) => {
  const state = useCharacterEditor();
  const { character } = useSnapshot(state);

  const handleAdd = () => {
    const newPoseAudio: PoseAudioDto = {
      id: -Math.random() * 1000 - 1,
      fileName: '',
      volume: 100,
      time: 0,
    };

    state.character.poses[index].poseAudioTicks.push(newPoseAudio);
  };

  const disabled =
    character.poses[index].poseAudioTicks.length >= 3 ||
    character.poses[index].poseAudioTicks.some((s) => s.id < 0);

  return (
    <Stack spacing={2}>
      <Box>
        <Button
          variant="contained"
          color="info"
          onClick={handleAdd}
          disabled={disabled}
        >
          Add Sound
        </Button>
      </Box>

      <Stack
        spacing={2}
        maxHeight={350}
        overflow="auto"
        sx={{ scrollbarWidth: 'thin' }}
      >
        {character.poses[index].poseAudioTicks.map((tick, tickIndex) => (
          <AudioPoseForm
            key={tick.id}
            poseAudioTick={tick}
            index={tickIndex}
            poseIndex={index}
          />
        ))}
      </Stack>
    </Stack>
  );
});

type FormType = {
  id: number;
  fileName: string;
  volume: number;
  time: number;
};

const AudioPoseForm = ({
  poseAudioTick,
  index,
  poseIndex,
}: {
  poseAudioTick: PoseAudioDto;
  index: number;
  poseIndex: number;
}) => {
  const state = useCharacterEditor();

  const schema = useMemo(
    () =>
      yup.object<FormType>().shape({
        fileName: urlValidation().required('Audio URL is required'),
        volume: yup.number().min(0).max(100).required(),
        time: yup.number().min(0).required(),
      }),
    [],
  );

  const onSubmit = async (data: FormType) => {
    const poseAudioTick =
      state.character.poses[poseIndex].poseAudioTicks[index];

    const result =
      poseAudioTick.id < 0
        ? await ApiClient.assets.poseAudio.create({
            ...data,
            poseId: state.character.poses[poseIndex].id,
          })
        : await ApiClient.assets.poseAudio.update(poseAudioTick.id, data);

    state.character.poses[poseIndex].poseAudioTicks[index] = {
      ...state.character.poses[poseIndex].poseAudioTicks[index],
      ...result.data,
    };

    assetStore.character.user[state.characterIndex].poses[
      poseIndex
    ].poseAudioTicks[index] = result.data;

    enqueueSnackbar('Sound saved', {
      variant: 'success',
      autoHideDuration: 2000,
    });
  };

  const handleCancel = () => {
    state.character.poses[poseIndex].poseAudioTicks = state.character.poses[
      poseIndex
    ].poseAudioTicks.filter((_, i) => i !== index);
  };

  return (
    <Form schema={schema} onSubmit={onSubmit} defaultValues={poseAudioTick}>
      {({ control, register, errors, loading }) => (
        <>
          <Paper
            sx={(theme) => ({
              p: 1,
              mb: 1,
              bgcolor: deepOrange[900],
              borderRadius: 0,
            })}
          >
            Sound {index + 1}
          </Paper>
          <Stack spacing={2}>
            {errors.root?.message && (
              <Alert severity="error" variant="filled">
                {errors.root.message}
              </Alert>
            )}

            <SoundUrlInput control={control} />

            <NumberTextField
              label="Delay time (milliseconds)"
              {...register('time')}
              variant="standard"
              size="small"
              error={!!errors.time}
              helperText={errors.time?.message}
              fullWidth
            />

            <Stack spacing={0}>
              <Typography variant="caption" color="textSecondary">
                Volume
              </Typography>
              <Controller
                control={control}
                name="volume"
                render={({ field }) => (
                  <VolumeSlider
                    label="Volume"
                    min={0}
                    max={100}
                    size="small"
                    valueLabelDisplay="auto"
                    value={field.value}
                    onChange={field.onChange}
                    sx={{ pt: 2 }}
                  />
                )}
              />
            </Stack>

            <Stack direction="row" justifyContent="space-between" spacing={2}>
              <Button
                variant="contained"
                type="submit"
                disabled={loading}
                color="info"
              >
                Save Sound
              </Button>

              {poseAudioTick.id > 0 && (
                <PoseExtraDeleteButton
                  id={poseAudioTick.id}
                  label="sound"
                  poseIndex={poseIndex}
                  propKey="poseAudioTicks"
                  apiKey="poseAudio"
                />
              )}

              {poseAudioTick.id < 0 && (
                <Button variant="outlined" onClick={handleCancel}>
                  Cancel
                </Button>
              )}
            </Stack>
          </Stack>

          <PoseExtraUpdateWatcher
            control={control}
            propKey="poseAudioTicks"
            index={index}
            poseIndex={poseIndex}
          />
        </>
      )}
    </Form>
  );
};

const SoundUrlInput = ({
  control,
}: {
  control: Control<PoseAudioDto> | undefined;
}) => {
  const [url, volume] = useWatch({ control, name: ['fileName', 'volume'] });
  const { errors } = useFormState({ control });
  const { play, playing } = useAudio({ url, volume });

  const {
    sound: { preset },
  } = useAssetStore();

  const options = useMemo(() => {
    return [...preset].sort((a, b) => a.name.localeCompare(b.name));
  }, [preset]);

  const selectedValue = useMemo(
    () => preset.find((f) => f.url === url)?.url || url || null,
    [preset, url],
  );

  return (
    <Controller
      control={control}
      name="fileName"
      render={({ field }) => (
        <Autocomplete
          value={selectedValue}
          onChange={(event, newValue) => {
            if (typeof newValue === 'object' && newValue !== null) {
              field.onChange(newValue.url);
            }
          }}
          onInputChange={(event, newInputValue) => {
            field.onChange(newInputValue);
          }}
          options={options}
          renderInput={(params) => (
            <RenderAutocompleteInput
              params={params}
              variant="standard"
              error={!!errors.fileName}
              helperText={errors.fileName?.message}
              label="Sound URL"
              InputProps={{
                endAdornment: (
                  <IconButton size="small" onClick={play}>
                    {playing ? <StopCircle /> : <PlayArrow />}
                  </IconButton>
                ),
              }}
            />
          )}
          sx={{
            '.MuiAutocomplete-endAdornment': { overflow: 'hidden' },
          }}
          getOptionLabel={(option) => {
            if (typeof option === 'string') return option;
            return option.name || '';
          }}
          getOptionKey={(option) => {
            if (typeof option === 'string') return option;
            return option.url!;
          }}
          isOptionEqualToValue={(option, value) => {
            if (typeof value === 'string') return false;
            return option.url === value.url;
          }}
          disableClearable={selectedValue !== null}
          freeSolo
        />
      )}
    />
  );
};
