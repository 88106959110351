/* tslint:disable */
/* eslint-disable */
/**
 * objection.lol API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AliasDto
 */
export interface AliasDto {
    /**
     * 
     * @type {string}
     * @memberof AliasDto
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof AliasDto
     */
    'to': string;
}
/**
 * 
 * @export
 * @interface AssetShareDto
 */
export interface AssetShareDto {
    /**
     * 
     * @type {boolean}
     * @memberof AssetShareDto
     */
    'isShared': boolean;
    /**
     * 
     * @type {string}
     * @memberof AssetShareDto
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface BackgroundDto
 */
export interface BackgroundDto {
    /**
     * 
     * @type {number}
     * @memberof BackgroundDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof BackgroundDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BackgroundDto
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof BackgroundDto
     */
    'deskUrl'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BackgroundDto
     */
    'isWide'?: boolean;
}
/**
 * 
 * @export
 * @interface BackgroundThumbnailDto
 */
export interface BackgroundThumbnailDto {
    /**
     * 
     * @type {string}
     * @memberof BackgroundThumbnailDto
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof BackgroundThumbnailDto
     */
    'deskUrl': string;
}
/**
 * 
 * @export
 * @interface BaseGroupDto
 */
export interface BaseGroupDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof BaseGroupDto
     */
    'comments': { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof BaseGroupDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BaseGroupDto
     */
    'type': BaseGroupDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BaseGroupDto
     */
    'name': string;
}

export const BaseGroupDtoTypeEnum = {
    Normal: 'normal',
    Gameover: 'gameover',
    CrossExamination: 'cross-examination',
    Investigation: 'investigation'
} as const;

export type BaseGroupDtoTypeEnum = typeof BaseGroupDtoTypeEnum[keyof typeof BaseGroupDtoTypeEnum];

/**
 * 
 * @export
 * @interface BulkIdDto
 */
export interface BulkIdDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof BulkIdDto
     */
    'ids': Array<number>;
}
/**
 * 
 * @export
 * @interface CaseDto
 */
export interface CaseDto {
    /**
     * 
     * @type {number}
     * @memberof CaseDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CaseDto
     */
    'caseId': string;
    /**
     * 
     * @type {string}
     * @memberof CaseDto
     */
    'title': string;
    /**
     * 
     * @type {boolean}
     * @memberof CaseDto
     */
    'archived': boolean;
    /**
     * 
     * @type {number}
     * @memberof CaseDto
     */
    'views': number;
    /**
     * 
     * @type {string}
     * @memberof CaseDto
     */
    'data': string;
}
/**
 * 
 * @export
 * @interface CaseProjectDto
 */
export interface CaseProjectDto {
    /**
     * 
     * @type {string}
     * @memberof CaseProjectDto
     */
    'type': CaseProjectDtoTypeEnum;
    /**
     * 
     * @type {CourtRecordDto}
     * @memberof CaseProjectDto
     */
    'courtRecord': CourtRecordDto;
    /**
     * 
     * @type {Array<BaseGroupDto>}
     * @memberof CaseProjectDto
     */
    'groups': Array<BaseGroupDto>;
    /**
     * 
     * @type {string}
     * @memberof CaseProjectDto
     */
    'id': string;
    /**
     * 
     * @type {OptionsDto}
     * @memberof CaseProjectDto
     */
    'options': OptionsDto;
    /**
     * 
     * @type {Array<AliasDto>}
     * @memberof CaseProjectDto
     */
    'aliases': Array<AliasDto>;
    /**
     * 
     * @type {Array<PairDto>}
     * @memberof CaseProjectDto
     */
    'pairs': Array<PairDto>;
    /**
     * 
     * @type {number}
     * @memberof CaseProjectDto
     */
    'nextFrameId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CaseProjectDto
     */
    'freeFrameIds': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof CaseProjectDto
     */
    'version': number;
}

export const CaseProjectDtoTypeEnum = {
    Case: 'case'
} as const;

export type CaseProjectDtoTypeEnum = typeof CaseProjectDtoTypeEnum[keyof typeof CaseProjectDtoTypeEnum];

/**
 * 
 * @export
 * @interface CaseResponseDto
 */
export interface CaseResponseDto {
    /**
     * 
     * @type {string}
     * @memberof CaseResponseDto
     */
    'caseId': string;
    /**
     * 
     * @type {string}
     * @memberof CaseResponseDto
     */
    'title': string;
    /**
     * 
     * @type {CaseProjectDto}
     * @memberof CaseResponseDto
     */
    'data': CaseProjectDto;
}
/**
 * 
 * @export
 * @interface ChangePasswordDto
 */
export interface ChangePasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordDto
     */
    'currentPassword': string;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface ChangeUsernameDto
 */
export interface ChangeUsernameDto {
    /**
     * 
     * @type {string}
     * @memberof ChangeUsernameDto
     */
    'username': string;
}
/**
 * 
 * @export
 * @interface CharacterDto
 */
export interface CharacterDto {
    /**
     * 
     * @type {number}
     * @memberof CharacterDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CharacterDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CharacterDto
     */
    'nameplate': string;
    /**
     * 
     * @type {string}
     * @memberof CharacterDto
     */
    'side': string;
    /**
     * 
     * @type {string}
     * @memberof CharacterDto
     */
    'blipUrl': string;
    /**
     * 
     * @type {number}
     * @memberof CharacterDto
     */
    'backgroundId': number;
    /**
     * 
     * @type {string}
     * @memberof CharacterDto
     */
    'iconUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CharacterDto
     */
    'galleryImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CharacterDto
     */
    'galleryAJImageUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof CharacterDto
     */
    'limitWidth': boolean;
    /**
     * 
     * @type {number}
     * @memberof CharacterDto
     */
    'alignment': number;
    /**
     * 
     * @type {number}
     * @memberof CharacterDto
     */
    'offsetX': number;
    /**
     * 
     * @type {number}
     * @memberof CharacterDto
     */
    'offsetY': number;
    /**
     * 
     * @type {Array<PoseDto>}
     * @memberof CharacterDto
     */
    'poses': Array<PoseDto>;
    /**
     * 
     * @type {Array<SpeechBubbleDto>}
     * @memberof CharacterDto
     */
    'speechBubbles': Array<SpeechBubbleDto>;
}
/**
 * 
 * @export
 * @interface CourtRecordBaseEvidenceDto
 */
export interface CourtRecordBaseEvidenceDto {
    /**
     * 
     * @type {string}
     * @memberof CourtRecordBaseEvidenceDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CourtRecordBaseEvidenceDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CourtRecordBaseEvidenceDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CourtRecordBaseEvidenceDto
     */
    'iconUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof CourtRecordBaseEvidenceDto
     */
    'hide': boolean;
}
/**
 * 
 * @export
 * @interface CourtRecordDto
 */
export interface CourtRecordDto {
    /**
     * 
     * @type {Array<CourtRecordEvidenceDto>}
     * @memberof CourtRecordDto
     */
    'evidence': Array<CourtRecordEvidenceDto>;
    /**
     * 
     * @type {Array<CourtRecordBaseEvidenceDto>}
     * @memberof CourtRecordDto
     */
    'profiles': Array<CourtRecordBaseEvidenceDto>;
}
/**
 * 
 * @export
 * @interface CourtRecordEvidenceDto
 */
export interface CourtRecordEvidenceDto {
    /**
     * 
     * @type {string}
     * @memberof CourtRecordEvidenceDto
     */
    'url': string;
    /**
     * 
     * @type {object}
     * @memberof CourtRecordEvidenceDto
     */
    'type': object;
    /**
     * 
     * @type {string}
     * @memberof CourtRecordEvidenceDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CourtRecordEvidenceDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CourtRecordEvidenceDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CourtRecordEvidenceDto
     */
    'iconUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof CourtRecordEvidenceDto
     */
    'hide': boolean;
}
/**
 * 
 * @export
 * @interface CreateBackgroundDto
 */
export interface CreateBackgroundDto {
    /**
     * 
     * @type {string}
     * @memberof CreateBackgroundDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBackgroundDto
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBackgroundDto
     */
    'deskUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateBackgroundDto
     */
    'isWide'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateCaseDraftDto
 */
export interface CreateCaseDraftDto {
    /**
     * 
     * @type {CaseProjectDto}
     * @memberof CreateCaseDraftDto
     */
    'data': CaseProjectDto;
    /**
     * 
     * @type {string}
     * @memberof CreateCaseDraftDto
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface CreateCaseDto
 */
export interface CreateCaseDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCaseDto
     */
    'title': string;
    /**
     * 
     * @type {CaseProjectDto}
     * @memberof CreateCaseDto
     */
    'data': CaseProjectDto;
}
/**
 * 
 * @export
 * @interface CreateCharacterDto
 */
export interface CreateCharacterDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCharacterDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCharacterDto
     */
    'nameplate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCharacterDto
     */
    'side': CreateCharacterDtoSideEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateCharacterDto
     */
    'blipUrl': string;
    /**
     * 
     * @type {number}
     * @memberof CreateCharacterDto
     */
    'backgroundId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateCharacterDto
     */
    'iconUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCharacterDto
     */
    'limitWidth': boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateCharacterDto
     */
    'alignment': CreateCharacterDtoAlignmentEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateCharacterDto
     */
    'offsetX': number;
    /**
     * 
     * @type {number}
     * @memberof CreateCharacterDto
     */
    'offsetY': number;
    /**
     * 
     * @type {string}
     * @memberof CreateCharacterDto
     */
    'galleryImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCharacterDto
     */
    'galleryAJImageUrl': string;
}

export const CreateCharacterDtoSideEnum = {
    Defense: 'defense',
    Prosecution: 'prosecution',
    Witness: 'witness',
    Judge: 'judge',
    Counsel: 'counsel',
    Gallery: 'gallery'
} as const;

export type CreateCharacterDtoSideEnum = typeof CreateCharacterDtoSideEnum[keyof typeof CreateCharacterDtoSideEnum];
export const CreateCharacterDtoAlignmentEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type CreateCharacterDtoAlignmentEnum = typeof CreateCharacterDtoAlignmentEnum[keyof typeof CreateCharacterDtoAlignmentEnum];

/**
 * 
 * @export
 * @interface CreateEvidenceDto
 */
export interface CreateEvidenceDto {
    /**
     * 
     * @type {string}
     * @memberof CreateEvidenceDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEvidenceDto
     */
    'url': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEvidenceDto
     */
    'isIcon': boolean;
}
/**
 * 
 * @export
 * @interface CreateExportRequestDto
 */
export interface CreateExportRequestDto {
    /**
     * 
     * @type {number}
     * @memberof CreateExportRequestDto
     */
    'objectionId': number;
    /**
     * 
     * @type {CreateExportRequestVolumeDto}
     * @memberof CreateExportRequestDto
     */
    'volumes'?: CreateExportRequestVolumeDto;
}
/**
 * 
 * @export
 * @interface CreateExportRequestVolumeDto
 */
export interface CreateExportRequestVolumeDto {
    /**
     * 
     * @type {number}
     * @memberof CreateExportRequestVolumeDto
     */
    'master': number;
    /**
     * 
     * @type {number}
     * @memberof CreateExportRequestVolumeDto
     */
    'music': number;
    /**
     * 
     * @type {number}
     * @memberof CreateExportRequestVolumeDto
     */
    'sound': number;
    /**
     * 
     * @type {number}
     * @memberof CreateExportRequestVolumeDto
     */
    'blip': number;
}
/**
 * 
 * @export
 * @interface CreateMusicDto
 */
export interface CreateMusicDto {
    /**
     * 
     * @type {string}
     * @memberof CreateMusicDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMusicDto
     */
    'url': string;
    /**
     * 
     * @type {number}
     * @memberof CreateMusicDto
     */
    'volume': number;
}
/**
 * 
 * @export
 * @interface CreatePopupDto
 */
export interface CreatePopupDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePopupDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePopupDto
     */
    'url': string;
    /**
     * 
     * @type {number}
     * @memberof CreatePopupDto
     */
    'resize': number;
    /**
     * 
     * @type {number}
     * @memberof CreatePopupDto
     */
    'posX': number;
    /**
     * 
     * @type {number}
     * @memberof CreatePopupDto
     */
    'posY': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePopupDto
     */
    'center': boolean;
}
/**
 * 
 * @export
 * @interface CreatePoseAudioDto
 */
export interface CreatePoseAudioDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePoseAudioDto
     */
    'fileName': string;
    /**
     * 
     * @type {number}
     * @memberof CreatePoseAudioDto
     */
    'volume': number;
    /**
     * 
     * @type {number}
     * @memberof CreatePoseAudioDto
     */
    'time': number;
    /**
     * 
     * @type {number}
     * @memberof CreatePoseAudioDto
     */
    'poseId': number;
}
/**
 * 
 * @export
 * @interface CreatePoseDto
 */
export interface CreatePoseDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePoseDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePoseDto
     */
    'idleImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePoseDto
     */
    'speakImageUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePoseDto
     */
    'isSpeedlines': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreatePoseDto
     */
    'iconUrl': string;
    /**
     * 
     * @type {number}
     * @memberof CreatePoseDto
     */
    'order': number;
    /**
     * 
     * @type {number}
     * @memberof CreatePoseDto
     */
    'characterId': number;
}
/**
 * 
 * @export
 * @interface CreatePoseFunctionDto
 */
export interface CreatePoseFunctionDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePoseFunctionDto
     */
    'functionName': CreatePoseFunctionDtoFunctionNameEnum;
    /**
     * 
     * @type {string}
     * @memberof CreatePoseFunctionDto
     */
    'functionParam': CreatePoseFunctionDtoFunctionParamEnum;
    /**
     * 
     * @type {number}
     * @memberof CreatePoseFunctionDto
     */
    'time': number;
    /**
     * 
     * @type {number}
     * @memberof CreatePoseFunctionDto
     */
    'poseId': number;
}

export const CreatePoseFunctionDtoFunctionNameEnum = {
    Flash: 'Flash',
    Shake: 'Shake'
} as const;

export type CreatePoseFunctionDtoFunctionNameEnum = typeof CreatePoseFunctionDtoFunctionNameEnum[keyof typeof CreatePoseFunctionDtoFunctionNameEnum];
export const CreatePoseFunctionDtoFunctionParamEnum = {
    S: 's',
    M: 'm',
    L: 'l'
} as const;

export type CreatePoseFunctionDtoFunctionParamEnum = typeof CreatePoseFunctionDtoFunctionParamEnum[keyof typeof CreatePoseFunctionDtoFunctionParamEnum];

/**
 * 
 * @export
 * @interface CreatePoseStateDto
 */
export interface CreatePoseStateDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePoseStateDto
     */
    'imageUrl': string;
    /**
     * 
     * @type {number}
     * @memberof CreatePoseStateDto
     */
    'nextPoseDelay': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePoseStateDto
     */
    'noSpeakDelay'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreatePoseStateDto
     */
    'poseId': number;
}
/**
 * 
 * @export
 * @interface CreateSaveDto
 */
export interface CreateSaveDto {
    /**
     * 
     * @type {string}
     * @memberof CreateSaveDto
     */
    'caseId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSaveDto
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof CreateSaveDto
     */
    'slot': number;
    /**
     * 
     * @type {SaveDataDto}
     * @memberof CreateSaveDto
     */
    'data': SaveDataDto;
}
/**
 * 
 * @export
 * @interface CreateSceneDraftDto
 */
export interface CreateSceneDraftDto {
    /**
     * 
     * @type {SceneProjectDto}
     * @memberof CreateSceneDraftDto
     */
    'data': SceneProjectDto;
    /**
     * 
     * @type {string}
     * @memberof CreateSceneDraftDto
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface CreateSceneDto
 */
export interface CreateSceneDto {
    /**
     * 
     * @type {string}
     * @memberof CreateSceneDto
     */
    'title': string;
    /**
     * 
     * @type {SceneProjectDto}
     * @memberof CreateSceneDto
     */
    'data': SceneProjectDto;
}
/**
 * 
 * @export
 * @interface CreateSoundDto
 */
export interface CreateSoundDto {
    /**
     * 
     * @type {string}
     * @memberof CreateSoundDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSoundDto
     */
    'url': string;
    /**
     * 
     * @type {number}
     * @memberof CreateSoundDto
     */
    'volume': number;
}
/**
 * 
 * @export
 * @interface CreateSpeechBubbleDto
 */
export interface CreateSpeechBubbleDto {
    /**
     * 
     * @type {string}
     * @memberof CreateSpeechBubbleDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSpeechBubbleDto
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSpeechBubbleDto
     */
    'soundUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSpeechBubbleDto
     */
    'shake': boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateSpeechBubbleDto
     */
    'duration': number;
    /**
     * 
     * @type {number}
     * @memberof CreateSpeechBubbleDto
     */
    'order': number;
    /**
     * 
     * @type {number}
     * @memberof CreateSpeechBubbleDto
     */
    'characterId': number;
}
/**
 * 
 * @export
 * @interface DeleteAccountDto
 */
export interface DeleteAccountDto {
    /**
     * 
     * @type {string}
     * @memberof DeleteAccountDto
     */
    'currentPassword': string;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteAccountDto
     */
    'deleteAssets': boolean;
}
/**
 * 
 * @export
 * @interface DraftDto
 */
export interface DraftDto {
    /**
     * 
     * @type {number}
     * @memberof DraftDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DraftDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof DraftDto
     */
    'projectId': string;
    /**
     * 
     * @type {number}
     * @memberof DraftDto
     */
    'type': DraftDtoTypeEnum;
    /**
     * 
     * @type {object}
     * @memberof DraftDto
     */
    'data': object;
    /**
     * 
     * @type {string}
     * @memberof DraftDto
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof DraftDto
     */
    'createdAt': string;
}

export const DraftDtoTypeEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type DraftDtoTypeEnum = typeof DraftDtoTypeEnum[keyof typeof DraftDtoTypeEnum];

/**
 * 
 * @export
 * @interface EvidenceDto
 */
export interface EvidenceDto {
    /**
     * 
     * @type {number}
     * @memberof EvidenceDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof EvidenceDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EvidenceDto
     */
    'url': string;
    /**
     * 
     * @type {boolean}
     * @memberof EvidenceDto
     */
    'isIcon': boolean;
}
/**
 * 
 * @export
 * @interface ExportRequestDto
 */
export interface ExportRequestDto {
    /**
     * 
     * @type {number}
     * @memberof ExportRequestDto
     */
    'requestId': number;
    /**
     * 
     * @type {string}
     * @memberof ExportRequestDto
     */
    'dropToken': string;
}
/**
 * 
 * @export
 * @interface ExportStatusDto
 */
export interface ExportStatusDto {
    /**
     * 
     * @type {number}
     * @memberof ExportStatusDto
     */
    'status': ExportStatusDtoStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ExportStatusDto
     */
    'queue': number;
}

export const ExportStatusDtoStatusEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type ExportStatusDtoStatusEnum = typeof ExportStatusDtoStatusEnum[keyof typeof ExportStatusDtoStatusEnum];

/**
 * 
 * @export
 * @interface ForgotPasswordDto
 */
export interface ForgotPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface LoginUserDto
 */
export interface LoginUserDto {
    /**
     * 
     * @type {string}
     * @memberof LoginUserDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginUserDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface MaintenanceDto
 */
export interface MaintenanceDto {
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceDto
     */
    'maintenanceMode': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceDto
     */
    'exportMaintenanceMode': boolean;
}
/**
 * 
 * @export
 * @interface MeResponseDto
 */
export interface MeResponseDto {
    /**
     * 
     * @type {string}
     * @memberof MeResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MeResponseDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof MeResponseDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof MeResponseDto
     */
    'image': string;
    /**
     * 
     * @type {boolean}
     * @memberof MeResponseDto
     */
    'admin': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeResponseDto
     */
    'isProvider': boolean;
}
/**
 * 
 * @export
 * @interface MusicDto
 */
export interface MusicDto {
    /**
     * 
     * @type {number}
     * @memberof MusicDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MusicDto
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof MusicDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof MusicDto
     */
    'volume': number;
}
/**
 * 
 * @export
 * @interface OptionsDto
 */
export interface OptionsDto {
    /**
     * 
     * @type {number}
     * @memberof OptionsDto
     */
    'chatbox': number;
    /**
     * 
     * @type {number}
     * @memberof OptionsDto
     */
    'textSpeed': number;
    /**
     * 
     * @type {number}
     * @memberof OptionsDto
     */
    'textBlipFrequency': number;
    /**
     * 
     * @type {number}
     * @memberof OptionsDto
     */
    'autoplaySpeed': number;
    /**
     * 
     * @type {string}
     * @memberof OptionsDto
     */
    'continueSoundUrl': string;
    /**
     * 
     * @type {string}
     * @memberof OptionsDto
     */
    'aspectRatio'?: OptionsDtoAspectRatioEnum;
    /**
     * 
     * @type {number}
     * @memberof OptionsDto
     */
    'width'?: number;
}

export const OptionsDtoAspectRatioEnum = {
    _32: '3:2',
    _43: '4:3',
    _169: '16:9',
    _1610: '16:10'
} as const;

export type OptionsDtoAspectRatioEnum = typeof OptionsDtoAspectRatioEnum[keyof typeof OptionsDtoAspectRatioEnum];

/**
 * 
 * @export
 * @interface PaginatedResponseOfCaseDto
 */
export interface PaginatedResponseOfCaseDto {
    /**
     * 
     * @type {Array<CaseDto>}
     * @memberof PaginatedResponseOfCaseDto
     */
    'data': Array<CaseDto>;
    /**
     * 
     * @type {PaginatedResponseOfSceneDtoAllOfMeta}
     * @memberof PaginatedResponseOfCaseDto
     */
    'meta': PaginatedResponseOfSceneDtoAllOfMeta;
}
/**
 * 
 * @export
 * @interface PaginatedResponseOfSceneDto
 */
export interface PaginatedResponseOfSceneDto {
    /**
     * 
     * @type {Array<SceneDto>}
     * @memberof PaginatedResponseOfSceneDto
     */
    'data': Array<SceneDto>;
    /**
     * 
     * @type {PaginatedResponseOfSceneDtoAllOfMeta}
     * @memberof PaginatedResponseOfSceneDto
     */
    'meta': PaginatedResponseOfSceneDtoAllOfMeta;
}
/**
 * 
 * @export
 * @interface PaginatedResponseOfSceneDtoAllOfMeta
 */
export interface PaginatedResponseOfSceneDtoAllOfMeta {
    /**
     * 
     * @type {number}
     * @memberof PaginatedResponseOfSceneDtoAllOfMeta
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedResponseOfSceneDtoAllOfMeta
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedResponseOfSceneDtoAllOfMeta
     */
    'perPage': number;
}
/**
 * 
 * @export
 * @interface PairDto
 */
export interface PairDto {
    /**
     * 
     * @type {string}
     * @memberof PairDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PairDto
     */
    'name': string;
    /**
     * 
     * @type {Array<PairedCharacterDto>}
     * @memberof PairDto
     */
    'pairs': Array<PairedCharacterDto>;
}
/**
 * 
 * @export
 * @interface PairedCharacterDto
 */
export interface PairedCharacterDto {
    /**
     * 
     * @type {number}
     * @memberof PairedCharacterDto
     */
    'characterId': number | null;
    /**
     * 
     * @type {number}
     * @memberof PairedCharacterDto
     */
    'offsetX': number;
    /**
     * 
     * @type {number}
     * @memberof PairedCharacterDto
     */
    'offsetY': number;
    /**
     * 
     * @type {number}
     * @memberof PairedCharacterDto
     */
    'front': number;
}
/**
 * 
 * @export
 * @interface PopupDto
 */
export interface PopupDto {
    /**
     * 
     * @type {number}
     * @memberof PopupDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PopupDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PopupDto
     */
    'url': string;
    /**
     * 
     * @type {number}
     * @memberof PopupDto
     */
    'posX': number;
    /**
     * 
     * @type {number}
     * @memberof PopupDto
     */
    'resize': number;
    /**
     * 
     * @type {number}
     * @memberof PopupDto
     */
    'posY': number;
    /**
     * 
     * @type {boolean}
     * @memberof PopupDto
     */
    'center': boolean;
}
/**
 * 
 * @export
 * @interface PoseAudioDto
 */
export interface PoseAudioDto {
    /**
     * 
     * @type {number}
     * @memberof PoseAudioDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PoseAudioDto
     */
    'fileName': string;
    /**
     * 
     * @type {number}
     * @memberof PoseAudioDto
     */
    'volume': number;
    /**
     * 
     * @type {number}
     * @memberof PoseAudioDto
     */
    'time': number;
}
/**
 * 
 * @export
 * @interface PoseDto
 */
export interface PoseDto {
    /**
     * 
     * @type {number}
     * @memberof PoseDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PoseDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PoseDto
     */
    'idleImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof PoseDto
     */
    'speakImageUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof PoseDto
     */
    'isSpeedlines': boolean;
    /**
     * 
     * @type {string}
     * @memberof PoseDto
     */
    'iconUrl': string;
    /**
     * 
     * @type {number}
     * @memberof PoseDto
     */
    'order': number;
    /**
     * 
     * @type {Array<PoseStateDto>}
     * @memberof PoseDto
     */
    'poseStates': Array<PoseStateDto>;
    /**
     * 
     * @type {Array<PoseAudioDto>}
     * @memberof PoseDto
     */
    'poseAudioTicks': Array<PoseAudioDto>;
    /**
     * 
     * @type {Array<PoseFunctionDto>}
     * @memberof PoseDto
     */
    'poseFunctionTicks': Array<PoseFunctionDto>;
}
/**
 * 
 * @export
 * @interface PoseFunctionDto
 */
export interface PoseFunctionDto {
    /**
     * 
     * @type {number}
     * @memberof PoseFunctionDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PoseFunctionDto
     */
    'functionName': string;
    /**
     * 
     * @type {string}
     * @memberof PoseFunctionDto
     */
    'functionParam': string;
    /**
     * 
     * @type {number}
     * @memberof PoseFunctionDto
     */
    'time': number;
}
/**
 * 
 * @export
 * @interface PoseStateDto
 */
export interface PoseStateDto {
    /**
     * 
     * @type {number}
     * @memberof PoseStateDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PoseStateDto
     */
    'imageUrl': string;
    /**
     * 
     * @type {number}
     * @memberof PoseStateDto
     */
    'nextPoseDelay': number;
    /**
     * 
     * @type {boolean}
     * @memberof PoseStateDto
     */
    'noSpeakDelay': boolean;
}
/**
 * 
 * @export
 * @interface RefreshResponseDto
 */
export interface RefreshResponseDto {
    /**
     * 
     * @type {string}
     * @memberof RefreshResponseDto
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface RegisterUserDto
 */
export interface RegisterUserDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface ReorderPosesDto
 */
export interface ReorderPosesDto {
    /**
     * 
     * @type {number}
     * @memberof ReorderPosesDto
     */
    'characterId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReorderPosesDto
     */
    'poses': Array<number>;
}
/**
 * 
 * @export
 * @interface ReorderSpeechBubblesDto
 */
export interface ReorderSpeechBubblesDto {
    /**
     * 
     * @type {number}
     * @memberof ReorderSpeechBubblesDto
     */
    'characterId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReorderSpeechBubblesDto
     */
    'speechBubbles': Array<number>;
}
/**
 * 
 * @export
 * @interface ResetPasswordDto
 */
export interface ResetPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordDto
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface SaveDataDto
 */
export interface SaveDataDto {
    /**
     * 
     * @type {CaseProjectDto}
     * @memberof SaveDataDto
     */
    'data': CaseProjectDto;
    /**
     * 
     * @type {object}
     * @memberof SaveDataDto
     */
    'session': object;
}
/**
 * 
 * @export
 * @interface SaveDto
 */
export interface SaveDto {
    /**
     * 
     * @type {number}
     * @memberof SaveDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SaveDto
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof SaveDto
     */
    'caseId': string;
    /**
     * 
     * @type {string}
     * @memberof SaveDto
     */
    'title': string;
    /**
     * 
     * @type {object}
     * @memberof SaveDto
     */
    'data': object;
    /**
     * 
     * @type {number}
     * @memberof SaveDto
     */
    'slot': number;
}
/**
 * 
 * @export
 * @interface SceneDto
 */
export interface SceneDto {
    /**
     * 
     * @type {number}
     * @memberof SceneDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SceneDto
     */
    'title': string;
    /**
     * 
     * @type {boolean}
     * @memberof SceneDto
     */
    'archived': boolean;
    /**
     * 
     * @type {number}
     * @memberof SceneDto
     */
    'views': number;
    /**
     * 
     * @type {string}
     * @memberof SceneDto
     */
    'data': string;
}
/**
 * 
 * @export
 * @interface SceneProjectDto
 */
export interface SceneProjectDto {
    /**
     * 
     * @type {string}
     * @memberof SceneProjectDto
     */
    'type': SceneProjectDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SceneProjectDto
     */
    'id': string;
    /**
     * 
     * @type {OptionsDto}
     * @memberof SceneProjectDto
     */
    'options': OptionsDto;
    /**
     * 
     * @type {Array<AliasDto>}
     * @memberof SceneProjectDto
     */
    'aliases': Array<AliasDto>;
    /**
     * 
     * @type {Array<PairDto>}
     * @memberof SceneProjectDto
     */
    'pairs': Array<PairDto>;
    /**
     * 
     * @type {Array<BaseGroupDto>}
     * @memberof SceneProjectDto
     */
    'groups': Array<BaseGroupDto>;
    /**
     * 
     * @type {number}
     * @memberof SceneProjectDto
     */
    'nextFrameId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof SceneProjectDto
     */
    'freeFrameIds': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof SceneProjectDto
     */
    'version': number;
}

export const SceneProjectDtoTypeEnum = {
    Scene: 'scene'
} as const;

export type SceneProjectDtoTypeEnum = typeof SceneProjectDtoTypeEnum[keyof typeof SceneProjectDtoTypeEnum];

/**
 * 
 * @export
 * @interface SceneResponseDto
 */
export interface SceneResponseDto {
    /**
     * 
     * @type {number}
     * @memberof SceneResponseDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SceneResponseDto
     */
    'title': string;
    /**
     * 
     * @type {SceneProjectDto}
     * @memberof SceneResponseDto
     */
    'data': SceneProjectDto;
    /**
     * 
     * @type {string}
     * @memberof SceneResponseDto
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface SoundDto
 */
export interface SoundDto {
    /**
     * 
     * @type {number}
     * @memberof SoundDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SoundDto
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof SoundDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof SoundDto
     */
    'volume': number;
}
/**
 * 
 * @export
 * @interface SpeechBubbleDto
 */
export interface SpeechBubbleDto {
    /**
     * 
     * @type {number}
     * @memberof SpeechBubbleDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SpeechBubbleDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SpeechBubbleDto
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof SpeechBubbleDto
     */
    'soundUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof SpeechBubbleDto
     */
    'shake': boolean;
    /**
     * 
     * @type {number}
     * @memberof SpeechBubbleDto
     */
    'duration': number;
    /**
     * 
     * @type {number}
     * @memberof SpeechBubbleDto
     */
    'order': number;
}
/**
 * 
 * @export
 * @interface ThumbnailDto
 */
export interface ThumbnailDto {
    /**
     * 
     * @type {string}
     * @memberof ThumbnailDto
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface UpdateBackgroundDto
 */
export interface UpdateBackgroundDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateBackgroundDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBackgroundDto
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBackgroundDto
     */
    'deskUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBackgroundDto
     */
    'isWide'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateCharacterDto
 */
export interface UpdateCharacterDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCharacterDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCharacterDto
     */
    'nameplate': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCharacterDto
     */
    'side': UpdateCharacterDtoSideEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateCharacterDto
     */
    'blipUrl': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateCharacterDto
     */
    'backgroundId': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateCharacterDto
     */
    'iconUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCharacterDto
     */
    'limitWidth': boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateCharacterDto
     */
    'alignment': UpdateCharacterDtoAlignmentEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateCharacterDto
     */
    'offsetX': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateCharacterDto
     */
    'offsetY': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateCharacterDto
     */
    'galleryImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCharacterDto
     */
    'galleryAJImageUrl': string;
}

export const UpdateCharacterDtoSideEnum = {
    Defense: 'defense',
    Prosecution: 'prosecution',
    Witness: 'witness',
    Judge: 'judge',
    Counsel: 'counsel',
    Gallery: 'gallery'
} as const;

export type UpdateCharacterDtoSideEnum = typeof UpdateCharacterDtoSideEnum[keyof typeof UpdateCharacterDtoSideEnum];
export const UpdateCharacterDtoAlignmentEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type UpdateCharacterDtoAlignmentEnum = typeof UpdateCharacterDtoAlignmentEnum[keyof typeof UpdateCharacterDtoAlignmentEnum];

/**
 * 
 * @export
 * @interface UpdateEvidenceDto
 */
export interface UpdateEvidenceDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateEvidenceDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEvidenceDto
     */
    'url': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEvidenceDto
     */
    'isIcon': boolean;
}
/**
 * 
 * @export
 * @interface UpdateMusicDto
 */
export interface UpdateMusicDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateMusicDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMusicDto
     */
    'url': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateMusicDto
     */
    'volume': number;
}
/**
 * 
 * @export
 * @interface UpdatePopupDto
 */
export interface UpdatePopupDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePopupDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePopupDto
     */
    'url': string;
    /**
     * 
     * @type {number}
     * @memberof UpdatePopupDto
     */
    'resize': number;
    /**
     * 
     * @type {number}
     * @memberof UpdatePopupDto
     */
    'posX': number;
    /**
     * 
     * @type {number}
     * @memberof UpdatePopupDto
     */
    'posY': number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePopupDto
     */
    'center': boolean;
}
/**
 * 
 * @export
 * @interface UpdatePoseAudioDto
 */
export interface UpdatePoseAudioDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePoseAudioDto
     */
    'fileName': string;
    /**
     * 
     * @type {number}
     * @memberof UpdatePoseAudioDto
     */
    'volume': number;
    /**
     * 
     * @type {number}
     * @memberof UpdatePoseAudioDto
     */
    'time': number;
}
/**
 * 
 * @export
 * @interface UpdatePoseDto
 */
export interface UpdatePoseDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePoseDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePoseDto
     */
    'idleImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePoseDto
     */
    'speakImageUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePoseDto
     */
    'isSpeedlines': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdatePoseDto
     */
    'iconUrl': string;
    /**
     * 
     * @type {number}
     * @memberof UpdatePoseDto
     */
    'order': number;
}
/**
 * 
 * @export
 * @interface UpdatePoseFunctionDto
 */
export interface UpdatePoseFunctionDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePoseFunctionDto
     */
    'functionName': UpdatePoseFunctionDtoFunctionNameEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdatePoseFunctionDto
     */
    'functionParam': UpdatePoseFunctionDtoFunctionParamEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdatePoseFunctionDto
     */
    'time': number;
}

export const UpdatePoseFunctionDtoFunctionNameEnum = {
    Flash: 'Flash',
    Shake: 'Shake'
} as const;

export type UpdatePoseFunctionDtoFunctionNameEnum = typeof UpdatePoseFunctionDtoFunctionNameEnum[keyof typeof UpdatePoseFunctionDtoFunctionNameEnum];
export const UpdatePoseFunctionDtoFunctionParamEnum = {
    S: 's',
    M: 'm',
    L: 'l'
} as const;

export type UpdatePoseFunctionDtoFunctionParamEnum = typeof UpdatePoseFunctionDtoFunctionParamEnum[keyof typeof UpdatePoseFunctionDtoFunctionParamEnum];

/**
 * 
 * @export
 * @interface UpdatePoseStateDto
 */
export interface UpdatePoseStateDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePoseStateDto
     */
    'imageUrl': string;
    /**
     * 
     * @type {number}
     * @memberof UpdatePoseStateDto
     */
    'nextPoseDelay': number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePoseStateDto
     */
    'noSpeakDelay'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateSoundDto
 */
export interface UpdateSoundDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateSoundDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSoundDto
     */
    'url': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateSoundDto
     */
    'volume': number;
}
/**
 * 
 * @export
 * @interface UpdateSpeechBubbleDto
 */
export interface UpdateSpeechBubbleDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateSpeechBubbleDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSpeechBubbleDto
     */
    'imageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSpeechBubbleDto
     */
    'soundUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSpeechBubbleDto
     */
    'shake': boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateSpeechBubbleDto
     */
    'duration': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateSpeechBubbleDto
     */
    'order': number;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ChangePasswordDto} changePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (changePasswordDto: ChangePasswordDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changePasswordDto' is not null or undefined
            assertParamExists('changePassword', 'changePasswordDto', changePasswordDto)
            const localVarPath = `/auth/change-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ChangeUsernameDto} changeUsernameDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUsername: async (changeUsernameDto: ChangeUsernameDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeUsernameDto' is not null or undefined
            assertParamExists('changeUsername', 'changeUsernameDto', changeUsernameDto)
            const localVarPath = `/auth/change-username`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeUsernameDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteAccountDto} deleteAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount: async (deleteAccountDto: DeleteAccountDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteAccountDto' is not null or undefined
            assertParamExists('deleteAccount', 'deleteAccountDto', deleteAccountDto)
            const localVarPath = `/auth/delete-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteAccountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discord: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/discord`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discordCallback: async (code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('discordCallback', 'code', code)
            const localVarPath = `/auth/callback/discord`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ForgotPasswordDto} forgotPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword: async (forgotPasswordDto: ForgotPasswordDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgotPasswordDto' is not null or undefined
            assertParamExists('forgotPassword', 'forgotPasswordDto', forgotPasswordDto)
            const localVarPath = `/auth/forgot-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        google: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/google`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleCallback: async (code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('googleCallback', 'code', code)
            const localVarPath = `/auth/callback/google`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginUserDto} loginUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginUserDto: LoginUserDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginUserDto' is not null or undefined
            assertParamExists('login', 'loginUserDto', loginUserDto)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        me: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterUserDto} registerUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (registerUserDto: RegisterUserDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerUserDto' is not null or undefined
            assertParamExists('register', 'registerUserDto', registerUserDto)
            const localVarPath = `/auth/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResetPasswordDto} resetPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (resetPasswordDto: ResetPasswordDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordDto' is not null or undefined
            assertParamExists('resetPassword', 'resetPasswordDto', resetPasswordDto)
            const localVarPath = `/auth/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ChangePasswordDto} changePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(changePasswordDto: ChangePasswordDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(changePasswordDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.changePassword']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {ChangeUsernameDto} changeUsernameDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeUsername(changeUsernameDto: ChangeUsernameDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeUsername(changeUsernameDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.changeUsername']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {DeleteAccountDto} deleteAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAccount(deleteAccountDto: DeleteAccountDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAccount(deleteAccountDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.deleteAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discord(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discord(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.discord']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discordCallback(code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefreshResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discordCallback(code, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.discordCallback']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {ForgotPasswordDto} forgotPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotPassword(forgotPasswordDto: ForgotPasswordDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forgotPassword(forgotPasswordDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.forgotPassword']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async google(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.google(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.google']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async googleCallback(code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefreshResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.googleCallback(code, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.googleCallback']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {LoginUserDto} loginUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginUserDto: LoginUserDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefreshResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginUserDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.login']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.logout']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async me(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.me(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.me']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshToken(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefreshResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.refreshToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {RegisterUserDto} registerUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(registerUserDto: RegisterUserDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefreshResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(registerUserDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.register']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {ResetPasswordDto} resetPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(resetPasswordDto: ResetPasswordDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(resetPasswordDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.resetPassword']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {ChangePasswordDto} changePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(changePasswordDto: ChangePasswordDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.changePassword(changePasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChangeUsernameDto} changeUsernameDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUsername(changeUsernameDto: ChangeUsernameDto, options?: any): AxiosPromise<void> {
            return localVarFp.changeUsername(changeUsernameDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteAccountDto} deleteAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount(deleteAccountDto: DeleteAccountDto, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAccount(deleteAccountDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discord(options?: any): AxiosPromise<void> {
            return localVarFp.discord(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discordCallback(code: string, options?: any): AxiosPromise<RefreshResponseDto> {
            return localVarFp.discordCallback(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForgotPasswordDto} forgotPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword(forgotPasswordDto: ForgotPasswordDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.forgotPassword(forgotPasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        google(options?: any): AxiosPromise<void> {
            return localVarFp.google(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleCallback(code: string, options?: any): AxiosPromise<RefreshResponseDto> {
            return localVarFp.googleCallback(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginUserDto} loginUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginUserDto: LoginUserDto, options?: any): AxiosPromise<RefreshResponseDto> {
            return localVarFp.login(loginUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): AxiosPromise<void> {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        me(options?: any): AxiosPromise<MeResponseDto> {
            return localVarFp.me(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(options?: any): AxiosPromise<RefreshResponseDto> {
            return localVarFp.refreshToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterUserDto} registerUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(registerUserDto: RegisterUserDto, options?: any): AxiosPromise<RefreshResponseDto> {
            return localVarFp.register(registerUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResetPasswordDto} resetPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(resetPasswordDto: ResetPasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.resetPassword(resetPasswordDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {ChangePasswordDto} changePasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public changePassword(changePasswordDto: ChangePasswordDto, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).changePassword(changePasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChangeUsernameDto} changeUsernameDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public changeUsername(changeUsernameDto: ChangeUsernameDto, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).changeUsername(changeUsernameDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteAccountDto} deleteAccountDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public deleteAccount(deleteAccountDto: DeleteAccountDto, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).deleteAccount(deleteAccountDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public discord(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).discord(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public discordCallback(code: string, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).discordCallback(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForgotPasswordDto} forgotPasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public forgotPassword(forgotPasswordDto: ForgotPasswordDto, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).forgotPassword(forgotPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public google(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).google(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public googleCallback(code: string, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).googleCallback(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginUserDto} loginUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public login(loginUserDto: LoginUserDto, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).login(loginUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public logout(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public me(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).me(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public refreshToken(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).refreshToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterUserDto} registerUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public register(registerUserDto: RegisterUserDto, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).register(registerUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResetPasswordDto} resetPasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public resetPassword(resetPasswordDto: ResetPasswordDto, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).resetPassword(resetPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BackgroundApi - axios parameter creator
 * @export
 */
export const BackgroundApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateBackgroundDto} createBackgroundDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createBackgroundDto: CreateBackgroundDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBackgroundDto' is not null or undefined
            assertParamExists('create', 'createBackgroundDto', createBackgroundDto)
            const localVarPath = `/assets/background`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBackgroundDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('delete', 'id', id)
            const localVarPath = `/assets/background/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('get', 'id', id)
            const localVarPath = `/assets/background/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BulkIdDto} bulkIdDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (bulkIdDto: BulkIdDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkIdDto' is not null or undefined
            assertParamExists('getAll', 'bulkIdDto', bulkIdDto)
            const localVarPath = `/assets/background/getAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkIdDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMine: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/assets/background/getMine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreset: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/assets/background/getPreset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateBackgroundDto} updateBackgroundDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: number, updateBackgroundDto: UpdateBackgroundDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateBackgroundDto' is not null or undefined
            assertParamExists('update', 'updateBackgroundDto', updateBackgroundDto)
            const localVarPath = `/assets/background/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBackgroundDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BackgroundApi - functional programming interface
 * @export
 */
export const BackgroundApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BackgroundApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateBackgroundDto} createBackgroundDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createBackgroundDto: CreateBackgroundDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackgroundDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createBackgroundDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BackgroundApi.create']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BackgroundApi.delete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackgroundDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BackgroundApi.get']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {BulkIdDto} bulkIdDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(bulkIdDto: BulkIdDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BackgroundDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(bulkIdDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BackgroundApi.getAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMine(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BackgroundDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMine(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BackgroundApi.getMine']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreset(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BackgroundDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreset(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BackgroundApi.getPreset']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateBackgroundDto} updateBackgroundDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: number, updateBackgroundDto: UpdateBackgroundDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackgroundDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateBackgroundDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BackgroundApi.update']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * BackgroundApi - factory interface
 * @export
 */
export const BackgroundApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BackgroundApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateBackgroundDto} createBackgroundDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createBackgroundDto: CreateBackgroundDto, options?: any): AxiosPromise<BackgroundDto> {
            return localVarFp.create(createBackgroundDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: number, options?: any): AxiosPromise<BackgroundDto> {
            return localVarFp.get(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BulkIdDto} bulkIdDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(bulkIdDto: BulkIdDto, options?: any): AxiosPromise<Array<BackgroundDto>> {
            return localVarFp.getAll(bulkIdDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMine(options?: any): AxiosPromise<Array<BackgroundDto>> {
            return localVarFp.getMine(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreset(options?: any): AxiosPromise<Array<BackgroundDto>> {
            return localVarFp.getPreset(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateBackgroundDto} updateBackgroundDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: number, updateBackgroundDto: UpdateBackgroundDto, options?: any): AxiosPromise<BackgroundDto> {
            return localVarFp.update(id, updateBackgroundDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BackgroundApi - object-oriented interface
 * @export
 * @class BackgroundApi
 * @extends {BaseAPI}
 */
export class BackgroundApi extends BaseAPI {
    /**
     * 
     * @param {CreateBackgroundDto} createBackgroundDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackgroundApi
     */
    public create(createBackgroundDto: CreateBackgroundDto, options?: RawAxiosRequestConfig) {
        return BackgroundApiFp(this.configuration).create(createBackgroundDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackgroundApi
     */
    public delete(id: number, options?: RawAxiosRequestConfig) {
        return BackgroundApiFp(this.configuration).delete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackgroundApi
     */
    public get(id: number, options?: RawAxiosRequestConfig) {
        return BackgroundApiFp(this.configuration).get(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BulkIdDto} bulkIdDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackgroundApi
     */
    public getAll(bulkIdDto: BulkIdDto, options?: RawAxiosRequestConfig) {
        return BackgroundApiFp(this.configuration).getAll(bulkIdDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackgroundApi
     */
    public getMine(options?: RawAxiosRequestConfig) {
        return BackgroundApiFp(this.configuration).getMine(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackgroundApi
     */
    public getPreset(options?: RawAxiosRequestConfig) {
        return BackgroundApiFp(this.configuration).getPreset(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateBackgroundDto} updateBackgroundDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackgroundApi
     */
    public update(id: number, updateBackgroundDto: UpdateBackgroundDto, options?: RawAxiosRequestConfig) {
        return BackgroundApiFp(this.configuration).update(id, updateBackgroundDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CaseApi - axios parameter creator
 * @export
 */
export const CaseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archive: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('archive', 'id', id)
            const localVarPath = `/case/archive/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateCaseDto} createCaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createCaseDto: CreateCaseDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCaseDto' is not null or undefined
            assertParamExists('create', 'createCaseDto', createCaseDto)
            const localVarPath = `/case`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCaseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('delete', 'id', id)
            const localVarPath = `/case/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('get', 'id', id)
            const localVarPath = `/case/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEdit: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEdit', 'id', id)
            const localVarPath = `/case/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} archived 
         * @param {number} page 
         * @param {number} perPage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMine: async (archived: boolean, page: number, perPage: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'archived' is not null or undefined
            assertParamExists('getMine', 'archived', archived)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getMine', 'page', page)
            // verify required parameter 'perPage' is not null or undefined
            assertParamExists('getMine', 'perPage', perPage)
            const localVarPath = `/case/getMine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (archived !== undefined) {
                localVarQueryParameter['archived'] = archived;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('update', 'body', body)
            const localVarPath = `/case/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CaseApi - functional programming interface
 * @export
 */
export const CaseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CaseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archive(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archive(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CaseApi.archive']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {CreateCaseDto} createCaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createCaseDto: CreateCaseDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createCaseDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CaseApi.create']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CaseApi.delete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CaseApi.get']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEdit(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEdit(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CaseApi.getEdit']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} archived 
         * @param {number} page 
         * @param {number} perPage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMine(archived: boolean, page: number, perPage: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseOfCaseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMine(archived, page, perPage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CaseApi.getMine']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CaseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CaseApi.update']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CaseApi - factory interface
 * @export
 */
export const CaseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CaseApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archive(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.archive(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateCaseDto} createCaseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createCaseDto: CreateCaseDto, options?: any): AxiosPromise<CaseDto> {
            return localVarFp.create(createCaseDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any): AxiosPromise<CaseResponseDto> {
            return localVarFp.get(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEdit(id: string, options?: any): AxiosPromise<CaseResponseDto> {
            return localVarFp.getEdit(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} archived 
         * @param {number} page 
         * @param {number} perPage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMine(archived: boolean, page: number, perPage: number, options?: any): AxiosPromise<PaginatedResponseOfCaseDto> {
            return localVarFp.getMine(archived, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, body: object, options?: any): AxiosPromise<CaseDto> {
            return localVarFp.update(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CaseApi - object-oriented interface
 * @export
 * @class CaseApi
 * @extends {BaseAPI}
 */
export class CaseApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public archive(id: string, options?: RawAxiosRequestConfig) {
        return CaseApiFp(this.configuration).archive(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateCaseDto} createCaseDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public create(createCaseDto: CreateCaseDto, options?: RawAxiosRequestConfig) {
        return CaseApiFp(this.configuration).create(createCaseDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public delete(id: string, options?: RawAxiosRequestConfig) {
        return CaseApiFp(this.configuration).delete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public get(id: string, options?: RawAxiosRequestConfig) {
        return CaseApiFp(this.configuration).get(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public getEdit(id: string, options?: RawAxiosRequestConfig) {
        return CaseApiFp(this.configuration).getEdit(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} archived 
     * @param {number} page 
     * @param {number} perPage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public getMine(archived: boolean, page: number, perPage: number, options?: RawAxiosRequestConfig) {
        return CaseApiFp(this.configuration).getMine(archived, page, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseApi
     */
    public update(id: string, body: object, options?: RawAxiosRequestConfig) {
        return CaseApiFp(this.configuration).update(id, body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CaseSaveApi - axios parameter creator
 * @export
 */
export const CaseSaveApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateSaveDto} createSaveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createSaveDto: CreateSaveDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSaveDto' is not null or undefined
            assertParamExists('create', 'createSaveDto', createSaveDto)
            const localVarPath = `/case/save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSaveDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('delete', 'id', id)
            const localVarPath = `/case/save/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('get', 'id', id)
            const localVarPath = `/case/save/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMine: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/case/save/getMine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CaseSaveApi - functional programming interface
 * @export
 */
export const CaseSaveApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CaseSaveApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateSaveDto} createSaveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createSaveDto: CreateSaveDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createSaveDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CaseSaveApi.create']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CaseSaveApi.delete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CaseSaveApi.get']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMine(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SaveDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMine(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CaseSaveApi.getMine']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CaseSaveApi - factory interface
 * @export
 */
export const CaseSaveApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CaseSaveApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateSaveDto} createSaveDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createSaveDto: CreateSaveDto, options?: any): AxiosPromise<SaveDto> {
            return localVarFp.create(createSaveDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: number, options?: any): AxiosPromise<SaveDto> {
            return localVarFp.get(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMine(options?: any): AxiosPromise<Array<SaveDto>> {
            return localVarFp.getMine(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CaseSaveApi - object-oriented interface
 * @export
 * @class CaseSaveApi
 * @extends {BaseAPI}
 */
export class CaseSaveApi extends BaseAPI {
    /**
     * 
     * @param {CreateSaveDto} createSaveDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseSaveApi
     */
    public create(createSaveDto: CreateSaveDto, options?: RawAxiosRequestConfig) {
        return CaseSaveApiFp(this.configuration).create(createSaveDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseSaveApi
     */
    public delete(id: number, options?: RawAxiosRequestConfig) {
        return CaseSaveApiFp(this.configuration).delete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseSaveApi
     */
    public get(id: number, options?: RawAxiosRequestConfig) {
        return CaseSaveApiFp(this.configuration).get(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CaseSaveApi
     */
    public getMine(options?: RawAxiosRequestConfig) {
        return CaseSaveApiFp(this.configuration).getMine(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CharacterApi - axios parameter creator
 * @export
 */
export const CharacterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCharacterDto} createCharacterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createCharacterDto: CreateCharacterDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCharacterDto' is not null or undefined
            assertParamExists('create', 'createCharacterDto', createCharacterDto)
            const localVarPath = `/assets/character`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCharacterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('delete', 'id', id)
            const localVarPath = `/assets/character/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('get', 'id', id)
            const localVarPath = `/assets/character/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BulkIdDto} bulkIdDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (bulkIdDto: BulkIdDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkIdDto' is not null or undefined
            assertParamExists('getAll', 'bulkIdDto', bulkIdDto)
            const localVarPath = `/assets/character/getAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkIdDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMine: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/assets/character/getMine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreset: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/assets/character/getPreset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isShared: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('isShared', 'id', id)
            const localVarPath = `/assets/character/shared/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReorderPosesDto} reorderPosesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reorderPoses: async (reorderPosesDto: ReorderPosesDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reorderPosesDto' is not null or undefined
            assertParamExists('reorderPoses', 'reorderPosesDto', reorderPosesDto)
            const localVarPath = `/assets/character/reorderPoses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reorderPosesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReorderSpeechBubblesDto} reorderSpeechBubblesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reorderSpeechBubbles: async (reorderSpeechBubblesDto: ReorderSpeechBubblesDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reorderSpeechBubblesDto' is not null or undefined
            assertParamExists('reorderSpeechBubbles', 'reorderSpeechBubblesDto', reorderSpeechBubblesDto)
            const localVarPath = `/assets/character/reorderSpeechBubbles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reorderSpeechBubblesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        share: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('share', 'id', id)
            const localVarPath = `/assets/character/share/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unshare: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unshare', 'id', id)
            const localVarPath = `/assets/character/share/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateCharacterDto} updateCharacterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: number, updateCharacterDto: UpdateCharacterDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateCharacterDto' is not null or undefined
            assertParamExists('update', 'updateCharacterDto', updateCharacterDto)
            const localVarPath = `/assets/character/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCharacterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useShareCode: async (code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('useShareCode', 'code', code)
            const localVarPath = `/assets/character/code/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CharacterApi - functional programming interface
 * @export
 */
export const CharacterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CharacterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateCharacterDto} createCharacterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createCharacterDto: CreateCharacterDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CharacterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createCharacterDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterApi.create']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterApi.delete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CharacterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterApi.get']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {BulkIdDto} bulkIdDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(bulkIdDto: BulkIdDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CharacterDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(bulkIdDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterApi.getAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMine(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CharacterDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMine(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterApi.getMine']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreset(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreset(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterApi.getPreset']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isShared(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetShareDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isShared(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterApi.isShared']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {ReorderPosesDto} reorderPosesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reorderPoses(reorderPosesDto: ReorderPosesDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reorderPoses(reorderPosesDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterApi.reorderPoses']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {ReorderSpeechBubblesDto} reorderSpeechBubblesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reorderSpeechBubbles(reorderSpeechBubblesDto: ReorderSpeechBubblesDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reorderSpeechBubbles(reorderSpeechBubblesDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterApi.reorderSpeechBubbles']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async share(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetShareDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.share(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterApi.share']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unshare(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unshare(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterApi.unshare']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateCharacterDto} updateCharacterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: number, updateCharacterDto: UpdateCharacterDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CharacterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateCharacterDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterApi.update']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async useShareCode(code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CharacterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.useShareCode(code, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterApi.useShareCode']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CharacterApi - factory interface
 * @export
 */
export const CharacterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CharacterApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateCharacterDto} createCharacterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createCharacterDto: CreateCharacterDto, options?: any): AxiosPromise<CharacterDto> {
            return localVarFp.create(createCharacterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: number, options?: any): AxiosPromise<CharacterDto> {
            return localVarFp.get(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BulkIdDto} bulkIdDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(bulkIdDto: BulkIdDto, options?: any): AxiosPromise<Array<CharacterDto>> {
            return localVarFp.getAll(bulkIdDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMine(options?: any): AxiosPromise<Array<CharacterDto>> {
            return localVarFp.getMine(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreset(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.getPreset(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isShared(id: number, options?: any): AxiosPromise<AssetShareDto> {
            return localVarFp.isShared(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReorderPosesDto} reorderPosesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reorderPoses(reorderPosesDto: ReorderPosesDto, options?: any): AxiosPromise<void> {
            return localVarFp.reorderPoses(reorderPosesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReorderSpeechBubblesDto} reorderSpeechBubblesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reorderSpeechBubbles(reorderSpeechBubblesDto: ReorderSpeechBubblesDto, options?: any): AxiosPromise<void> {
            return localVarFp.reorderSpeechBubbles(reorderSpeechBubblesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        share(id: number, options?: any): AxiosPromise<AssetShareDto> {
            return localVarFp.share(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unshare(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.unshare(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateCharacterDto} updateCharacterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: number, updateCharacterDto: UpdateCharacterDto, options?: any): AxiosPromise<CharacterDto> {
            return localVarFp.update(id, updateCharacterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useShareCode(code: string, options?: any): AxiosPromise<CharacterDto> {
            return localVarFp.useShareCode(code, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CharacterApi - object-oriented interface
 * @export
 * @class CharacterApi
 * @extends {BaseAPI}
 */
export class CharacterApi extends BaseAPI {
    /**
     * 
     * @param {CreateCharacterDto} createCharacterDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterApi
     */
    public create(createCharacterDto: CreateCharacterDto, options?: RawAxiosRequestConfig) {
        return CharacterApiFp(this.configuration).create(createCharacterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterApi
     */
    public delete(id: number, options?: RawAxiosRequestConfig) {
        return CharacterApiFp(this.configuration).delete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterApi
     */
    public get(id: number, options?: RawAxiosRequestConfig) {
        return CharacterApiFp(this.configuration).get(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BulkIdDto} bulkIdDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterApi
     */
    public getAll(bulkIdDto: BulkIdDto, options?: RawAxiosRequestConfig) {
        return CharacterApiFp(this.configuration).getAll(bulkIdDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterApi
     */
    public getMine(options?: RawAxiosRequestConfig) {
        return CharacterApiFp(this.configuration).getMine(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterApi
     */
    public getPreset(options?: RawAxiosRequestConfig) {
        return CharacterApiFp(this.configuration).getPreset(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterApi
     */
    public isShared(id: number, options?: RawAxiosRequestConfig) {
        return CharacterApiFp(this.configuration).isShared(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReorderPosesDto} reorderPosesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterApi
     */
    public reorderPoses(reorderPosesDto: ReorderPosesDto, options?: RawAxiosRequestConfig) {
        return CharacterApiFp(this.configuration).reorderPoses(reorderPosesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReorderSpeechBubblesDto} reorderSpeechBubblesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterApi
     */
    public reorderSpeechBubbles(reorderSpeechBubblesDto: ReorderSpeechBubblesDto, options?: RawAxiosRequestConfig) {
        return CharacterApiFp(this.configuration).reorderSpeechBubbles(reorderSpeechBubblesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterApi
     */
    public share(id: number, options?: RawAxiosRequestConfig) {
        return CharacterApiFp(this.configuration).share(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterApi
     */
    public unshare(id: number, options?: RawAxiosRequestConfig) {
        return CharacterApiFp(this.configuration).unshare(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateCharacterDto} updateCharacterDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterApi
     */
    public update(id: number, updateCharacterDto: UpdateCharacterDto, options?: RawAxiosRequestConfig) {
        return CharacterApiFp(this.configuration).update(id, updateCharacterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterApi
     */
    public useShareCode(code: string, options?: RawAxiosRequestConfig) {
        return CharacterApiFp(this.configuration).useShareCode(code, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CharacterPoseApi - axios parameter creator
 * @export
 */
export const CharacterPoseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePoseDto} createPoseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createPoseDto: CreatePoseDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPoseDto' is not null or undefined
            assertParamExists('create', 'createPoseDto', createPoseDto)
            const localVarPath = `/assets/character/pose`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPoseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('delete', 'id', id)
            const localVarPath = `/assets/character/pose/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('get', 'id', id)
            const localVarPath = `/assets/character/pose/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdatePoseDto} updatePoseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: number, updatePoseDto: UpdatePoseDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updatePoseDto' is not null or undefined
            assertParamExists('update', 'updatePoseDto', updatePoseDto)
            const localVarPath = `/assets/character/pose/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePoseDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CharacterPoseApi - functional programming interface
 * @export
 */
export const CharacterPoseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CharacterPoseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePoseDto} createPoseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createPoseDto: CreatePoseDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createPoseDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterPoseApi.create']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterPoseApi.delete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterPoseApi.get']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdatePoseDto} updatePoseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: number, updatePoseDto: UpdatePoseDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updatePoseDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterPoseApi.update']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CharacterPoseApi - factory interface
 * @export
 */
export const CharacterPoseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CharacterPoseApiFp(configuration)
    return {
        /**
         * 
         * @param {CreatePoseDto} createPoseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createPoseDto: CreatePoseDto, options?: any): AxiosPromise<PoseDto> {
            return localVarFp.create(createPoseDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: number, options?: any): AxiosPromise<PoseDto> {
            return localVarFp.get(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdatePoseDto} updatePoseDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: number, updatePoseDto: UpdatePoseDto, options?: any): AxiosPromise<PoseDto> {
            return localVarFp.update(id, updatePoseDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CharacterPoseApi - object-oriented interface
 * @export
 * @class CharacterPoseApi
 * @extends {BaseAPI}
 */
export class CharacterPoseApi extends BaseAPI {
    /**
     * 
     * @param {CreatePoseDto} createPoseDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterPoseApi
     */
    public create(createPoseDto: CreatePoseDto, options?: RawAxiosRequestConfig) {
        return CharacterPoseApiFp(this.configuration).create(createPoseDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterPoseApi
     */
    public delete(id: number, options?: RawAxiosRequestConfig) {
        return CharacterPoseApiFp(this.configuration).delete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterPoseApi
     */
    public get(id: number, options?: RawAxiosRequestConfig) {
        return CharacterPoseApiFp(this.configuration).get(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdatePoseDto} updatePoseDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterPoseApi
     */
    public update(id: number, updatePoseDto: UpdatePoseDto, options?: RawAxiosRequestConfig) {
        return CharacterPoseApiFp(this.configuration).update(id, updatePoseDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CharacterPoseAudioApi - axios parameter creator
 * @export
 */
export const CharacterPoseAudioApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePoseAudioDto} createPoseAudioDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createPoseAudioDto: CreatePoseAudioDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPoseAudioDto' is not null or undefined
            assertParamExists('create', 'createPoseAudioDto', createPoseAudioDto)
            const localVarPath = `/assets/character/pose/audio`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPoseAudioDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('delete', 'id', id)
            const localVarPath = `/assets/character/pose/audio/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('get', 'id', id)
            const localVarPath = `/assets/character/pose/audio/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdatePoseAudioDto} updatePoseAudioDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: number, updatePoseAudioDto: UpdatePoseAudioDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updatePoseAudioDto' is not null or undefined
            assertParamExists('update', 'updatePoseAudioDto', updatePoseAudioDto)
            const localVarPath = `/assets/character/pose/audio/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePoseAudioDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CharacterPoseAudioApi - functional programming interface
 * @export
 */
export const CharacterPoseAudioApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CharacterPoseAudioApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePoseAudioDto} createPoseAudioDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createPoseAudioDto: CreatePoseAudioDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoseAudioDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createPoseAudioDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterPoseAudioApi.create']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterPoseAudioApi.delete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoseAudioDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterPoseAudioApi.get']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdatePoseAudioDto} updatePoseAudioDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: number, updatePoseAudioDto: UpdatePoseAudioDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoseAudioDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updatePoseAudioDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterPoseAudioApi.update']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CharacterPoseAudioApi - factory interface
 * @export
 */
export const CharacterPoseAudioApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CharacterPoseAudioApiFp(configuration)
    return {
        /**
         * 
         * @param {CreatePoseAudioDto} createPoseAudioDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createPoseAudioDto: CreatePoseAudioDto, options?: any): AxiosPromise<PoseAudioDto> {
            return localVarFp.create(createPoseAudioDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: number, options?: any): AxiosPromise<PoseAudioDto> {
            return localVarFp.get(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdatePoseAudioDto} updatePoseAudioDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: number, updatePoseAudioDto: UpdatePoseAudioDto, options?: any): AxiosPromise<PoseAudioDto> {
            return localVarFp.update(id, updatePoseAudioDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CharacterPoseAudioApi - object-oriented interface
 * @export
 * @class CharacterPoseAudioApi
 * @extends {BaseAPI}
 */
export class CharacterPoseAudioApi extends BaseAPI {
    /**
     * 
     * @param {CreatePoseAudioDto} createPoseAudioDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterPoseAudioApi
     */
    public create(createPoseAudioDto: CreatePoseAudioDto, options?: RawAxiosRequestConfig) {
        return CharacterPoseAudioApiFp(this.configuration).create(createPoseAudioDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterPoseAudioApi
     */
    public delete(id: number, options?: RawAxiosRequestConfig) {
        return CharacterPoseAudioApiFp(this.configuration).delete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterPoseAudioApi
     */
    public get(id: number, options?: RawAxiosRequestConfig) {
        return CharacterPoseAudioApiFp(this.configuration).get(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdatePoseAudioDto} updatePoseAudioDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterPoseAudioApi
     */
    public update(id: number, updatePoseAudioDto: UpdatePoseAudioDto, options?: RawAxiosRequestConfig) {
        return CharacterPoseAudioApiFp(this.configuration).update(id, updatePoseAudioDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CharacterPoseFunctionApi - axios parameter creator
 * @export
 */
export const CharacterPoseFunctionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePoseFunctionDto} createPoseFunctionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createPoseFunctionDto: CreatePoseFunctionDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPoseFunctionDto' is not null or undefined
            assertParamExists('create', 'createPoseFunctionDto', createPoseFunctionDto)
            const localVarPath = `/assets/character/pose/function`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPoseFunctionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('delete', 'id', id)
            const localVarPath = `/assets/character/pose/function/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('get', 'id', id)
            const localVarPath = `/assets/character/pose/function/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdatePoseFunctionDto} updatePoseFunctionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: number, updatePoseFunctionDto: UpdatePoseFunctionDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updatePoseFunctionDto' is not null or undefined
            assertParamExists('update', 'updatePoseFunctionDto', updatePoseFunctionDto)
            const localVarPath = `/assets/character/pose/function/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePoseFunctionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CharacterPoseFunctionApi - functional programming interface
 * @export
 */
export const CharacterPoseFunctionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CharacterPoseFunctionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePoseFunctionDto} createPoseFunctionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createPoseFunctionDto: CreatePoseFunctionDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoseFunctionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createPoseFunctionDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterPoseFunctionApi.create']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterPoseFunctionApi.delete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoseFunctionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterPoseFunctionApi.get']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdatePoseFunctionDto} updatePoseFunctionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: number, updatePoseFunctionDto: UpdatePoseFunctionDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoseFunctionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updatePoseFunctionDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterPoseFunctionApi.update']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CharacterPoseFunctionApi - factory interface
 * @export
 */
export const CharacterPoseFunctionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CharacterPoseFunctionApiFp(configuration)
    return {
        /**
         * 
         * @param {CreatePoseFunctionDto} createPoseFunctionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createPoseFunctionDto: CreatePoseFunctionDto, options?: any): AxiosPromise<PoseFunctionDto> {
            return localVarFp.create(createPoseFunctionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: number, options?: any): AxiosPromise<PoseFunctionDto> {
            return localVarFp.get(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdatePoseFunctionDto} updatePoseFunctionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: number, updatePoseFunctionDto: UpdatePoseFunctionDto, options?: any): AxiosPromise<PoseFunctionDto> {
            return localVarFp.update(id, updatePoseFunctionDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CharacterPoseFunctionApi - object-oriented interface
 * @export
 * @class CharacterPoseFunctionApi
 * @extends {BaseAPI}
 */
export class CharacterPoseFunctionApi extends BaseAPI {
    /**
     * 
     * @param {CreatePoseFunctionDto} createPoseFunctionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterPoseFunctionApi
     */
    public create(createPoseFunctionDto: CreatePoseFunctionDto, options?: RawAxiosRequestConfig) {
        return CharacterPoseFunctionApiFp(this.configuration).create(createPoseFunctionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterPoseFunctionApi
     */
    public delete(id: number, options?: RawAxiosRequestConfig) {
        return CharacterPoseFunctionApiFp(this.configuration).delete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterPoseFunctionApi
     */
    public get(id: number, options?: RawAxiosRequestConfig) {
        return CharacterPoseFunctionApiFp(this.configuration).get(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdatePoseFunctionDto} updatePoseFunctionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterPoseFunctionApi
     */
    public update(id: number, updatePoseFunctionDto: UpdatePoseFunctionDto, options?: RawAxiosRequestConfig) {
        return CharacterPoseFunctionApiFp(this.configuration).update(id, updatePoseFunctionDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CharacterPoseStateApi - axios parameter creator
 * @export
 */
export const CharacterPoseStateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePoseStateDto} createPoseStateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createPoseStateDto: CreatePoseStateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPoseStateDto' is not null or undefined
            assertParamExists('create', 'createPoseStateDto', createPoseStateDto)
            const localVarPath = `/assets/character/pose/state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPoseStateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('delete', 'id', id)
            const localVarPath = `/assets/character/pose/state/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('get', 'id', id)
            const localVarPath = `/assets/character/pose/state/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdatePoseStateDto} updatePoseStateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: number, updatePoseStateDto: UpdatePoseStateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updatePoseStateDto' is not null or undefined
            assertParamExists('update', 'updatePoseStateDto', updatePoseStateDto)
            const localVarPath = `/assets/character/pose/state/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePoseStateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CharacterPoseStateApi - functional programming interface
 * @export
 */
export const CharacterPoseStateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CharacterPoseStateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePoseStateDto} createPoseStateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createPoseStateDto: CreatePoseStateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoseStateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createPoseStateDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterPoseStateApi.create']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterPoseStateApi.delete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoseStateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterPoseStateApi.get']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdatePoseStateDto} updatePoseStateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: number, updatePoseStateDto: UpdatePoseStateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoseStateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updatePoseStateDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterPoseStateApi.update']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CharacterPoseStateApi - factory interface
 * @export
 */
export const CharacterPoseStateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CharacterPoseStateApiFp(configuration)
    return {
        /**
         * 
         * @param {CreatePoseStateDto} createPoseStateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createPoseStateDto: CreatePoseStateDto, options?: any): AxiosPromise<PoseStateDto> {
            return localVarFp.create(createPoseStateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: number, options?: any): AxiosPromise<PoseStateDto> {
            return localVarFp.get(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdatePoseStateDto} updatePoseStateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: number, updatePoseStateDto: UpdatePoseStateDto, options?: any): AxiosPromise<PoseStateDto> {
            return localVarFp.update(id, updatePoseStateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CharacterPoseStateApi - object-oriented interface
 * @export
 * @class CharacterPoseStateApi
 * @extends {BaseAPI}
 */
export class CharacterPoseStateApi extends BaseAPI {
    /**
     * 
     * @param {CreatePoseStateDto} createPoseStateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterPoseStateApi
     */
    public create(createPoseStateDto: CreatePoseStateDto, options?: RawAxiosRequestConfig) {
        return CharacterPoseStateApiFp(this.configuration).create(createPoseStateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterPoseStateApi
     */
    public delete(id: number, options?: RawAxiosRequestConfig) {
        return CharacterPoseStateApiFp(this.configuration).delete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterPoseStateApi
     */
    public get(id: number, options?: RawAxiosRequestConfig) {
        return CharacterPoseStateApiFp(this.configuration).get(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdatePoseStateDto} updatePoseStateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterPoseStateApi
     */
    public update(id: number, updatePoseStateDto: UpdatePoseStateDto, options?: RawAxiosRequestConfig) {
        return CharacterPoseStateApiFp(this.configuration).update(id, updatePoseStateDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CharacterSpeechBubbleApi - axios parameter creator
 * @export
 */
export const CharacterSpeechBubbleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateSpeechBubbleDto} createSpeechBubbleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createSpeechBubbleDto: CreateSpeechBubbleDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSpeechBubbleDto' is not null or undefined
            assertParamExists('create', 'createSpeechBubbleDto', createSpeechBubbleDto)
            const localVarPath = `/assets/character/speech-bubble`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSpeechBubbleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('delete', 'id', id)
            const localVarPath = `/assets/character/speech-bubble/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('get', 'id', id)
            const localVarPath = `/assets/character/speech-bubble/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateSpeechBubbleDto} updateSpeechBubbleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: number, updateSpeechBubbleDto: UpdateSpeechBubbleDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateSpeechBubbleDto' is not null or undefined
            assertParamExists('update', 'updateSpeechBubbleDto', updateSpeechBubbleDto)
            const localVarPath = `/assets/character/speech-bubble/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSpeechBubbleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CharacterSpeechBubbleApi - functional programming interface
 * @export
 */
export const CharacterSpeechBubbleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CharacterSpeechBubbleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateSpeechBubbleDto} createSpeechBubbleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createSpeechBubbleDto: CreateSpeechBubbleDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpeechBubbleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createSpeechBubbleDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterSpeechBubbleApi.create']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterSpeechBubbleApi.delete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpeechBubbleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterSpeechBubbleApi.get']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateSpeechBubbleDto} updateSpeechBubbleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: number, updateSpeechBubbleDto: UpdateSpeechBubbleDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpeechBubbleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateSpeechBubbleDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CharacterSpeechBubbleApi.update']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CharacterSpeechBubbleApi - factory interface
 * @export
 */
export const CharacterSpeechBubbleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CharacterSpeechBubbleApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateSpeechBubbleDto} createSpeechBubbleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createSpeechBubbleDto: CreateSpeechBubbleDto, options?: any): AxiosPromise<SpeechBubbleDto> {
            return localVarFp.create(createSpeechBubbleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: number, options?: any): AxiosPromise<SpeechBubbleDto> {
            return localVarFp.get(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateSpeechBubbleDto} updateSpeechBubbleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: number, updateSpeechBubbleDto: UpdateSpeechBubbleDto, options?: any): AxiosPromise<SpeechBubbleDto> {
            return localVarFp.update(id, updateSpeechBubbleDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CharacterSpeechBubbleApi - object-oriented interface
 * @export
 * @class CharacterSpeechBubbleApi
 * @extends {BaseAPI}
 */
export class CharacterSpeechBubbleApi extends BaseAPI {
    /**
     * 
     * @param {CreateSpeechBubbleDto} createSpeechBubbleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterSpeechBubbleApi
     */
    public create(createSpeechBubbleDto: CreateSpeechBubbleDto, options?: RawAxiosRequestConfig) {
        return CharacterSpeechBubbleApiFp(this.configuration).create(createSpeechBubbleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterSpeechBubbleApi
     */
    public delete(id: number, options?: RawAxiosRequestConfig) {
        return CharacterSpeechBubbleApiFp(this.configuration).delete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterSpeechBubbleApi
     */
    public get(id: number, options?: RawAxiosRequestConfig) {
        return CharacterSpeechBubbleApiFp(this.configuration).get(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateSpeechBubbleDto} updateSpeechBubbleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CharacterSpeechBubbleApi
     */
    public update(id: number, updateSpeechBubbleDto: UpdateSpeechBubbleDto, options?: RawAxiosRequestConfig) {
        return CharacterSpeechBubbleApiFp(this.configuration).update(id, updateSpeechBubbleDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DraftApi - axios parameter creator
 * @export
 */
export const DraftApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCaseDraftDto} createCaseDraftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCase: async (createCaseDraftDto: CreateCaseDraftDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCaseDraftDto' is not null or undefined
            assertParamExists('createCase', 'createCaseDraftDto', createCaseDraftDto)
            const localVarPath = `/draft/case`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCaseDraftDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateSceneDraftDto} createSceneDraftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScene: async (createSceneDraftDto: CreateSceneDraftDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSceneDraftDto' is not null or undefined
            assertParamExists('createScene', 'createSceneDraftDto', createSceneDraftDto)
            const localVarPath = `/draft/scene`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSceneDraftDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('delete', 'id', id)
            const localVarPath = `/draft/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('get', 'id', id)
            const localVarPath = `/draft/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMine: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/draft/getMine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DraftApi - functional programming interface
 * @export
 */
export const DraftApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DraftApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateCaseDraftDto} createCaseDraftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCase(createCaseDraftDto: CreateCaseDraftDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DraftDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCase(createCaseDraftDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DraftApi.createCase']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {CreateSceneDraftDto} createSceneDraftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createScene(createSceneDraftDto: CreateSceneDraftDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DraftDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createScene(createSceneDraftDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DraftApi.createScene']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DraftApi.delete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DraftDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DraftApi.get']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMine(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DraftDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMine(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DraftApi.getMine']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DraftApi - factory interface
 * @export
 */
export const DraftApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DraftApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateCaseDraftDto} createCaseDraftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCase(createCaseDraftDto: CreateCaseDraftDto, options?: any): AxiosPromise<DraftDto> {
            return localVarFp.createCase(createCaseDraftDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateSceneDraftDto} createSceneDraftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createScene(createSceneDraftDto: CreateSceneDraftDto, options?: any): AxiosPromise<DraftDto> {
            return localVarFp.createScene(createSceneDraftDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: number, options?: any): AxiosPromise<DraftDto> {
            return localVarFp.get(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMine(options?: any): AxiosPromise<Array<DraftDto>> {
            return localVarFp.getMine(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DraftApi - object-oriented interface
 * @export
 * @class DraftApi
 * @extends {BaseAPI}
 */
export class DraftApi extends BaseAPI {
    /**
     * 
     * @param {CreateCaseDraftDto} createCaseDraftDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DraftApi
     */
    public createCase(createCaseDraftDto: CreateCaseDraftDto, options?: RawAxiosRequestConfig) {
        return DraftApiFp(this.configuration).createCase(createCaseDraftDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateSceneDraftDto} createSceneDraftDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DraftApi
     */
    public createScene(createSceneDraftDto: CreateSceneDraftDto, options?: RawAxiosRequestConfig) {
        return DraftApiFp(this.configuration).createScene(createSceneDraftDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DraftApi
     */
    public delete(id: number, options?: RawAxiosRequestConfig) {
        return DraftApiFp(this.configuration).delete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DraftApi
     */
    public get(id: number, options?: RawAxiosRequestConfig) {
        return DraftApiFp(this.configuration).get(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DraftApi
     */
    public getMine(options?: RawAxiosRequestConfig) {
        return DraftApiFp(this.configuration).getMine(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EvidenceApi - axios parameter creator
 * @export
 */
export const EvidenceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateEvidenceDto} createEvidenceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createEvidenceDto: CreateEvidenceDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEvidenceDto' is not null or undefined
            assertParamExists('create', 'createEvidenceDto', createEvidenceDto)
            const localVarPath = `/assets/evidence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEvidenceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('delete', 'id', id)
            const localVarPath = `/assets/evidence/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('get', 'id', id)
            const localVarPath = `/assets/evidence/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMine: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/assets/evidence/getMine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateEvidenceDto} updateEvidenceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: number, updateEvidenceDto: UpdateEvidenceDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateEvidenceDto' is not null or undefined
            assertParamExists('update', 'updateEvidenceDto', updateEvidenceDto)
            const localVarPath = `/assets/evidence/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEvidenceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EvidenceApi - functional programming interface
 * @export
 */
export const EvidenceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EvidenceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateEvidenceDto} createEvidenceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createEvidenceDto: CreateEvidenceDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EvidenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createEvidenceDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EvidenceApi.create']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EvidenceApi.delete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EvidenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EvidenceApi.get']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMine(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EvidenceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMine(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EvidenceApi.getMine']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateEvidenceDto} updateEvidenceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: number, updateEvidenceDto: UpdateEvidenceDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EvidenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateEvidenceDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EvidenceApi.update']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * EvidenceApi - factory interface
 * @export
 */
export const EvidenceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EvidenceApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateEvidenceDto} createEvidenceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createEvidenceDto: CreateEvidenceDto, options?: any): AxiosPromise<EvidenceDto> {
            return localVarFp.create(createEvidenceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: number, options?: any): AxiosPromise<EvidenceDto> {
            return localVarFp.get(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMine(options?: any): AxiosPromise<Array<EvidenceDto>> {
            return localVarFp.getMine(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateEvidenceDto} updateEvidenceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: number, updateEvidenceDto: UpdateEvidenceDto, options?: any): AxiosPromise<EvidenceDto> {
            return localVarFp.update(id, updateEvidenceDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EvidenceApi - object-oriented interface
 * @export
 * @class EvidenceApi
 * @extends {BaseAPI}
 */
export class EvidenceApi extends BaseAPI {
    /**
     * 
     * @param {CreateEvidenceDto} createEvidenceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvidenceApi
     */
    public create(createEvidenceDto: CreateEvidenceDto, options?: RawAxiosRequestConfig) {
        return EvidenceApiFp(this.configuration).create(createEvidenceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvidenceApi
     */
    public delete(id: number, options?: RawAxiosRequestConfig) {
        return EvidenceApiFp(this.configuration).delete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvidenceApi
     */
    public get(id: number, options?: RawAxiosRequestConfig) {
        return EvidenceApiFp(this.configuration).get(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvidenceApi
     */
    public getMine(options?: RawAxiosRequestConfig) {
        return EvidenceApiFp(this.configuration).getMine(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateEvidenceDto} updateEvidenceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvidenceApi
     */
    public update(id: number, updateEvidenceDto: UpdateEvidenceDto, options?: RawAxiosRequestConfig) {
        return EvidenceApiFp(this.configuration).update(id, updateEvidenceDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ExportApi - axios parameter creator
 * @export
 */
export const ExportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} dropToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dropRequest: async (dropToken: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dropToken' is not null or undefined
            assertParamExists('dropRequest', 'dropToken', dropToken)
            const localVarPath = `/export/drop/{dropToken}`
                .replace(`{${"dropToken"}}`, encodeURIComponent(String(dropToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateExportRequestDto} createExportRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportObjection: async (createExportRequestDto: CreateExportRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createExportRequestDto' is not null or undefined
            assertParamExists('exportObjection', 'createExportRequestDto', createExportRequestDto)
            const localVarPath = `/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createExportRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExportStatus: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getExportStatus', 'id', id)
            const localVarPath = `/export/status/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVideo: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getVideo', 'id', id)
            const localVarPath = `/export/download/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportError: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/export/report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExportApi - functional programming interface
 * @export
 */
export const ExportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} dropToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dropRequest(dropToken: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dropRequest(dropToken, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ExportApi.dropRequest']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {CreateExportRequestDto} createExportRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportObjection(createExportRequestDto: CreateExportRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportObjection(createExportRequestDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ExportApi.exportObjection']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExportStatus(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportStatusDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExportStatus(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ExportApi.getExportStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVideo(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVideo(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ExportApi.getVideo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportError(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportError(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ExportApi.reportError']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ExportApi - factory interface
 * @export
 */
export const ExportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExportApiFp(configuration)
    return {
        /**
         * 
         * @param {string} dropToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dropRequest(dropToken: string, options?: any): AxiosPromise<void> {
            return localVarFp.dropRequest(dropToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateExportRequestDto} createExportRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportObjection(createExportRequestDto: CreateExportRequestDto, options?: any): AxiosPromise<ExportRequestDto> {
            return localVarFp.exportObjection(createExportRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExportStatus(id: number, options?: any): AxiosPromise<ExportStatusDto> {
            return localVarFp.getExportStatus(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVideo(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.getVideo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportError(options?: any): AxiosPromise<void> {
            return localVarFp.reportError(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExportApi - object-oriented interface
 * @export
 * @class ExportApi
 * @extends {BaseAPI}
 */
export class ExportApi extends BaseAPI {
    /**
     * 
     * @param {string} dropToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportApi
     */
    public dropRequest(dropToken: string, options?: RawAxiosRequestConfig) {
        return ExportApiFp(this.configuration).dropRequest(dropToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateExportRequestDto} createExportRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportApi
     */
    public exportObjection(createExportRequestDto: CreateExportRequestDto, options?: RawAxiosRequestConfig) {
        return ExportApiFp(this.configuration).exportObjection(createExportRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportApi
     */
    public getExportStatus(id: number, options?: RawAxiosRequestConfig) {
        return ExportApiFp(this.configuration).getExportStatus(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportApi
     */
    public getVideo(id: number, options?: RawAxiosRequestConfig) {
        return ExportApiFp(this.configuration).getVideo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportApi
     */
    public reportError(options?: RawAxiosRequestConfig) {
        return ExportApiFp(this.configuration).reportError(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MaintenanceApi - axios parameter creator
 * @export
 */
export const MaintenanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MaintenanceDto} maintenanceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeMaintenanceMode: async (maintenanceDto: MaintenanceDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'maintenanceDto' is not null or undefined
            assertParamExists('changeMaintenanceMode', 'maintenanceDto', maintenanceDto)
            const localVarPath = `/maintenance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(maintenanceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMode: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/maintenance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MaintenanceApi - functional programming interface
 * @export
 */
export const MaintenanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MaintenanceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MaintenanceDto} maintenanceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeMaintenanceMode(maintenanceDto: MaintenanceDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaintenanceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeMaintenanceMode(maintenanceDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MaintenanceApi.changeMaintenanceMode']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceMode(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaintenanceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceMode(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MaintenanceApi.maintenanceMode']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * MaintenanceApi - factory interface
 * @export
 */
export const MaintenanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MaintenanceApiFp(configuration)
    return {
        /**
         * 
         * @param {MaintenanceDto} maintenanceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeMaintenanceMode(maintenanceDto: MaintenanceDto, options?: any): AxiosPromise<MaintenanceDto> {
            return localVarFp.changeMaintenanceMode(maintenanceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceMode(options?: any): AxiosPromise<MaintenanceDto> {
            return localVarFp.maintenanceMode(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MaintenanceApi - object-oriented interface
 * @export
 * @class MaintenanceApi
 * @extends {BaseAPI}
 */
export class MaintenanceApi extends BaseAPI {
    /**
     * 
     * @param {MaintenanceDto} maintenanceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public changeMaintenanceMode(maintenanceDto: MaintenanceDto, options?: RawAxiosRequestConfig) {
        return MaintenanceApiFp(this.configuration).changeMaintenanceMode(maintenanceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public maintenanceMode(options?: RawAxiosRequestConfig) {
        return MaintenanceApiFp(this.configuration).maintenanceMode(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MusicApi - axios parameter creator
 * @export
 */
export const MusicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateMusicDto} createMusicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createMusicDto: CreateMusicDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMusicDto' is not null or undefined
            assertParamExists('create', 'createMusicDto', createMusicDto)
            const localVarPath = `/assets/music`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMusicDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('delete', 'id', id)
            const localVarPath = `/assets/music/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('get', 'id', id)
            const localVarPath = `/assets/music/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMine: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/assets/music/getMine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreset: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/assets/music/getPreset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateMusicDto} updateMusicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: number, updateMusicDto: UpdateMusicDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateMusicDto' is not null or undefined
            assertParamExists('update', 'updateMusicDto', updateMusicDto)
            const localVarPath = `/assets/music/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMusicDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MusicApi - functional programming interface
 * @export
 */
export const MusicApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MusicApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateMusicDto} createMusicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createMusicDto: CreateMusicDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MusicDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createMusicDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MusicApi.create']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MusicApi.delete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MusicDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MusicApi.get']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMine(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MusicDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMine(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MusicApi.getMine']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreset(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MusicDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreset(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MusicApi.getPreset']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateMusicDto} updateMusicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: number, updateMusicDto: UpdateMusicDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MusicDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateMusicDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MusicApi.update']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * MusicApi - factory interface
 * @export
 */
export const MusicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MusicApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateMusicDto} createMusicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createMusicDto: CreateMusicDto, options?: any): AxiosPromise<MusicDto> {
            return localVarFp.create(createMusicDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: number, options?: any): AxiosPromise<MusicDto> {
            return localVarFp.get(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMine(options?: any): AxiosPromise<Array<MusicDto>> {
            return localVarFp.getMine(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreset(options?: any): AxiosPromise<Array<MusicDto>> {
            return localVarFp.getPreset(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateMusicDto} updateMusicDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: number, updateMusicDto: UpdateMusicDto, options?: any): AxiosPromise<MusicDto> {
            return localVarFp.update(id, updateMusicDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MusicApi - object-oriented interface
 * @export
 * @class MusicApi
 * @extends {BaseAPI}
 */
export class MusicApi extends BaseAPI {
    /**
     * 
     * @param {CreateMusicDto} createMusicDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MusicApi
     */
    public create(createMusicDto: CreateMusicDto, options?: RawAxiosRequestConfig) {
        return MusicApiFp(this.configuration).create(createMusicDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MusicApi
     */
    public delete(id: number, options?: RawAxiosRequestConfig) {
        return MusicApiFp(this.configuration).delete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MusicApi
     */
    public get(id: number, options?: RawAxiosRequestConfig) {
        return MusicApiFp(this.configuration).get(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MusicApi
     */
    public getMine(options?: RawAxiosRequestConfig) {
        return MusicApiFp(this.configuration).getMine(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MusicApi
     */
    public getPreset(options?: RawAxiosRequestConfig) {
        return MusicApiFp(this.configuration).getPreset(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateMusicDto} updateMusicDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MusicApi
     */
    public update(id: number, updateMusicDto: UpdateMusicDto, options?: RawAxiosRequestConfig) {
        return MusicApiFp(this.configuration).update(id, updateMusicDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PopupApi - axios parameter creator
 * @export
 */
export const PopupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePopupDto} createPopupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createPopupDto: CreatePopupDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPopupDto' is not null or undefined
            assertParamExists('create', 'createPopupDto', createPopupDto)
            const localVarPath = `/assets/popup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPopupDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('delete', 'id', id)
            const localVarPath = `/assets/popup/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('get', 'id', id)
            const localVarPath = `/assets/popup/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BulkIdDto} bulkIdDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (bulkIdDto: BulkIdDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkIdDto' is not null or undefined
            assertParamExists('getAll', 'bulkIdDto', bulkIdDto)
            const localVarPath = `/assets/popup/getAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkIdDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMine: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/assets/popup/getMine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdatePopupDto} updatePopupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: number, updatePopupDto: UpdatePopupDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updatePopupDto' is not null or undefined
            assertParamExists('update', 'updatePopupDto', updatePopupDto)
            const localVarPath = `/assets/popup/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePopupDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PopupApi - functional programming interface
 * @export
 */
export const PopupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PopupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePopupDto} createPopupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createPopupDto: CreatePopupDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PopupDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createPopupDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PopupApi.create']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PopupApi.delete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PopupDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PopupApi.get']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {BulkIdDto} bulkIdDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(bulkIdDto: BulkIdDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PopupDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(bulkIdDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PopupApi.getAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMine(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PopupDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMine(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PopupApi.getMine']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdatePopupDto} updatePopupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: number, updatePopupDto: UpdatePopupDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PopupDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updatePopupDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PopupApi.update']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * PopupApi - factory interface
 * @export
 */
export const PopupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PopupApiFp(configuration)
    return {
        /**
         * 
         * @param {CreatePopupDto} createPopupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createPopupDto: CreatePopupDto, options?: any): AxiosPromise<PopupDto> {
            return localVarFp.create(createPopupDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: number, options?: any): AxiosPromise<PopupDto> {
            return localVarFp.get(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BulkIdDto} bulkIdDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(bulkIdDto: BulkIdDto, options?: any): AxiosPromise<Array<PopupDto>> {
            return localVarFp.getAll(bulkIdDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMine(options?: any): AxiosPromise<Array<PopupDto>> {
            return localVarFp.getMine(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdatePopupDto} updatePopupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: number, updatePopupDto: UpdatePopupDto, options?: any): AxiosPromise<PopupDto> {
            return localVarFp.update(id, updatePopupDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PopupApi - object-oriented interface
 * @export
 * @class PopupApi
 * @extends {BaseAPI}
 */
export class PopupApi extends BaseAPI {
    /**
     * 
     * @param {CreatePopupDto} createPopupDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PopupApi
     */
    public create(createPopupDto: CreatePopupDto, options?: RawAxiosRequestConfig) {
        return PopupApiFp(this.configuration).create(createPopupDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PopupApi
     */
    public delete(id: number, options?: RawAxiosRequestConfig) {
        return PopupApiFp(this.configuration).delete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PopupApi
     */
    public get(id: number, options?: RawAxiosRequestConfig) {
        return PopupApiFp(this.configuration).get(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BulkIdDto} bulkIdDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PopupApi
     */
    public getAll(bulkIdDto: BulkIdDto, options?: RawAxiosRequestConfig) {
        return PopupApiFp(this.configuration).getAll(bulkIdDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PopupApi
     */
    public getMine(options?: RawAxiosRequestConfig) {
        return PopupApiFp(this.configuration).getMine(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdatePopupDto} updatePopupDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PopupApi
     */
    public update(id: number, updatePopupDto: UpdatePopupDto, options?: RawAxiosRequestConfig) {
        return PopupApiFp(this.configuration).update(id, updatePopupDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SceneApi - axios parameter creator
 * @export
 */
export const SceneApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archive: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('archive', 'id', id)
            const localVarPath = `/scene/archive/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateSceneDto} createSceneDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createSceneDto: CreateSceneDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSceneDto' is not null or undefined
            assertParamExists('create', 'createSceneDto', createSceneDto)
            const localVarPath = `/scene`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSceneDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('delete', 'id', id)
            const localVarPath = `/scene/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('get', 'id', id)
            const localVarPath = `/scene/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEdit: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEdit', 'id', id)
            const localVarPath = `/scene/edit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} archived 
         * @param {number} page 
         * @param {number} perPage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMine: async (archived: boolean, page: number, perPage: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'archived' is not null or undefined
            assertParamExists('getMine', 'archived', archived)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getMine', 'page', page)
            // verify required parameter 'perPage' is not null or undefined
            assertParamExists('getMine', 'perPage', perPage)
            const localVarPath = `/scene/getMine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (archived !== undefined) {
                localVarQueryParameter['archived'] = archived;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: number, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('update', 'body', body)
            const localVarPath = `/scene/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SceneApi - functional programming interface
 * @export
 */
export const SceneApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SceneApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archive(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archive(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SceneApi.archive']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {CreateSceneDto} createSceneDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createSceneDto: CreateSceneDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createSceneDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SceneApi.create']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SceneApi.delete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SceneApi.get']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEdit(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEdit(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SceneApi.getEdit']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} archived 
         * @param {number} page 
         * @param {number} perPage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMine(archived: boolean, page: number, perPage: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResponseOfSceneDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMine(archived, page, perPage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SceneApi.getMine']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: number, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SceneDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SceneApi.update']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * SceneApi - factory interface
 * @export
 */
export const SceneApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SceneApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archive(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.archive(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateSceneDto} createSceneDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createSceneDto: CreateSceneDto, options?: any): AxiosPromise<SceneDto> {
            return localVarFp.create(createSceneDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: number, options?: any): AxiosPromise<SceneResponseDto> {
            return localVarFp.get(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEdit(id: number, options?: any): AxiosPromise<SceneResponseDto> {
            return localVarFp.getEdit(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} archived 
         * @param {number} page 
         * @param {number} perPage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMine(archived: boolean, page: number, perPage: number, options?: any): AxiosPromise<PaginatedResponseOfSceneDto> {
            return localVarFp.getMine(archived, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: number, body: object, options?: any): AxiosPromise<SceneDto> {
            return localVarFp.update(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SceneApi - object-oriented interface
 * @export
 * @class SceneApi
 * @extends {BaseAPI}
 */
export class SceneApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public archive(id: number, options?: RawAxiosRequestConfig) {
        return SceneApiFp(this.configuration).archive(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateSceneDto} createSceneDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public create(createSceneDto: CreateSceneDto, options?: RawAxiosRequestConfig) {
        return SceneApiFp(this.configuration).create(createSceneDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public delete(id: number, options?: RawAxiosRequestConfig) {
        return SceneApiFp(this.configuration).delete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public get(id: number, options?: RawAxiosRequestConfig) {
        return SceneApiFp(this.configuration).get(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public getEdit(id: number, options?: RawAxiosRequestConfig) {
        return SceneApiFp(this.configuration).getEdit(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} archived 
     * @param {number} page 
     * @param {number} perPage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public getMine(archived: boolean, page: number, perPage: number, options?: RawAxiosRequestConfig) {
        return SceneApiFp(this.configuration).getMine(archived, page, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SceneApi
     */
    public update(id: number, body: object, options?: RawAxiosRequestConfig) {
        return SceneApiFp(this.configuration).update(id, body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SoundApi - axios parameter creator
 * @export
 */
export const SoundApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateSoundDto} createSoundDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createSoundDto: CreateSoundDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSoundDto' is not null or undefined
            assertParamExists('create', 'createSoundDto', createSoundDto)
            const localVarPath = `/assets/sound`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSoundDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('delete', 'id', id)
            const localVarPath = `/assets/sound/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('get', 'id', id)
            const localVarPath = `/assets/sound/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMine: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/assets/sound/getMine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreset: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/assets/sound/getPreset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateSoundDto} updateSoundDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: number, updateSoundDto: UpdateSoundDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            // verify required parameter 'updateSoundDto' is not null or undefined
            assertParamExists('update', 'updateSoundDto', updateSoundDto)
            const localVarPath = `/assets/sound/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSoundDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SoundApi - functional programming interface
 * @export
 */
export const SoundApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SoundApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateSoundDto} createSoundDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createSoundDto: CreateSoundDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SoundDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createSoundDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SoundApi.create']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SoundApi.delete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SoundDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SoundApi.get']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMine(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SoundDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMine(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SoundApi.getMine']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreset(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SoundDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreset(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SoundApi.getPreset']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateSoundDto} updateSoundDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: number, updateSoundDto: UpdateSoundDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SoundDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateSoundDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SoundApi.update']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * SoundApi - factory interface
 * @export
 */
export const SoundApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SoundApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateSoundDto} createSoundDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createSoundDto: CreateSoundDto, options?: any): AxiosPromise<SoundDto> {
            return localVarFp.create(createSoundDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: number, options?: any): AxiosPromise<SoundDto> {
            return localVarFp.get(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMine(options?: any): AxiosPromise<Array<SoundDto>> {
            return localVarFp.getMine(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreset(options?: any): AxiosPromise<Array<SoundDto>> {
            return localVarFp.getPreset(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateSoundDto} updateSoundDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: number, updateSoundDto: UpdateSoundDto, options?: any): AxiosPromise<SoundDto> {
            return localVarFp.update(id, updateSoundDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SoundApi - object-oriented interface
 * @export
 * @class SoundApi
 * @extends {BaseAPI}
 */
export class SoundApi extends BaseAPI {
    /**
     * 
     * @param {CreateSoundDto} createSoundDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundApi
     */
    public create(createSoundDto: CreateSoundDto, options?: RawAxiosRequestConfig) {
        return SoundApiFp(this.configuration).create(createSoundDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundApi
     */
    public delete(id: number, options?: RawAxiosRequestConfig) {
        return SoundApiFp(this.configuration).delete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundApi
     */
    public get(id: number, options?: RawAxiosRequestConfig) {
        return SoundApiFp(this.configuration).get(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundApi
     */
    public getMine(options?: RawAxiosRequestConfig) {
        return SoundApiFp(this.configuration).getMine(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundApi
     */
    public getPreset(options?: RawAxiosRequestConfig) {
        return SoundApiFp(this.configuration).getPreset(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateSoundDto} updateSoundDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoundApi
     */
    public update(id: number, updateSoundDto: UpdateSoundDto, options?: RawAxiosRequestConfig) {
        return SoundApiFp(this.configuration).update(id, updateSoundDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ThumbnailApi - axios parameter creator
 * @export
 */
export const ThumbnailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBackgroundThumbnail: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBackgroundThumbnail', 'id', id)
            const localVarPath = `/thumbnail/background/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPopupThumbnail: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPopupThumbnail', 'id', id)
            const localVarPath = `/thumbnail/popup/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} isCustomCharacter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoseThumbnail: async (id: number, isCustomCharacter: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPoseThumbnail', 'id', id)
            // verify required parameter 'isCustomCharacter' is not null or undefined
            assertParamExists('getPoseThumbnail', 'isCustomCharacter', isCustomCharacter)
            const localVarPath = `/thumbnail/pose/{id}/{isCustomCharacter}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"isCustomCharacter"}}`, encodeURIComponent(String(isCustomCharacter)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetThumbnailTypeEnum} type 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThumbnail: async (type: GetThumbnailTypeEnum, key: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getThumbnail', 'type', type)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getThumbnail', 'key', key)
            const localVarPath = `/thumbnail/image/{type}/{key}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {GetThumbnailIconTypeEnum} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThumbnailIcon: async (id: number, type: GetThumbnailIconTypeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getThumbnailIcon', 'id', id)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getThumbnailIcon', 'type', type)
            const localVarPath = `/thumbnail/icon/{id}/{type}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ThumbnailApi - functional programming interface
 * @export
 */
export const ThumbnailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ThumbnailApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBackgroundThumbnail(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackgroundThumbnailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBackgroundThumbnail(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ThumbnailApi.getBackgroundThumbnail']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPopupThumbnail(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThumbnailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPopupThumbnail(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ThumbnailApi.getPopupThumbnail']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} isCustomCharacter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPoseThumbnail(id: number, isCustomCharacter: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThumbnailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPoseThumbnail(id, isCustomCharacter, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ThumbnailApi.getPoseThumbnail']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {GetThumbnailTypeEnum} type 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getThumbnail(type: GetThumbnailTypeEnum, key: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getThumbnail(type, key, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ThumbnailApi.getThumbnail']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {GetThumbnailIconTypeEnum} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getThumbnailIcon(id: number, type: GetThumbnailIconTypeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThumbnailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getThumbnailIcon(id, type, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ThumbnailApi.getThumbnailIcon']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ThumbnailApi - factory interface
 * @export
 */
export const ThumbnailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ThumbnailApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBackgroundThumbnail(id: number, options?: any): AxiosPromise<BackgroundThumbnailDto> {
            return localVarFp.getBackgroundThumbnail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPopupThumbnail(id: number, options?: any): AxiosPromise<ThumbnailDto> {
            return localVarFp.getPopupThumbnail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} isCustomCharacter 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPoseThumbnail(id: number, isCustomCharacter: boolean, options?: any): AxiosPromise<ThumbnailDto> {
            return localVarFp.getPoseThumbnail(id, isCustomCharacter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetThumbnailTypeEnum} type 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThumbnail(type: GetThumbnailTypeEnum, key: string, options?: any): AxiosPromise<void> {
            return localVarFp.getThumbnail(type, key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {GetThumbnailIconTypeEnum} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThumbnailIcon(id: number, type: GetThumbnailIconTypeEnum, options?: any): AxiosPromise<ThumbnailDto> {
            return localVarFp.getThumbnailIcon(id, type, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ThumbnailApi - object-oriented interface
 * @export
 * @class ThumbnailApi
 * @extends {BaseAPI}
 */
export class ThumbnailApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThumbnailApi
     */
    public getBackgroundThumbnail(id: number, options?: RawAxiosRequestConfig) {
        return ThumbnailApiFp(this.configuration).getBackgroundThumbnail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThumbnailApi
     */
    public getPopupThumbnail(id: number, options?: RawAxiosRequestConfig) {
        return ThumbnailApiFp(this.configuration).getPopupThumbnail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {boolean} isCustomCharacter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThumbnailApi
     */
    public getPoseThumbnail(id: number, isCustomCharacter: boolean, options?: RawAxiosRequestConfig) {
        return ThumbnailApiFp(this.configuration).getPoseThumbnail(id, isCustomCharacter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetThumbnailTypeEnum} type 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThumbnailApi
     */
    public getThumbnail(type: GetThumbnailTypeEnum, key: string, options?: RawAxiosRequestConfig) {
        return ThumbnailApiFp(this.configuration).getThumbnail(type, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {GetThumbnailIconTypeEnum} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThumbnailApi
     */
    public getThumbnailIcon(id: number, type: GetThumbnailIconTypeEnum, options?: RawAxiosRequestConfig) {
        return ThumbnailApiFp(this.configuration).getThumbnailIcon(id, type, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetThumbnailTypeEnum = {
    Background: 'background',
    Desk: 'desk',
    Pose: 'pose',
    Popup: 'popup',
    Evidence: 'evidence'
} as const;
export type GetThumbnailTypeEnum = typeof GetThumbnailTypeEnum[keyof typeof GetThumbnailTypeEnum];
/**
 * @export
 */
export const GetThumbnailIconTypeEnum = {
    Popup: 'popup',
    Evidence: 'evidence'
} as const;
export type GetThumbnailIconTypeEnum = typeof GetThumbnailIconTypeEnum[keyof typeof GetThumbnailIconTypeEnum];


