import { Edit } from '@mui/icons-material';
import { Box } from '@mui/material';
import {
  DataGridProps,
  GridActionsCellItem,
  GridColDef,
} from '@mui/x-data-grid';
import { CaseFrame } from '@shared/types';
import { BackgroundDto } from '@web/api/api';
import { useAssetStore } from '@web/store/assets/state';
import { memo, useMemo } from 'react';
import { Thumbnail } from '../../thumbnail/Thumbnail';
import { AssetsDataGrid } from '../AssetsDataGrid';

type BackgroundAssetsProps = {
  onSelectChange?: (selection: number[]) => void;
  onAction?: (action: 'edit', id: number) => void;
} & Partial<DataGridProps>;

export const DataGridBackgrounds = memo(
  ({ onSelectChange, onAction, ...props }: BackgroundAssetsProps) => {
    const {
      background: { user },
    } = useAssetStore();

    const rows = useMemo(() => [...user], [user]);

    const columns = useMemo<GridColDef<BackgroundDto>[]>(
      () => [
        { field: 'id', headerName: 'ID', width: 100, hideSortIcons: false },
        {
          field: 'url',
          headerName: 'Background',
          renderCell: (params) => (
            <RenderThumbnailCell background={params.row} />
          ),
          width: 120,
          sortable: false,
        },
        {
          field: 'name',
          headerName: 'Name',
          width: 240,
        },
        {
          field: 'isWide',
          headerName: 'Wide',
          width: 80,
          type: 'boolean',
        },
        {
          field: 'actions',
          type: 'actions',
          headerName: 'Actions',
          width: 100,
          getActions: (params) => [
            <GridActionsCellItem
              icon={<Edit />}
              label="Edit"
              onClick={() => {
                onAction?.('edit', params.id as number);
              }}
            />,
          ],
        },
      ],
      [onAction],
    );

    return (
      <AssetsDataGrid
        {...props}
        rows={rows}
        columns={columns}
        rowHeight={66.67}
        onSelectChange={onSelectChange}
      />
    );
  },
);

const RenderThumbnailCell = ({ background }: { background: BackgroundDto }) => {
  const frame: CaseFrame = useMemo(
    () => ({ id: -1, text: '', backgroundId: background.id }),
    [background],
  );

  return (
    <Box
      minWidth={100}
      sx={{
        '&:hover': {
          transform: 'scale(2)',
          position: 'absolute',
          zIndex: 9,
          transition: 'transform 0.15s',
        },
      }}
    >
      <Thumbnail frame={frame} />
    </Box>
  );
};
