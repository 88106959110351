import { Box } from '@mui/material';
import { memo } from 'react';
import { usePlayer } from './providers/PlayerProvider';

export const PlayerPreloadBox = memo(() => {
  const { playerLoading } = usePlayer();

  return (
    <Box display="none">
      {playerLoading.preloadedImages.map((image, index) => (
        <img key={index} src={image} alt="" />
      ))}
    </Box>
  );
});
