class EntityService {
  insertEntity(array, newObj, index) {
    newObj['iid'] = this._getAvailableId(array);

    array.splice(index, 0, newObj);
  }

  pushEntity(array, newObj) {
    newObj['iid'] = this._getAvailableId(array);

    array.push(newObj);
  }

  _getAvailableId(array) {
    const usedIds = array.map((m) => m['iid']);

    for (let i = 1; i <= 10000; i++) {
      if (!usedIds.includes(i)) {
        return i;
      }
    }

    return undefined;
  }
}

export default new EntityService();
