import { Autocomplete } from '@mui/material';
import { RenderAutocompleteInput } from '@web/components/common/ui/RenderAutocompleteInput';
import { assetStore } from '@web/store/assets/state';
import { useMemo } from 'react';
import { useSnapshot } from 'valtio';

type SpeechBubblePickerProps = {
  characterId?: number;
  value?: number;
  onChange: (value: number | undefined) => void;
};

export const SpeechBubblePicker = ({
  characterId,
  value,
  onChange,
}: SpeechBubblePickerProps) => {
  const characters = useSnapshot(assetStore.character);
  const character = characters.cache[characterId ?? 0];

  const speechBubbleOptions = useMemo(() => {
    if (!character) {
      return [];
    }

    return [{ id: undefined, name: 'None' }, ...character.speechBubbles];
  }, [character]);

  const selectedOption = useMemo(() => {
    if (!character) {
      return null;
    }

    return (
      character.speechBubbles.find(
        (speechBubble) => speechBubble.id === value,
      ) ?? null
    );
  }, [character, value]);

  return (
    <Autocomplete
      options={speechBubbleOptions}
      value={selectedOption}
      onChange={(e, option) => onChange(option?.id ?? 0)}
      getOptionLabel={(option) => option.name}
      getOptionKey={(option) => option.id ?? 0}
      renderInput={(params) => (
        <RenderAutocompleteInput
          params={params}
          label="Speech Bubble"
          variant="standard"
        />
      )}
      disableClearable={value !== null}
      blurOnSelect
      fullWidth
    />
  );
};
