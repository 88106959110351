import { useEffect, useRef, useState } from 'react';

type useAudioProps = {
  url?: string;
  volume?: number;
};

export const useAudio = ({ url, volume }: useAudioProps) => {
  const howl = useRef<Howl | null>(null);
  const [playing, setPlaying] = useState(false);

  const play = () => {
    if (!url) return;

    if (playing) {
      stop();
    } else {
      howl.current = new Howl({
        src: [url],
        volume: (volume ?? 0) / 100,
        html5: true,
        onend: () => {
          setPlaying(false);
        },
      });

      howl.current.play();
      setPlaying(true);
    }
  };

  const stop = () => {
    howl.current?.stop();
    setPlaying(false);
  };

  useEffect(() => {
    howl.current?.volume((volume ?? 0) / 100);
  }, [volume]);

  useEffect(() => {
    if (playing) {
      stop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  useEffect(() => {
    return () => {
      howl.current?.unload();
    };
  }, []);

  return { play, stop, playing };
};
