import { Box } from '@mui/material';
import { SceneProjectDto } from '@web/api/api';
import { ApiClient } from '@web/api/api-client';
import { Player } from '@web/components/player/Player';
import { PlayerMetaProvider } from '@web/components/player/providers/PlayerMetaProvider';
import { PlayerActionsType } from '@web/components/player/providers/PlayerProvider';
import { maxContainerWidth } from '@web/layouts/BaseLayout';
import { settingsStore } from '@web/store/settings';
import { memo, useEffect, useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { proxy } from 'valtio';
import { useProxy } from 'valtio/utils';

const state = proxy({
  project: undefined as SceneProjectDto | undefined,
});

const RecordPage = () => {
  const [searchParams] = useSearchParams();
  const width = searchParams.get('width');

  useEffect(() => {
    document.title = 'Recording Objection';

    const volumes = searchParams.get('v');

    if (volumes) {
      const [master, music, sound, blip] = volumes
        .split(',')
        .map((v) => parseInt(v.trim()));

      settingsStore.audio.volume.master = master;
      settingsStore.audio.volume.music = music;
      settingsStore.audio.volume.sound = sound;
      settingsStore.audio.volume.blip = blip;
    }
  }, [searchParams]);

  return (
    <Box maxWidth={!width ? maxContainerWidth : 'inherit'}>
      <ObjectionPlayer />
    </Box>
  );
};

const ObjectionPlayer = memo(() => {
  const { id } = useParams();
  const { project } = useProxy(state);

  const ref = useRef<PlayerActionsType>(null);

  useEffect(() => {
    if (!id) return;

    fetchObjection(parseInt(id));
  }, [id]);

  useEffect(() => {
    if (!project) return;

    ref.current?.reset();

    setTimeout(() => {
      ref.current?.init(project);
    }, 100);
  }, [project]);

  if (!id) {
    return null;
  }

  return (
    <PlayerMetaProvider value={{ isRecording: true }}>
      <Player
        id="recording_target"
        ref={ref}
        width={project?.options.width}
        aspectRatio={project?.options.aspectRatio}
        hideAutoplay
        hideVolume
      />
    </PlayerMetaProvider>
  );
});

const fetchObjection = async (id: number) => {
  try {
    const response = await ApiClient.scene.get(id);

    state.project = response.data.data;
  } catch (error) {
    console.error(error);
  }
};

export default RecordPage;
