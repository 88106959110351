import { Box, styled } from '@mui/material';

type ViewportProps = {
  children: React.ReactNode;
  fullWidth?: boolean;
  left?: number;
};

export const Viewport = ({ children, fullWidth, left }: ViewportProps) => {
  return (
    <StyledViewport
      style={{
        width: fullWidth ? '100%' : 'auto',
        left: !fullWidth && left ? `${left}%` : 0,
        transform: !fullWidth && left ? `translateX(-${left}%)` : 'unset',
      }}
    >
      {children}
    </StyledViewport>
  );
};

const StyledViewport = styled(Box)({
  position: 'absolute',
  height: '100%',
  top: 0,
  userSelect: 'none',
  pointerEvents: 'none',
});
