import { useState } from 'react';
import { GameInvestigationState, GameState } from '../types/state';

export type InvestigationPlayerType = {
  state: GameInvestigationState;
  actions: {
    reset: () => void;
  };
};

const defaultState: GameInvestigationState = {
  locationState: {},
};

export const useInvestigationPlayer = (
  gameState: GameState,
): InvestigationPlayerType => {
  const [state, setState] = useState<GameInvestigationState>(defaultState);

  const reset = () => {
    setState(defaultState);
  };

  const actions: InvestigationPlayerType['actions'] = { reset };

  return { state, actions };
};
