import { keyframes } from '@emotion/react';
import { useMemo } from 'react';
import { ShakeType } from '../types';

// const generateShakeKeyframes = (intensity: number, unit = 'px') => keyframes`
//   10%, 90% {
//     transform: translate(-${intensity}${unit}, -${intensity}${unit});
//   }
//   20%, 80% {
//     transform: translate(${intensity}${unit}, ${intensity}${unit});
//   }
//   30%, 50%, 70% {
//     transform: translate(-${intensity}${unit}, ${intensity}${unit});
//   }
//   40%, 60% {
//     transform: translate(${intensity}${unit}, -${intensity}${unit});
//   }
//   0%, 100% {
//     transform: translate(0, 0);
//   }
// `;

const generateShakeKeyframes = (intensity: number, unit = 'px') => keyframes`
  2% {
    transform: translate(0${unit}, ${intensity}${unit});
  }

  4% {
    transform: translate(0${unit}, ${intensity}${unit});
  }

  6% {
    transform: translate(${intensity}${unit}, ${intensity}${unit});
  }

  8% {
    transform: translate(0${unit}, 0${unit});
  }

  10% {
    transform: translate(${intensity}${unit}, 0${unit});
  }

  12% {
    transform: translate(${intensity}${unit}, ${intensity}${unit});
  }

  14% {
    transform: translate(0${unit}, 0${unit});
  }

  16% {
    transform: translate(${intensity}${unit}, 0${unit});
  }

  18% {
    transform: translate(0${unit}, ${intensity}${unit});
  }

  20% {
    transform: translate(0${unit}, ${intensity}${unit});
  }

  22% {
    transform: translate(${intensity}${unit}, ${intensity}${unit});
  }

  24% {
    transform: translate(0${unit}, ${intensity}${unit});
  }

  26% {
    transform: translate(0${unit}, 0${unit});
  }

  28% {
    transform: translate(${intensity}${unit}, 0${unit});
  }

  30% {
    transform: translate(${intensity}${unit}, 0${unit});
  }

  32% {
    transform: translate(0${unit}, ${intensity}${unit});
  }

  34% {
    transform: translate(0${unit}, ${intensity}${unit});
  }

  36% {
    transform: translate(0${unit}, 0${unit});
  }

  38% {
    transform: translate(${intensity}${unit}, ${intensity}${unit});
  }

  40% {
    transform: translate(${intensity}${unit}, 0${unit});
  }

  42% {
    transform: translate(0${unit}, ${intensity}${unit});
  }

  44% {
    transform: translate(0${unit}, ${intensity}${unit});
  }

  46% {
    transform: translate(0${unit}, 0${unit});
  }

  48% {
    transform: translate(0${unit}, ${intensity}${unit});
  }

  50% {
    transform: translate(0${unit}, ${intensity}${unit});
  }

  52% {
    transform: translate(${intensity}${unit}, ${intensity}${unit});
  }

  54% {
    transform: translate(0${unit}, 0${unit});
  }

  56% {
    transform: translate(0${unit}, 0${unit});
  }

  58% {
    transform: translate(0${unit}, 0${unit});
  }

  60% {
    transform: translate(0${unit}, ${intensity}${unit});
  }

  62% {
    transform: translate(0${unit}, 0${unit});
  }

  64% {
    transform: translate(${intensity}${unit}, 0${unit});
  }

  66% {
    transform: translate(0${unit}, ${intensity}${unit});
  }

  68% {
    transform: translate(0${unit}, 0${unit});
  }

  70% {
    transform: translate(${intensity}${unit}, ${intensity}${unit});
  }

  72% {
    transform: translate(${intensity}${unit}, 0${unit});
  }

  74% {
    transform: translate(0${unit}, ${intensity}${unit});
  }

  76% {
    transform: translate(0${unit}, ${intensity}${unit});
  }

  78% {
    transform: translate(${intensity}${unit}, 0${unit});
  }

  80% {
    transform: translate(${intensity}${unit}, 0${unit});
  }

  82% {
    transform: translate(0${unit}, 0${unit});
  }

  84% {
    transform: translate(${intensity}${unit}, 0${unit});
  }

  86% {
    transform: translate(0${unit}, 0${unit});
  }

  88% {
    transform: translate(0${unit}, 0${unit});
  }

  90% {
    transform: translate(${intensity}${unit}, ${intensity}${unit});
  }

  92% {
    transform: translate(${intensity}${unit}, ${intensity}${unit});
  }

  94% {
    transform: translate(${intensity}${unit}, 0${unit});
  }

  96% {
    transform: translate(${intensity}${unit}, 0${unit});
  }

  98% {
    transform: translate(0${unit}, 0${unit});
  }

  0%,
  100% {
    transform: translate(0, 0);
  }
`;

const shakeBubbleKeyFrames = keyframes`
  10%, 90% {
    transform: translate(-50%, -52%) translate3d(-0.5%, 0, 0);
  }
  20%, 80% {
    transform: translate(-50%, -52%) translate3d(1%, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate(-50%, -52%) translate3d(-2%, 0, 0);
  }
  40%, 60% {
    transform: translate(-50%, -52%) translate3d(2%, 0, 0);
  }
`;

const evidenceIconScaleKeyframes = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

export const shakeBubble = {
  animation: `${shakeBubbleKeyFrames}`,
  animationIterationCount: 2,
  animationTimingFunction: 'cubic-bezier(0.36, 0.07, 0.19, 0.97)',
  animationDuration: '0.135s',
};

export const evidenceIconScale = {
  animation: `${evidenceIconScaleKeyframes}`,
  animationDuration: '0.2s',
};

// TODO: check if this adds a lot of animations to the page
export const usePlayerStyles = (size: number) => {
  const shake = useMemo<Record<ShakeType, React.CSSProperties>>(() => {
    const shakeSmallKeyframes = generateShakeKeyframes(2 * size);
    const shakeMediumKeyframes = generateShakeKeyframes(4 * size);
    const shakeLargeKeyframes = generateShakeKeyframes(6 * size);

    return {
      small: {
        animation: `${shakeSmallKeyframes}`,
        animationIterationCount: 'infinite',
        animationDuration: '0.1s',
        animationTimingFunction: 'ease-in-out',
      },
      medium: {
        animation: `${shakeMediumKeyframes}`,
        animationIterationCount: 'infinite',
        animationDuration: '0.1s',
        animationTimingFunction: 'ease-in-out',
      },
      large: {
        animation: `${shakeLargeKeyframes}`,
        animationIterationCount: 'infinite',
        animationDuration: '0.1s',
        animationTimingFunction: 'ease-in-out',
      },
    };
  }, [size]);

  return {
    shake,
  };
};
