import {
  AnyGroup,
  CaseFrame,
  Conversation,
  Examine,
  Location,
} from '@shared/types';
import type { FramesTarget } from '@web/types/project';

export type GameState = {
  frameIndex: number;
  pressIndex?: number;
  presentIndex?: number;
  investigationMenu?: boolean;
  resumeExamine?: boolean;
  health?: number;
  healthFlash?: number;
  isEnded?: boolean;
  previousFrame?: CaseFrame;
} & FramesTarget;

export type GameDerivedState = {
  frame?: CaseFrame;
  group?: AnyGroup;
  location?: Location;
  conversation?: Conversation;
  examine?: Examine;
};

export type GameInvestigationState = {
  lastPresentedEvidenceId?: string;
  locationState: Record<string, LocationState>;
};

export type LocationState = {
  characterId?: string;
  poseId?: string;
  pairId?: string;
  pairPoseId?: string;
  musicId?: number;
  visited?: boolean;
  visitedConversationIds?: string[];
  visitedExamineIds?: string[];
  completion: unknown; // TODO
};

export type GameData = {
  messages: string[];
  gameoverGroupId: string;
  variables: Record<string, string | number>;
};

export enum GameCategory {
  CrossExaminationCounsel = 'counsel',
  CrossExaminationEvidenceFailure = 'failure',
  InvestigationPresentFailure = 'presentFailure',
  InvestigationExamineFailure = 'examineFailure',
  InvestigationCompletion = 'completion',
}

export type EvidenceState = {
  id: number;
  isIcon: boolean;
};
