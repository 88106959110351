import { Box } from '@mui/material';
import { useMemo } from 'react';
import { NextButtonIcon } from '../../ui/NextButton';
import { DialogueBox } from '../DialogueBox';
import { NameplateProps } from '../Nameplate';
import { NextButton, continueButtonAnimation } from '../NextButton';
import { DialogueBoxTextContainerProps } from '../types';

export const DSDialogueBox = () => {
  const nameplateProps = useMemo<NameplateProps>(
    () => ({
      fontSize: 26,
      url: '/Images/UI/Chatbox/DS/textbox.png',
      top: 61.7,
      right: 39.7,
      fontFamily: 'DS-nameplate',
      fontUrl: '/Fonts/DS-nameplate.ttf',
      replaceBox: true,
      letterSpacing: 0.05,
    }),
    [],
  );

  const textProps = useMemo<DialogueBoxTextContainerProps>(
    () => ({
      fontSize: 50,
      fontFamily: 'DS-body',
      width: 92,
      height: 3.6,
      left: 4,
      top: 70,
      fontUrl: '/Fonts/DS.ttf',
      lineHeight: 1.2,
    }),
    [],
  );

  return (
    <DialogueBox
      url="/Images/UI/Chatbox/DS/textbox-nameless.png"
      nameplate={nameplateProps}
      text={textProps}
      letterSpacing={0.05}
      opacity={0.85}
      NextButton={<DSDialogueBoxNextButton />}
    />
  );
};

const DSDialogueBoxNextButton = () => {
  const defaultFontSize = 2.75;

  return (
    <NextButton>
      {({ onClick, showNextButton, size }) => (
        <NextButtonIcon
          display={showNextButton ? 'flex' : 'none'}
          style={{
            display: showNextButton ? 'flex' : 'none',
            top: '87%',
            left: '96.25%',
          }}
          onClick={onClick}
        >
          <Box
            component="span"
            sx={{
              animation: `${continueButtonAnimation} 0.3s ease-in-out infinite both`,
            }}
            style={{
              fontFamily: 'DS-body',
              fontSize: `${defaultFontSize * size}em`,
              color: '#ffffff',
            }}
          >
            ►
          </Box>
        </NextButtonIcon>
      )}
    </NextButton>
  );
};
