export const getErrorMessage = (error: any) => {
  if (error.message === 'Network Error') {
    return 'Unable to connect to the server. Please check your internet connection.';
  }

  const statusCode = error.response?.status;

  switch (statusCode) {
    case 401:
    case 403:
      return 'You are not authorized to perform this action.';
    case 404:
      return 'The requested resource was not found.';
    case 429:
      return 'Too many requests. Please try again later.';
    case 503:
      return 'Service unavailable. Please try again later.';
  }

  if (error.response?.data?.message) {
    if (Array.isArray(error.response.data.message)) {
      return error.response.data.message[0];
    }

    return error.response.data.message;
  }

  return 'Something went wrong.';
};
