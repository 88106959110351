import { Container } from '@web/components/player/ui/Container';
import { useMakerStore } from '@web/store/maker/state';
import { forwardRef } from 'react';

type ThumbnailContainerProps = {
  children: React.ReactNode;
  onClick?: () => void;
};

export const ThumbnailContainer = forwardRef(
  ({ children, onClick }: ThumbnailContainerProps, ref) => {
    const { aspectRatio } = useMakerStore();

    const handleClick = () => {
      onClick?.();
    };

    // TODO: make it work with project's width and height setting
    return (
      <Container
        ref={ref}
        aspectRatio={aspectRatio}
        onClick={handleClick}
        sx={{ cursor: onClick ? 'pointer' : 'inherit' }}
      >
        {children}
      </Container>
    );
  },
);
