import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { Image } from '../../common/ui/Image';
import { ZIndex } from '../utils/constants';

export type PopupProps = {
  url: string;
  size: number;
  x: number;
  y: number;
  center?: boolean;
  sx?: React.CSSProperties;
};

export const Popup = ({ url, size, x, y, center, sx }: PopupProps) => {
  return (
    <PopupContainer
      style={{
        top: !center ? `${y}%` : `50%`,
        left: !center ? `${x}%` : `50%`,
        transform: center ? 'translate(-50%, -50%)' : 'unset',
        width: `${size}%`,
      }}
    >
      <PopupStyled
        src={url}
        alt="Popup"
        style={{
          width: `100%`,
        }}
        sx={sx}
      />
    </PopupContainer>
  );
};

const PopupContainer = styled(Box)({
  position: 'absolute',
  zIndex: ZIndex.DialogueBox,
  pointerEvents: 'none',
  display: 'flex',
});

const PopupStyled = styled(Image)(({ theme }) => ({
  maxHeight: '100%',
  userSelect: 'none',
  pointerEvents: 'none',
}));
