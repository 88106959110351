import { Effect } from '@shared/types';

export const getFilterCSS = (effect: Effect) => {
  switch (effect.type) {
    case 'invert':
      return `invert(${effect.amount}%)`;
    case 'grayscale':
      return `grayscale(${effect.amount}%)`;
    case 'sepia':
      return `sepia(${effect.amount}%)`;
    case 'hue-rotate':
      return `hue-rotate(${effect.amount}deg)`;
    default:
      return '';
  }
};
