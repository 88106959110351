/* eslint-disable @typescript-eslint/no-explicit-any */
import { CharacterDto } from '@web/api/api';
import { ApiClient } from '@web/api/api-client';
import {
  AssetDtoTypeMap,
  CreateAssetDtoTypeMap,
  GetAllAssetsType,
  UpdateAssetDtoTypeMap,
} from '.';
import { AssetType, assetStore } from './state';

export const assetActions = {
  getAsset: async <T extends AssetType>(type: T, id: number, user = false) => {
    const res = await ApiClient.assets[type].get(id);

    const asset = res.data as AssetDtoTypeMap[T];

    assetActions.cacheAssets(type, [asset]);

    if (type === 'character') {
      await assetActions.loadExtraAssets(type, asset as any);
    }

    if (user) {
      assetStore[type].user = assetStore[type].user.filter(
        (f) => f.id !== id,
      ) as any[];
      assetStore[type].user.push(asset as any);
    }

    return asset;
  },
  getAllAssets: async <T extends GetAllAssetsType>(
    type: T,
    ids: number[],
    force?: boolean,
    abortController?: AbortController,
  ) => {
    const fetchIds = ids
      .filter((id) => force || !assetStore[type].cache[id])
      .slice(0, 200);

    if (fetchIds.length === 0) return [];

    const res = await ApiClient.assets[type].getAll(
      { ids: fetchIds },
      { signal: abortController?.signal },
    );

    if (abortController?.signal.aborted) return [];

    const assets = res.data as AssetDtoTypeMap[T][];

    assetActions.cacheAssets(type, assets as AssetDtoTypeMap[T][]);

    if (type === 'character') {
      await assetActions.loadExtraAssets(type, assets as any);
    }

    return assets;
  },
  addAsset: async <T extends AssetType>(
    type: T,
    asset: CreateAssetDtoTypeMap[T],
  ) => {
    const res = await ApiClient.assets[type].create(asset as any);

    const newAsset = res.data as AssetDtoTypeMap[T];

    assetStore[type].user.push(newAsset as any);
    assetActions.cacheAssets(type, [newAsset]);

    return newAsset;
  },
  updateAsset: async <T extends AssetType>(
    type: T,
    id: number,
    asset: UpdateAssetDtoTypeMap[T],
  ) => {
    const res = await ApiClient.assets[type].update(id, asset as any);

    const updatedAsset = res.data as AssetDtoTypeMap[T];

    assetActions.updateAssetLocally(type, id, updatedAsset);

    return updatedAsset;
  },
  updateAssetLocally: <T extends AssetType>(
    type: T,
    id: number,
    asset: AssetDtoTypeMap[T],
  ) => {
    assetStore[type].user = assetStore[type].user.map((f) =>
      f.id === id ? asset : f,
    ) as any[];

    assetActions.cacheAssets(type, [asset]);
  },
  updateUserAssetLocally: <T extends AssetType>(
    type: T,
    id: number,
    data: Partial<AssetDtoTypeMap[T]>,
  ) => {
    const asset = assetStore[type].user.find((f) => f.id === id);

    if (!asset) return;

    const updatedAsset = { ...asset, ...data };

    assetActions.updateAssetLocally(type, id, updatedAsset as any);
  },
  deleteAssets: async (type: AssetType, ids: number[]) => {
    const deletedIds: number[] = [];

    for (const id of ids) {
      try {
        await ApiClient.assets[type].delete(id);

        deletedIds.push(id);
      } catch (e) {
        console.error(`Failed to delete ${type} ${id}`);
      }

      await new Promise((resolve) => setTimeout(resolve, 100));
    }

    assetStore[type].user = assetStore[type].user.filter(
      (f) => !deletedIds.includes(f.id),
    ) as any[];

    deletedIds.forEach((id) => {
      delete assetStore[type].cache[id];
    });
  },
  loadPresetAssetsType: async (type: AssetType, force = false) => {
    try {
      if (type === 'evidence' || type === 'popup') return;

      if (assetStore[type].presetLoaded && !force) return;

      const res = await ApiClient.assets[type].getPreset();

      assetActions.cacheAssets(type, res.data as any);

      assetStore[type].preset = res.data as any;
      assetStore[type].presetLoaded = true;
    } catch (e) {
      throw Error(`Failed to load ${type} assets`);
    }
  },
  loadUserAssetsType: async (type: AssetType) => {
    try {
      const res = await ApiClient.assets[type].getMine();

      assetActions.cacheAssets(type, res.data ?? []);

      assetStore[type].user = res.data ?? [];

      if (type === 'character') {
        await assetActions.loadExtraAssets(type, res.data);
      }
    } catch (e) {
      throw Error(`Failed to load ${type} assets`);
    }
  },
  loadExtraAssets: async <T extends AssetType>(
    type: 'character',
    assets: AssetDtoTypeMap[T][],
  ) => {
    try {
      switch (type) {
        case 'character': {
          const characters = assets as CharacterDto[];
          const backgrounds = characters
            .filter(
              (f) =>
                f.backgroundId && !assetStore.background.cache[f.backgroundId],
            )
            .map((m) => m.backgroundId);

          if (backgrounds.length === 0) return;

          const backgroundRes = await ApiClient.assets.background.getAll({
            ids: backgrounds,
          });

          assetActions.cacheAssets('background', backgroundRes.data);
        }
      }
    } catch (e) {
      console.error(`Failed to load extra assets for ${type}`);
    }
  },
  loadPresetAssets: async () => {
    await Promise.all([
      assetActions.loadPresetAssetsType('sound'),
      assetActions.loadPresetAssetsType('music'),
      assetActions.loadPresetAssetsType('background'),
      assetActions.loadPresetAssetsType('character'),
    ]);
  },
  loadUserAssets: async () => {
    await Promise.all([
      assetActions.loadUserAssetsType('sound'),
      assetActions.loadUserAssetsType('music'),
      assetActions.loadUserAssetsType('background'),
      assetActions.loadUserAssetsType('character'),
      assetActions.loadUserAssetsType('evidence'),
      assetActions.loadUserAssetsType('popup'),
    ]);
  },
  cacheAssets<T extends AssetType>(type: T, assets: AssetDtoTypeMap[T][]) {
    const existingCache = assetStore[type].cache;
    const newCache = assets.reduce(
      (acc, asset) => {
        acc[asset.id] = asset;
        return acc;
      },
      {} as Record<number, AssetDtoTypeMap[T]>,
    );

    assetStore[type].cache = { ...existingCache, ...newCache };
  },
};
