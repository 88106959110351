import { Box } from '@mui/material';
import { Character_Side } from '@shared/types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FrameTextUtils, QueueItem, TagType } from '../maker/utils/frameText';
import { ApolloJusticeDialogueBox } from './dialogue/preset/ApolloJusticeDialogueBox';
import { ClassicDialogueBox } from './dialogue/preset/ClassicDialogueBox';
import { DSDialogueBox } from './dialogue/preset/DSDialogueBox';
import { TheGreatAceAttorneyDialogueBox } from './dialogue/preset/TheGreatAceAttorneyDialogueBox copy';
import { TrilogyDialogueBox } from './dialogue/preset/TrilogyDialogueBox';
import { useDialogueBoxTags } from './hooks/useDialogueBoxTags';
import { usePlayerDialogueBox } from './hooks/usePlayerDialogueBox';
import { PlayerDialogueBoxContext } from './providers/PlayerDialogueBoxContext';
import { usePlayer } from './providers/PlayerProvider';

export const PlayerDialogueBox = () => {
  const {
    actions: { nextStep, update: updatePlayerState },
    frame: {
      text,
      shouldClearDialogue,
      shouldPlayPoseAnimation,
      shouldWaitForPoseAnimation,
      character,
    },
    state: { showDialoguebox },
    step,
    playerDefaults,
  } = usePlayer();

  const dialogue = usePlayerDialogueBox();
  const { runTag } = useDialogueBoxTags({ dialogue });

  const dialogueBoxId = playerDefaults.chatbox ?? 0;

  const defaultColor = useMemo(() => {
    switch (dialogueBoxId) {
      case 2:
        return '#DCDCDC';
      case 3:
        return '#E0E0E0';
      default:
        return '#ffffff';
    }
  }, [dialogueBoxId]);

  const processQueueItem = useCallback(
    async (item: QueueItem) => {
      const tag = FrameTextUtils.getTagType(item);

      switch (tag.type) {
        case TagType.Text:
          updatePlayerState({ showDialoguebox: true });

          await dialogue.typeDialogue(
            item.contents,
            playerDefaults.textSpeed,
            defaultColor,
          );
          break;
        case TagType.ColorText:
          updatePlayerState({ showDialoguebox: true });

          await dialogue.typeDialogue(
            item.contents,
            playerDefaults.textSpeed,
            getColor(String(tag.param), dialogueBoxId) ?? defaultColor,
          );
          break;
        default:
          await runTag(tag, item);
          break;
      }
    },
    [
      defaultColor,
      dialogue,
      dialogueBoxId,
      playerDefaults.textSpeed,
      runTag,
      updatePlayerState,
    ],
  );

  const dialogueStep = useCallback(async () => {
    dialogue.newDialogue(shouldClearDialogue);

    const queue = FrameTextUtils.parseText(text ?? '');

    for (const item of queue) {
      await processQueueItem(item);
    }

    nextStep();
  }, [dialogue, nextStep, processQueueItem, shouldClearDialogue, text]);

  useEffect(() => {
    // why twice?
    if (step === undefined) return;

    if (
      step === 'speech_bubble' ||
      step === 'transition' ||
      (step === 'character' &&
        shouldPlayPoseAnimation &&
        shouldWaitForPoseAnimation) ||
      (step === 'character' && character?.side === Character_Side.Gallery)
    ) {
      updatePlayerState({ showDialoguebox: false });
    }

    if (step === 'dialogue') {
      dialogueStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  return (
    <PlayerDialogueBoxContext.Provider value={dialogue}>
      <Box display={showDialoguebox ? 'inherit' : 'none'}>
        <DialogueBox id={dialogueBoxId} />
      </Box>
    </PlayerDialogueBoxContext.Provider>
  );
};

const DialogueBox = React.memo(({ id }: { id: number }) => {
  switch (id) {
    case 0:
      return <ClassicDialogueBox />;
    case 1:
      return <TrilogyDialogueBox />;
    case 2:
      return <ApolloJusticeDialogueBox />;
    case 3:
      return <TheGreatAceAttorneyDialogueBox />;
    case 4:
      return <DSDialogueBox />;
    default:
      return null;
  }
});

const getColor = (color: string, dialogueBoxId: number) => {
  if (color.length > 1) return color;

  switch (color.toLowerCase()) {
    case 'r':
      switch (dialogueBoxId) {
        case 3:
          return '#FF6D18';
        default:
          return '#F77337';
      }
    case 'g':
      switch (dialogueBoxId) {
        case 3:
          return '#80FB0A';
        default:
          return '#00F61C';
      }
    case 'b':
      switch (dialogueBoxId) {
        case 3:
          return '#18D7FA';
        default:
          return '#6BC7F6';
      }
    default:
      return undefined;
  }
};
