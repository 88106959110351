import { MeResponseDto } from '@web/api/api';

export interface AuthContextType {
  user: MeResponseDto | null;
  loggedIn: boolean;
  login: (credentials: { email: string; password: string }) => Promise<void>;
  register: (credentials: {
    email: string;
    password: string;
    captcha: string;
  }) => Promise<void>;
  me: () => Promise<void>;
  discordLogin: (code: string) => Promise<void>;
  googleLogin: (code: string) => Promise<void>;
  logout: () => Promise<void>;
  clear: () => void;
}

let authContext: AuthContextType | null = null;

export const setAuthContext = (context: AuthContextType) => {
  authContext = context;
};

export const handleTokenRefreshFailure = () => {
  if (authContext) {
    authContext.clear();
  } else {
    console.error(
      'AuthContext not set. Unable to handle token refresh failure.',
    );
  }
};
