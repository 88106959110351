import { Build } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';
import { ApiClient } from '@web/api/api-client';
import { useAuth } from '@web/providers/auth/AuthProvider';
import { rootStore, useRootStore } from '@web/store/root/state';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const MaintenanceModeDialog = () => {
  const {
    dialogs: { maintenance },
  } = useRootStore();

  return (
    <Dialog transitionDuration={0} open={maintenance} fullScreen>
      <DialogContent sx={{ p: { xs: 1, sm: 1.5 } }}>
        <Stack
          spacing={2}
          textAlign="center"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Build sx={{ fontSize: 75, textAlign: 'center' }} />

          <Typography variant="h4">Maintenance Mode</Typography>

          <Typography>
            The application is currently in maintenance mode, Please try again
            later.
          </Typography>

          <Stack direction="row" spacing={1} pt={2}>
            <RefreshButton />
            <LoginLogoutButton />
          </Stack>

          <AdminButton />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

const LoginLogoutButton = () => {
  const { loggedIn, logout } = useAuth();

  const handleClickLogin = async () => {
    rootStore.dialogs.login = true;
  };

  if (!loggedIn) {
    return (
      <Button color="inherit" variant="contained" onClick={handleClickLogin}>
        Login
      </Button>
    );
  } else {
    return (
      <Button color="inherit" variant="contained" onClick={logout}>
        Logout
      </Button>
    );
  }
};

const RefreshButton = () => {
  const [loading, setLoading] = useState(false);

  const fetchMaintenance = async () => {
    try {
      setLoading(true);

      const res = await ApiClient.maintenance.maintenanceMode();

      if (!res.data.maintenanceMode) {
        rootStore.dialogs.maintenance = false;
      } else {
        setTimeout(() => setLoading(false), 2000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Button
      color="inherit"
      variant="contained"
      onClick={fetchMaintenance}
      disabled={loading}
    >
      Refresh
    </Button>
  );
};

const AdminButton = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  if (!user || !user.admin) {
    return null;
  }

  return (
    <Button
      color="inherit"
      variant="contained"
      onClick={() => {
        navigate('/admin');

        rootStore.dialogs.maintenance = false;
      }}
    >
      Admin
    </Button>
  );
};
