import {
  MenuList as MuiMenuList,
  MenuListProps as MuiMenuListProps,
  PaperProps,
  PopperProps,
} from '@mui/material';
import * as React from 'react';
import { Popper } from './Popper';

type MenuListProps = {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  placement: PopperProps['placement'];
  popperProps?: Partial<PopperProps>;
  paperProps?: PaperProps;
} & MuiMenuListProps;

export const MenuList = ({
  anchorEl,
  open,
  onClose,
  children,
  placement,
  popperProps,
  paperProps,
  ...props
}: MenuListProps) => {
  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      onClose();
    } else if (event.key === 'Escape') {
      onClose();
    }
  };

  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorEl?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Popper
      {...popperProps}
      placement={placement}
      paperProps={paperProps}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <MuiMenuList
        // autoFocusItem={open}
        onKeyDown={handleListKeyDown}
        {...props}
      >
        {children}
      </MuiMenuList>
    </Popper>
  );
};
