import { Box, BoxProps, styled } from '@mui/material';
import React from 'react';

type ContainerProps = {
  children: React.ReactNode;
  maxWidth?: number;
  aspectRatio: number;
} & Omit<BoxProps, 'maxWidth' | 'aspectRatio'>;

export const Container = React.forwardRef(
  (
    { children, maxWidth, aspectRatio, style, ...props }: ContainerProps,
    ref: React.Ref<unknown>,
  ) => {
    return (
      <ContainerStyled
        ref={ref}
        {...props}
        style={{
          ...style,
          maxWidth: maxWidth ? maxWidth : '100%',
          aspectRatio: aspectRatio,
        }}
      >
        {children}
      </ContainerStyled>
    );
  },
);

const ContainerStyled = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  overflow: 'hidden',
  backgroundColor: 'white',
  '& *': { pointerEvents: 'none', userSelect: 'none' },
}));
