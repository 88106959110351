import { Autocomplete } from '@mui/material';
import { RenderAutocompleteInput } from '@web/components/common/ui/RenderAutocompleteInput';
import { assetStore } from '@web/store/assets/state';
import { useMemo } from 'react';
import { useSnapshot } from 'valtio';

type PosePickerProps = {
  characterId?: number;
  value: number;
  onChange: (value: number) => void;
};

export const PosePicker = ({
  characterId,
  value,
  onChange,
}: PosePickerProps) => {
  const characters = useSnapshot(assetStore.character);
  const character = characters.cache[characterId ?? 0];

  const poseOptions = useMemo(() => {
    if (!character) {
      return [];
    }

    return character.poses;
  }, [character]);

  const selectedOption = useMemo(() => {
    if (!character) {
      return null;
    }

    return character.poses.find((pose) => pose.id === value) ?? null;
  }, [character, value]);

  return (
    <Autocomplete
      options={poseOptions}
      getOptionLabel={(option) => option.name}
      getOptionKey={(option) => option.id}
      value={selectedOption}
      onChange={(e, option) => onChange(option?.id ?? 0)}
      renderInput={(params) => (
        <RenderAutocompleteInput
          params={params}
          placeholder="Pose"
          variant="outlined"
        />
      )}
      size="small"
      disabled={!character}
      disableClearable={value !== null}
      blurOnSelect
      fullWidth
    />
  );
};
