import { useAssetStore } from '@web/store/assets/state';
import { Frame } from '@web/types/project';
import { useMemo } from 'react';

export const usePopup = (frame: Frame | undefined) => {
  const {
    popup: { cache },
  } = useAssetStore();

  const popup = useMemo(() => {
    if (!frame || !frame.popupId) return undefined;

    return cache[frame.popupId];
  }, [frame, cache]);

  return popup;
};
