import { CircularProgress, Dialog, Stack } from '@mui/material';
import { ProjectMigration } from '@shared/migration';
import { ProjectType } from '@shared/types';
import { CaseProjectDto, SceneProjectDto } from '@web/api/api';
import { makerActions } from '@web/store/maker/actions';
import { makerStore } from '@web/store/maker/state';
import { projectUtils } from '@web/utils/project';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';

export const LoadDialog = React.memo(() => {
  const [loading, setLoading] = useState(false);

  const ref = React.useRef<HTMLInputElement>(null);
  const snapshot = useSnapshot(makerStore.dialogs);

  const handleClose = useCallback(() => {
    if (loading) return;

    makerStore.dialogs.loadProject = false;
  }, [loading]);

  const migrateProject = useCallback(
    async (saveData: SceneProjectDto | CaseProjectDto) => {
      setLoading(true);

      try {
        return ProjectMigration.migrate(
          saveData,
          (saveData.type ?? 'scene') as ProjectType,
        ) as CaseProjectDto | SceneProjectDto;
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const handleLoad = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];

      if (!file) return;

      const reader = new FileReader();

      reader.onload = async (e) => {
        const data = e.target?.result;

        if (!data) return;

        try {
          const saveData = projectUtils.loadProject(data as string);

          if (!saveData) {
            throw new Error('Invalid project file');
          }

          makerActions.loadProject(
            saveData,
            makerStore.title,
            makerStore.editId,
          );
        } catch (e) {
          enqueueSnackbar('Invalid project file', { variant: 'error' });

          console.error(e);
        }

        handleClose();
      };

      reader.onerror = (e) => {
        console.error(e);

        handleClose();
      };

      reader.readAsText(file);

      ref.current!.value = '';
    },
    [handleClose],
  );

  useEffect(() => {
    if (snapshot.loadProject) {
      ref.current?.click();
    }
  }, [snapshot.loadProject]);

  // this event is not supported in all browsers
  useEffect(() => {
    ref.current?.addEventListener('cancel', () => {
      handleClose();
    });

    const localRef = ref.current;

    return () => {
      localRef?.removeEventListener('cancel', handleClose);
    };
  }, [handleClose]);

  return (
    <Dialog
      open={!!snapshot.loadProject}
      onClose={handleClose}
      maxWidth="xs"
      keepMounted
      disablePortal
      fullWidth
    >
      <input
        id="load-project-file"
        ref={ref}
        type="file"
        accept=".objection"
        onChange={handleLoad}
        style={{ display: 'none' }}
      />

      {loading && (
        <Stack p={2} alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}
    </Dialog>
  );
});
