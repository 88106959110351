import { useAssetStore } from '@web/store/assets/state';
import { Frame } from '@web/types/project';
import { useMemo } from 'react';
import { useCharacter } from './useCharacter';

export const useBackground = (frame: Frame | undefined) => {
  const {
    background: { cache },
  } = useAssetStore();

  const character = useCharacter(frame);

  const pose = useMemo(
    () => character?.poses?.find((pose) => pose.id === frame?.poseId),
    [character, frame],
  );

  const background = useMemo(() => {
    if (!frame) return undefined;

    if (pose?.isSpeedlines) {
      return {
        id: 0,
        name: 'Speedlines',
        url: '/Images/speedlines.gif',
        isWide: false,
      };
    }

    if (frame.backgroundId) {
      return cache[frame.backgroundId];
    }

    return character && cache[character?.backgroundId];
  }, [frame, pose, cache, character]);

  return background;
};
