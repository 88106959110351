import { Box, styled } from '@mui/material';
import { Image } from '@web/components/common/ui/Image';
import { ZIndex } from '../utils/constants';

export const DialogueBoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'absolute',
  bottom: 0,
  left: 0,
  zIndex: ZIndex.DialogueBox,
  willChange: 'transform',
}));

export const DialogueBoxTextContainer = styled(Box)({
  position: 'absolute',
  whiteSpace: 'pre-line',
  overflowWrap: 'break-word',
  padding: 0,
  margin: 0,
  overflow: 'hidden',
});

export const DialogueBoxImage = styled(Image)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  width: '100%',
  height: 'auto',
}));
