import { BackgroundDto, CharacterDto } from '@web/api/api';
import { presetSpeechBlips } from '@web/data/preset-speech-blips';
import { Character, Frame } from '@web/types/project';
import { defaultBackground } from '@web/utils/project';
import { assetStore } from '../store/assets/state';

export const frameUtils = {
  getCharacterId(frame: Frame) {
    if (!frame.characterId && frame.poseId) {
      const character = assetStore.character.preset.find((character) =>
        character.poses.some((pose) => pose.id === frame.poseId),
      );
      return character?.id;
    }

    return frame.characterId;
  },
  getCharacter(frame: Frame): CharacterDto | undefined {
    return assetStore.character.cache[this.getCharacterId(frame) || 0];
  },
  getPose(frame: Frame) {
    if (!frame.poseId) return undefined;

    const character = this.getCharacter(frame);

    return character?.poses.find((pose) => pose.id === frame.poseId);
  },
  getDefaultPose(characterId: number) {
    const character = assetStore.character.cache[characterId];

    return character?.poses[0];
  },
  getSpeechBubble(frame: Frame) {
    if (!frame.speechBubble) return undefined;

    const character = this.getCharacter(frame);

    return character?.speechBubbles.find(
      (speechBubble) => speechBubble.id === frame.speechBubble,
    );
  },
  getSpeechBlipUrl(frame: Frame, character?: Character) {
    if (frame.blip) {
      const presetSpeechBlip = presetSpeechBlips.find(
        (f) => f.id === frame.blip,
      );

      if (presetSpeechBlip) {
        return presetSpeechBlip.url;
      }

      return (
        assetStore.character.cache[frame.blip]?.blipUrl ??
        presetSpeechBlips[0].url
      );
    }

    // support old action
    const findAction = frame.actions?.find((f) => f.actionId === 4);

    if (findAction) {
      return (
        presetSpeechBlips.find((f) => f.id === Number(findAction.actionParam))
          ?.url ?? presetSpeechBlips[0].url
      );
    }

    return character?.blipUrl ?? presetSpeechBlips[0].url;
  },
  getBackground(frame: Frame): BackgroundDto {
    const pose = this.getPose(frame);

    if (pose?.isSpeedlines) {
      return {
        id: 0,
        name: 'Speedlines',
        url: '/Images/speedlines.gif',
        isWide: false,
      };
    }

    if (frame.backgroundId) {
      return (
        assetStore.background.cache[frame.backgroundId] || defaultBackground
      );
    }

    const character = this.getCharacter(frame);

    return character
      ? assetStore.background.cache[character?.backgroundId]
      : defaultBackground;
  },
};
