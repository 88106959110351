import {
  CaseFrame,
  Frame,
  GroupType,
  NormalGroup,
  ProjectType,
} from '@shared/types';
import { CaseProjectDto, SceneProjectDto } from '@web/api/api';

import { GameUtils } from '@web/components/player/utils/game-utils';
import { projectUtils } from '@web/utils/project';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';
import { assetActions } from '../assets/actions';
import { makerStore, makerStore as state } from './state';
import { makerUtils as utils } from './utils';

const fetchedAssetsToIgnore: Record<string, true> = {}; // in order to not re-send requests to get the same assets that don't exist
let autoSaveInterval: ReturnType<typeof setInterval> | null = null;

export const makerActions = {
  newProject(type: ProjectType) {
    const common: SceneProjectDto = {
      id: uuidv4(),
      type: ProjectType.Scene,
      aliases: [],
      groups: [
        {
          id: uuidv4(),
          comments: {},
          name: 'Main',
          type: GroupType.Normal,
          frames: [],
        } as NormalGroup,
      ],
      pairs: [],
      options: projectUtils.getDefaultProjectOptions(),
      nextFrameId: 1,
      freeFrameIds: [],
      version: 5,
    };

    makerActions.loadProject(
      type === 'scene'
        ? common
        : {
            ...common,
            type: ProjectType.Case,
            courtRecord: { evidence: [], profiles: [] },
          },
    );
  },
  addFrame(index: number) {
    if (!state.project || state.disableAddFrame) return;

    const ref = GameUtils.getTargetFrames(state.framesTarget, state.project);

    if (!ref || utils.framesOverLimit(ref)) return;

    let newFrame: Frame = {
      id: -1,
      text: '',
      poseId: 1,
      characterId: 1,
    };

    if (index > 0 && ref[index - 1]) {
      newFrame = utils.copyFrame(ref[index - 1], false);
    }

    ref.splice(index, 0, { ...newFrame, id: utils.getNextFrameId() });

    state.deletedFrame = undefined;

    if (index > state.page * state.perPage - 1) {
      state.page++;
    }
  },
  deleteFrame(index: number) {
    if (!state.project || !state.frames) return;

    state.deletedFrame = {
      frame: JSON.parse(JSON.stringify(state.frames[index])),
      comment: state.group?.comments[state.frames[index].id],
      index,
    };

    state.project.freeFrameIds.push(state.frames[index].id);

    state.frames.splice(index, 1);

    if (
      state.frames.length <= (state.page - 1) * state.perPage &&
      state.page > 1
    ) {
      state.page--;
    }
  },
  undoDeletedFrame() {
    if (!state.project || !state.deletedFrame || !state.frames) return;

    if (utils.framesOverLimit(state.frames)) return;

    const id = utils.getNextFrameId();

    state.frames.splice(state.deletedFrame.index, 0, {
      ...state.deletedFrame.frame,
      id,
    });

    if (state.group && state.deletedFrame.comment !== undefined) {
      state.group.comments[id] = state.deletedFrame.comment;
    }

    if (state.deletedFrame.index > state.page * state.perPage - 1) {
      state.page++;
    }

    state.deletedFrame = undefined;
  },
  copyFrame(index: number, full: boolean) {
    state.copiedFrame = utils.copyFrame(state.frames[index], full);
  },
  pasteFrame(index: number) {
    if (!state.copiedFrame) return;

    if (utils.framesOverLimit(state.frames)) return;

    state.frames.splice(index, 0, {
      ...state.copiedFrame,
      id: utils.getNextFrameId(),
    });

    state.focusedFrameIndex = index;

    state.copiedFrame = undefined;

    if (index > state.page * state.perPage - 1) {
      state.page++;
    }
  },
  loadProject(
    project: SceneProjectDto | CaseProjectDto,
    title = '',
    editId?: string | number,
  ) {
    if (!project.groups?.length) {
      makerStore.dialogs.newProject = true;

      return;
    }

    if (!project.nextFrameId) {
      project.nextFrameId =
        GameUtils.getAllFrames(project)
          .map((m) => m.id)
          .reduce((a, b) => Math.max(a, b), 0) + 1;
    }

    if (!project.freeFrameIds) {
      project.freeFrameIds = [];
    }

    state.project = project;
    state.framesTarget = { groupId: project.groups[0].id };
    state.page = 1;
    state.title = title;
    state.editId = editId;

    if (!editId) {
      makerActions.startAutosave();
    } else {
      makerActions.stopAutosave();
    }

    state.changesDetected = false;
  },
  async loadFramesData(frames: CaseFrame[]) {
    const characterIds = new Set<number>(
      frames
        .filter(
          (f) =>
            f.characterId &&
            !fetchedAssetsToIgnore[`character-${f.characterId}`],
        )
        .map((f) => f.characterId!),
    );
    const backgroundIds = new Set<number>(
      frames
        .filter(
          (f) =>
            f.backgroundId &&
            !fetchedAssetsToIgnore[`background-${f.backgroundId}`],
        )
        .map((f) => f.backgroundId!),
    );
    const popupIds = new Set<number>(
      frames
        .filter(
          (f) => f.popupId && !fetchedAssetsToIgnore[`popup-${f.popupId}`],
        )
        .map((f) => f.popupId!),
    );

    const totalLength = characterIds.size + backgroundIds.size + popupIds.size;

    if (totalLength === 0) return;

    backgroundIds.forEach((id) => {
      fetchedAssetsToIgnore[`background-${id}`] = true;
    });

    characterIds.forEach((id) => {
      fetchedAssetsToIgnore[`character-${id}`] = true;
    });

    popupIds.forEach((id) => {
      fetchedAssetsToIgnore[`popup-${id}`] = true;
    });

    try {
      // first load backgrounds inorder to not get the same backgrounds twice (if they are the same for characters)
      await assetActions.getAllAssets('background', Array.from(backgroundIds));

      await Promise.all([
        assetActions.getAllAssets('character', Array.from(characterIds)),
        assetActions.getAllAssets('popup', Array.from(popupIds)),
      ]);
    } catch (e) {
      if (e instanceof AxiosError && e.response?.status === 503) {
        return;
      }

      enqueueSnackbar('Failed to load frames data', { variant: 'error' });
    }
  },
  startAutosave() {
    if (autoSaveInterval) {
      clearInterval(autoSaveInterval);
    }

    autoSaveInterval = setInterval(() => {
      if (!makerStore.project) return;

      localStorage.setItem('makerFrames', makerActions.getSaveData());
    }, 5000);
  },
  stopAutosave() {
    if (autoSaveInterval) {
      clearInterval(autoSaveInterval);
    }
  },
  getSaveData() {
    return JSON.stringify(state.project);
  },
};
