import {
  Delete,
  Download,
  RestorePage,
  Videocam,
  VideoSettings,
} from '@mui/icons-material';
import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { CaseProjectDto, DraftDto, SceneProjectDto } from '@web/api/api';
import { ApiClient } from '@web/api/api-client';
import { XSmallButton } from '@web/components/common/ui/Buttons';
import { DeleteConfirmation } from '@web/components/common/ui/DeleteConfirmation';
import Dialog from '@web/components/common/ui/Dialog';
import { useDeleteConfirmation } from '@web/hooks/useDeleteConfirmation';
import { makerActions } from '@web/store/maker/actions';
import { makerStore, useMakerStore } from '@web/store/maker/state';
import { settingsStore, useSettingsStore } from '@web/store/settings';
import { projectUtils } from '@web/utils/project';
import { timeAgoLabel } from '@web/utils/utils';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import { proxy, useSnapshot } from 'valtio';

const state = proxy({
  backups: [] as DraftDto[],
  loading: false,
  saveLoading: false,
  selectedId: undefined as number | undefined,
  confirmAction: 'delete' as 'delete' | 'restore',
});

export const ProjectBackupsDialog = () => {
  const {
    dialogs: { projectBackups },
  } = useMakerStore();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    makerStore.dialogs.projectBackups = false;
  };

  return (
    <Dialog
      title="Backups"
      open={!!projectBackups}
      onClose={handleClose}
      dividers={false}
      maxWidth="xs"
      fullScreen={mobile}
      disableContentPadding
    >
      <ProjectBackupsDialogContent />
    </Dialog>
  );
};

const ProjectBackupsDialogContent = () => {
  const { loading, backups } = useSnapshot(state);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const loadBackups = async () => {
    try {
      state.loading = true;

      state.backups = (await ApiClient.draft.getMine()).data;
    } catch (e) {
      console.error(e);

      enqueueSnackbar('Failed to load backups', { variant: 'error' });
    } finally {
      state.loading = false;
    }
  };

  useEffect(() => {
    loadBackups();
  }, []);

  return (
    <Stack
      p={loading ? 2 : 0}
      maxHeight={!mobile ? '50vh' : 'inherit'}
      overflow="auto"
    >
      {loading && <CircularProgress sx={{ alignSelf: 'center' }} />}

      {!loading && backups.length === 0 && (
        <Typography variant="body2" align="center" mb={2}>
          No backups found
        </Typography>
      )}

      {!loading && backups.length > 0 && (
        <List sx={{ paddingTop: 0 }}>
          {backups.map((backup) => (
            <BackupItem key={backup.id} backup={backup} />
          ))}
        </List>
      )}

      {!loading && (
        <>
          <Divider />
          <BackupSettings />
        </>
      )}
    </Stack>
  );
};

type BackupItemProps = {
  backup: DraftDto;
};

const BackupItem = ({ backup }: BackupItemProps) => {
  const handleClick = () => {
    state.selectedId = backup.id;
  };

  return (
    <ListItemButton onClick={handleClick} disableRipple dense>
      <ListItemText
        primary={<Typography noWrap>{getTitle(backup)}</Typography>}
        secondary={
          <Typography component="div" color="text.secondary">
            <BackupItemSecondary backup={backup} />
          </Typography>
        }
      />
    </ListItemButton>
  );
};

const BackupItemSecondary = ({ backup }: BackupItemProps) => {
  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={1}>
          {backup.type === 0 ? (
            <Videocam fontSize="small" />
          ) : (
            <VideoSettings fontSize="small" />
          )}

          <Typography variant="caption" lineHeight={0}>
            {backup.type === 0 ? 'Scene' : 'Case'}
          </Typography>
        </Stack>

        <Typography variant="caption" lineHeight={0}>
          {timeAgoLabel(backup.modifiedAt)}
        </Typography>
      </Stack>

      <BackupItemActions backup={backup} />
    </Stack>
  );
};

const BackupItemActions = ({ backup }: BackupItemProps) => {
  const { selectedId, saveLoading, confirmAction } = useSnapshot(state);
  const deleteConfirmation = useDeleteConfirmation();

  const handleDeleteClick = () => {
    state.confirmAction = 'delete';

    deleteConfirmation.startConfirming();
  };

  const handleRestoreClick = () => {
    state.confirmAction = 'restore';

    deleteConfirmation.startConfirming();
  };

  const handleConfirm = async () => {
    if (confirmAction === 'delete') {
      deleteConfirmation.confirm(async () => await deleteBackup(backup.id));
    } else {
      deleteConfirmation.confirm(async () => await restoreBackupFile(backup));
    }
  };

  const handleSave = async () => {
    await saveBackupFile(backup);
  };

  if (selectedId !== backup.id) return null;

  return (
    <DeleteConfirmation
      deleteConfirmation={deleteConfirmation}
      handleDelete={handleConfirm}
      message={
        confirmAction === 'delete'
          ? 'Are you sure you want to delete this backup?'
          : 'Restoring this backup will overwrite your current project. Are you sure?'
      }
    >
      <Stack direction="row" spacing={1}>
        <XSmallButton
          variant="contained"
          size="small"
          color="success"
          startIcon={<Download />}
          onClick={handleSave}
          disabled={saveLoading}
        >
          Save
        </XSmallButton>

        <XSmallButton
          variant="contained"
          size="small"
          color="info"
          startIcon={<RestorePage />}
          onClick={handleRestoreClick}
          disabled={saveLoading}
        >
          Restore
        </XSmallButton>

        <Box flexGrow={1} />

        <XSmallButton
          variant="contained"
          size="small"
          color="error"
          startIcon={<Delete />}
          onClick={handleDeleteClick}
        >
          Delete
        </XSmallButton>
      </Stack>
    </DeleteConfirmation>
  );
};

const BackupSettings = () => {
  const { enableBackups } = useSettingsStore();

  const handleToggle = () => {
    settingsStore.enableBackups = !settingsStore.enableBackups;
  };

  return (
    <List>
      <ListItemButton onClick={handleToggle} dense>
        <ListItemIcon>
          <Checkbox
            checked={enableBackups}
            edge="start"
            tabIndex={-1}
            disableRipple
          />
        </ListItemIcon>
        <ListItemText
          primary="Enable automatic backups on this device"
          secondary="If you are on a limited data plan and editing a large project, you may want to disable this"
        />
      </ListItemButton>
    </List>
  );
};

const restoreBackupFile = async (backup: DraftDto) => {
  try {
    state.saveLoading = true;

    const draft = await ApiClient.draft.get(backup.id);
    const project = draft.data.data as SceneProjectDto | CaseProjectDto;

    if (!project) {
      throw new Error('Failed to load project');
    }

    makerActions.loadProject(project, makerStore.title, makerStore.editId);

    makerStore.dialogs.projectBackups = false;
  } catch (e) {
    console.error(e);

    enqueueSnackbar('Failed to restore backup', { variant: 'error' });
  } finally {
    state.saveLoading = false;
  }
};

const saveBackupFile = async (backup: DraftDto) => {
  try {
    state.saveLoading = true;

    const draft = await ApiClient.draft.get(backup.id);
    const project = draft.data.data as SceneProjectDto | CaseProjectDto;

    if (!project) {
      throw new Error('Failed to load project');
    }

    const data = projectUtils.saveProject(project);

    const a = document.createElement('a');
    a.href = 'data:application/octet-stream,' + data;
    a.download = `${getTitle(backup)}.objection`;
    a.click();
  } catch (e) {
    console.error(e);

    enqueueSnackbar('Failed to save backup', { variant: 'error' });
  } finally {
    state.saveLoading = false;
  }
};

const deleteBackup = async (id: number) => {
  try {
    await ApiClient.draft.delete(id);

    state.backups = state.backups.filter((b) => b.id !== id);
  } catch (e) {
    console.error(e);

    enqueueSnackbar('Failed to delete backup', { variant: 'error' });
  }
};

const getTitle = (backup: DraftDto) => {
  return backup.title || `${backup.type === 0 ? 'Scene' : 'Case'} Backup`;
};
