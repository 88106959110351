import { PlayArrow } from '@mui/icons-material';
import { useMemo } from 'react';
import { NextButtonIcon, NextButtonImage } from '../../ui/NextButton';
import { DialogueBox } from '../DialogueBox';
import { NameplateProps } from '../Nameplate';
import { NextButton, continueButtonAnimation } from '../NextButton';
import { DialogueBoxTextContainerProps } from '../types';

export const ApolloJusticeDialogueBox = () => {
  const nameplateProps = useMemo<NameplateProps>(
    () => ({
      fontSize: 27,
      url: '/Images/UI/Chatbox/AJ/textbox.png',
      top: 71,
      right: 27.6,
      fontFamily: 'AAAJNameplate',
      fontUrl: '/Fonts/AJ-nameplate.ttf',
      replaceBox: true,
    }),
    [],
  );

  const textProps = useMemo<DialogueBoxTextContainerProps>(
    () => ({
      fontSize: 28,
      width: 69,
      height: 4.2,
      left: 15.5,
      top: 77.5,
      topCenter: 77.8,
      lineHeight: 1.4,
      fontFamily: 'AAAJBody',
      fontUrl: '/Fonts/AJ_body.woff2',
    }),
    [],
  );

  return (
    <DialogueBox
      url="/Images/UI/Chatbox/AJ/textbox-nameless.png"
      nameplate={nameplateProps}
      text={textProps}
      NextButton={<ApolloJusticeNextButton />}
      wordSpacing={0.5}
    />
  );
};

// same as custom next button
// fix different aspect ratios
const ApolloJusticeNextButton = () => {
  const defaultFontSize = 5.8125;

  return (
    <NextButton>
      {({ onClick, showNextButton, size }) => (
        <>
          <NextButtonImage
            display={showNextButton ? 'flex' : 'none'}
            src="/Images/UI/Chatbox/AJ/continue.png"
            alt="next"
          />

          <NextButtonIcon
            style={{
              display: showNextButton ? 'flex' : 'none',
              top: '83%',
              left: '88.8%',
            }}
            onClick={onClick}
          >
            <PlayArrow
              sx={{
                animation: `${continueButtonAnimation} 0.7s ease-in-out infinite both`,
              }}
              style={{
                fontSize: `${defaultFontSize * size}em`,
                color: '#ffffff',
              }}
            />
          </NextButtonIcon>
        </>
      )}
    </NextButton>
  );
};
