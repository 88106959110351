import { Alias, CaseFrame, Pair } from '@shared/types';
import { FrameTextUtils } from '@web/components/maker/utils/frameText';
import frameActions from '@web/data/frame-actions';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { usePlayerMeta } from '../providers/PlayerMetaProvider';
import { useBackground } from './useBackground';
import { useCharacter } from './useCharacter';
import { useCharacters } from './useCharacters';
import { usePopup } from './usePopup';
import { useSpeechBlip } from './useSpeechBlip';
import { useSpeechBubble } from './useSpeechBubble';

export const useFrame = (
  frame?: CaseFrame,
  previousFrame?: CaseFrame,
  aliases?: Alias[],
  pairs?: Pair[],
) => {
  const text = frame?.text;
  const plainText = useMemo(
    () => (frame?.text ? FrameTextUtils.getPlainText(frame?.text || '') : ''),
    [frame?.text],
  );

  const background = useBackground(frame);
  const previousBackground = useBackground(previousFrame);
  const speechBubble = useSpeechBubble(frame);
  const character = useCharacter(frame);
  const characters = useCharacters(frame, pairs);

  const popup = usePopup(frame);
  const speechBlipUrl = useSpeechBlip(frame);
  const pose = character?.poses.find((pose) => pose.id === frame?.poseId);

  const pair = useMemo(
    () => pairs?.find((f) => f.id === frame?.pair?.id),
    [frame?.pair?.id, pairs],
  );

  const meta = usePlayerMeta();

  const shouldShowSpeechBubble = !!frame?.speechBubble;
  const shouldPlayPoseAnimation =
    frame &&
    !frame.noPoseAnimation &&
    pose &&
    (frame.poseId !== previousFrame?.poseId ||
      (!meta?.preventSamePoseAnimation && character?.isPreset)) &&
    (pose.poseStates?.length > 0 ||
      pose.poseAudioTicks?.length > 0 ||
      pose.poseFunctionTicks?.length > 0);
  const shouldClearDialogue =
    !previousFrame?.mergeWithNext && plainText.length > 0;
  const shouldPlayPoseTalkingAnimation = useMemo(
    () =>
      !frame?.doNotTalk &&
      (!plainText.trim().startsWith('(') || !plainText.trim().endsWith(')')) &&
      plainText.replace(/\./g, '').replace(/ /g, '').length > 0,
    [frame?.doNotTalk, plainText],
  );
  const shouldWaitForPoseAnimation = !frame?.noPauseForAnimation;

  const nameplate = useMemo(() => {
    if (frame?.customName) {
      return frame.customName;
    }

    if (character) {
      const alias = aliases?.find((f) => f.from === character.nameplate);

      return alias?.to || character.nameplate;
    }

    return '.';
  }, [aliases, character, frame?.customName]);

  const shouldRunStartActions = useMemo(
    () =>
      frame?.actions?.some(
        (action) =>
          frameActions.find((fa) => fa.id === action.actionId)?.runAtStart,
      ),
    [frame],
  );
  const shouldRunDelayedActions = useMemo(
    () =>
      frame?.actions?.some(
        (action) =>
          !frameActions.find((fa) => fa.id === action.actionId)?.runAtStart,
      ),
    [frame],
  );

  const offscreenFrame = useMemo(
    () => frame?.actions?.find((f) => f.actionId === 6),
    [frame],
  );

  const shouldPanBackground = background?.isWide && !!frame?.transition;
  const transitionDuration =
    shouldPanBackground &&
    !pose?.isSpeedlines &&
    frame.transition!.duration > 0 &&
    background?.id === previousBackground?.id
      ? frame.transition!.duration
      : 0;

  return {
    id: uuidv4(),
    background,
    speechBubble,
    character,
    characters,
    popup,
    pose,
    pair,
    text,
    plainText,
    shouldShowSpeechBubble,
    shouldPlayPoseAnimation,
    shouldClearDialogue,
    shouldPlayPoseTalkingAnimation,
    shouldWaitForPoseAnimation,
    shouldRunStartActions,
    shouldRunDelayedActions,
    speechBlipUrl,
    nameplate,
    frame,
    previousFrame,
    offscreenFrame,
    shouldPanBackground,
    transitionDuration,
  };
};
