import { Alias } from './alias';
import { CourtRecord } from './court-record';
import { BaseGroup } from './groups';
import { Options } from './options';
import { Pair } from './pair';

export interface Project {
  id: string;
  options: Options;
  aliases: Alias[];
  pairs: Pair[];
  groups: BaseGroup[];
  courtRecord?: CourtRecord;
  type: ProjectType;
  nextFrameId: number;
  freeFrameIds: number[];
  version: 5;
}

export enum ProjectType {
  Scene = 'scene',
  Case = 'case',
}
