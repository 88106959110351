import { GroupType, ProjectType } from '@shared/types';
import { GameUtils } from '@web/components/player/utils/game-utils';
import { makerStore } from '@web/store/maker/state';
import { useCallback, useMemo } from 'react';
import { useSnapshot } from 'valtio';

export const useProjectConversion = () => {
  const { project, editId } = useSnapshot(makerStore);

  const canConvertToCase = useMemo(() => {
    return !editId && project?.type !== ProjectType.Case;
  }, [editId, project?.type]);

  const canConvertToScene = useMemo(() => {
    return (
      !editId &&
      project?.type !== ProjectType.Scene &&
      project?.groups?.length === 1 &&
      project?.groups?.[0]?.type === GroupType.Normal
    );
  }, [editId, project?.groups, project?.type]);

  const canConvert = useMemo(() => {
    if (!project) return false;

    return (
      (project?.type === ProjectType.Scene && canConvertToCase) ||
      (project?.type === ProjectType.Case && canConvertToScene)
    );
  }, [canConvertToCase, canConvertToScene, project]);

  const convertToCase = useCallback(() => {
    if (!makerStore.project || !canConvertToCase) return;

    makerStore.project = {
      ...makerStore.project,
      type: ProjectType.Case,
      courtRecord: {
        evidence: [],
        profiles: [],
      },
    };

    makerStore.changesDetected = false;

    if (
      makerStore.project.options.textSpeed === 35 &&
      makerStore.project.options.textBlipFrequency === 64
    ) {
      makerStore.project.options.textSpeed = 28;
      makerStore.project.options.textBlipFrequency = 56;
    }
  }, [canConvertToCase]);

  const convertToScene = useCallback(() => {
    if (
      !makerStore.project ||
      makerStore.project.type !== ProjectType.Case ||
      !canConvertToScene
    )
      return;

    const allFrames = GameUtils.getAllFrames(makerStore.project).slice(
      makerStore.framesLimit,
    );

    allFrames.forEach((e) => {
      delete e.hide;
      delete e.caseAction;
    });

    const { courtRecord, ...project } = makerStore.project;

    makerStore.project = {
      ...project,
      type: ProjectType.Scene,
    };

    makerStore.changesDetected = false;

    if (
      makerStore.project.options.textSpeed === 28 &&
      makerStore.project.options.textBlipFrequency === 56
    ) {
      makerStore.project.options.textSpeed = 35;
      makerStore.project.options.textBlipFrequency = 64;
    }
  }, [canConvertToScene]);

  return { canConvert, convertToCase, convertToScene };
};
