import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { getErrorMessage } from '@web/utils/error';
import {
  Control,
  DefaultValues,
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormSetError,
  useForm,
} from 'react-hook-form';
import yup from 'yup';

type FormChildrenProps<T extends FieldValues> = {
  control: Control<T, any>;
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
  setError: UseFormSetError<T>;
  loading: boolean;
};

type FormComponentProps<T extends FieldValues> = {
  schema: yup.AnyObjectSchema;
  onSubmit: (data: T) => Promise<void>;
  children: (props: FormChildrenProps<T>) => React.ReactNode;
  defaultValues?: DefaultValues<T>;

  mode?: 'onBlur' | 'onChange' | 'onSubmit' | 'onTouched' | 'all';
};

const Form = <T extends Record<string, any>>({
  schema,
  onSubmit,
  children,
  defaultValues,
  mode = 'onSubmit',
}: FormComponentProps<T>) => {
  const {
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<T>({
    resolver: yupResolver(schema),
    mode,
    defaultValues,
  });

  const onFormSubmit = async (data: T) => {
    try {
      clearErrors();

      await onSubmit(data);
    } catch (err) {
      setError('root', { message: getErrorMessage(err) });
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onFormSubmit)} noValidate>
      {children({
        control,
        register,
        errors,
        setError,
        loading: isSubmitting,
      })}
    </Box>
  );
};

export default Form;
