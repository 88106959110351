import React from 'react';

const MultiHideView = ({
  index,
  children,
}: {
  index: number;
  children: React.ReactNode;
}) => {
  const childrenArray = React.Children.toArray(children);

  return childrenArray.map((child, i) => (
    <div key={i} style={{ display: i === index ? 'block' : 'none' }}>
      {child}
    </div>
  ));
};

export default MultiHideView;
