/* eslint-disable @typescript-eslint/no-explicit-any */
import { Stack, Typography } from '@mui/material';
import { Fade } from '@shared/types';
import { SceneTargets } from '@shared/types/scene-targets';
import { Checkbox } from '@web/components/common/form/Checkbox';
import { NumberTextField } from '@web/components/common/form/NumberTextField';
import { SimpleSelect } from '@web/components/common/form/SimpleSelect';
import easings from '@web/data/easings';
import { makerStore, useMakerStore } from '@web/store/maker/state';
import { MuiColorInput } from 'mui-color-input';
import {
  ChangeEventHandler,
  memo,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

export const FrameFadeEditor = memo(() => {
  return (
    <Stack spacing={2}>
      <FrameFadeOptionInput />
      <FrameFadeSelectedOptionInputs />
    </Stack>
  );
});

const FrameFadeSelectedOptionInputs = memo(() => {
  const { focusedFrame, focusedFrameIndex } = useMakerStore();
  const fade = focusedFrame?.fade;

  const handleChangeDuration: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = useCallback(
    (event) => {
      if (!focusedFrame) return;

      makerStore.frames[focusedFrameIndex!].fade!.duration =
        parseInt(event.target.value) || 0;
    },
    [focusedFrame, focusedFrameIndex],
  );

  if (!fade) return null;

  return (
    <>
      <FrameFadeTargetInput />

      {fade.target === 'characters' && <FrameFadeTargetCharacters />}

      <NumberTextField
        value={fade?.duration}
        label="Duration"
        onChange={handleChangeDuration}
        variant="standard"
      />

      <FrameFadeEasingInput />
      <FrameFadeColorInput />
    </>
  );
});

const FrameFadeOptionInput = memo(() => {
  const { focusedFrame, focusedFrameIndex } = useMakerStore();

  const options = useMemo(
    () => [
      { name: 'None', id: '' },
      { name: 'Fade Out', id: 'fadeOut' },
      { name: 'Fade In', id: 'fadeIn' },
      { name: 'Fade Out In', id: 'fadeOutIn' },
    ],
    [],
  );

  const selectedFadeOption = useMemo(
    () => options.find((o) => o.id === focusedFrame?.fade?.type) || options[0],
    [options, focusedFrame?.fade],
  );

  const handleChangeFadeOption = useCallback(
    (
      value: {
        name: string;
        id: string;
      } | null,
    ) => {
      if (!focusedFrame) return;

      if (!value || !value.id) {
        makerStore.frames[focusedFrameIndex!].fade = undefined;

        return;
      }

      if (!makerStore.frames[focusedFrameIndex!].fade) {
        makerStore.frames[focusedFrameIndex!].fade = {
          type: value.id as any,
          target: value.id === 'fadeOutIn' ? 'everything' : 'background',
          duration: value.id === 'fadeOutIn' ? 2000 : 1000,
          color: '#000',
        };
      } else if (value.id === 'fadeOutIn') {
        makerStore.frames[focusedFrameIndex!].fade!.type = value.id as any;
        makerStore.frames[focusedFrameIndex!].fade!.target = 'everything';
      } else {
        makerStore.frames[focusedFrameIndex!].fade!.type = value.id as any;
      }
    },
    [focusedFrame, focusedFrameIndex],
  );

  return (
    <SimpleSelect
      label="Perform"
      options={options}
      value={selectedFadeOption}
      onChange={handleChangeFadeOption}
      variant="standard"
    />
  );
});

const FrameFadeEasingInput = memo(() => {
  const { focusedFrame, focusedFrameIndex } = useMakerStore();

  const options = useMemo(
    () =>
      easings.map((m) => ({
        name: m.text,
        id: m.id ?? '',
      })),
    [],
  );

  const selectedOption = useMemo(
    () =>
      options.find((o) => o.id === focusedFrame?.fade!.easing) || options[0],
    [options, focusedFrame?.fade],
  );

  const handleChangeOption = useCallback(
    (
      value: {
        name: string;
        id: string;
      } | null,
    ) => {
      if (!focusedFrame) return;

      if (!value) {
        makerStore.frames[focusedFrameIndex!].fade!.easing = undefined;

        return;
      }

      makerStore.frames[focusedFrameIndex!].fade!.easing = value.id;
    },
    [focusedFrame, focusedFrameIndex],
  );

  return (
    <SimpleSelect
      label="Easing"
      options={options}
      value={selectedOption}
      onChange={handleChangeOption}
      variant="standard"
    />
  );
});

const FrameFadeTargetCharacters = memo(() => {
  const { focusedFrame, focusedFrameIndex } = useMakerStore();
  const fade = focusedFrame?.fade;

  const characterChecks = {
    2:
      (fade?.characters && fade.characters & SceneTargets.CHARACTER_1) ===
      SceneTargets.CHARACTER_1,
    4:
      (fade?.characters && fade.characters & SceneTargets.CHARACTER_2) ===
      SceneTargets.CHARACTER_2,
    8:
      (fade?.characters && fade.characters & SceneTargets.CHARACTER_3) ===
      SceneTargets.CHARACTER_3,
    16:
      (fade?.characters && fade.characters & SceneTargets.CHARACTER_4) ===
      SceneTargets.CHARACTER_4,
    32:
      (fade?.characters && fade.characters & SceneTargets.CHARACTER_5) ===
      SceneTargets.CHARACTER_5,
  };

  const handleCheckCharacter = useCallback(
    (checked: boolean, target: SceneTargets) => {
      if (!focusedFrame) return;

      let newCharacters = fade?.characters || SceneTargets.NONE;

      if (checked) {
        newCharacters |= target;
      } else {
        newCharacters &= ~target;
      }

      makerStore.frames[focusedFrameIndex!].fade!.characters = newCharacters;
    },
    [focusedFrame, fade, focusedFrameIndex],
  );

  if (!focusedFrame?.fade || fade?.target !== 'characters') return null;

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
    >
      {!focusedFrame.pair && (
        <Checkbox
          label="Character"
          value={characterChecks[SceneTargets.CHARACTER_1]}
          onChange={(checked) =>
            handleCheckCharacter(checked, SceneTargets.CHARACTER_1)
          }
          size="small"
        />
      )}

      {focusedFrame.pair &&
        [
          SceneTargets.CHARACTER_1,
          SceneTargets.CHARACTER_2,
          SceneTargets.CHARACTER_3,
          SceneTargets.CHARACTER_4,
          SceneTargets.CHARACTER_5,
        ].map((target, index) => (
          <Checkbox
            label={`Character ${index + 1}`}
            value={characterChecks[target]}
            onChange={(checked) => handleCheckCharacter(checked, target)}
            size="small"
          />
        ))}
    </Stack>
  );
});

const FrameFadeTargetInput = memo(() => {
  const { focusedFrame, focusedFrameIndex } = useMakerStore();
  const fade = focusedFrame?.fade;

  const options = useMemo(
    () => [
      { name: 'The Background', id: 'background' },
      { name: 'Characters', id: 'characters' },
      { name: 'The Scene (excluding chatbox)', id: 'scene' },
      { name: 'Everything', id: 'everything' },
    ],
    [],
  );

  const selectedOption = useMemo(
    () => options.find((o) => o.id === fade?.target) || options[0],
    [options, fade?.target],
  );

  const handleChangeOption = useCallback(
    (
      value: {
        name: string;
        id: string;
      } | null,
    ) => {
      if (!focusedFrame || !value) return;

      makerStore.frames[focusedFrameIndex!].fade!.target =
        value.id as Fade['target'];
    },
    [focusedFrame, focusedFrameIndex],
  );

  return (
    <SimpleSelect
      label="Target"
      options={options}
      value={selectedOption}
      onChange={handleChangeOption}
      variant="standard"
      disabled={fade?.type === 'fadeOutIn'}
    />
  );
});

const FrameFadeColorInput = memo(() => {
  const { focusedFrame, focusedFrameIndex } = useMakerStore();
  const fade = focusedFrame?.fade;

  useEffect(() => {
    if (!fade?.color) return;

    if (fade.target === 'characters') {
      delete makerStore.frames[focusedFrameIndex!].fade!.color;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fade, fade?.target]);

  const handleChange = useCallback(
    (color: string) => {
      if (!focusedFrame) return;

      makerStore.frames[focusedFrameIndex!].fade!.color = color;
    },
    [focusedFrame, focusedFrameIndex],
  );

  if (!fade || fade.target === 'characters') return null;

  return (
    <Stack spacing={1}>
      <Typography variant="caption" color="text.secondary">
        Fade color
      </Typography>

      <MuiColorInput
        value={fade?.color || '#000000'}
        onChange={handleChange}
        format="hex"
        fallbackValue="#000000"
        isAlphaHidden
      />
    </Stack>
  );
});
