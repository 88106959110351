import {
  AuthApi,
  BackgroundApi,
  CaseApi,
  CaseSaveApi,
  CharacterApi,
  CharacterPoseApi,
  CharacterPoseAudioApi,
  CharacterPoseFunctionApi,
  CharacterPoseStateApi,
  CharacterSpeechBubbleApi,
  DraftApi,
  EvidenceApi,
  ExportApi,
  MaintenanceApi,
  MusicApi,
  PopupApi,
  SceneApi,
  SoundApi,
  ThumbnailApi,
} from './api/api';
import { Configuration } from './api/configuration';
import axiosInstance from './axios';

const basePath = (import.meta.env.VITE_BACKEND_BASE_URL ?? '/api') as string;

const configuration: Configuration = {
  basePath: basePath,
  isJsonMime: (mime: string) => mime.includes('application/json'),
};

export const ApiClient = {
  auth: new AuthApi(configuration, '', axiosInstance),
  assets: {
    background: new BackgroundApi(configuration, '', axiosInstance),
    sound: new SoundApi(configuration, '', axiosInstance),
    music: new MusicApi(configuration, '', axiosInstance),
    popup: new PopupApi(configuration, '', axiosInstance),
    evidence: new EvidenceApi(configuration, '', axiosInstance),
    character: new CharacterApi(configuration, '', axiosInstance),
    pose: new CharacterPoseApi(configuration, '', axiosInstance),
    poseState: new CharacterPoseStateApi(configuration, '', axiosInstance),
    poseAudio: new CharacterPoseAudioApi(configuration, '', axiosInstance),
    poseFunction: new CharacterPoseFunctionApi(
      configuration,
      '',
      axiosInstance,
    ),
    characterSpeechBubble: new CharacterSpeechBubbleApi(
      configuration,
      '',
      axiosInstance,
    ),
  },
  case: new CaseApi(configuration, '', axiosInstance),
  scene: new SceneApi(configuration, '', axiosInstance),
  draft: new DraftApi(configuration, '', axiosInstance),
  export: new ExportApi(configuration, '', axiosInstance),
  caseSave: new CaseSaveApi(configuration, '', axiosInstance),
  thumbnail: new ThumbnailApi(configuration, '', axiosInstance),
  maintenance: new MaintenanceApi(configuration, '', axiosInstance),
};
