import { Edit } from '@mui/icons-material';
import { Box } from '@mui/material';
import {
  DataGridProps,
  GridActionsCellItem,
  GridColDef,
} from '@mui/x-data-grid';
import { PopupDto } from '@web/api/api';
import { Container } from '@web/components/player/ui/Container';
import { Popup } from '@web/components/player/ui/Popup';
import { ZIndex } from '@web/components/player/utils/constants';
import { useAssetStore } from '@web/store/assets/state';
import { useMakerStore } from '@web/store/maker/state';
import { memo, useMemo } from 'react';
import { AssetsDataGrid } from '../AssetsDataGrid';

type PopupAssetsProps = {
  onSelectChange?: (selection: number[]) => void;
  onAction?: (action: 'edit', id: number) => void;
} & Partial<DataGridProps>;

export const DataGridPopups = memo(
  ({ onSelectChange, onAction, ...props }: PopupAssetsProps) => {
    const {
      popup: { user },
    } = useAssetStore();

    const rows = useMemo(() => [...user], [user]);

    const columns = useMemo<GridColDef<PopupDto>[]>(
      () => [
        { field: 'id', headerName: 'ID', width: 100, hideSortIcons: false },
        {
          field: 'url',
          headerName: 'Popup',
          renderCell: (params) => <RenderThumbnailCell popup={params.row} />,
          width: 120,
          sortable: false,
        },
        {
          field: 'name',
          headerName: 'Name',
          width: 240,
        },
        {
          field: 'center',
          headerName: 'Center',
          width: 80,
          type: 'boolean',
        },
        {
          field: 'actions',
          type: 'actions',
          headerName: 'Actions',
          width: 100,
          getActions: (params) => [
            <GridActionsCellItem
              icon={<Edit />}
              label="Edit"
              onClick={() => {
                onAction?.('edit', params.id as number);
              }}
            />,
          ],
        },
      ],
      [onAction],
    );

    return (
      <AssetsDataGrid
        {...props}
        rows={rows}
        columns={columns}
        rowHeight={66.67}
        onSelectChange={onSelectChange}
      />
    );
  },
);

const RenderThumbnailCell = ({ popup }: { popup: PopupDto }) => {
  const { aspectRatio } = useMakerStore();

  return (
    <Box
      minWidth={100}
      sx={{
        '&:hover': {
          transform: 'scale(2)',
          position: 'absolute',
          zIndex: ZIndex.NextButton,
          transition: 'transform 0.15s',
        },
      }}
    >
      <Container
        aspectRatio={aspectRatio}
        sx={{ backgroundColor: 'transparent' }}
      >
        <Popup
          url={popup.url}
          x={popup.posX}
          y={popup.posY}
          size={popup.resize}
          center={popup.center}
        />
      </Container>
    </Box>
  );
};
