import { MusicDto } from '@web/api/api';
import { memo } from 'react';
import { AssetTabMobile } from '../AssetTabMobile';
import { AudioPreviewButton } from '../ui/AudioPreviewButton';

export const MusicTabMobile = () => {
  return <AssetTabMobile type="music" PreviewComponent={PreviewMusic} />;
};

const PreviewMusic = memo(({ value }: { value: MusicDto | null }) => {
  if (value === null) {
    return null;
  }

  return (
    <AudioPreviewButton url={value.url} volume={value.volume} size="small" />
  );
});
