import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDebounceCallback, useEventListener } from 'usehooks-ts';
import { v4 as uuidv4 } from 'uuid';
import { proxy, useSnapshot } from 'valtio';
import { FlashType, ShakeType } from '../types';
import { getAspectRatioNumber } from '../utils/utils';
import { useTimeline } from './useTimeline';

type ShakeEffect = {
  id: string;
  shake: ShakeType;
};

type PlayerUI = {
  shakes: ShakeEffect[];
  flash?: FlashType;
  aspectRatio: number;
  width: number;
  playerSize: number; // change of player size
  size: number; // difference from original 960 width size
};

const initialState = (
  aspectRatio: string | undefined,
  width: number | undefined,
): PlayerUI => ({
  shakes: [],
  playerSize: 1,
  size: 1,
  aspectRatio: getAspectRatioNumber(aspectRatio || '3:2'),
  width: width || 960,
});

export const usePlayerUi = (
  playerContainerRef: React.RefObject<HTMLDivElement>,
  aspectRatio: string | undefined,
  width: number | undefined,
  addEvent: ReturnType<typeof useTimeline>['addEvent'],
) => {
  const state = useRef(
    proxy<PlayerUI>(initialState(aspectRatio, width)),
  ).current;
  const snapshot = useSnapshot(state);

  const setPlayerSize = useCallback(() => {
    if (playerContainerRef.current) {
      state.playerSize = playerContainerRef.current.clientWidth / state.width;
      state.size = (state.width / 960) * state.playerSize;
    }
  }, [playerContainerRef, state]);

  const setPlayerSizeDebounced = useDebounceCallback(setPlayerSize, 12);
  // const height = getHeightFromAspectRatio(snapshot.width, snapshot.aspectRatio);

  useEventListener('resize', () => {
    setPlayerSizeDebounced();
  });

  const addShake = useCallback(
    (shake: ShakeType, duration: number) => {
      const id = uuidv4();
      const newShake = { id, shake };

      state.shakes.push(newShake);

      addEvent(
        () => {
          state.shakes = state.shakes.filter((s) => s.id !== id);
        },
        duration,
        false,
      );
    },
    [addEvent, state],
  );

  const setFlash = useCallback(
    (flash?: FlashType) => {
      state.flash = flash;
    },
    [state],
  );

  const init = useCallback(() => {
    setPlayerSize();
  }, [setPlayerSize]);

  const reset = useCallback(() => {
    Object.assign(state, initialState(aspectRatio, width));
  }, [aspectRatio, state, width]);

  const actions = useMemo(
    () => ({
      init,
      reset,
      addShake,
      setFlash,
    }),
    [addShake, init, reset, setFlash],
  );

  useEffect(() => {
    Object.assign(state, initialState(aspectRatio, width));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aspectRatio, width]);

  useEffect(() => {
    setPlayerSize();
  }, [setPlayerSize, snapshot.width]);

  return { state: snapshot, actions };
};
