import { Slider, SliderProps, Stack, Typography } from '@mui/material';
import { NumberTextField } from './NumberTextField';

type SliderWithInputProps = {
  value: number;
  onChange: (value: number | number[]) => void;
  label: string;
  error?: string;
} & Omit<SliderProps, 'value' | 'onChange'>;

export const SliderWithInput = ({
  value,
  onChange,
  label,
  error,
  ...props
}: SliderWithInputProps) => {
  const { min, max } = props;

  const handleChange = (value: number) => {
    if ((min || min === 0) && value < min) {
      onChange(min);
    } else if ((max || max === 0) && value > max) {
      onChange(max);
    } else {
      onChange(value);
    }
  };

  return (
    <Stack spacing={0}>
      <Typography>{label}</Typography>

      <Stack direction="row">
        <Slider
          value={value ?? 0}
          onChange={(_, value) => onChange(value)}
          valueLabelDisplay="auto"
          min={min}
          max={max}
          step={1}
          {...props}
        />

        <NumberTextField
          value={value}
          onChange={(e) => handleChange(Number(e.target.value))}
          variant="standard"
          size="small"
          sx={{ width: 60 }}
          InputProps={{ disableUnderline: true }}
          inputProps={{ style: { textAlign: 'end' } }}
        />
      </Stack>

      {error && (
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      )}
    </Stack>
  );
};
