import { Add } from '@mui/icons-material';
import { Button, styled } from '@mui/material';
import { makerActions } from '@web/store/maker/actions';
import { makerStore } from '@web/store/maker/state';
import React, { useCallback } from 'react';
import { useSnapshot } from 'valtio';

export const AddFrameButton = React.memo(({ index }: { index: number }) => {
  const { copiedFrame } = useSnapshot(makerStore);

  const handleClickAdd = useCallback(() => {
    if (copiedFrame) {
      makerActions.pasteFrame(index);
    } else {
      makerActions.addFrame(index);
    }
  }, [copiedFrame, index]);

  return (
    <StyledAddFrameButton
      size="small"
      onClick={handleClickAdd}
      startIcon={<Add />}
      color="inherit"
    >
      {copiedFrame ? 'Paste Frame' : 'Add Frame'}
    </StyledAddFrameButton>
  );
});

const StyledAddFrameButton = styled(Button)(({ theme }) => ({
  border: `1px dashed ${theme.palette.divider}`,
  lineHeight: 1,
  height: '25px',
  opacity: theme.palette.mode === 'dark' ? 0.75 : 1,
}));
