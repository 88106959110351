import { PoseDto, SpeechBubbleDto } from '@web/api/api';
import { assetActions } from '@web/store/assets/actions';
import { assetStore } from '@web/store/assets/state';

export const updateCharacterPoses = (characterId: number, poses: PoseDto[]) => {
  const character = assetStore.character.user.find((c) => c.id === characterId);

  if (character) {
    assetActions.updateAssetLocally('character', character.id, {
      ...character,
      poses,
    });
  }
};

export const updateCharacterSpeechBubbles = (
  characterId: number,
  speechBubbles: SpeechBubbleDto[],
) => {
  const character = assetStore.character.user.find((c) => c.id === characterId);

  if (character) {
    assetActions.updateAssetLocally('character', character.id, {
      ...character,
      speechBubbles,
    });
  }
};
