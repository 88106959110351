import { useEffect, useState } from 'react';
import { shakeBubble } from './hooks/usePlayerStyles';
import { usePlayer } from './providers/PlayerProvider';
import { SpeechBubble } from './ui/SpeechBubble';

export const PlayerSpeechBubble = () => {
  const {
    timeline: { addEvent },
    actions: { nextStep },
    step,
    audio: { playSound },
    frame: { speechBubble },
  } = usePlayer();

  const [show, setShow] = useState(false);
  const [animationKey, setAnimationKey] = useState(0);

  useEffect(() => {
    if (step !== 'speech_bubble') {
      setShow(false);

      return;
    }

    if (!speechBubble) {
      setShow(false);

      nextStep();

      return;
    }

    setShow(true);

    setAnimationKey((prev) => prev + 1);

    addEvent(() => {
      nextStep();
    }, speechBubble?.duration || 950);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    if (!show || !speechBubble) {
      return;
    }

    playSound(
      speechBubble.soundUrl || '/Audio/Vocal/sfx-objection.wav',
      100,
      100,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, speechBubble, animationKey]);

  if (!speechBubble || !show) {
    return null;
  }

  return (
    <SpeechBubble
      key={animationKey}
      url={speechBubble.imageUrl}
      sx={speechBubble.shake ? { ...shakeBubble } : {}}
    />
  );
};
