import {
  AutocompleteRenderInputParams,
  TextField,
  TextFieldVariants,
} from '@mui/material';
import React from 'react';
import { isMobile } from 'react-device-detect';

type RenderAutocompleteInputProps = {
  label?: string;
  placeholder?: string;
  search?: boolean;
  params: AutocompleteRenderInputParams;
  variant?: TextFieldVariants;
} & Omit<React.ComponentProps<typeof TextField>, 'variant'>;

export const RenderAutocompleteInput = React.memo(
  ({
    label,
    placeholder,
    search,
    variant = 'filled',
    params,
    InputProps: customInputProps,
    ...other
  }: RenderAutocompleteInputProps) => {
    const { endAdornment, ...paramsInputPropsRest } = params.InputProps;

    const endAdornments = (
      <React.Fragment>
        {customInputProps?.endAdornment}
        {endAdornment}
      </React.Fragment>
    );

    const InputProps = {
      ...paramsInputPropsRest,
      ...customInputProps,
      endAdornment: endAdornments,
    };

    const disableSearch = search === undefined ? isMobile : !search;

    return (
      <TextField
        {...params}
        {...other}
        InputProps={InputProps}
        inputProps={{
          ...params.inputProps,
          readOnly: disableSearch,
        }}
        variant={variant}
        label={label}
        placeholder={placeholder}
        sx={{
          '& .MuiAutocomplete-input': {
            cursor: !disableSearch ? 'text' : 'pointer',
            userSelect: !disableSearch ? undefined : 'none',
            '::selection': {
              backgroundColor: !disableSearch ? undefined : 'transparent',
            },
          },
          '& .MuiInputBase-root': {
            cursor: !disableSearch ? 'text' : 'pointer',
          },
        }}
      />
    );
  },
);
