import { AppBar, Stack, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import { ColorTag } from './tags/ColorTag';
import { EvidenceTag } from './tags/EvidenceTag';
import { FlashTag } from './tags/FlashTag';
import { MusicTag } from './tags/MusicTag';
import { PauseTag } from './tags/PauseTag';
import { ShakeTag } from './tags/ShakeTag';
import { SoundTag } from './tags/SoundTag';
import { TextSpeedTag } from './tags/TextSpeedTag';

export const FrameTextEditorToolbar = () => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const overflowing = useMediaQuery(theme.breakpoints.down(353));
  const width = sm ? 42 : 50;

  return (
    <AppBar
      position="static"
      color="accent"
      elevation={0}
      sx={{ width: '100%' }}
      enableColorOnDark
    >
      <Toolbar variant="dense" sx={{ minHeight: 0 }} disableGutters>
        <Stack
          direction="row"
          flexWrap="wrap"
          flexGrow={1}
          justifyContent={sm && !overflowing ? 'space-around' : 'normal'}
        >
          <ColorTag width={width} />

          <TextSpeedTag width={width} />

          <PauseTag width={width} />

          <FlashTag width={width} />

          <ShakeTag width={width} />

          <MusicTag width={width} />

          <SoundTag width={width} />

          <EvidenceTag width={width} />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
