import { Box, styled } from '@mui/material';
import { animated } from 'react-spring';

export const FadeBox = animated(Box);

export const FadeColorBox = styled(FadeBox)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  opacity: 0,
}));
