import {
  DoNotDisturbAlt,
  Equalizer,
  MusicNote,
  RecordVoiceOver,
  VolumeUp,
} from '@mui/icons-material';
import { Button, IconButton, Slider, Stack, Tooltip } from '@mui/material';
import { useAnchorMenu } from '@web/hooks/useAnchorMenu';
import {
  settingsStore,
  SettingsStoreType,
  useSettingsStore,
} from '@web/store/settings';
import { useMemo } from 'react';
import { Popper } from '../common/ui/Popper';

export const PlayerVolumeControl = () => {
  const { anchorEl, toggle, close } = useAnchorMenu();

  return (
    <>
      <Button
        size="small"
        variant="contained"
        color="success"
        onClick={toggle}
        sx={{ minWidth: 50 }}
      >
        <VolumeUp />
      </Button>

      <Popper
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={close}
        sx={{ minWidth: 300 }}
        placement="auto-start"
        transitionDuration={100}
        disablePortal
      >
        <Stack spacing={1} p={1.5}>
          <VolumeSlider setting="master" icon={<Equalizer />} />
          <VolumeSlider setting="music" icon={<MusicNote />} />
          <VolumeSlider setting="sound" icon={<VolumeUp />} />
          <VolumeSlider setting="blip" icon={<RecordVoiceOver />} />
        </Stack>
      </Popper>
    </>
  );
};

type VolumeSliderProps = {
  setting: keyof SettingsStoreType['audio']['volume'];
  icon: React.ReactNode;
};

const VolumeSlider = ({ setting, icon }: VolumeSliderProps) => {
  const {
    audio: { volume, muted },
  } = useSettingsStore();

  const value = useMemo(() => volume[setting], [setting, volume]);
  const isMuted = useMemo(
    () => muted[setting] || muted.master,
    [muted, setting],
  );

  const handleChangeVolume = (event: Event, newValue: number | number[]) => {
    settingsStore.audio.volume[setting] = newValue as number;
  };

  const handleToggleMute = () => {
    settingsStore.audio.muted = {
      ...settingsStore.audio.muted,
      [setting]: !muted[setting],
    };
  };

  const disabled = muted.master && setting !== 'master';

  return (
    <Stack direction="row" spacing={1.5} alignItems="center">
      <Tooltip
        title={!disabled ? setting : null}
        placement="top"
        disableInteractive
      >
        <span>
          <IconButton
            size="small"
            onClick={handleToggleMute}
            disabled={disabled}
          >
            {icon}

            {isMuted && (
              <DoNotDisturbAlt
                color={muted[setting] ? 'error' : 'info'}
                sx={{ position: 'absolute', width: '100%', height: '100%' }}
              />
            )}
          </IconButton>
        </span>
      </Tooltip>

      <Slider
        color="info"
        value={value}
        valueLabelDisplay="auto"
        onChange={handleChangeVolume}
        disabled={isMuted}
      />
    </Stack>
  );
};
