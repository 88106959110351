import { easings } from 'react-spring';

export default Object.freeze([
  {
    text: 'Linear',
    oldId: 'linear',
    value: undefined,
    id: null,
  },
  {
    text: 'Ease',
    oldId: 'ease',
    value: (t: number) => cubicBezier(0.25, 0.1, 0.25, 1, t),
    id: 'ease',
  },
  {
    text: 'Ease in',
    oldId: 'ease-in',
    value: (t: number) => cubicBezier(0.42, 0, 1, 1, t),
    id: 'easeIn',
  },
  {
    text: 'Ease out',
    oldId: 'ease-out',
    value: (t: number) => cubicBezier(0, 0, 0.58, 1, t),
    id: 'easeOut',
  },
  {
    text: 'Ease in out',
    oldId: 'ease-in-out',
    value: (t) => cubicBezier(0.42, 0, 0.58, 1, t),
    id: 'easeInOut',
  },
  {
    text: 'Ease in sine',
    oldId: 'easeInSine',
    value: easings.easeInSine,
    id: 'easeInSine',
  },
  {
    text: 'Ease out sine',
    oldId: 'easeOutSine',
    value: easings.easeOutSine,
    id: 'easeOutSine',
  },
  {
    text: 'Ease in out sine',
    oldId: 'easeInOutSine',
    value: easings.easeInOutSine,
    id: 'easeInOutSine',
  },
  {
    text: 'Ease in quad',
    oldId: 'easeInQuad',
    value: easings.easeInQuad,
    id: 'easeInQuad',
  },
  {
    text: 'Ease out quad',
    oldId: 'easeOutQuad',
    value: easings.easeOutQuad,
    id: 'easeOutQuad',
  },
  {
    text: 'Ease in out quad',
    oldId: 'easeInOutQuad',
    value: easings.easeInOutQuad,
    id: 'easeInOutQuad',
  },
  {
    text: 'Ease in cubic',
    oldId: 'easeInCubic',
    value: easings.easeInCubic,
    id: 'easeInCubic',
  },
  {
    text: 'Ease out cubic',
    oldId: 'easeOutCubic',
    value: easings.easeOutCubic,
    id: 'easeOutCubic',
  },
  {
    text: 'Ease in out cubic',
    oldId: 'easeInOutCubic',
    value: easings.easeInOutCubic,
    id: 'easeInOutCubic',
  },
  {
    text: 'Ease in quart',
    oldId: 'easeInQuart',
    value: easings.easeInQuart,
    id: 'easeInQuart',
  },
  {
    text: 'Ease out quart',
    oldId: 'easeOutQuart',
    value: easings.easeOutQuart,
    id: 'easeOutQuart',
  },
  {
    text: 'Ease in out quart',
    oldId: 'easeInOutQuart',
    value: easings.easeInOutQuart,
    id: 'easeInOutQuart',
  },
  {
    text: 'Ease in quint',
    oldId: 'easeInQuint',
    value: easings.easeInQuint,
    id: 'easeInQuint',
  },
  {
    text: 'Ease out quint',
    oldId: 'easeOutQuint',
    value: easings.easeOutQuint,
    id: 'easeOutQuint',
  },
  {
    text: 'Ease in out quint',
    oldId: 'easeInOutQuint',
    value: easings.easeInOutQuint,
    id: 'easeInOutQuint',
  },
  {
    text: 'Ease in exponential',
    oldId: 'easeInExpo',
    value: easings.easeInExpo,
    id: 'easeInExpo',
  },
  {
    text: 'Ease out exponential',
    oldId: 'easeOutExpo',
    value: easings.easeOutExpo,
    id: 'easeOutExpo',
  },
  {
    text: 'Ease in out exponential',
    oldId: 'easeInOutExpo',
    value: easings.easeInOutExpo,
    id: 'easeInOutExpo',
  },
  {
    text: 'Ease in circular',
    oldId: 'easeInCirc',
    value: easings.easeInCirc,
    id: 'easeInCirc',
  },
  {
    text: 'Ease out circular',
    oldId: 'easeOutCirc',
    value: easings.easeOutCirc,
    id: 'easeOutCirc',
  },
  {
    text: 'Ease in out circular',
    oldId: 'easeInOutCirc',
    value: easings.easeInOutCirc,
    id: 'easeInOutCirc',
  },
  {
    text: 'Spring',
    oldId: 'spring',
    value: easings.easeInOutBounce,
    id: 'spring',
  },
]);

const cubicBezier = (
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  t: number,
): number => {
  const cx = 3 * x1;
  const bx = 3 * (x2 - x1) - cx;
  const ax = 1 - cx - bx;
  const cy = 3 * y1;
  const by = 3 * (y2 - y1) - cy;
  const ay = 1 - cy - by;

  function sampleCurveX(t: number): number {
    return ((ax * t + bx) * t + cx) * t;
  }

  function sampleCurveY(t: number): number {
    return ((ay * t + by) * t + cy) * t;
  }

  function sampleCurveDerivativeX(t: number): number {
    return (3 * ax * t + 2 * bx) * t + cx;
  }

  function solveCurveX(x: number, epsilon = 1e-6): number {
    let t0, t1, t2, x2, d2;

    for (let i = 0; i < 8; i++) {
      t2 = x;
      x2 = sampleCurveX(t2) - x;
      if (Math.abs(x2) < epsilon) return t2;
      d2 = sampleCurveDerivativeX(t2);
      if (Math.abs(d2) < epsilon) break;
      t2 = t2 - x2 / d2;
    }

    t0 = 0;
    t1 = 1;
    t2 = x;

    if (t2 < t0) return t0;
    if (t2 > t1) return t1;

    while (t0 < t1) {
      x2 = sampleCurveX(t2);
      if (Math.abs(x2 - x) < epsilon) return t2;
      if (x > x2) t0 = t2;
      else t1 = t2;
      t2 = (t1 - t0) * 0.5 + t0;
    }
    return t2;
  }

  return sampleCurveY(solveCurveX(t));
};
