import { SoundDto } from '@web/api/api';
import { memo } from 'react';
import { AssetTabMobile } from '../AssetTabMobile';
import { AudioPreviewButton } from '../ui/AudioPreviewButton';

export const SoundTabMobile = () => {
  return <AssetTabMobile type="sound" PreviewComponent={PreviewSound} />;
};

const PreviewSound = memo(({ value }: { value: SoundDto | null }) => {
  if (value === null) {
    return null;
  }

  return (
    <AudioPreviewButton url={value.url} volume={value.volume} size="small" />
  );
});
