import { useAssetStore } from '@web/store/assets/state';
import { useSettingsStore } from '@web/store/settings';
import { AudioTag } from './AudioTag';

export const MusicTag = ({ width }: { width: number }) => {
  const {
    favorite: { music: favoriteList },
  } = useSettingsStore();

  const {
    music: { preset, user },
  } = useAssetStore();

  return (
    <AudioTag
      type="music"
      favoriteList={favoriteList}
      presetList={preset}
      userList={user}
      width={width}
    />
  );
};
