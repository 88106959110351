import * as ReactDOM from 'react-dom/client';

import { StrictMode } from 'react';
import App from './app/App';

import './styles.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);

export const maxContainerWidth = 1280;
