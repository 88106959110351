import { SxProps, Theme, styled } from '@mui/material';
import { Character_Alignment, Character_Side } from '@shared/types';
import React, { CSSProperties, useMemo } from 'react';
import { Image } from '../../common/ui/Image';
import { ZIndex } from '../utils/constants';

type CharacterProps = {
  url: string;
  name?: string;
  alignment: Character_Alignment;
  side: Character_Side;
  flipped?: boolean;
  limitWidth?: boolean;
  offsetX: number;
  offsetY: number;
  front?: number;
  sx?: SxProps<Theme>;
  style?: CSSProperties;
};

export const Character = ({
  url,
  offsetX = 0,
  offsetY = 0,
  name,
  side,
  alignment,
  flipped,
  limitWidth,
  front,
  sx,
  style,
}: CharacterProps) => {
  const sideAndOffsetStyle = useMemo(() => {
    return getSideAndOffsetStyle(side, alignment, flipped, offsetX, offsetY);
  }, [side, alignment, flipped, offsetX, offsetY]);

  return (
    <CharacterStyled
      src={url}
      alt={name ?? 'Character'}
      style={{
        ...sideAndOffsetStyle,
        ...(limitWidth ? { maxWidth: '100%' } : {}),
        ...style,
        zIndex: ZIndex.Character + (front ?? 0),
      }}
      sx={sx}
    />
  );
};

const CharacterStyled = styled(Image)(() => ({
  width: 'auto',
  height: '100%',
  position: 'absolute',
  userSelect: 'none',
  pointerEvents: 'none',
}));

const getSideAndOffsetStyle = (
  side: Character_Side,
  alignment: Character_Alignment,
  flipped: boolean | undefined,
  offsetX: number,
  offsetY: number,
): React.CSSProperties => {
  let position = [Character_Side.Defense, Character_Side.Counsel].includes(side)
    ? 'left'
    : side === Character_Side.Prosecution
      ? 'right'
      : 'center';

  if (alignment === Character_Alignment.Left) {
    position = 'left';
  } else if (alignment === Character_Alignment.Right) {
    position = 'right';
  }

  if (flipped) {
    if (position === 'left') {
      position = 'right';
    } else if (position === 'right') {
      position = 'left';
    }
  }

  if (position === 'left') {
    return {
      top: `${offsetY}%`,
      left: `${offsetX}%`,
      ...(flipped ? { scale: '-1 1' } : {}),
    };
  } else if (position === 'right') {
    return {
      top: `${offsetY}%`,
      right: `${offsetX}%`,
      ...(flipped ? { scale: '-1 1' } : {}),
    };
  } else {
    return {
      top: `${offsetY}%`,
      left: `${50 + offsetX}%`,
      translate: `${-50 + offsetX}% 0`,
      ...(flipped ? { scale: '-1 1' } : {}),
    };
  }
};
