import { Box, styled } from '@mui/material';
import { Image } from '@web/components/common/ui/Image';
import { ZIndex } from '../utils/constants';

export const NameplateContainer = styled(Box)(({ theme }) => ({
  zIndex: ZIndex.DialogueBox,
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  height: 'auto',
  textAlign: 'center',
}));

export const NameplateImage = styled(Image)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  width: '100%',
  height: 'auto',
}));

export const NameplateTextContainer = styled(Box)({
  color: '#ffffff',
  whiteSpace: 'nowrap',
  position: 'absolute',
  width: '100%',
});
