import {
  BackgroundDto,
  EvidenceDto,
  MusicDto,
  PopupDto,
  SoundDto,
} from '@web/api/api';
import { Character } from '@web/types/project';
import { derive } from 'derive-valtio';
import { proxy } from 'valtio';
import { useProxy } from 'valtio/utils';

type AssetRecords<T> = {
  cache: Record<string, T>;
  user: Array<T>;
  preset: Array<T>;
  presetLoaded?: boolean;
};

type AssetStoreType = {
  sound: AssetRecords<SoundDto>;
  music: AssetRecords<MusicDto>;
  evidence: AssetRecords<EvidenceDto>;
  background: AssetRecords<BackgroundDto>;
  popup: AssetRecords<PopupDto>;
  character: AssetRecords<Character>;
};

export type AssetType = keyof AssetStoreType;

export const assetStore: AssetStoreType = proxy({
  music: { cache: {}, preset: [], user: [] },
  sound: { cache: {}, preset: [], user: [] },
  evidence: { cache: {}, preset: [], user: [] },
  background: { cache: {}, preset: [], user: [] },
  popup: { cache: {}, preset: [], user: [] },
  character: { cache: {}, preset: [], user: [] },
});

const allPresetsLoaded = derive({
  isLoaded: (get) => {
    const { music, sound, background, character } = get(assetStore);

    return (
      music.presetLoaded &&
      sound.presetLoaded &&
      background.presetLoaded &&
      character.presetLoaded
    );
  },
});

export const useAssetStore = () => useProxy(assetStore);
export const useAllPresetsLoaded = () => useProxy(allPresetsLoaded).isLoaded;

export const usePreviewBackground = () => {
  const { background } = useAssetStore();

  return background.preset.find((f) => f.name === '[PW] Defense');
};
