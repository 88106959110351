export const FrameTextUtils = {
  getUniqueSoundTags(txt: string) {
    let data = this.parseTags(txt);
    const ids: string[] = [];

    while (data) {
      if (this.isValidSoundTag(data.tag)) {
        ids.push(data.tag.slice(3));
      }

      txt = txt.slice(data.last_index);

      data = this.parseTags(txt);
    }

    return [...new Set(ids)];
  },
  isValidSoundTag(tag: string) {
    return (
      tag &&
      tag.length > 3 &&
      tag.startsWith('bgs') &&
      isNaN(parseInt(tag.slice(3))) === false
    );
  },
  isStopMusicTagAtStart(frameText: string) {
    const data = this.parseTags(frameText);

    if (data) {
      if (data.tag === 'bgmd' && data.text_before.length <= 0) {
        return true;
      } else if (data.tag === 'bgms') {
        return true;
      } else {
        return (
          data.tag.startsWith('bgm') &&
          data.tag !== 'bgmfi' &&
          data.tag !== 'bgmfo' &&
          data.text_before.length <= 0
        );
      }
    }

    return false;
  },
  getMusicTagCommand(frameText: string) {
    if (!frameText || frameText.length === 0) return '';

    let data = this.parseTags(frameText);

    while (data) {
      if (data.tag === 'bgmd') {
        return 'bgmd';
      } else if (data.tag === 'bgms' || data.tag === 'bgmfo') {
        return 'bgms';
      } else if (
        data.tag.startsWith('bgm') &&
        data.tag !== 'bgmfi' &&
        data.tag !== 'bgmfo'
      ) {
        return data.tag;
      }

      frameText = frameText.slice(data.last_index);

      data = this.parseTags(frameText);
    }

    return '';
  },
  getMusicTagCommands(frameText: string) {
    const res: string[] = [];

    if (!frameText || frameText.length === 0) return res;

    let data = this.parseTags(frameText);

    while (data) {
      if (data.tag === 'bgmd') {
        res.push('bgmd');
      } else if (data.tag === 'bgms' || data.tag === 'bgmfo') {
        res.push('bgms');
      } else if (
        data.tag.startsWith('bgm') &&
        data.tag !== 'bgmfi' &&
        data.tag !== 'bgmfo'
      ) {
        res.push(data.tag);
      }

      frameText = frameText.slice(data.last_index);

      data = this.parseTags(frameText);
    }

    return res;
  },
  isShowingEvidence(txt: string) {
    let data = this.parseTags(txt);

    while (data) {
      if (
        (data.tag.startsWith('evd') || data.tag.startsWith('evdi')) &&
        data.tag !== 'evdh'
      ) {
        return true;
      }

      txt = txt.slice(data.last_index);

      data = this.parseTags(txt);
    }

    return false;
  },
  colorize(txt: string, color: string) {
    let res = '';
    color = color.replace('#', '');

    const openTag = `[#/${color}]`;
    const closeTag = `[/#]`;

    let data = this.parseTags(txt);

    while (data) {
      if (data.text_before.length > 0) {
        // text before tag
        res += `${openTag}${data.text_before}${closeTag}[#${data.tag}]`;
      } else if (
        data.text_before.length <= 0 &&
        data.contents.length <= 0 &&
        data.tag.length > 0
      ) {
        // simple tag
        res += `[#${data.tag}]`;
      } else if (data.contents.length > 0 && data.tag.length > 0) {
        // surround tag
        // note: this replaces the surround tag, right now there is no usable surround tag in courtroom
        res += `${openTag}${data.contents}${closeTag}[#${data.tag}]`;
      }

      txt = txt.slice(data.last_index);

      data = this.parseTags(txt);
    }

    if (txt.length > 0) {
      res += `${openTag}${txt}${closeTag}`;
    }

    return res;
  },
  // returns the ID of a tag like [#bgm200] will return 200, only for the first occurrence of the tag
  getFirstTagId(txt: string, tag: string) {
    let data = this.parseTags(txt);
    let id: number | undefined = undefined;

    while (data) {
      if (
        data.tag &&
        data.tag.length > tag.length &&
        data.tag.toLowerCase().startsWith(tag) &&
        isNaN(parseInt(data.tag.slice(tag.length))) === false
      ) {
        id = parseInt(data.tag.slice(tag.length));

        return id;
      }

      txt = txt.slice(data.last_index);

      data = this.parseTags(txt);
    }

    return id;
  },
  getExplanatoryText(txt: string) {
    let data = this.parseTags(txt);
    let res = '';

    while (data) {
      if (data.text_before.length > 0) {
        res += data.text_before;
      }

      if (data.contents.length > 0) {
        res += data.contents;
      }

      if (data.tag) {
        if (data.tag === 'fs' || data.tag === 'fm' || data.tag === 'fl')
          res += '[Flash]';
        else if (data.tag === 'ss' || data.tag === 'sm' || data.tag === 'sl')
          res += '[Shake]';
        else if (
          data.tag.startsWith('bgm') &&
          data.tag !== 'bgms' &&
          data.tag !== 'bgmfo' &&
          data.tag !== 'bgmfi'
        )
          res += '[Play Music]';
        else if (data.tag === 'bgms') res += '[Stop Music]';
        else if (data.tag === 'bgmfi') res += '[Fade In Music]';
        else if (data.tag === 'bgmfo') res += '[Fade Out Music]';
        else if (data.tag.startsWith('bgs') && data.tag !== 'bgss')
          res += '[Play Sound]';
        else if (data.tag === 'bgss') res += '[Stop Sounds]';
        else if (data.tag.startsWith('evd') && data.tag !== 'evdh')
          res += '[Show Evidence]';
      }

      txt = txt.slice(data.last_index);

      data = this.parseTags(txt);
    }

    if (txt.length > 0) {
      res += txt;
    }

    return res;
  },
  getPlainText(frameText: string) {
    if (!frameText) return '';

    let data = this.parseTags(frameText);
    let res = '';

    while (data) {
      if (data.text_before.length > 0) {
        res += data.text_before;
      }

      if (data.contents.length > 0) {
        res += data.contents;
      }

      frameText = frameText.slice(data.last_index);

      data = this.parseTags(frameText);
    }

    if (frameText.length > 0) {
      res += frameText;
    }

    return res;
  },
  getTagType(item: { tag: string }): TagItem {
    const ret: { type: TagType; param: string | number } = {
      type: TagType.Text,
      param: '',
    };

    if (item.tag === '') {
      ret.type = TagType.Text;
      ret.param = '';
    } else if (item.tag[0] === 'p') {
      ret.type = TagType.Pause;
      ret.param = getNumbers(item.tag);
    } else if (
      (item.tag[0] === 'r' ||
        item.tag[0] === 'b' ||
        item.tag[0] === 'g' ||
        item.tag[0] === 'c') &&
      !item.tag.startsWith('bg')
    ) {
      ret.type = TagType.ColorText;
      //ret.param = "#F77337";
      ret.param = 'r';

      //if (item.tag[0] === "b") ret.param = "#6BC7F6";
      //else if (item.tag[0] === "g") ret.param = "#00F61C";
      if (item.tag[0] === 'b') ret.param = 'b';
      else if (item.tag[0] === 'g') ret.param = 'g';
      else if (item.tag[0] === 'c') {
        const remainingTag = item.tag.substring(1);
        const remainingCode = '#' + remainingTag;

        if (isValidHexColor(remainingCode)) {
          ret.param = remainingCode;
        } else {
          ret.param = '#ffffff';
        }
      }
    } else if (
      item.tag.length > 1 &&
      item.tag[0] === 't' &&
      item.tag[1] === 's'
    ) {
      ret.type = TagType.TextSpeed;
      ret.param = getNumbers(item.tag, 0);
    } else if (
      item.tag.length > 1 &&
      item.tag[0] === 'f' &&
      (item.tag[1] === 's' || item.tag[1] === 'm' || item.tag[1] === 'l')
    ) {
      ret.type = TagType.Flash;
      ret.param = item.tag[1];
    } else if (
      item.tag.length > 1 &&
      item.tag.length <= 2 &&
      item.tag[0] === 's' &&
      (item.tag[1] === 's' || item.tag[1] === 'm' || item.tag[1] === 'l')
    ) {
      // legacy shake
      ret.type = TagType.ShakeLegacy;
      ret.param = item.tag[1];
    } else if (
      item.tag.length > 1 &&
      item.tag.length > 2 &&
      item.tag[0] === 's' &&
      (item.tag[1] === 's' || item.tag[1] === 'm' || item.tag[1] === 'l')
    ) {
      // shake w/ duration
      let dur = getNumbers(item.tag.slice(2));

      dur = !dur || String(dur).length === 0 || dur > 5000 ? 5000 : dur;

      ret.type = TagType.Shake;
      ret.param = `${item.tag[1]}|${dur}`;
    } else if (
      item.tag.length > 1 &&
      item.tag[0] === 's' &&
      (item.tag[1] === 's' || item.tag[1] === 'm' || item.tag[1] === 'l')
    ) {
      ret.type = TagType.Shake;
      ret.param = item.tag[1];
    } else if (item.tag === 'bgmd') {
      ret.type = TagType.PlayMusicDefault;
    } else if (item.tag === 'bgms') {
      ret.type = TagType.StopMusic;
    } else if (item.tag === 'bgmfo') {
      ret.type = TagType.FadeoutMusic;
    } else if (item.tag === 'bgmfi') {
      ret.type = TagType.FadeinMusic;
    } else if (this.isValidTag(item.tag, 'bgm')) {
      ret.type = TagType.PlayMusic;
      ret.param = getNumbers(item.tag);
    } else if (this.isValidTag(item.tag, 'bgs')) {
      ret.type = TagType.PlaySound;
      ret.param = getNumbers(item.tag);
    } else if (item.tag === 'bgss') {
      ret.type = TagType.StopSounds;
    } else if (this.isValidTag(item.tag, 'evd')) {
      ret.type = TagType.ShowEvidence;
      ret.param = getNumbers(item.tag);
    } else if (this.isValidTag(item.tag, 'evdi')) {
      ret.type = TagType.ShowEvidenceIcon;
      ret.param = getNumbers(item.tag);
    } else if (item.tag === 'evdh') {
      ret.type = TagType.HideEvidence;
    }

    return ret;
  },
  isValidTag(text: string, tag: string) {
    return (
      text &&
      text.length > tag.length &&
      text.startsWith(tag) &&
      !isNaN(parseFloat(text.slice(tag.length)))
    );
  },
  parseText(text: string): QueueItem[] {
    if (!text) return [];

    const queue: QueueItem[] = [];

    let data = this.parseTags(text);

    while (data) {
      if (data.text_before.length > 0) {
        queue.push({
          tag: '',
          contents: data.text_before,
        });
      }

      queue.push(data);

      text = text.slice(data.last_index);

      data = this.parseTags(text);
    }

    if (text.length > 0) {
      queue.push({
        tag: '',
        contents: text,
      });
    }

    return queue;
  },
  parseTags(text: string, isFirst?: boolean) {
    const tag_pattern = /(\[#(\/?)(.*?)\])|(\[\/#\])/g;

    let match = tag_pattern.exec(text);

    if (match !== null && (!isFirst || match.index === 0)) {
      if (match[4]) {
        return null;
      } else {
        const text_before = text.substring(0, match.index);
        const tag = match[3];

        if (!match[2]) {
          return {
            text_before: text_before,
            last_index: tag_pattern.lastIndex,
            tag: tag,
            contents: '',
          };
        } else {
          const startIndex = match.index + match[0].length;

          let level = 1;

          while (level > 0 && (match = tag_pattern.exec(text)) !== null) {
            if (match[4]) {
              level--;
            } else if (match[2]) {
              level++;
            }
          }

          if (level > 0) {
            return null;
          } else if (level < 0) {
            return null;
          } else {
            return {
              text_before: text_before,
              last_index: tag_pattern.lastIndex,
              tag: tag,
              contents: text.substring(startIndex, match?.index),
            };
          }
        }
      }
    }

    return null;
  },
};

export enum TagType {
  Text = 'text',
  Pause = 'pause',
  ColorText = 'color_text',
  TextSpeed = 'text_speed',
  Flash = 'flash',
  ShakeLegacy = 'shake_legacy',
  Shake = 'shake',
  PlayMusicDefault = 'play_music_default',
  StopMusic = 'stop_music',
  FadeoutMusic = 'fadeout_music',
  FadeinMusic = 'fadein_music',
  PlayMusic = 'play_music',
  PlaySound = 'play_sound',
  StopSounds = 'stop_sounds',
  ShowEvidence = 'show_evidence',
  ShowEvidenceIcon = 'show_evidence_icon',
  HideEvidence = 'hide_evidence',
}

export type QueueItem =
  | {
      text_before: string;
      last_index: number;
      tag: string;
      contents: string;
    }
  | {
      tag: string;
      contents: string;
    };

export type TagItem = {
  type: TagType;
  param: string | number;
};

const getNumbers = (str: string, def = 0) => {
  const num = parseInt(str.replace(/\D/g, ''));
  return isNaN(num) ? def : num;
};

const isValidHexColor = (str: string) => {
  return /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(str);
};
