import {
  Button,
  Snackbar,
  SnackbarCloseReason,
  SnackbarContent,
  Typography,
  useTheme,
} from '@mui/material';
import { makerActions } from '@web/store/maker/actions';
import { makerStore, useMakerStore } from '@web/store/maker/state';
import React, { useEffect, useState } from 'react';

export const FrameDeletionUndoSnackbar = () => {
  const [show, setShow] = useState(false);
  const { deletedFrame } = useMakerStore();

  const theme = useTheme();

  const handleUndo = () => {
    makerActions.undoDeletedFrame();

    makerStore.deletedFrame = undefined;
  };

  const handleClose = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    event: Event | React.SyntheticEvent<any, Event>,
    reason: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setShow(false);

    makerStore.deletedFrame = undefined;
  };

  useEffect(() => {
    setShow(!!deletedFrame);
  }, [deletedFrame]);

  return (
    <Snackbar
      open={show}
      autoHideDuration={5000}
      onClose={handleClose}
      message="Frame deleted"
    >
      <SnackbarContent
        sx={{ width: '100%', bgcolor: 'background.paper' }}
        message={
          <Typography color={theme.palette.text.primary}>
            Frame deleted
          </Typography>
        }
        action={
          <>
            <Button color="info" size="small" onClick={handleUndo}>
              Undo
            </Button>

            <Button
              color="inherit"
              sx={{
                color:
                  theme.palette.mode === 'dark'
                    ? theme.palette.common.white
                    : theme.palette.text.primary,
              }}
              size="small"
              onClick={() => setShow(false)}
            >
              Close
            </Button>
          </>
        }
      />
    </Snackbar>
  );
};
