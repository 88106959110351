import Footer from '@web/components/common/Footer';
import Navbar from '@web/components/common/Navbar';
import { Outlet } from 'react-router-dom';

const PrimaryLayout = () => {
  return (
    <>
      <Navbar />

      <Outlet />

      <Footer />
    </>
  );
};

export default PrimaryLayout;
