import { FrameTextUtils } from './frameText';

export const EditorUtils = {
  surroundTextWithColor(editor: HTMLTextAreaElement, tag: string) {
    const startSelection = editor.selectionStart;
    const endSelection = editor.selectionEnd;
    const currentText = editor.value;

    if (
      !currentText ||
      currentText.length <= 0 ||
      startSelection < 0 ||
      endSelection < 0
    )
      return;

    const selected = currentText.substring(startSelection, endSelection);
    const str1 = currentText.substring(0, startSelection);
    const str2 = currentText.substring(endSelection, currentText.length);

    const text = `${str1}${FrameTextUtils.colorize(selected, tag)}${str2}`;

    return text;
  },
  insertTag(editor: HTMLTextAreaElement, tag: string) {
    let cursorPos = editor.selectionStart;
    const insert = `[#${tag}]`;

    const text =
      editor.value.substring(0, cursorPos) +
      insert +
      editor.value.substring(cursorPos);

    cursorPos += insert.length;

    return { text, cursorPos };
  },
  insertSurroundTag(tag: string) {
    // const startSelection = this.elem.selectionStart;
    // const endSelection = this.elem.selectionEnd;
    // const txt = this.frame.text;
    // if (!txt || txt.length <= 0 || startSelection < 0 || endSelection < 0)
    //   return;
    // const selected = txt.substring(startSelection, endSelection);
    // const str1 = txt.substring(0, startSelection);
    // const str2 = txt.substring(endSelection, txt.length);
    // const text = `${str1}[#/${tag}]${selected}[/#]${str2}`;
    // this.frame.text = text;
    // this.$store.commit('maker/MARK_UNSAVED');
  },
};
