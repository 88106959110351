import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grow,
  IconButton,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  Slide,
  Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';

const StyledDialog = styled(MuiDialog, {
  shouldForwardProp: (prop) => prop !== 'disableContentPadding',
})<{ disableContentPadding: boolean }>(({ theme, disableContentPadding }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(disableContentPadding ? 0 : 2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

type DialogProps = {
  children: React.ReactNode;
  actions?: React.ReactNode;
  open: boolean;
  persist?: boolean;
  onClose: () => void;
  title?: string;
  dividers?: boolean;
  disableContentPadding?: boolean;
} & MuiDialogProps;

const Dialog = React.memo(
  ({
    title,
    open,
    onClose,
    persist,
    children,
    actions,
    dividers = true,
    disableContentPadding = false,
    ...props
  }: DialogProps) => {
    const handleClose = (
      event: {},
      reason: 'backdropClick' | 'escapeKeyDown',
    ) => {
      if (!persist) {
        onClose();
      }
    };

    return (
      <StyledDialog
        onClose={handleClose}
        open={open}
        fullWidth
        maxWidth="sm"
        disableContentPadding={disableContentPadding}
        {...props}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <DialogTitle sx={{ m: 0, p: 2 }}>
            {title || <Box sx={{ height: '8px' }} />}
          </DialogTitle>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 10,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>

        {dividers && title && <Divider />}

        <DialogContent>{children}</DialogContent>

        {actions && (
          <>
            {dividers && <Divider />}

            <DialogActions>{actions}</DialogActions>
          </>
        )}
      </StyledDialog>
    );
  },
);

export const DialogTransitionSlide = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) => {
    return <Slide direction="up" ref={ref} {...props} />;
  },
);

export const DialogTransitionGrow = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) => {
    return <Grow ref={ref} {...props} />;
  },
);

export default Dialog;
