import { PaperProps, Stack, Typography } from '@mui/material';
import { EvidenceDto } from '@web/api/api';
import { BaseRenderAutocompleteOptionWithFavorite } from '@web/components/common/ui/RenderAutocompleteOptionWithFavorite';
import { useAssetStore } from '@web/store/assets/state';
import { forwardRef } from 'react';
import {
  AutocompleteWithFavorites,
  StyledAutocompletePopper,
} from '../../common/form/AutocompleteWithFavorites';
import { RenderAutocompleteInput } from '../../common/ui/RenderAutocompleteInput';
import { VirtualizedListbox } from '../../common/ui/VirtualizedListbox';
import { useAssetOptions } from '../hooks/useAssetOptions';
import { AssetImageServerIcon } from './AssetImageIcon';

type EvidencePickerProps = {
  value: number | null;
  onChange: (value: number | null) => void;
};

export const EvidencePicker = ({ value, onChange }: EvidencePickerProps) => {
  const {
    evidence: { user },
  } = useAssetStore();

  const { selectedValue, sortedOptions } = useAssetOptions({
    value,
    type: 'evidence',
    presetList: [],
    userList: user,
  });

  return (
    <AutocompleteWithFavorites
      type="evidence"
      label=""
      size="small"
      ListboxComponent={EvidenceVirtualizedListBox}
      ListboxProps={{ style: { maxHeight: '364px' } }}
      PopperComponent={StyledAutocompletePopper}
      value={selectedValue}
      onChange={(_, value) => onChange(value?.id ?? null)}
      options={sortedOptions}
      slotProps={{
        paper: {} as PaperProps,
      }}
      renderInput={(params) => (
        <RenderAutocompleteInput
          variant="outlined"
          label="Evidence"
          params={params}
        />
      )}
      renderOption={(
        props,
        option,
        optionState,
        favoriteList,
        handleFavoriteToggle,
      ) => {
        const evidence = option as EvidenceDto;

        return (
          <BaseRenderAutocompleteOptionWithFavorite
            key={option.id}
            props={props}
            optionId={option.id}
            optionState={optionState}
            favoriteList={favoriteList}
            handleFavoriteToggle={handleFavoriteToggle}
          >
            <RenderEvidenceOption evidence={evidence} />
          </BaseRenderAutocompleteOptionWithFavorite>
        );
      }}
      disableClearable
    />
  );
};

const RenderEvidenceOption = ({ evidence }: { evidence: EvidenceDto }) => {
  return (
    <Stack direction="row" spacing={2} alignItems="center" overflow="hidden">
      <AssetImageServerIcon
        id={evidence.id}
        type="evidence"
        alt={evidence.name}
      />

      <Typography variant="body2" noWrap={true} color="textPrimary">
        {evidence.name}
      </Typography>
    </Stack>
  );
};

const EvidenceVirtualizedListBox = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>((props, ref) => {
  return (
    <VirtualizedListbox ref={ref} {...props} itemHeight={52} height={52 * 7}>
      {props.children}
    </VirtualizedListbox>
  );
});
