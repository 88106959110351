import styled from '@emotion/styled';
import { SxProps, Theme } from '@mui/material';
import { Image } from '../../common/ui/Image';
import { ZIndex } from '../utils/constants';

type GallerySpriteProps = {
  url: string;
  sx?: SxProps<Theme>;
};

export const GallerySprite = ({ url, sx }: GallerySpriteProps) => {
  return <GallerySpriteStyled src={url} alt="GallerySprite" sx={sx} />;
};

const GallerySpriteStyled = styled(Image)(({ theme }) => {
  return {
    maxWidth: '100%',
    height: 'auto',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: ZIndex.Character,
    userSelect: 'none',
    pointerEvents: 'none',
  };
});
