import { useAuth } from '@web/providers/auth/AuthProvider';
import { setAuthContext } from '@web/providers/auth/authService';
import { settingsActions } from '@web/store/settings/actions';
import { useEffect } from 'react';

export const useApp = () => {
  const authContext = useAuth();

  useEffect(() => {
    setAuthContext(authContext);
  }, [authContext]);

  useEffect(() => {
    settingsActions.load();
  }, []);
};
