import { useState } from 'react';

export const useAnchorMenu = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const toggle = (event: React.MouseEvent<HTMLElement>) => {
    anchorEl ? close() : open(event);
  };

  const close = () => {
    setAnchorEl(null);
  };

  return { anchorEl, open, toggle, close };
};
