import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Link,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import Dialog, {
  DialogTransitionSlide,
} from '@web/components/common/ui/Dialog';
import { makerStore, useMakerStore } from '@web/store/maker/state';
import React from 'react';
import { isMobile } from 'react-device-detect';

export const MakerHelpDialog = () => {
  const {
    dialogs: { help },
  } = useMakerStore();

  const handleClose = () => {
    makerStore.dialogs.help = false;
  };

  const [expanded, setExpanded] = React.useState<number | false>(false);

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Dialog
      open={!!help}
      onClose={handleClose}
      title="Help / Information"
      fullScreen={true}
      disableContentPadding={true}
      TransitionComponent={DialogTransitionSlide}
    >
      <Container maxWidth="md" sx={{ px: { xs: 1, sm: 2 } }}>
        <Accordion
          sx={{ mt: 2 }}
          expanded={expanded === 0}
          onChange={handleChange(0)}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">General</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <UnorderedList>
              <UnorderedListItem>Tags cannot be nested.</UnorderedListItem>
              <UnorderedListItem>
                To hide the name plate, enter a single dot "." as custom name
                for the frame.
              </UnorderedListItem>
              <GeneralProjectInfo />
            </UnorderedList>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 1} onChange={handleChange(1)}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">Saving</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <UnorderedList>
              <UnorderedListItem>
                Objection Maker auto saves on your browser, except when editing
                a submitted project.
              </UnorderedListItem>
              <UnorderedListItem>
                If you are logged in, backups for all projects are automatically
                taken if enabled, by default it is enabled.
              </UnorderedListItem>
              <UnorderedListItem>
                You can save your project as a file at the top-left menu.
              </UnorderedListItem>
            </UnorderedList>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 2} onChange={handleChange(2)}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">Music</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <UnorderedList>
              <UnorderedListItem>
                Each frame can only have one play music tag, the rest of them
                will be ignored.
              </UnorderedListItem>
              <UnorderedListItem>
                Preset music will loop automatically, custom music will loop by
                seeking back to the beginning of the track.
              </UnorderedListItem>
            </UnorderedList>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 3} onChange={handleChange(3)}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">Frame Flow</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <UnorderedList>
              <UnorderedListItem>
                A Frame runs in the following order:
                <br />
                {'Frame Fade -> Frame Actions -> Frame Body -> Case Action'}
              </UnorderedListItem>
            </UnorderedList>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 4} onChange={handleChange(4)}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">Evidence</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <UnorderedList>
              <UnorderedListItem>
                Each frame can only have one show evidence tag, the rest of them
                will be ignored.
              </UnorderedListItem>
              <UnorderedListItem>
                The evidence will keep showing till you hide it with the hide
                evidence tag {'[#evdh]'}.
              </UnorderedListItem>
            </UnorderedList>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 5} onChange={handleChange(5)}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">Background Transition</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <UnorderedList>
              <UnorderedListItem>
                To use a background transition, you need to select a wide
                background.
              </UnorderedListItem>
              <UnorderedListItem>
                The character will change halfway through the transition
                duration.
              </UnorderedListItem>
              <UnorderedListItem>
                It pauses during the transition automatically.
              </UnorderedListItem>
            </UnorderedList>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 6} onChange={handleChange(6)}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">Offsets</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <UnorderedList>
              <UnorderedListItem>
                Offsets are relative to the width of the background, when using
                wide backgrounds your offsets will look different.
              </UnorderedListItem>
              <UnorderedListItem>
                You can use pairing to offset characters or configure the
                character offset in manage assets.
              </UnorderedListItem>
            </UnorderedList>
          </AccordionDetails>
        </Accordion>

        {!isMobile && (
          <Accordion expanded={expanded === 7} onChange={handleChange(7)}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h6">Keyboard Shortcuts</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <KeyboardShortcutsModal />
            </AccordionDetails>
          </Accordion>
        )}

        {!isMobile && (
          <Accordion expanded={expanded === 8} onChange={handleChange(8)}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h6">Tags Shortcuts</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                You can type the following and then press TAB to insert tags
                using shortcuts {'(replace 500 with your number)'}
                <br />
                <br />
              </Typography>

              <UnorderedList>
                <UnorderedListItem>p.500: Pause tag</UnorderedListItem>
                <UnorderedListItem>ts.500: Text speed tag</UnorderedListItem>
                <UnorderedListItem>
                  ss.500: Shake {'(small)'} tag
                </UnorderedListItem>
                <UnorderedListItem>
                  sm.500: Shake {'(medium)'} tag
                </UnorderedListItem>
                <UnorderedListItem>
                  sl.500: Shake {'(large)'} tag
                </UnorderedListItem>
              </UnorderedList>
            </AccordionDetails>
          </Accordion>
        )}

        <Accordion expanded={expanded === 9} onChange={handleChange(9)}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">Export MP4</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <UnorderedList>
              <UnorderedListItem>
                Export MP4 records up to 7-9 minutes, to have unlimited time use
                the recording tool.
              </UnorderedListItem>
              <UnorderedListItem>
                MP4 export is only available for scene projects. If you
                mistakenly created a case project, you can convert it to a scene
                project:
                <br />
                <br />
                <OrderedList>
                  <UnorderedListItem>
                    Go to the "Edit Case" section.
                  </UnorderedListItem>
                  <UnorderedListItem>
                    Save the project as a file.
                  </UnorderedListItem>
                  <UnorderedListItem>
                    Load the saved file in the Objection Maker and choose
                    "Convert to Scene Project" from the top-left menu.
                  </UnorderedListItem>
                </OrderedList>
              </UnorderedListItem>
            </UnorderedList>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 10} onChange={handleChange(10)}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">Help</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <UnorderedList>
              <UnorderedListItem>
                Facing a problem? Email objection@objection.lol or direct
                message our{' '}
                <Link
                  underline="hover"
                  color="Highlight"
                  href="https://x.com/objection_lol"
                  target="_blank"
                >
                  X (Twitter)
                </Link>
                .
              </UnorderedListItem>
            </UnorderedList>
          </AccordionDetails>
        </Accordion>
      </Container>
    </Dialog>
  );
};

const KeyboardShortcutsModal = () => {
  const shortcuts = [
    { key: 'W / D', action: 'Go to next page' },
    { key: 'S / A', action: 'Go to previous page' },
    { key: 'V', action: 'Toggle scene editor dialog' },
    {
      key: 'E / Q',
      action: 'Use the navigation arrows in the scene editor dialog',
    },
    { key: 'F', action: 'Toggle frame effects dialog' },
    { key: 'T', action: 'Toggle frame actions dialog' },
    { key: 'I', action: 'Toggle the aliases dialog' },
    {
      key: 'R',
      action: 'Toggle the pairs dialog',
    },
    { key: 'P', action: 'Preview' },
    { key: 'CTRL + ALT + N', action: 'New Project' },
    { key: 'CTRL + S', action: 'Save Project' },
    { key: 'CTRL + L', action: 'Load Project' },
  ];

  return (
    <Table size="small">
      <TableBody>
        {shortcuts.map((shortcut, index) => (
          <TableRow key={index}>
            <TableCell>{shortcut.key}</TableCell>
            <TableCell>{shortcut.action}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const GeneralProjectInfo = () => {
  const { project } = useMakerStore();

  return project?.type === 'case' ? (
    <UnorderedListItem>
      Each group can have 10000 frames, 200 unique custom characters, or 200
      unique custom popups, whichever comes first.
    </UnorderedListItem>
  ) : (
    <UnorderedListItem>
      You can have up to 10000 frames, 200 unique custom characters, or 200
      unique custom popups, whichever comes first.
    </UnorderedListItem>
  );
};

const UnorderedList = ({ children }: { children: React.ReactNode }) => (
  <List sx={{ listStyleType: 'disc', px: 2, py: 0 }}>{children}</List>
);

const OrderedList = ({ children }: { children: React.ReactNode }) => (
  <List sx={{ listStyleType: 'numbers', px: 2, py: 0 }}>{children}</List>
);

const UnorderedListItem = ({ children }: { children: React.ReactNode }) => (
  <ListItem sx={{ display: 'list-item', pl: 0, pt: 0 }}>{children}</ListItem>
);
