import { useEffect, useRef } from 'react';
import { usePlayerSaveLoad } from '../providers/PlayerSaveLoadProvider';

type SaveLoad<T> = {
  name: string;
  onSave: () => T;
  onLoad: (state: T) => void;
};

export const usePlayerSaveLoadRegister = <T>({
  name,
  onSave,
  onLoad,
}: SaveLoad<T>) => {
  const context = usePlayerSaveLoad();

  const savedOnSave = useRef(onSave);
  const savedOnLoad = useRef(onLoad);

  useEffect(() => {
    savedOnSave.current = onSave;
    savedOnLoad.current = onLoad;
  }, [onSave, onLoad]);

  useEffect(() => {
    if (!context) {
      return;
    }

    const { register } = context;

    register(name, savedOnSave.current, savedOnLoad.current);
  }, [context, name]);
};
