import { Button, ButtonProps, useMediaQuery, useTheme } from '@mui/material';

export const AppBarButton = ({
  children,
  ...props
}: ButtonProps & { to?: string }) => {
  const height = useAppBarHeight();
  //   const theme = useTheme();

  return (
    <Button
      {...props}
      sx={{
        height,
        p: 2,
        borderRadius: 0,
        // color: theme.palette.secondary.contrastText,
        color: 'inherit',
        ...props.sx,
      }}
    >
      {children}
    </Button>
  );
};

const useAppBarHeight = (): number => {
  const {
    mixins: { toolbar },
    breakpoints,
  } = useTheme();

  const queryDesktop = breakpoints.up('sm');
  const queryLandscape = `${breakpoints.up('xs')} and (orientation: landscape)`;

  const isDesktop = useMediaQuery(queryDesktop);
  const isLandscape = useMediaQuery(queryLandscape);

  const cssToolbar =
    toolbar[isDesktop ? queryDesktop : isLandscape ? queryLandscape : ''];

  return ((cssToolbar ?? toolbar) as { minHeight: number })?.minHeight ?? 0;
};
