import { CaseFrame, Pair } from '@shared/types';
import { useBackground } from '@web/components/player/hooks/useBackground';
import { useCharacter } from '@web/components/player/hooks/useCharacter';
import { forwardRef } from 'react';
import { ThumbnailProvider } from '../maker/providers/ThumbnailProvider';
import { useCharacters } from '../player/hooks/useCharacters';
import { usePopup } from '../player/hooks/usePopup';
import { ThumbnailBackground } from './ThumbnailBackground';
import { ThumbnailCharacters } from './ThumbnailCharacter';
import { ThumbnailContainer } from './ThumbnailContainer';
import { ThumbnailPopup } from './ThumbnailPopup';
import { ThumbnailSpeechBubble } from './ThumbnailSpeechBubble';
import { ThumbnailViewport } from './ThumbnailViewport';

interface ThumbnailProps {
  onClick?: () => void;
  serverThumbnails?: boolean;
  frame: CaseFrame;
  pair?: Pair;
}

export const Thumbnail = forwardRef(
  ({ frame, pair, serverThumbnails = true, onClick }: ThumbnailProps, ref) => {
    const background = useBackground(frame);
    const character = useCharacter(frame);
    const characters = useCharacters(frame, pair ? [pair] : undefined);
    const speechBubble = frame?.speechBubble
      ? character?.speechBubbles.find((f) => f.id === frame.speechBubble)
      : undefined;
    const popup = usePopup(frame);
    const pose = character?.poses?.find((pose) => pose.id === frame?.poseId);
    const effect = frame?.effect;
    const pairPoses = frame?.pair?.poseIds;
    const transition = frame?.transition;
    const flipTargets = frame?.flipped;

    return (
      <ThumbnailProvider
        value={{
          serverThumbnails,
          background,
          character,
          characters,
          pairPoses,
          speechBubble,
          popup,
          pose,
          effect,
          transition,
          flipTargets,
          pair,
        }}
      >
        <ThumbnailContainer ref={ref} onClick={onClick}>
          <ThumbnailViewport>
            <ThumbnailBackground />
            <ThumbnailCharacters />
          </ThumbnailViewport>
          <ThumbnailSpeechBubble />
          <ThumbnailPopup />
        </ThumbnailContainer>
      </ThumbnailProvider>
    );
  },
);
