import { useEffect, useRef, useState } from 'react';

export const useDeleteConfirmation = (
  selectedIds?: number[] | string[] | undefined,
) => {
  const [confirming, setConfirming] = useState(false);
  const [enableConfirm, setEnableConfirm] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const timeout = useRef<ReturnType<typeof setTimeout>>();

  const confirm = async (callback: () => Promise<void>) => {
    setDeleting(true);

    await callback();

    setConfirming(false);
    setDeleting(false);
    setEnableConfirm(true);
  };

  const startConfirming = () => {
    setConfirming(true);

    if (selectedIds?.length ?? 0 > 1) {
      setEnableConfirm(false);

      timeout.current = setTimeout(
        () => {
          setEnableConfirm(true);
        },
        Math.min((selectedIds?.length ?? 0) * 250, 3000),
      );
    } else {
      setEnableConfirm(true);
    }
  };

  const cancelConfirming = () => {
    setConfirming(false);
    setEnableConfirm(true);
  };

  useEffect(() => {
    if (confirming && !deleting) {
      cancelConfirming();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIds]);

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  return {
    confirming,
    startConfirming,
    cancelConfirming,
    confirm,
    deleting,
    enableConfirm,
  };
};
