import {
  Box,
  FormControlLabel,
  FormGroup,
  Switch as MuiSwitch,
} from '@mui/material';

type SwitchProps = {
  label: string;
} & React.ComponentProps<typeof MuiSwitch>;

const Switch = ({ label, ...props }: SwitchProps) => {
  return (
    <FormGroup>
      <Box display="inline-block">
        <FormControlLabel
          control={<MuiSwitch {...props} />}
          label={label}
          sx={{ marginRight: 0 }}
        />
      </Box>
    </FormGroup>
  );
};

export default Switch;
