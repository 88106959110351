import { ProjectType } from '@shared/types';
import { CaseResponseDto, SceneResponseDto } from '@web/api/api';
import { ApiClient } from '@web/api/api-client';
import { GameUtils } from '@web/components/player/utils/game-utils';
import {
  migrateCharacterIds,
  migrateFadeEasings,
} from '@web/data/data-migration';
import { useAuth } from '@web/providers/auth/AuthProvider';
import { assetActions } from '@web/store/assets/actions';
import { assetStore } from '@web/store/assets/state';
import { makerActions } from '@web/store/maker/actions';
import { makerStore, useMakerStore } from '@web/store/maker/state';
import { rootStore } from '@web/store/root/state';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSnapshot } from 'valtio';

type MakerUrlParamsType = {
  id?: string;
  type?: 'scene' | 'case';
};

export const useMaker = () => {
  const { presetLoaded } = useSnapshot(assetStore.character);
  const { project } = useMakerStore();
  const { loggedIn } = useAuth();

  const location = useLocation();
  const navigate = useNavigate();

  // get the id from url
  const { id, type } = useParams<MakerUrlParamsType>();

  const loadProject = useCallback(async () => {
    if (id && type) {
      let data: SceneResponseDto | CaseResponseDto | null = null;

      try {
        rootStore.loading = true;

        if (type === 'scene') {
          data = (await ApiClient['scene'].getEdit(parseInt(id))).data;
        } else if (type === 'case') {
          data = (await ApiClient.case.getEdit(id)).data;
        }

        if (!data) {
          enqueueSnackbar('Project not found', { variant: 'error' });

          navigate('/');

          return;
        }

        makerActions.loadProject(
          data.data,
          data.title ?? '',
          'caseId' in data ? data.caseId : data.id,
        );
      } catch (e) {
        console.error(e);

        enqueueSnackbar('Could not load project.', { variant: 'error' });

        navigate('/');
      } finally {
        rootStore.loading = false;
      }

      return;
    }

    if (!localStorage.getItem('makerFrames')) {
      makerActions.newProject(ProjectType.Scene);
      makerActions.addFrame(0);
    } else {
      makerActions.loadProject(
        JSON.parse(localStorage.getItem('makerFrames')!),
      );
    }
  }, [id, navigate, type]);

  useEffect(() => {
    loadProject();

    assetActions.loadPresetAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (loggedIn) {
      assetActions.loadUserAssets();
    }
  }, [loggedIn]);

  useEffect(() => {
    if (presetLoaded && makerStore.project) {
      const allFrames = GameUtils.getAllFrames(makerStore.project);

      migrateCharacterIds(allFrames);
      migrateFadeEasings(allFrames);
    }
  }, [presetLoaded, project?.id]);
};
