import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { Checkbox } from '@web/components/common/form/Checkbox';
import { makerStore } from '@web/store/maker/state';
import React, { useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { useProjectConversion } from '../hooks/useProjectConvertion';

export const ConvertProjectDialog = React.memo(() => {
  const [confirm, setConfirm] = useState(false);
  const snapshot = useSnapshot(makerStore);

  const { canConvert, convertToCase, convertToScene } = useProjectConversion();
  const currentType = snapshot.project?.type ?? 'scene';

  const handleConvert = () => {
    if (currentType === 'scene') {
      convertToCase();
    } else {
      convertToScene();
    }

    handleClose();
  };

  const handleClose = () => {
    makerStore.dialogs.convertProject = false;
  };

  useEffect(() => {
    setConfirm(false);
  }, [snapshot.dialogs.convertProject]);

  return (
    <Dialog
      open={!!snapshot.dialogs.convertProject}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>
        Convert to {currentType === 'scene' ? 'Case' : 'Scene'}
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 0 }}>
        <Stack spacing={2} pb={2}>
          {currentType === 'case' ? (
            <>
              <Typography>
                Converting the project type to scene will permanently delete
                data available to cases only.
              </Typography>

              <Typography>
                Scenes can be recorded or exported as a video. Proceed?
              </Typography>
            </>
          ) : (
            <>
              <Typography>
                Converting the project type to case can be irreversible if you
                use case features.
              </Typography>

              <Typography>
                Cases can only be played on a browser. Proceed?
              </Typography>
            </>
          )}

          <Checkbox
            size="small"
            label="I understand"
            value={confirm}
            onChange={setConfirm}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          spacing={2}
          px={1}
          flexGrow={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            color="primary"
            onClick={handleConvert}
            disabled={!confirm || !canConvert}
          >
            Confirm
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
});
