export enum Character_Alignment {
  Default = 0,
  Left = 1,
  Right = 2,
}

export enum Character_Side {
  'Defense' = 'defense',
  'Prosecution' = 'prosecution',
  'Witness' = 'witness',
  'Judge' = 'judge',
  'Counsel' = 'counsel',
  'Gallery' = 'gallery',
}
