import { Box, Card, CardContent, Popover } from '@mui/material';
import { useAnchorMenu } from '@web/hooks/useAnchorMenu';
import React, { useState } from 'react';

type ContextMenuProps = {
  children: React.ReactNode;
};

export const ContextMenu = ({ children }: ContextMenuProps) => {
  const { anchorEl, toggle, close } = useAnchorMenu();
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });

  if (React.Children.count(children) !== 2) {
    throw new Error('ContextMenu requires exactly 2 children.');
  }

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();

    setMenuPosition({ top: event.clientY, left: event.clientX });

    toggle(event);
  };

  const [triggerElement, popoverContentElement] =
    React.Children.toArray(children);

  return (
    <Box>
      <Popover
        open={!!anchorEl}
        onClose={close}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition}
        transitionDuration={0}
        disableScrollLock
      >
        <Card>
          <CardContent>{popoverContentElement}</CardContent>
        </Card>
      </Popover>

      <Box sx={{ cursor: 'pointer' }} onClick={handleClick}>
        {triggerElement}
      </Box>
    </Box>
  );
};
