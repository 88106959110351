/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';

type SaveLoad<T> = {
  save: () => T;
  load: (state: T) => void;
};

type SaveLoadContext = {
  comps: {
    [key: string]: SaveLoad<any>;
  };
  register: <T>(key: string, save: () => T, load: (state: T) => void) => void;
  save: () => any;
  load: (data: any) => void;
};

const saveLoadContext = createContext<SaveLoadContext | undefined>(undefined);

export const usePlayerSaveLoad = () => {
  const context = useContext(saveLoadContext);

  const contextValue = useMemo(() => {
    if (!context) {
      return undefined;
    }

    const { save, load, register } = context;

    return { save, load, register };
  }, [context]);

  return contextValue;
};

export const PlayerSaveLoadProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const comps = useRef<SaveLoadContext['comps']>({});

  const register = useCallback(
    <T,>(key: string, save: () => T, load: (state: T) => void) => {
      if (comps.current[key]) {
        delete comps.current[key];
      }

      comps.current[key] = { save, load };
    },
    [],
  );

  const save = useCallback(() => {
    const data: Record<string, any> = {};

    for (const key in comps.current) {
      data[key] = comps.current[key].save();
    }

    return data;
  }, []);

  const load = useCallback((data: Record<string, any>) => {
    for (const key in comps.current) {
      if (data[key]) {
        comps.current[key].load(data[key]);
      }
    }
  }, []);

  return (
    <saveLoadContext.Provider
      value={{ comps: comps.current, register, save, load }}
    >
      {children}
    </saveLoadContext.Provider>
  );
};
