import styled from '@emotion/styled';
import { SxProps, Theme } from '@mui/material';
import { CSSProperties } from 'react';
import { Image } from '../../common/ui/Image';
import { ZIndex } from '../utils/constants';

type DeskProps = {
  url?: string;
  isWide?: boolean;
  flipped?: boolean;
  sx?: SxProps<Theme>;
  style?: CSSProperties;
};

export const Desk = ({ url, isWide, flipped, sx, style }: DeskProps) => {
  if (!url) {
    return null;
  }

  return (
    <DeskStyled
      src={url}
      alt="Desk"
      style={{
        width: !isWide ? '100%' : 'auto',
        ...(flipped ? { scale: '-1 1' } : {}),
        ...style,
      }}
      sx={sx}
    />
  );
};

const DeskStyled = styled(Image)(({ theme }) => {
  return {
    height: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: ZIndex.Desk,
    userSelect: 'none',
    pointerEvents: 'none',
  };
});
