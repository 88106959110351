/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  DialogContent,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DialogTransitionGrow } from '@web/components/common/ui/Dialog';
import {
  DraggableDialog,
  DraggableDialogDefaultTitle,
} from '@web/components/common/ui/DraggableDialog';
import MultiHideView from '@web/components/common/ui/MultiHideView';
import { makerStore, useMakerStore } from '@web/store/maker/state';
import { memo, useCallback, useState } from 'react';
import { FrameFadeEditor } from '../frame/FrameFadeEditor';
import { FrameEffectEditor } from '../frame/FrameFilterEditor';

export const FrameEffectsDialog = memo(() => {
  const {
    dialogs: { frameEffects },
  } = useMakerStore();

  const handleClose = () => {
    makerStore.dialogs.frameEffects = false;
  };

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <DraggableDialog
      open={!!frameEffects}
      onClose={handleClose}
      titleComponent={<FrameEffectsDialogTitle />}
      TransitionComponent={DialogTransitionGrow}
      transitionDuration={200}
      maxWidth="sm"
      fullscreen={sm}
      hideBackdrop={false}
      disableEnforceFocus
      fullWidth
    >
      <DialogContent sx={{ p: 0 }}>
        <FrameEffectsDialogContent />
      </DialogContent>
    </DraggableDialog>
  );
});

const FrameEffectsDialogContent = memo(() => {
  const [tab, setTab] = useState(0);

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setTab(newValue);
    },
    [],
  );

  return (
    <>
      <Tabs value={tab} onChange={handleTabChange} variant="fullWidth">
        <Tab label="Fade" />
        <Tab label="Filter" />
      </Tabs>

      <Box sx={{ p: { xs: 1, sm: 2 } }}>
        <MultiHideView index={tab}>
          <FrameFadeEditor />
          <FrameEffectEditor />
        </MultiHideView>
      </Box>
    </>
  );
});

const FrameEffectsDialogTitle = memo(() => {
  const { focusedFrameIndex, isCaseProject } = useMakerStore();

  const title = `Effects for [${isCaseProject ? makerStore.frames[focusedFrameIndex!]?.id : focusedFrameIndex! + 1}]`;

  const handleClose = () => {
    makerStore.dialogs.frameEffects = false;
  };

  return <DraggableDialogDefaultTitle title={title} onClose={handleClose} />;
});
