import { useTheme } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';

const ReCaptcha = ({
  onChange,
}: {
  onChange: ((token: string | null) => void) | undefined;
}) => {
  const theme = useTheme();

  return (
    <ReCAPTCHA
      sitekey={import.meta.env.VITE_GOOGLE_RECAPTCHA_KEY as string}
      onChange={onChange}
      theme={theme.palette.mode}
    />
  );
};

export default ReCaptcha;
