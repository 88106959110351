import { Search } from '@mui/icons-material';
import { TextField, TextFieldProps } from '@mui/material';
import { debounce } from 'lodash';
import { useRef, useState } from 'react';
import { AdornmentButton } from '../maker/ui/AdornmentButton';
import { assetDialogState } from './dialogs/AssetsDialogState';

export const DataGridSearchField = (props: TextFieldProps) => {
  const [search, setSearch] = useState(assetDialogState.search);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);

    debounceonChange(e.target.value);
  };

  const onSearchChange = (text: string) => {
    assetDialogState.search = text;
  };

  const debounceonChange = useRef(
    debounce((text: string) => {
      onSearchChange(text);
    }, 350),
  ).current;

  const onClear = () => {
    setSearch('');

    assetDialogState.search = '';
  };

  return (
    <TextField
      value={search}
      onChange={onChange}
      variant="outlined"
      size="small"
      InputProps={{
        startAdornment: <Search sx={{ mr: '14px' }} color="disabled" />,
        endAdornment: search ? <AdornmentButton onClick={onClear} /> : null,
      }}
      placeholder="Search..."
      {...props}
    />
  );
};
