import { Alert, Box, Button, Paper, Stack, TextField } from '@mui/material';
import { indigo } from '@mui/material/colors';
import { PoseStateDto } from '@web/api/api';
import { ApiClient } from '@web/api/api-client';
import { Checkbox } from '@web/components/common/form/Checkbox';
import Form from '@web/components/common/form/Form';
import { NumberTextField } from '@web/components/common/form/NumberTextField';
import { urlValidation } from '@web/utils/yup';
import { enqueueSnackbar } from 'notistack';
import { memo, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useSnapshot } from 'valtio';
import * as yup from 'yup';
import { PoseExtraUpdateWatcher } from '../CharacterUpdateWatchers';
import { useCharacterEditor } from '../providers/CharacterEditorProvider';
import { PoseExtraDeleteButton } from './PoseDeleteButtons';
import { updateCharacterPoses } from './utils';

export const AnimationPoseEditor = memo(({ index }: { index: number }) => {
  const state = useCharacterEditor();
  const { character } = useSnapshot(state);

  const handleAdd = () => {
    const newPoseAnimation: PoseStateDto = {
      id: -Math.random() * 1000 - 1,
      imageUrl: '',
      nextPoseDelay: 0,
      noSpeakDelay: false,
    };

    state.character.poses[index].poseStates.push(newPoseAnimation);
  };

  const disabled =
    character.poses[index].poseStates.length >= 3 ||
    character.poses[index].poseStates.some((s) => s.id < 0);

  return (
    <Stack spacing={2}>
      <Box>
        <Button
          variant="contained"
          color="info"
          onClick={handleAdd}
          disabled={disabled}
        >
          Add Animation
        </Button>
      </Box>

      <Stack
        spacing={2}
        maxHeight={350}
        overflow="auto"
        sx={{ scrollbarWidth: 'thin' }}
      >
        {character.poses[index].poseStates.map((tick, tickIndex) => (
          <AnimationPoseForm
            key={tick.id}
            poseStateTick={tick}
            index={tickIndex}
            poseIndex={index}
          />
        ))}
      </Stack>
    </Stack>
  );
});

type FormType = {
  id: number;
  imageUrl: string;
  nextPoseDelay: number;
  noSpeakDelay: boolean;
};

const AnimationPoseForm = ({
  poseStateTick,
  index,
  poseIndex,
}: {
  poseStateTick: PoseStateDto;
  index: number;
  poseIndex: number;
}) => {
  const state = useCharacterEditor();

  const schema = useMemo(
    () =>
      yup.object<FormType>().shape({
        imageUrl: urlValidation().required('Image URL is required'),
        nextPoseDelay: yup.number().min(0).required(),
        noSpeakDelay: yup.boolean(),
      }),
    [],
  );

  const onSubmit = async (data: FormType) => {
    const poseStateTick = state.character.poses[poseIndex].poseStates[index];

    const result =
      poseStateTick.id < 0
        ? await ApiClient.assets.poseState.create({
            ...data,
            poseId: state.character.poses[poseIndex].id,
          })
        : await ApiClient.assets.poseState.update(poseStateTick.id, data);

    state.character.poses[poseIndex].poseStates[index] = result.data;

    updateCharacterPoses(state.characterId, state.character.poses);

    enqueueSnackbar('Animation saved', {
      variant: 'success',
      autoHideDuration: 2000,
    });
  };

  const handleCancel = () => {
    state.character.poses[poseIndex].poseStates = state.character.poses[
      poseIndex
    ].poseStates.filter((_, i) => i !== index);
  };

  return (
    <Form schema={schema} onSubmit={onSubmit} defaultValues={poseStateTick}>
      {({ control, register, errors, loading }) => (
        <>
          <Paper
            sx={(theme) => ({
              p: 1,
              mb: 1,
              bgcolor: indigo[500],
              borderRadius: 0,
            })}
          >
            Animation {index + 1}
          </Paper>
          <Stack spacing={2}>
            {errors.root?.message && (
              <Alert severity="error" variant="filled">
                {errors.root.message}
              </Alert>
            )}

            <TextField
              label="Image URL"
              {...register('imageUrl')}
              variant="standard"
              size="small"
              error={!!errors.imageUrl}
              helperText={errors.imageUrl?.message}
              fullWidth
            />

            <NumberTextField
              label="Duration (milliseconds)"
              {...register('nextPoseDelay')}
              variant="standard"
              size="small"
              error={!!errors.nextPoseDelay}
              helperText={errors.nextPoseDelay?.message}
              fullWidth
            />

            <Controller
              control={control}
              name="noSpeakDelay"
              render={({ field }) => (
                <Checkbox
                  value={field.value}
                  onChange={field.onChange}
                  label="Don't delay dialogue"
                />
              )}
            />

            <Stack direction="row" justifyContent="space-between" spacing={2}>
              <Button
                variant="contained"
                color="info"
                type="submit"
                disabled={loading}
              >
                Save Animation
              </Button>

              {poseStateTick.id > 0 && (
                <PoseExtraDeleteButton
                  id={poseStateTick.id}
                  label="animation"
                  poseIndex={poseIndex}
                  propKey="poseStates"
                  apiKey="poseState"
                />
              )}

              {poseStateTick.id < 0 && (
                <Button variant="outlined" onClick={handleCancel}>
                  Cancel
                </Button>
              )}
            </Stack>
          </Stack>

          <PoseExtraUpdateWatcher
            control={control}
            propKey="poseStates"
            index={index}
            poseIndex={poseIndex}
          />
        </>
      )}
    </Form>
  );
};
