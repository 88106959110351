import { CaseFrame, Pair } from '@shared/types';
import { createContext, useContext } from 'react';

export type FrameAndIndexContext = {
  index: number;
  frame: CaseFrame;
  pair?: Pair;
};

const frameAndIndexContext = createContext<FrameAndIndexContext>({
  frame: {} as CaseFrame,
  index: 0,
});

export const FrameAndIndexProvider = frameAndIndexContext.Provider;

export const useFrameAndIndexContext = () => useContext(frameAndIndexContext);
