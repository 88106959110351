import { Character_Alignment, Character_Side } from '@shared/types';
import {
  CreateCharacterDto,
  CreatePopupDto,
  MusicDto,
  SoundDto,
} from '@web/api/api';
import {
  AssetDtoTypeMap,
  CreateAssetDto,
  UpdateAssetDto,
} from '@web/store/assets';
import { AssetType } from '@web/store/assets/state';

export const getDefaultAssetAddValue = (type: AssetType): CreateAssetDto => {
  switch (type) {
    case 'character':
      return {
        side: Character_Side.Defense,
        alignment: Character_Alignment.Default,
        offsetX: 0,
        offsetY: 0,
      } as CreateCharacterDto;
    case 'popup':
      return {
        posX: 0,
        posY: 0,
        resize: 100,
        center: false,
      } as CreatePopupDto;
    case 'music':
      return {
        volume: 75,
      } as MusicDto;
    case 'sound':
      return {
        volume: 75,
      } as SoundDto;
    default:
      return {} as CreateAssetDto;
  }
};

export const getDefaultAssetUpdateValue = <T extends AssetType>(
  type: T,
  data: AssetDtoTypeMap[T],
): UpdateAssetDto => {
  switch (type) {
    // case 'character': {
    //   const character = data as CharacterDto;

    //   return {
    //     name: character.name,
    //     side: character.side,
    //     alignment: character.alignment,
    //     offsetX: character.offsetX,
    //     offsetY: character.offsetY,
    //     backgroundId: character.backgroundId,
    //     blipUrl: character.blipUrl,
    //     iconUrl: character.iconUrl,
    //     galleryImageUrl: character.galleryImageUrl,
    //     galleryAJImageUrl: character.galleryAJImageUrl,
    //     limitWidth: character.limitWidth,
    //     nameplate: character.nameplate,
    //   } as UpdateCharacterDto;
    // }

    default:
      return data as UpdateAssetDto;
  }
};
