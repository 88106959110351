import { useAuth } from '@web/providers/auth/AuthProvider';
import React from 'react';
import { Navigate } from 'react-router-dom';

const AdminRoute = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAuth();

  if (!user || !user.admin) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default AdminRoute;
