import { Button, ButtonProps, styled } from '@mui/material';

type FrameToolbarButtonProps = {
  children: React.ReactNode;
  width: number;
  height: number;
  bl?: boolean;
  br?: boolean;
} & ButtonProps;

const StyledFrameToolbarButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== 'bl' &&
    prop !== 'br' &&
    prop !== 'width' &&
    prop !== 'height' &&
    prop !== 'sx',
})<FrameToolbarButtonProps>(({ width, height, bl, br }) => ({
  borderRadius: 0,
  minWidth: width,
  minHeight: height,
  paddingBlock: 0,
  ...(bl && { borderLeft: `1px solid rgba(255, 255, 255, 0.12)` }),
  ...(br && { borderRight: `1px solid rgba(255, 255, 255, 0.12)` }),
}));

export const FrameToolbarButton = ({
  children,
  width,
  height,
  bl,
  br,
  sx,
  ...props
}: FrameToolbarButtonProps) => {
  return (
    <StyledFrameToolbarButton
      size="small"
      color="inherit"
      width={width}
      height={height}
      bl={bl}
      br={br}
      sx={sx}
      {...props}
    >
      {children}
    </StyledFrameToolbarButton>
  );
};
