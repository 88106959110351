import { CSSProperties, useMemo } from 'react';
import { usePlayerDialogueBoxContext } from '../providers/PlayerDialogueBoxContext';
import { usePlayer } from '../providers/PlayerProvider';
import {
  NameplateContainer,
  NameplateImage,
  NameplateTextContainer,
} from '../ui/Nameplate';
import { NameplateTextProps } from './types';

export type NameplateProps = NameplateTextProps & {
  url: string;
  fontUrl: string;
  replaceBox?: boolean;
};

export const Nameplate = ({
  fontSize,
  url,
  fontUrl,
  replaceBox,
  fontFamily,
  top,
  right,
  letterSpacing,
  style,
}: NameplateProps) => {
  const {
    playerUi: {
      state: { size },
    },
  } = usePlayer();

  const { nameplate, showNameplate } = usePlayerDialogueBoxContext();

  const letterSpacingSize = letterSpacing ? letterSpacing * size : undefined;

  const nameplateContainerStyle = useMemo<CSSProperties>(
    () => ({
      fontFamily: fontFamily,
      top: `${top}%`,
      right: `${right}%`,
      fontSize: `${fontSize * size}px`,
      ...(letterSpacingSize && { letterSpacing: `${letterSpacingSize}em` }),
      ...style,
    }),
    [fontFamily, fontSize, letterSpacingSize, right, size, style, top],
  );

  return (
    <NameplateContainer sx={{ display: !showNameplate ? 'none' : 'inherit' }}>
      <NameplateImage
        alt="Nameplate"
        src={url}
        sx={{ visibility: replaceBox ? 'hidden' : 'visible' }}
      />

      <NameplateTextContainer style={nameplateContainerStyle} dir="auto">
        {nameplate}
      </NameplateTextContainer>
    </NameplateContainer>
  );
};
