import { SceneTargets } from '@shared/types/scene-targets';
import easings from '@web/data/easings';
import { useEffect, useState } from 'react';
import { useSpring, useSpringRef } from 'react-spring';
import { usePlayer } from '../providers/PlayerProvider';
import { ZIndex } from '../utils/constants';
import { usePlayerResetRegister } from './usePlayerResetRegister';
import { usePlayerSaveLoadRegister } from './usePlayerSaveLoadRegister';

type FadeType = {
  fadeType?: 'fadeIn' | 'fadeOut';
  duration: number;
  color: string;
  easing?: (t: number) => number;
  zIndex: number;
};

export const useColorFade = () => {
  const [fade, setFade] = useState<FadeType>();
  const api = useSpringRef();

  const fadeAnimation = useSpring({
    ref: api,
    from: {
      opacity: 0,
    },
  });

  const {
    frame: { frame, previousFrame },
    step,
    state: { skipping },
    actions: { nextStep },
    timeline,
  } = usePlayer();

  usePlayerSaveLoadRegister({
    name: 'colorFade',
    onSave: () => ({ fade, opacity: fadeAnimation.opacity.get() }),
    onLoad: (data) => {
      setFade(data.fade);
      api.start({
        from: {
          opacity: data.opacity,
        },
        to: {
          opacity: data.opacity,
        },
        immediate: true,
      });
    },
  });

  usePlayerResetRegister({
    name: 'colorFade',
    onReset: () => {
      setFade(undefined);

      api.start({
        from: {
          opacity: 0,
        },
        to: {
          opacity: 0,
        },
        immediate: true,
      });
    },
  });

  useEffect(() => {
    if (!frame?.fade || frame?.fade?.type === 'fadeOutIn') return;

    const fadeType = frame?.fade?.type;
    const duration = frame?.fade?.duration || 1500;
    const color = frame?.fade?.color ?? '#000000';
    const target = frame?.fade?.target || 'background';

    const easing = frame?.fade?.easing
      ? easings.find((f) => f.id === frame.fade!.easing)?.value
      : (t: number) => t;

    const zIndex =
      frame?.fade?.target === 'background'
        ? ZIndex.Background
        : frame?.fade?.target === 'scene'
          ? ZIndex.Desk
          : ZIndex.DialogueBox;

    if (target !== 'characters') {
      setFade({
        fadeType,
        duration: duration,
        color: color,
        easing: easing,
        zIndex: zIndex,
      });
    }
  }, [frame]);

  useEffect(() => {
    if (step !== 'fade_out' && step !== 'fade_in') return;

    if (
      !previousFrame?.fade ||
      previousFrame?.fade?.type !== 'fadeOutIn' ||
      frame?.fade
    ) {
      return;
    }

    const duration = !skipping
      ? (previousFrame?.fade?.duration || 1500) / 2
      : 0;
    const color = previousFrame?.fade?.color ?? '#000000';
    const target = frame?.fade?.target || 'background';

    const easing = previousFrame?.fade?.easing
      ? easings.find((f) => f.id === previousFrame.fade!.easing)?.value
      : (t: number) => t;

    const zIndex =
      previousFrame?.fade?.target === 'background'
        ? ZIndex.Background
        : previousFrame?.fade?.target === 'scene'
          ? ZIndex.Desk
          : ZIndex.DialogueBox;

    if (target !== 'characters') {
      setFade({
        fadeType: step === 'fade_out' ? 'fadeOut' : 'fadeIn',
        duration: duration / 2,
        color: color,
        easing: easing,
        zIndex: zIndex,
      });
    }

    timeline.addEvent(() => {
      if (step === 'fade_out' || step === 'fade_in') {
        nextStep();
      }
    }, duration / 2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    if (!fade?.fadeType) return;

    api.start({
      from: {
        opacity: fade.fadeType === 'fadeIn' ? 1 : 0,
      },
      to: {
        opacity: fade.fadeType === 'fadeIn' ? 0 : 1,
      },
      config: {
        duration: !skipping ? fade.duration : 0,
        easing: fade.easing,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fade]);

  useEffect(() => {
    if (skipping) {
      api.set({
        opacity: fade?.fadeType === 'fadeIn' ? 0 : 1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipping, fade]);

  return {
    fade,
    fadeAnimation,
  };
};

export const useCharactersFade = (target: SceneTargets) => {
  const [fade, setFade] = useState<Omit<FadeType, 'color' | 'zIndex'>>();
  const api = useSpringRef();

  const fadeAnimation = useSpring({
    ref: api,
    from: {
      opacity: 1,
    },
  });

  const {
    frame: { frame },
    state: { skipping },
  } = usePlayer();

  usePlayerSaveLoadRegister({
    name: `charactersFade-${target}`,
    onSave: () => ({ fade, opacity: fadeAnimation.opacity.get() }),
    onLoad: (data) => {
      setFade(data.fade);

      api.start({
        from: {
          opacity: data.opacity,
        },
        to: {
          opacity: data.opacity,
        },
        immediate: true,
      });
    },
  });

  usePlayerResetRegister({
    name: 'charactersFade',
    onReset: () => {
      setFade(undefined);

      api.start({
        from: {
          opacity: 1,
        },
        to: {
          opacity: 1,
        },
        immediate: true,
      });
    },
  });

  const isValidTarget =
    frame?.fade?.characters && (frame.fade.characters & target) === target;

  useEffect(() => {
    if (!frame?.fade || frame?.fade?.type === 'fadeOutIn' || !isValidTarget)
      return;

    const fadeType = frame?.fade?.type;
    const duration = frame?.fade?.duration || 1500;
    const target = frame?.fade?.target || 'background';

    const easing = frame?.fade?.easing
      ? easings.find((f) => f.id === frame.fade!.easing)?.value
      : (t: number) => t;

    if (target === 'characters') {
      setFade({
        fadeType,
        duration: duration,
        easing: easing,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frame]);

  useEffect(() => {
    if (!fade?.fadeType || !isValidTarget) return;

    api.start({
      from: {
        opacity: fade.fadeType === 'fadeIn' ? 0 : 1,
      },
      to: {
        opacity: fade.fadeType === 'fadeIn' ? 1 : 0,
      },
      config: {
        duration: !skipping ? fade.duration : 0,
        easing: fade.easing,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fade]);

  useEffect(() => {
    if (skipping && isValidTarget) {
      api.set({
        opacity: fade?.fadeType === 'fadeIn' ? 1 : 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipping, fade]);

  return {
    fade,
    fadeAnimation,
  };
};
