import { Pagination as MuiPagination, PaginationItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';

type PaginationProps = {
  count: number;
  page: number;
  onChange?: (event: React.ChangeEvent<unknown>, value: number) => void;
  parentRef?: React.RefObject<HTMLDivElement>;
};

export const Pagination = ({ count, page, onChange }: PaginationProps) => {
  const theme = useTheme();
  const isXSmall = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));

  const siblingCount = isXSmall ? 0 : isSmall ? 1 : isMedium ? 1 : 3;
  const boundaryCount = isXSmall ? 1 : isSmall ? 1 : isMedium ? 1 : 2;

  return (
    <MuiPagination
      shape="rounded"
      color="primary"
      count={count}
      page={page}
      onChange={onChange}
      siblingCount={siblingCount}
      boundaryCount={boundaryCount}
      showFirstButton={!isXSmall}
      showLastButton={!isXSmall}
      sx={{ '& .MuiPagination-ul': { flexWrap: 'nowrap' } }}
      renderItem={(item) =>
        isXSmall && item.type.includes('ellipsis') ? null : (
          <PaginationItem {...item} />
        )
      }
    />
  );
};
